import React, { Component } from "react";
import axios from "axios";
import Constants from "../Variables/Constant";
import { Link, Navigate } from "react-router-dom";
import init from "../../helpers/WindowToken";
import {
  Button,
  Grid,
  Snackbar,
  TextField,
  Typography,
  Container,
} from "@mui/material";

export default class Setting extends Component {
  state = {
    loading: false,
    physicalInspection: "",
    id: "",
    notify: false,
    message: "",
    error: false,
    notify: false,
    post:false,
  };
  componentDidMount() {
    // axios.get(Constants.getUrls.getSetting).then((resp)=>{

    // })

    this.getId();
  }
  getSetting = () => {
    if (init() === "success") {
      let auth = localStorage.getItem("accessToken");
      axios
        .get(Constants.getUrls.getSetting + "/" + this.state.id, {
          headers: { Authorization: `Bearer ${auth}` },
        })
        .then((resp) => {
          // console.log(resp)
          if (resp.data.status === "success") {
            this.setState({
              physicalInspection: resp.data.setting.physical_inspection_amount,
            });
          } else {
            this.setState({
              notify: true,
              message: "some error occured=" + resp.data.message,
            });
            setTimeout(() => {
              this.setState({
                notify: false,
                message: "",
              });
            }, 1500);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  getId = () => {
    axios.get(Constants.getUrls.getSetting).then((resp) => {
      if (resp.data.status === "success") {
      if(resp.data.setting.docs.length>0){
        
      this.setState({post:false,
        id: resp.data.setting.docs[0]._id,
        physicalInspection: resp.data.setting.docs[0].physical_inspection_amount,
      });
    }else{
this.setState({
  post:true
})
    }
  }else {
    this.setState({
      notify: true,
      message: "some error occured=" + resp.data.message,
    });
    setTimeout(() => {
      this.setState({
        notify: false,
        message: "",
      });
    }, 1500);
  }}
  ).catch((err)=>{
    console.log(err);
  });
   
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ loading: true });
    let auth = localStorage.getItem("accessToken");
      let fd = {
        physical_inspection_amount: this.state.physicalInspection,
      };
    if(this.state.post){
      if (init() === "success") {
        let auth = localStorage.getItem("accessToken");
        let fd = {
          physical_inspection_amount: this.state.physicalInspection,
        };}
        axios.post(Constants.getUrls.getSetting,fd, {
          headers: {
            // "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth}`,
          }}).then((resp)=>{
            this.setState({
              notify: true,
              message: resp.data.message,
              loading: false,
            });
            setTimeout(() => {
              this.setState({
                notify: false,
                message: "",
              });
              this.getId();
            }, 1500);

        })
    }
else{
    if (init() === "success") {
      
      axios
        .put(Constants.putUrls.updateSetting + this.state.id, fd, {
          headers: {
            // "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth}`,
          },
        })
        .then((resp) => {
          // console.log(resp);
          if (resp.data.message === "Setting does not exist.") {
            this.setState({
              notify: true,
              message: "Id not found please try again",
              loading: false,
            });
            setTimeout(() => {
              this.setState({
                notify: false,
                message: "",
              });
              this.getId();
            }, 1500);
          } else if (resp.data.message === "Updated Successfully") {
            this.setState({
              notify: true,
              loading: false,
              message: "Updated Successfully",
            });
            setTimeout(() => {
              this.setState({
                notify: false,
                message: "",
              });
              this.getId();
            }, 1500);
          } else {
            this.setState({
              notify: true,
              loading: false,
              message: "error=" + this.state.message,
            });
            setTimeout(() => {
              this.setState({
                notify: false,
                message: "",
              });
            }, 1500);
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            notify: true,
            message: "failed to update check console for error",
          });
          setTimeout(() => {
            this.setState({
              notify: false,
              message: "",
            });
          }, 1500);
        });
    }}
  };

  render() {
    return (
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6"> Settings </Typography>
          </Grid>
        </Grid>
        {this.state.notify ? (
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={true}
            autoHideDuration={5000}
            color="green"
            message={this.state.message}
          ></Snackbar>
        ) : null}
        <div
          style={{
            background: "#5b5757",
            padding: "20px",
            borderRadius: "10px",
            // color: rgba(0, 0, 0, 0.87);
            width: "100%",
            border: "0",
            display: "flex",
            position: "relative",
            fontSize: ".875rem",
            minWidth: "0",
            wordWrap: " break-word",
            /* background: #FFF; */
            boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
            marginTop: "30px",
            borderRadius: "6px",
            marginBottom: "30px",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <form onSubmit={this.handleSubmit}>
            <Grid container sm={12} spacing={2}>
              <Grid item sm={6}>
                <TextField
                  name="physicalInspection"
                  id="outlined-basic"
                  label="Physical Inspection Amount"
                  // multiline={true}
                  //     maxRows={4}
                  size="medium"
                  variant="outlined"
                  required={true}
                  type="number"
                  InputProps={{
        inputProps: { 
            min:1
        }
    }}
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  value={this.state.physicalInspection}
                />
              </Grid>
            </Grid>

            <Button
              variant="contained"
              type="submit"
              size="medium"
              color="primary"
              style={{
                marginRight: "20px",

                marginTop: "35px",
              }}
            >
              {this.state.post?this.state.loading ? "Adding.." : "Add ":this.state.loading ? "Updating.." : "Update "}
            </Button>
          </form>
        </div>
      </Container>
    );
  }
}
