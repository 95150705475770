import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Lottie from "react-lottie";
import useMediaQuery from "@mui/material/useMediaQuery";
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import offlineTemp from "./Assets/network-error.json";
import { Offline, Online } from "react-detect-offline";
import ForgotPassword from "./App/Auth/ForgotPassword";
import ResetPassword from "./App/Auth/ResetPassword";
import NewPassword from "./App/Auth/NewPassword";
import Login from "./App/Auth/Login";
import { isAuth, authToken } from "./helpers/AuthHelpers";
import Main from "./App/Main/Main";
import "./App.css";

var time = new Date().getHours();
const _theme = localStorage.getItem("theme")
  ? localStorage.getItem("theme")
  : time > 18
  ? "dark"
  : "light";

function App() {
  const prefersDarkMode = useMediaQuery(
    "(prefers-color-scheme:" + _theme + ")"
  );
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? "dark" : "light",
        },
      }),
    [prefersDarkMode]
  );

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: offlineTemp,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <ThemeProvider theme={theme}>
      {/* <Online> */}
      <CssBaseline />

      <BrowserRouter>
        <Routes>
          {isAuth ? (
            <Route
              path="/login"
              element={<Navigate replace to="/dashboard" />}
            />
          ) : (
            <>
              <Route exact path="/" element={<Login />}></Route>
              <Route path="/login" element={<Login />} />
            </>
          )}
          <Route path="" element={<Navigate replace to="/login" />}></Route>
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/newpassword" element={<NewPassword />} />
        </Routes>
        <Main />
      </BrowserRouter>

      {/* </Online>
      <Offline>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: window.innerHeight,
          }}
        >
          <Lottie
            options={defaultOptions}
            autoplay
            height={"auto"}
            width={"50%"}
          />
        </div>
      </Offline> */}
    </ThemeProvider>
  );
}

export default App;
