import React, { Component } from "react";
import axios from "axios";
import Constants from "../App/Variables/Constant";
import { Link, Navigate } from "react-router-dom";
import init from "../helpers/WindowToken";
import {
  Button,
  Container,
  Grid,
  Snackbar,
  TextField,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

export default class AddAgent extends Component {
  state = {
    loading: false,
    name: "",
    email: "",
    mob_no: "",
    city: "",
    password: "",
    confirm_password: "",
    emailErr: false,
    mobErr: false,
    redirect: false,
    notification: false,
    message: "",
    redirect: false,
  };
  handleChange = (e) => {
    if (e.target.name === "email") {
      this.setState({
        emailErr: false,
      });
    }
    if (e.target.name === "mob_no") {
      this.setState({
        mobErr: false,
      });
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();

    // if(this.state.image===""){
    //   window.location.reload();
    // } else{
    this.setState({ loading: true });

    if (init() === "success") {
      let fd = {
        name: this.state.name,
        email: this.state.email,
        mob_no: this.state.mob_no,
        city: this.state.city,
        password: this.state.password,
        confirm_password: this.state.confirm_password,
      };
      let auth = localStorage.getItem("accessToken");
      axios
        .post(Constants.postUrls.postAgent, fd, {
          headers: { Authorization: `Bearer ${auth}` },
        })
        .then((resp) => {
          // console.log(resp.data.message);
          this.setState({
            loading: false,
            message: resp.data.message,
            notification: true,
          });
          if (resp.data.message === undefined || resp.data.message === null) {
            // console.log(resp);
            if (resp.data.name === "MongoError") {
              if (resp.data.keyValue.mob_no) {
                this.setState({
                  message: "Mob. No. already exist",
                  mobErr: true,
                });
              } else if (resp.data.keyValue.email) {
                this.setState({
                  message: "Email already exist",
                  emailErr: true,
                });
              }
            } else {
              this.setState({
                message: "unknown error",
              });
            }
          }
          setTimeout(() => {
            if (resp.data.message === "added successfully") {
              this.setState({
                redirect: true,
              });
            }

            this.setState({
              // title:"",
              // image:"",
              // no_of_clicks:"",
              // url:"",
              message: "",
              notification: false,
            });

            // this.emptyFile.current.value = "";
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
          // window.location.reload();
          this.setState({
            notification: true,
            message: "error occured check console",
          });
          setTimeout(() => {
            this.setState({
              // title:"",
              //   image:"",
              //   no_of_clicks:"",
              //   url:"",
              notification: false,
              message: "",
              loading: false,
            });
            // this.emptyFile.current.value = "";
          }, 2000);
        });

      // }
    }
  };

  render() {
    return (
      <Container maxWidth="xl">
        {this.state.redirect && <Navigate to="/agent" replace={true} />}

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6"> Agent </Typography>
          </Grid>
        </Grid>
        {this.state.notification ? (
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={true}
            autoHideDuration={5000}
            color="green"
            message={this.state.message}
          ></Snackbar>
        ) : null}
        <div
          style={{
            background: "#5b5757",
            padding: "20px",
            borderRadius: "10px",
            // color: rgba(0, 0, 0, 0.87);
            width: "100%",
            border: "0",
            display: "flex",
            position: "relative",
            fontSize: ".875rem",
            minWidth: "0",
            wordWrap: " break-word",
            /* background: #FFF; */
            boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
            marginTop: "30px",
            borderRadius: "6px",
            marginBottom: "30px",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <div
            style={{
              background:
                "linear-gradient(60deg, rgb(128 115 115), rgb(255 250 250)) rgb(153, 153, 153))",
              boxShadow:
                " rgb(0 0 0 / 14%) 0px 4px 20px 0px, rgb(128 120 120 1/ 40%) 0px 7px 10px -5px",
              float: "left",
              padding: "15px",
              marginTop: "-43px",
              marginRight: "15px",
              borderRadius: "3px",
              backgroundColor: "#999",
              width: "6%",
              height: "60px",
              marginBottom: "25px",
              textAlign: "center",
            }}
          >
            <EditIcon />
          </div>
          <form onSubmit={this.handleSubmit}>
            <Grid container sm={12} spacing={2}>
              <Grid item sm={4}>
                <TextField
                  name="name"
                  id="outlined-basic"
                  label="Name"
                  size="medium"
                  variant="outlined"
                  //   multiline={true}
                  //   maxRows={4}
                  required={true}
                  type="text"
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  value={this.state.name}
                />
              </Grid>
              <Grid item sm={4}>
                <TextField
                  name="email"
                  error={this.state.emailErr}
                  id={this.state.emailErr ? "outlined-error" : "outlined-basic"}
                  label={this.state.emailErr ? "Error" : "Email"}
                  size="medium"
                  variant="outlined"
                  //   multiline={true}
                  //   maxRows={4}
                  required={true}
                  type="email"
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  value={this.state.email}
                />
              </Grid>
              <Grid item sm={4}>
                <TextField
                  name="mob_no"
                  error={this.state.mobErr}
                  id={this.state.mobErr ? "outlined-error" : "outlined-basic"}
                  label={this.state.mobErr ? "Error" : "Mob. No."}
                  size="medium"
                  variant="outlined"
                  //   multiline={true}
                  //   maxRows={4}
                  required={true}
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 1000000000,
                    },
                  }}
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  value={this.state.mob_no}
                />
              </Grid>

              <Grid item sm={4}>
                <TextField
                  name="city"
                  id="outlined-basic"
                  label="City"
                  size="medium"
                  variant="outlined"
                  // multiline={true}
                  // maxRows={4}
                  required={true}
                  type="text"
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  value={this.state.city}
                />
              </Grid>
              <Grid item sm={4}>
                <TextField
                  name="password"
                  id="outlined-basic"
                  label="Password"
                  size="medium"
                  variant="outlined"
                  // multiline={true}
                  // maxRows={4}
                  required={true}
                  type="password"
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  value={this.state.password}
                />
              </Grid>
              <Grid item sm={4}>
                <TextField
                  name="confirm_password"
                  id="outlined-basic"
                  label="Confirm Password"
                  size="medium"
                  variant="outlined"
                  // multiline={true}
                  // maxRows={4}
                  required={true}
                  type="password"
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  value={this.state.confirm_password}
                />
              </Grid>
            </Grid>
            <Button
              variant="contained"
              type="submit"
              size="medium"
              color="primary"
              style={{
                marginRight: "20px",

                marginTop: "35px",
              }}
            >
              {this.state.loading ? "Adding.." : "Add "}
            </Button>
            <Link
              to={{
                pathname: "/agent",
              }}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                type="submit"
                color="secondary"
                style={{ marginRight: "20px", marginTop: "35px" }}
              >
                Cancel
              </Button>
            </Link>
          </form>
        </div>
      </Container>
    );
  }
}
