import React, { Component } from 'react';
import {
    Button,
    Container,
    FormControl,
    Grid,
    Select,
    InputLabel,
    Paper,
    Snackbar,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    TextField,
    Typography,
    ButtonGroup,
    OutlinedInput,
    Pagination
  } from "@mui/material";
  import axios from "axios";
  import { Link } from "react-router-dom";
  import init from "../../helpers/WindowToken";
  import Constants from "../Variables/Constant.jsx";
  import Modal from "react-awesome-modal";
  import SearchIcon from "@mui/icons-material/Search";


export default class ContactUs extends Component {
    state = {
        open: false,
        getContactUs: [],
        data: [],
        notifyStatus: false,
        notify: false,
        searchedValue: "",
        visible: false,
        delLoading: false,
        rowId: 0,
        status: "",
        active: 1,
        perPage: "10",
        limit:10,
        searchpage:1,
        search:false,
        currentPage: 1,
        page: 1,
        prevPage: 0,
        nextPage: 0,
        lastPage: 0,
        lastPageView: false,
        firstPageView: false,
        total: 0,
        from: 1,
        to: 0,
        fromInc: 1,
        toInc: 5,
        btnIdArray: [],
        filteredBtnIdArray: [],
        msg: "",
        totalPage:1,
        searchTotalPage:1,
        pageinfo:1,
      };
      handleClose = () => {
        this.setState({ open: false });
      };
    
      componentDidMount() {
        this.getContactUs();
      }
      getContactUs = () => {
        axios
          .get(
            Constants.getUrls.getAllContactUs +
              "?page=" +
              this.state.page +
              "&sort=true&limit=" +
              this.state.limit
          )
          .then((resp) => {
            // console.log(resp.data.contactUs.docs);
    
            this.setState({
              fromInc: 1,
              active: 1,
              toInc: 5,
              getContactUs: resp.data.contactUs.docs,
              total: resp.data.contactUs.totalDocs,
              nextPage: resp.data.contactUs.hasNextPage,
              prevPage: resp.data.contactUs.hasPrevPage,
              limit: resp.data.contactUs.limit,
              pagingCounter: resp.data.contactUs.pagingCounter,
              totalPage:resp.data.contactUs.totalPages,
              pageinfo:this.state.page,
              // PAGINATION
    
              from: 1,
              // to: resp.data.data.length,
            });
          });
      };
      handleRows = (value) => {
        this.setState(
          {
            limit: value,
          },
          () => {
            if(this.state.search){
              this.handleSearch();
            }else
            {this.getContactUs();}
          }
        );
        // this.getBanner();
      };
      // PAGINATION BUTTONS
    
      handleNext = () => {
        if(this.state.search){
          if (this.state.nextPage === true) {
            // console.log(this.state.nextPage)
      
            this.setState(
              {
                searchpage: Number(this.state.searchpage) + 1,
                // to: this.state.to + this.state.limit,
              },
              () => {
                this.handleSearch();
              }
            );
            // console.log(this.state.page)
            // this.getAdd();
          }
    
        }else{
        if (this.state.nextPage === true) {
          // console.log(this.state.nextPage)
    
          this.setState(
            {
              page: Number(this.state.page) + 1,
              // to: this.state.to + this.state.limit,
            },
            () => {
              this.getContactUs();
            }
          );
          // console.log(this.state.page)
          // this.getBrand();
        }}
      };
    
      handlePrev = () => {
        if(this.state.search){
          if (this.state.prevPage === true)
          this.setState(
            {
              searchpage: Number(this.state.searchpage) - 1,
            },
    
            () => {
              this.handleSearch();
            }
          );
        }
    else{    if (this.state.prevPage === true)
          this.setState(
            {
              page: Number(this.state.page) - 1,
            },
    
            () => {
              this.getContactUs();
            }
          );
        // this.getContactUs);
      }
      };
      handleChangePage=(e)=>{
        this.setState({
          pageinfo:e.target.value
        })
      }
      handlePages=(e,value)=>{
        if(this.state.search){
          this.setState({
            searchpage:value
          },()=>{
            this.handleSearch()
          })
        } else{
          this.setState({
            page:value
          },()=>{
            this.getContactUs()
          })
        }
      }
      handlePage=(e)=>{
        e.preventDefault();
        if(this.state.pageinfo){
        if (this.state.search) {
          if (this.state.pageinfo>0 && this.state.pageinfo<=this.state.searchTotalPage) {
            // console.log(this.state.nextPage)
    
            this.setState(
              {
                searchpage: this.state.pageinfo,
                // to: this.state.to + this.state.limit,
              },
              () => {
                this.handleSearch();
              }
            );
            // console.log(this.state.page)
            // this.getAdd();
          }
        } else {
          if (this.state.pageinfo>0 && this.state.pageinfo<=this.state.totalPage) {
            // console.log(this.state.nextPage)
    
            this.setState(
              {
                page: this.state.pageinfo,
                // to: this.state.to + this.state.limit,
              },
              () => {
                this.getContactUs();
              }
            );
            // console.log(this.state.page)
            // this.getCategory();
          }
        }}
    
      }
      handleModal = (contactUs) => {
        // console.log(contactus);
        this.setState(
          {
            rowId: contactUs._id,
            visible: true,
          }
        //   ,
        //   () => {
        //     console.log(this.state.contactUs);
        //   }
        );
      };
      
      handleDelete = () => {
        this.setState({
          delLoading: true,
        });
        // console.log(Constants.deleteUrls.deleteCategory + "/" + this.state.rowId);
    
        if (init() === "success") {
          // this.setState({ delLoading: true });
          let auth = localStorage.getItem("accessToken");
          axios
            .delete(Constants.deleteUrls.deleteContactUs + "/" + this.state.rowId, {
              headers: { Authorization: `Bearer ${auth}` },
            })
            .then((res) => {
            //   console.log(res);
    
              this.setState({
                notify: true,
                delLoading: false,
                msg: res.data.message,
              });
              setTimeout(() => {
                this.setState({ notify: false, visible: false }, () => {
                  if(this.state.search){this.handleSearch();}else {this.getContactUs();}
                });
              }, 2000);
            })
            .catch((err) => console.log(err));
        }
      };
      handleChange = (event) => {
        if (event.target.value.length<1) {
          this.setState({
            searchedValue: "",
            search:false,
           
            
    
    
          });
          // this.defaultValues();
          this.getContactUs();
        } else {
          this.setState({
            searchedValue: event.target.value,
          });
        }
      };
      handleSubmit=(e)=>{
        e.preventDefault();
        this.setState({
          searchpage:1
        },
        () => {
          this.handleSearch();
        }
        )
    
      }
      handleSearch = (event) => {
        if(event){
          this.setState({
            searchedValue:event.target.value
          },()=>{
            this.handleSearch();
          })
         }
         if (this.state.searchedValue < 1) {
          this.setState({
            search: false,
          });
          // this.defaultValues();
          this.getContactUs();
        } else {
        if (this.state.searchedValue.length >= 2) {
          this.setState({ 
          search:true,
        page:1});
          axios
            .get(
              Constants.getUrls.getAllContactUs +
                "?page=" +
                this.state.searchpage +
                "&limit=" +
                this.state.limit +
                "&sort=true&q=" +
               this.state.searchedValue
              // this.state.name
            )
            .then((resp) => {
              console.log(resp);
              // let btnIdArray = [];
              // for (let i = 1; i <= resp.data.pageCount; i++) {
              //   btnIdArray.push(i);
              // }
             
            this.setState({
                fromInc: 1,
                active: 1,
                toInc: 5,
                getContactUs: resp.data.contactUs.docs,
                total: resp.data.contactUs.totalDocs,
                nextPage: resp.data.contactUs.hasNextPage,
                prevPage: resp.data.contactUs.hasPrevPage,
                limit: resp.data.contactUs.limit,
                pagingCounter: resp.data.contactUs.pagingCounter,
                searchTotalPage:resp.data.contactUs.totalPages,
                pageinfo:this.state.searchpage,
                // PAGINATION
      
                from: 1,
              // to: resp.data.data.length
            });
          
            });
        }
        }
        };
  render() {
    return (
        <Container maxwidth="xl">
        {this.state.notify ? (
          <Snackbar
            message={this.state.msg}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            autoHideDuration={6000}
            color="white"
            open="true"
          ></Snackbar>
        ) : null}
        <Grid container style={{ margin: "10px 0 10px" }} alignItems="baseline">
          <Grid item xs={6}>
            <Typography variant="h6">
              {/* {this.props.title} */}
              Contact Us
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent="flex-end">
           
                <TextField
                  id="outlined-basic"
                  label={"Search "}
                  size="small"
                  variant="outlined"
                  onChange={this.handleSearch}
                  sx={{marginRight:"5px"}}
                />
             
              
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Paper elevation={1}>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                  <TableCell >Sr.No.</TableCell>
                    <TableCell align="right">Name</TableCell>
                    <TableCell align="right">Email</TableCell>
                    <TableCell align="right">Mobile No.</TableCell>

                    <TableCell align="right">Subject</TableCell>
                    <TableCell align="right">Description</TableCell>
                    <TableCell align="right">Created Date</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.getContactUs.length > 0 ? (
                    this.state.getContactUs.map((contactUs,index) => (
                      <TableRow key={contactUs._id}>
                      <TableCell >{this.state.search?Number((this.state.searchpage-1)*this.state.limit)+Number(index+1):Number((this.state.page-1)*this.state.limit)+Number(index+1)}</TableCell>
                        <TableCell align="right">{contactUs.name}</TableCell>
                        <TableCell align="right">{contactUs.email}</TableCell>
                        <TableCell align="right">{contactUs.mob_no}</TableCell>
                        <TableCell align="right">{contactUs.subject}</TableCell>
                        <TableCell align="right">{contactUs.description}</TableCell>
                        <TableCell align="right">{contactUs.createdAt.substring(0,10)}</TableCell>
                        <TableCell align="right">
                          
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => this.handleModal(contactUs)}
                          >
                            Delete
                          </Button>
                        </TableCell>
                        
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Nothing to show here
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter></TableFooter>
              </Table>
              <TableRow
                style={{ display: "flex", justifyContent: "space-evenly" }}
              >
                {/* <div>
                <p>
                  Showing {this.state.pagingCounter} to {this.state.limit} of{" "}
                  {this.state.total} Entries
                </p>
              </div> */}

                {/* Rows PerPage START */}

                <div
                  style={{
                    display: "flex",
                    alignItems: "right",
                    justifyContent: "space-between",
                    width: "200px",
                    marginTop: "10px",
                    marginLeft:"150px",
                  }}
                >
                  <p>Rows Per Page</p>

                  <FormControl
                    className="selectMerchants"
                    style={{ marginRight: "20px", marginTop: "-5px" }}
                  >
                    <InputLabel htmlFor="age-native-simple"></InputLabel>
                    <Select
                      native
                      defaultValue={10}
                      // value={state.age}
                      onChange={(e) => this.handleRows(e.target.value)}
                      inputProps={{
                        name: "Merchants",
                        id: "age-native-simple",
                      }}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                    </Select>
                  </FormControl>
                </div>
                {/* Rows PerPage END */}
                <Pagination sx={{marginTop:"auto",marginBottom:"auto"}} count={this.state.search?this.state.searchTotalPage:this.state.totalPage} color="secondary" page={this.state.search?this.state.searchpage:this.state.page} onChange={this.handlePages} />
              </TableRow>
            </TableContainer>
          </Paper>
        </Grid>

        <Modal
          visible={this.state.visible}
          width="450"
          height="100"
          effect="fadeInUp"
          onClickAway={() => this.setState({ visible: false })}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <h4
              style={{ color: "black", textAlign: "center", display: "block" }}
            >
              Are you sure about that???
            </h4>

            <br></br>
            <ButtonGroup>
              <Button
                className="btn"
                color="secondary"
                variant="contained"
                style={{ margin: "20px" }}
                onClick={() => {
                  this.handleDelete();
                }}
              >
                {this.state.delLoading ? "Deleting..." : "Yes"}{" "}
              </Button>
              <Button
                className="btn"
                color="primary"
                variant="contained"
                style={{ margin: "20px", marginLeft: "4px" }}
                onClick={() => {
                  this.setState({ visible: false });
                }}
              >
                NO
              </Button>
            </ButtonGroup>
          </Grid>
        </Modal>
      </Container>
    )
  }
}
