import React, { Component } from "react";
import axios from "axios";
import Constants from "../App/Variables/Constant";
import { Link, Navigate } from "react-router-dom";
import init from "../helpers/WindowToken";
import {
  Button,
  Container,
  Grid,
  Snackbar,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Resizer from "react-image-file-resizer";
import DoneIcon from "@mui/icons-material/Done";
// import SunEditor from "suneditor-react";
// import "suneditor/dist/css/suneditor.min.css";
let fd = new FormData();

export default class AddTestimonial extends Component {
  constructor(props) {
    super(props);
    this.emptyFile = React.createRef();
  }

  state = {
    loading: false,
    title: "",
    image: "",
    location: "",
    description: "",
    type: "",
    notification: false,
    message: "",
    redirect: false,
    error: false,
    crop: {
      unit: "%",
      width: "50",
      aspect: 1 / 1,
    },
    src: null,
    imgwaiting: false,
    imgloading: false,
    croppedImageUrl: null,
    waiting: false,
    showLoading: false,
    showDone: false,
  };

  // handleChangeDesc(content) {
  //   // console.log(content); //Get Content Inside Editor
  //   this.setState(
  //     {
  //       description: content,
  //     },
  //     () => {
  //       console.log(this.state.description);
  //     }
  //   );
  // }
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      this.setState({
        imgwaiting: true,
      });

      // try {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );

      // }
      //     catch (err) {(
      //       console.log("error", err)
      //     )}

      this.setState({ croppedImageUrl });
      // console.log(croppedImageUrl)
      let blob = await fetch(croppedImageUrl).then((r) => r.blob());
      this.compress(new File([blob], "name"));
      // this.setState({image:blob,
      // imgwaiting:false},()=>{
      //   // if(this.state.loading){
      //   //   this.handleSubmit();
      //   // }
      //   console.log(blob)

      // })
    }
  }
  compress = (file) => {
    if (file) {
      this.setState({
        waiting: true,
        showLoading: true,
        showDone: false,
      });
      if (file.size > 100000) {
        try {
          this.setState({ waiting: true });
          Resizer.imageFileResizer(
            file,
            300,
            300,
            "JPEG",
            100,
            0,
            (uri) => {
              // console.log(uri);
              this.setState(
                {
                  image: uri,
                  waiting: false,

                  showDone: true,
                },
                () => {
                  if (this.state.loading) {
                    this.handleSubmit();
                  }
                }
              );
            },
            "file",
            150,
            150
          );
        } catch (err) {
          console.log(err);
          this.setState({
            notification: true,
            message:
              "Failed to Compress Image,press Add to submit Uncompressed Image ",
            image: file,
            waiting: false,
            loading: false,
            showDone: true,
          });
          setTimeout(() => {
            this.setState({ notification: false, message: "" });
          }, 2000);
        }
      } else {
        this.setState({
          image: file,
          showDone: true,
          waiting: false,
        });
      }
    }
  };

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error("Canvas is empty");
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve(this.fileUrl);
        },
        "image/jpeg",
        1
      );
    });
  }
  handleChange = (e) => {
    if (e.target.name === "title") {
      this.setState({
        error: false,
      });
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      this.setState({
        waiting: true,
        showLoading: true,
        showDone: false,
      });
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  handleSubmit = (e) => {
    if (e) e.preventDefault();
    if (this.state.description.length === 0) {
      this.setState({
        notification: true,
        message: "Description cannot be empty",
      });
      setTimeout(() => {
        this.setState({ notification: false });
      }, 2000);
    } else if (this.state.type.length === 0) {
      this.setState({ notification: true, message: "Type cannot be empty" });
      setTimeout(() => {
        this.setState({ notification: false });
      }, 2000);
    } else {
      fd = new FormData();
      // if(this.state.image===""){
      //   window.location.reload();
      // } else{
      this.setState({ loading: true });
      if (this.state.waiting) {
        this.setState({
          notification: true,
          message: "Image Compressing please wait",
        });
        setTimeout(() => {
          this.setState({ notification: false });
        }, 1000);
      } else {
        if (init() === "success") {
          fd.append("title", this.state.title);
          fd.append("description", this.state.description);
          fd.append("image", this.state.image);
          fd.append("location", this.state.location);
          fd.append("type", this.state.type);

          let auth = localStorage.getItem("accessToken");
          axios
            .post(Constants.postUrls.postTestimonial, fd, {
              headers: { Authorization: `Bearer ${auth}` },
            })
            .then((resp) => {
              this.setState({
                loading: false,
                message: resp.data.message,
                notification: true,
              });
              if (
                resp.data.message === undefined ||
                resp.data.message === null
              ) {
                console.log(resp);
                if (resp.data.errors.msg) {
                  this.setState({
                    message: resp.data.errors.msg,
                  });
                } else {
                  this.setState({
                    message: "unknown error",
                  });
                }
              }

              if (
                resp.data.message ===
                `Title already exist with the name ${this.state.title}`
              ) {
                this.setState({
                  error: true,
                });
              }
              setTimeout(() => {
                if (resp.data.message === "Testimonial added successfully") {
                  this.setState({
                    redirect: true,
                  });
                }

                this.setState({
                  // title:"",
                  // image:"",
                  // location:"",

                  // description:"",
                  notification: false,
                  message: "",
                });
                //   fd.delete('title');
                // fd.delete('image');

                // fd.delete('description');
                // this.emptyFile.current.value = "";
              }, 2000);
            })
            .catch((err) => {
              console.log(err);
              // window.location.reload();
              this.setState({
                notification: true,
                message: "error occured probabaly due to oversize file",
              });
              setTimeout(() => {
                this.setState({
                  // title:"",
                  //   image:"",
                  //   description:"",
                  //   location:"",
                  notification: false,
                  message: "",
                  loading: false,
                });
              }, 2000);
            });

          // }
        }
      }
    }
  };

  render() {
    const { crop, croppedImageUrl, src } = this.state;
    return (
      <Container maxWidth="xl">
        {this.state.redirect && <Navigate to="/testimonial" replace={true} />}

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6"> Testimonial </Typography>
          </Grid>
        </Grid>
        {this.state.notification ? (
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={true}
            autoHideDuration={5000}
            color="green"
            message={this.state.message}
          ></Snackbar>
        ) : null}
        <div
          style={{
            background: "#5b5757",
            padding: "20px",
            borderRadius: "10px",
            // color: rgba(0, 0, 0, 0.87);
            width: "100%",
            border: "0",
            display: "flex",
            position: "relative",
            fontSize: ".875rem",
            minWidth: "0",
            wordWrap: " break-word",
            /* background: #FFF; */
            boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
            marginTop: "30px",
            borderRadius: "6px",
            marginBottom: "30px",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <div
            style={{
              background:
                "linear-gradient(60deg, rgb(128 115 115), rgb(255 250 250)) rgb(153, 153, 153))",
              boxShadow:
                " rgb(0 0 0 / 14%) 0px 4px 20px 0px, rgb(128 120 120 1/ 40%) 0px 7px 10px -5px",
              float: "left",
              padding: "15px",
              marginTop: "-43px",
              marginRight: "15px",
              borderRadius: "3px",
              backgroundColor: "#999",
              width: "6%",
              height: "60px",
              marginBottom: "25px",
              textAlign: "center",
            }}
          >
            <EditIcon />
          </div>
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item sm={4}>
                <TextField
                  name="title"
                  error={this.state.error}
                  id={this.state.error ? "outlined-error" : "outlined-basic"}
                  label={this.state.error ? "Error" : "Name"}
                  size="medium"
                  variant="outlined"
                  rows={1}
                  multiline={true}
                  required={true}
                  type="text"
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  value={this.state.title}
                />
              </Grid>
              {/* {
                          this.state.all_exchange_type.map(val =>(
                              console.log(val.instrument)
                          ))
                      } */}

              <Grid item sm={4}>
                <TextField
                  name="location"
                  id="outlined-basic"
                  label="Location"
                  size="medium"
                  variant="outlined"
                  required={true}
                  type="text"
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  value={this.state.location}
                />
              </Grid>
              <Grid item sm={4}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="Id">Type</InputLabel>

                  <Select
                    name="type"
                    labelId="Id"
                    id="Id"
                    value={this.state.type}
                    label="Type"
                    onChange={this.handleChange}
                    sx={{ width: "100%" }}
                  >
                    <MenuItem value="buyer">Buyer</MenuItem>
                    <MenuItem value="seller">Seller</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={4}>
                {/* <TextField
                  name="image"
                  id="outlined-basic"
                  placeholder={"Add Brand Image"}
                  size="medium"
                  variant="outlined"
                  required={true}
                  type="file"
                  onChange={this.handleFile}
                  style={{ width: "100%" }}
                /> */}
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    height: "60px",
                    alignItems: "baseline",
                    justifyContent: "left",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "baseline",
                    }}
                  >
                    <label>Image:</label>
                    <input
                      name="image"
                      type="file"
                      onChange={this.handleFile}
                      accept="image/*"
                      ref={this.emptyFile}
                      disabled={
                        this.state.showLoading &&
                        (this.state.showDone ? false : true)
                      }
                      required
                      style={{ width: "100%" }}
                    />
                  </div>
                  {this.state.showLoading ? (
                    this.state.showDone ? (
                      <DoneIcon
                        color="success"
                        sx={{ position: "absolute", right: "0" }}
                      />
                    ) : (
                      <CircularProgress
                        color="success"
                        size={25}
                        sx={{ position: "absolute", right: "0" }}
                      />
                    )
                  ) : null}
                </div>
              </Grid>
              <Grid item md={4}>
                {src && (
                  <ReactCrop
                    src={src}
                    crop={crop}
                    ruleOfThirds
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                  />
                )}
              </Grid>
              <Grid item md={4}>
                {croppedImageUrl && (
                  <img
                    alt="Crop"
                    style={{ maxWidth: "50%" }}
                    src={croppedImageUrl}
                  />
                )}
              </Grid>
            </Grid>
            <div style={{ color: "black", marginTop: "20px" }}>
              <label style={{ color: "white" }}>Description:</label>

              <CKEditor
                className="ckeditor"
                value={this.state.description}
                editor={ClassicEditor}
                data={this.state.description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  this.setState({
                    description: data,
                  });
                }}
              />
            </div>
            <br />
            <Button
              variant="contained"
              type="submit"
              size="medium"
              color="primary"
              style={{
                marginRight: "20px",

                marginTop: "35px",
              }}
            >
              {this.state.loading ? "Adding.." : "Add "}
            </Button>
            <Link
              to={{
                pathname: "/testimonial",
              }}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                type="submit"
                color="secondary"
                style={{ marginRight: "20px", marginTop: "35px" }}
              >
                Cancel
              </Button>
            </Link>
          </form>
        </div>
      </Container>
    );
  }
}
