import React, { Component } from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import SourceIcon from '@mui/icons-material/Source';
import CategoryIcon from '@mui/icons-material/Category';
import DataArrayIcon from '@mui/icons-material/DataArray';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import HelpIcon from '@mui/icons-material/Help';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import Dashboard from './App/Main/Dashboard.jsx';
import Banner from "./App/Main/Banner.jsx";
import Advertisment from "./App/Main/Advertisment";
import FeaturedVideoIcon from '@mui/icons-material/FeaturedVideo';
import Brand from "./App/Main/Brand";
import Cms from "./App/Main/Cms";
import Category from "./App/Main/Category";
import Testimonial from './App/Main/Testimonial.jsx';
import Faq from "./App/Main/Faq";
import Vehicles from "./App/Main/Vehicles";
import ContactUs from "./App/Main/ContactUs";
import Kilometers from "./App/Main/Kilometers";
import Years from "./App/Main/Years";
import FuelType from "./App/Main/FuelType";
import Model from "./App/Main/Model";
import Subscription from "./App/Main/Subscription";
import GroupIcon from '@mui/icons-material/Group';
import User from "./App/Main/User.jsx";
import State from "./App/Main/State.jsx";
import City from "./App/Main/City.jsx";
import Setting from './App/Main/Setting.jsx';
import SettingsIcon from '@mui/icons-material/Settings';
import FeaturedVehicle from './App/Main/FeaturedVehicle.jsx';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import Agent from "./App/Main/Agent";
import BoyIcon from '@mui/icons-material/Boy';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ManualNotification from './App/Main/ManualNotification.jsx';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import BodyType from './App/Main/BodyType.jsx';
import FeedIcon from '@mui/icons-material/Feed';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import Enquiry from './App/Main/Enquiry.jsx';
import Physicalnspection from './App/Main/Physicalnspection.jsx';
import NightShelterIcon from '@mui/icons-material/NightShelter';
import SummarizeIcon from '@mui/icons-material/Summarize';
import Report from './App/Main/Report.jsx';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import TrustedClient from "./App/Main/TrustedClient"
 const routes = {Dashboard:
   [ {
      title: "Dashboard",
      path: "/dashboard",
      component: <Dashboard/>,
      icon: <DashboardIcon />,
      exact: true,
      // pathId: "61d48beb4e865687542a4d6e",
      // role:"superadmin",
    }],
  Banners:[
     {
      title: "Banners",
      path: "/banner",
      component: <Banner/>,
      icon: <ViewCarouselIcon />,
      exact: true,
      // pathId: "628dc71b310ea90d4c92e9f2"
      // role:"superadmin",
    },
     {
      title: "Advertisement",
      path: "/advertisement",
      component: <Advertisment/>,
      icon: <FeaturedVideoIcon />,
      exact: true,
      // pathId: "6291f88d6885c427e031dd14"
    },
  ],
Vehicles:[{
  
      title: "Vehicles",
      path: "/vehicles",
      component: <Vehicles/>,
      icon: <AgricultureIcon/>,
      exact: true,
      pathId: "628dc71b310ea90d4c92e9f2"
      // role:"superadmin",
      
  
},
{
  title:"Physical Inspection",
  path:"/physicalInpection",
  component:<Physicalnspection/>,
  icon:<ContentPasteSearchIcon/>,
  exact:true,
  
},
// {
//     title: "Featured Vehicle",
//     path: "/featuredVehicle",
//     component: <FeaturedVehicle/>,
//     icon: <DirectionsBusIcon />,
//     exact: true,
//     // pathId: "6291d9633a22de08400ff118"
//     // role:"superadmin",
//   },
],
Motors:[
  {
      title: "Category",
      path: "/category",
      component: <Category/>,
      icon: <CategoryIcon />,
      exact: true,
      // pathId: "62908e1a8c1191332c185034"
    },
     {
        title: "Brand",
        path: "/brand",
        component: <Brand/>,
        icon: <BrandingWatermarkIcon/>,
        exact: true,
        // pathId: "628de602552fde1ed80748f0"
      },
      {
          title: "Model",
          path: "/model",
          component: <Model/>,
          icon: <LocalShippingIcon />,
          exact: true,
          
        },
        {
          title: "State",
          path: "/state",
          component: <State/>,
          icon: <LocationCityIcon />,
          exact: true,
          
        },
        {
          title: "City",
          path: "/city",
          component: <City/>,
          icon: <NightShelterIcon />,
          exact: true,
          
        },

        {
            title: "Kilometers",
            path: "/kms",
            component: <Kilometers/>,
            icon: <DriveEtaIcon />,
            exact: true,
            
          },
          {
            title: "Years",
            path: "/years",
            component: <Years/>,
            icon: <CalendarMonthIcon />,
            exact: true,
            
          },
          {
            title: "Fuel Type",
            path: "/fuelType",
            component: <FuelType/>,
            icon: <LocalGasStationIcon />,
            exact: true,
            
          },
          {
            title: "Body Type",
            path: "/bodyType",
            component: <BodyType/>,
            icon: <DirectionsCarFilledIcon />,
            exact: true,
            
          }

],
Client:[{
  title: "Trusted Clients",
  path: "/trustedClient",
  component: <TrustedClient/>,
  icon: <FollowTheSignsIcon />,
  exact: true,
  // pathId: "6291d9633a22de08400ff118"
  // role:"superadmin",
},
],

Enquiry:[{
  title: "Enquiries",
      path: "/enquiry",
      component: <Enquiry/>,
      icon: <FeedIcon />,
      exact: true,
      // pathId: "628f6bd7ca2c180b9c7f3b7f",
    },
],
ContactUs:[{
  title: "Contact Us",
      path: "/contact",
      component: <ContactUs/>,
      icon: <ContactMailIcon />,
      exact: true,
      // pathId: "628f6bd7ca2c180b9c7f3b7f",
    },
],
Masters:[
   {
      title: "Cms",
      path: "/cms",
      component: <Cms/>,
      icon: <SourceIcon/>,
      exact: true,
      // pathId: "628e2fb03d81801810df5da9"
    },
    {
      title: "Testimonial",
      path: "/testimonial",
      component: <Testimonial/>,
      icon: <DataArrayIcon />,
      exact: true,
      // role:"superadmin",
      // pathId: "628f7db25a837134489edace"
    },
    {
      title: "Faq",
      path: "/faq",
      component: <Faq/>,
      icon: <HelpIcon />,
      exact: true,
      // pathId: "628e24ae56a6fc2aa4955e63"
    },
     {
      title: "Subscription",
      path: "/subscription",
      component: <Subscription/>,
      icon: <SubscriptionsIcon />,
      exact: true,
      // pathId: "6291d9633a22de08400ff118"
      // role:"superadmin",
    },
    {
      title: "Setting",
      path: "/setting",
      component: <Setting/>,
      icon: <SettingsIcon />,
      exact: true,
      // pathId: "6291d9633a22de08400ff118"
      // role:"superadmin",
    },
    {
      title: "Manual Notification",
      path: "/manualNotification",
      component: <ManualNotification/>,
      icon: <NotificationsActiveIcon />,
      exact: true,
      // pathId: "6291d9633a22de08400ff118"
      // role:"superadmin",
    },


],
Users:[
  {
      title: "User",
      path: "/user",
      component: <User/>,
      icon: <GroupIcon />,
      exact: true,
      // pathId: "6291d9633a22de08400ff118"
      // role:"superadmin",
    },
    
    {
        title: "Agent",
        path: "/agent",
        component: <Agent/>,
        icon: <BoyIcon />,
        exact: true,
        // pathId: "6291d9633a22de08400ff118"
        // role:"superadmin",
      },

],
Report:[
  {
    title: "Report",
    path: "/report",
    component: <Report/>,
    icon: <SummarizeIcon />,
    exact: true,
    // pathId: "6291d9633a22de08400ff118"
    // role:"superadmin",
  },
]}
    //
    // {
    //   title: "Vehicles",
    //   path: "/vehicles",
    //   component: <Vehicles/>,
    //   icon: <AgricultureIcon/>,
    //   exact: true,
    //   pathId: "628dc71b310ea90d4c92e9f2"
    //   // role:"superadmin",
    // },
    //
    // {
    //   title: "Brand",
    //   path: "/brand",
    //   component: <Brand/>,
    //   icon: <BrandingWatermarkIcon/>,
    //   exact: true,
    //   // pathId: "628de602552fde1ed80748f0"
    // },
    // {
    //   title: "Cms",
    //   path: "/cms",
    //   component: <Cms/>,
    //   icon: <SourceIcon/>,
    //   exact: true,
    //   // pathId: "628e2fb03d81801810df5da9"
    // },
    //  {
    //   title: "Category",
    //   path: "/category",
    //   component: <Category/>,
    //   icon: <CategoryIcon />,
    //   exact: true,
    //   // pathId: "62908e1a8c1191332c185034"
    // },
    // {
    //   title: "Testimonial",
    //   path: "/testimonial",
    //   component: <Testimonial/>,
    //   icon: <DataArrayIcon />,
    //   exact: true,
    //   // role:"superadmin",
    //   // pathId: "628f7db25a837134489edace"
    // },
  
    // {
    //   title: "Contact Us",
    //   path: "/contact",
    //   component: <ContactUs/>,
    //   icon: <ContactMailIcon />,
    //   exact: true,
    //   // pathId: "628f6bd7ca2c180b9c7f3b7f",
    // },
    // {
    //   title: "Faq",
    //   path: "/faq",
    //   component: <Faq/>,
    //   icon: <HelpIcon />,
    //   exact: true,
    //   // pathId: "628e24ae56a6fc2aa4955e63"
    // },
    // {
    //   title: "Kilometers",
    //   path: "/kms",
    //   component: <Kilometers/>,
    //   icon: <DriveEtaIcon />,
    //   exact: true,
      
    // },
    // {
    //   title: "Years",
    //   path: "/years",
    //   component: <Years/>,
    //   icon: <CalendarMonthIcon />,
    //   exact: true,
      
    // },
    // {
    //   title: "Fuel Type",
    //   path: "/fuelType",
    //   component: <FuelType/>,
    //   icon: <LocalGasStationIcon />,
    //   exact: true,
      
    // },
    // {
    //   title: "Body Type",
    //   path: "/bodyType",
    //   component: <BodyType/>,
    //   icon: <DirectionsCarFilledIcon />,
    //   exact: true,
      
    // },
    // {
    //   title: "Model",
    //   path: "/model",
    //   component: <Model/>,
    //   icon: <LocalShippingIcon />,
    //   exact: true,
      
    // },
    
  
  
    // {
    //   title: "Subscription",
    //   path: "/subscription",
    //   component: <Subscription/>,
    //   icon: <SubscriptionsIcon />,
    //   exact: true,
    //   // pathId: "6291d9633a22de08400ff118"
    //   // role:"superadmin",
    // }
    // ,
    // {
    //   title: "User",
    //   path: "/user",
    //   component: <User/>,
    //   icon: <GroupIcon />,
    //   exact: true,
    //   // pathId: "6291d9633a22de08400ff118"
    //   // role:"superadmin",
    // },
    // {
    //   title: "Setting",
    //   path: "/setting",
    //   component: <Setting/>,
    //   icon: <SettingsIcon />,
    //   exact: true,
    //   // pathId: "6291d9633a22de08400ff118"
    //   // role:"superadmin",
    // },
    // {
    //   title: "Featured Vehicle",
    //   path: "/featuredVehicle",
    //   component: <FeaturedVehicle/>,
    //   icon: <DirectionsBusIcon />,
    //   exact: true,
    //   // pathId: "6291d9633a22de08400ff118"
    //   // role:"superadmin",
    // },
    // {
    //   title: "Agent",
    //   path: "/agent",
    //   component: <Agent/>,
    //   icon: <BoyIcon />,
    //   exact: true,
    //   // pathId: "6291d9633a22de08400ff118"
    //   // role:"superadmin",
    // },
    // ,
    // {
    //   title: "Manual Notification",
    //   path: "/manualNotification",
    //   component: <ManualNotification/>,
    //   icon: <NotificationsActiveIcon />,
    //   exact: true,
    //   // pathId: "6291d9633a22de08400ff118"
    //   // role:"superadmin",
    // },
    
    
  
    
export default routes;