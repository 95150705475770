
import { Button, Container, Grid, Paper,Snackbar,CircularProgress, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import axios from "axios";
import {Link,BrowserRouter,Route,Routes,Navigate} from "react-router-dom"
import NewPassword from "./NewPassword";
import Handle from "../../helpers/handle";
import React, { Component } from 'react';
let url="";

export default class ForgotPassword extends Component {
  state = {
    email: "",
    loading: false,
    error: false,
    redirect: false,
    message: "",
    
  };
  
  submit = (e) => {
     localStorage.setItem("Newpasskey", this.state.key);
    this.setState({
      loading: true,
      redirect:true
    });
    e.preventDefault();
    let submitLoad = {
      email: this.state.email,
    };
    console.log(submitLoad);
    
   
    
    
    
     
    axios.post(url, submitLoad).then((resp) => {

      setTimeout(() => {
        
        this.setState({
          notification: true,
          loading: false,
          message: resp.data.Message,
        });
      }, 2000);
      setTimeout(() => {
        
        this.setState({
          redirect: true
        })
      }, 4000);})
      .catch(err => {
        if (err) {
          console.log(err);
          this.setState({
          loading: false,
          message: "Invalid Credentials",
          error: true,
        });
        setTimeout(() => {
          this.setState({
            error: false,
          });
          }, 3000);
        }
      });
      
      // if (resp.data.status === "failed") {
      //   this.setState({
      //     loading: false,
      //     message: resp.data.message,
      //     error: true,
      //   });
      //   setTimeout(() => {
      //     this.setState({
      //       error: false,
      //     });
      //   }, 3000);
      // } else {
      //   // localStorage.setItem("accessToken", resp.data.user.accessToken);

      //   setTimeout(() => {
      //     this.setState({
      //       notification: true,
      //       loading: false,
      //       message: resp.data.message,
      //     });
      //   }, 2000);
      //   setTimeout(() => {
      //     window.location.href = "/login";
      //   }, 4000);
      // }
    // })
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {if(this.state.redirect){
    return( <> 
      <Handle/>
     <Navigate replace to="/newpassword" /></>);
  }
  else{

    return (
      <div className="loginWrapper">
        <Container maxWidth="md">
          <Grid container>
            <Grid item sm={6} style={{ position: "relative", height: "100vh" }}>
              {/* NOTIFICATION */}
              {this.state.notification && (
                <Snackbar 
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={true}
                  color={"s"}
                  autoHideDuration={6000}
                  message={this.state.message}
                ></Snackbar>
              ) }
              {/* NOTIFICATION */}
              <Paper className="loginCard" elevation={15}>
                <Typography variant="h4" component="h2" gutterBottom>
                  Gaddideals.com
                </Typography>
                <Typography variant="h6" component="h1">
                  Forgot Password
                </Typography>

                {/* <Typography
                    color="primary"
                    variant="body2"
                    style={{ alignSelf: "flex-end" , cursor:"pointer" }}
                  >
                    Forgot password?
                  </Typography> */}
                <form className="loginFormWrapper forgot" onSubmit={this.submit} style={{minHeight:"180px"}}>
                  <TextField
                    id="outlined-basic"
                    label="E-mail id"
                    variant="outlined"
                    fullWidth
                    name="email"
                    onChange={this.handleInput}
                    required
                    style={{marginBottom:"0px"}}
                  />

                  <Button
                    color="primary"
                    style={{ alignSelf: "flex-start" }}
                    variant="contained"
                    size="large"
                    type="submit"
                  >
                    {this.state.loading ? (
                      "Submitting"
                    ) : this.state.error ? (
                      <span style={{ color: "#BD0600" }}>
                        Incorrect Credentials!!!
                      </span>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                  {this.state.loading ? (
                    <CircularProgress size="1.5rem" color="secondary" />
                  ) : null}
                </form>

                {/* <Grid
                    item
                    sm={12}
                    style={{ position: "relative"}}
                  >
                    <Link to="/forgot" style={{ textDecoration: "none" }}>
                      <Typography
                        color="primary"
                        variant="body2"
                        style={{
                          alignSelf: "center",
                          cursor: "pointer",
                          display: "inline",
                        }}
                      >
                        Forgot password?
                      </Typography> 
                    </Link>
                    <Link to="/change" style={{ textDecoration: "none" }}>
                      <Typography
                        color="primary"
                        variant="body2"
                        style={{
                          alignSelf: "flex-end",
                          cursor: "pointer",
                          display: "inline",
                         
                        }}
                      >
                        Change password?
                      </Typography>
                    </Link>
                  </Grid> */}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
    )}
  }
}

