import React, { Component } from "react";
import {
  Button,
  Container,
  FormControl,
  Grid,
  Select,
  InputLabel,
  Paper,
  Snackbar,
  Switch,
  Table,
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
  ButtonGroup,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Pagination,
} from "@mui/material";
import axios from "axios";
import { Link } from "react-router-dom";
import init from "../../helpers/WindowToken";
import Constants from "../Variables/Constant.jsx";
import SearchIcon from "@mui/icons-material/Search";
import MoreInfo from "@mui/material/Modal";
import Modal from "react-awesome-modal";
import fileDownload from "js-file-download";
import Nothing from "../../Assets/Nothing.jpg";
import Brand from "./Brand";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default class Vehicle extends Component {
  state = {
    open: false,
    getVehicle: [],
    data: [],
    notifyStatus: false,
    notify: false,
    searchedValue: "",
    visible: false,
    delLoading: false,
    rowId: 0,
    status: "",
    active: 1,
    perPage: "10",
    limit: 10,
    searchpage: 1,
    search: false,
    currentPage: 1,
    page: 1,
    prevPage: 0,
    nextPage: 0,
    lastPage: 0,
    lastPageView: false,
    firstPageView: false,
    total: 0,
    from: 1,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    inf: "",
    filteredBtnIdArray: [],
    msg: "",
    open: false,
    deleteId: "",
    pageinfo: 1,
    sortByStatus: "published",
    searchTotalPage: 1,
    totalPage: 1,
    sold: "",
    isRestoreOpen: false,
    restoreLoading: false,
  };
  // handleClose = () => {
  //   this.setState({ visible: false });
  // };

  componentDidMount() {
    this.getVehicle();
  }

  getVehicle = () => {
    let auth = localStorage.getItem("accessToken");
    axios
      .get(
        Constants.getUrls.getAllVehicle +
          "?page=" +
          this.state.page +
          "&sort=true&limit=" +
          this.state.limit +
          "&is_sold=" +
          this.state.sold +
          `&status=${
            this.state.sortByStatus !== "yes" ? this.state.sortByStatus : ""
          }` +
          `&is_delete=${
            this.state.sortByStatus === "yes" ? this.state.sortByStatus : "no"
          }`,
        // "&is_delete=" +
        // this.state.sortByStatus ===
        // "yes"
        // ? this.state.sortByStatus
        // : "",
        { headers: { Authorization: `Bearer ${auth}` } }
      )
      .then((resp) => {
        console.log(resp, "resp");

        this.setState({
          fromInc: 1,
          active: 1,
          toInc: 5,
          getVehicle: resp.data.vehicle.docs,
          total: resp.data.vehicle.totalDocs,
          nextPage: resp.data.vehicle.hasNextPage,
          prevPage: resp.data.vehicle.hasPrevPage,
          limit: resp.data.vehicle.limit,
          pagingCounter: resp.data.vehicle.pagingCounter,
          totalPage: resp.data.vehicle.totalPages,
          pageinfo: this.state.page,
          // PAGINATION

          from: 1,
          // to: resp.data.data.length,
        });
      });
  };
  handleRows = (value) => {
    this.setState(
      {
        limit: value,
      },
      () => {
        if (this.state.search) {
          this.handleSearch();
        } else {
          this.getVehicle();
        }
      }
    );
    // this.getBanner();
  };
  // PAGINATION BUTTONS

  handleNext = () => {
    if (this.state.search) {
      if (this.state.nextPage === true) {
        // console.log(this.state.nextPage)

        this.setState(
          {
            searchpage: Number(this.state.searchpage) + 1,
            // to: this.state.to + this.state.limit,
          },
          () => {
            this.handleSearch();
          }
        );
        // console.log(this.state.page)
        // this.getAdd();
      }
    } else {
      if (this.state.nextPage === true) {
        // console.log(this.state.nextPage)

        this.setState(
          {
            page: Number(this.state.page) + 1,
            // to: this.state.to + this.state.limit,
          },
          () => {
            this.getVehicle();
          }
        );
        // console.log(this.state.page)
        // this.getBrand();
      }
    }
  };
  handleChangePage = (e) => {
    this.setState({
      pageinfo: e.target.value,
    });
  };
  handlePages = (e, value) => {
    if (this.state.search) {
      this.setState(
        {
          searchpage: value,
        },
        () => {
          this.handleSearch();
        }
      );
    } else {
      this.setState(
        {
          page: value,
        },
        () => {
          this.getVehicle();
        }
      );
    }
  };
  handlePage = (e) => {
    e.preventDefault();
    if (this.state.pageinfo) {
      if (this.state.search) {
        if (
          this.state.pageinfo > 0 &&
          this.state.pageinfo <= this.state.searchTotalPage
        ) {
          // console.log(this.state.nextPage)

          this.setState(
            {
              searchpage: this.state.pageinfo,
              // to: this.state.to + this.state.limit,
            },
            () => {
              this.handleSearch();
            }
          );
          // console.log(this.state.page)
          // this.getAdd();
        }
      } else {
        if (
          this.state.pageinfo > 0 &&
          this.state.pageinfo <= this.state.totalPage
        ) {
          // console.log(this.state.nextPage)

          this.setState(
            {
              page: this.state.pageinfo,
              // to: this.state.to + this.state.limit,
            },
            () => {
              this.getVehicle();
            }
          );
          // console.log(this.state.page)
          // this.getBrand();
        }
      }
    }
  };
  handleStatus = (id, value) => {
    let payload;
    // console.log(id);
    let auth = localStorage.getItem("accessToken");

    if (init() === "success") {
      payload = {
        status: value,
      };

      payload = JSON.stringify(payload);
      axios
        .post(Constants.statusUrl + id + "?type=vehicle", payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth}`,
          },
        })
        .then((res) => {
          console.log(res);
          this.setState({
            notify: true,
            msg: res.data.message,
          });
          setTimeout(() => {
            this.setState({ notify: false }, () => {
              if (this.state.search) {
                this.handleSearch();
              } else {
                this.getVehicle();
              }
            });
          }, 750);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  handleClick = (id) => {
    let payload;
    console.log(id);
    let auth = localStorage.getItem("accessToken");

    if (init() === "success") {
      payload = {
        status: "approved",
      };

      payload = JSON.stringify(payload);
      axios
        .post(Constants.statusUrl + id + "?type=vehicle", payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth}`,
          },
        })
        .then((res) => {
          // console.log(res);
          this.setState({
            notify: true,
            msg: res.data.message,
          });
          setTimeout(() => {
            this.setState({ notify: false }, () => {
              if (this.state.search) {
                this.handleSearch();
              } else {
                this.getVehicle();
              }
            });
          }, 750);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  handlePrev = () => {
    if (this.state.search) {
      if (this.state.prevPage === true)
        this.setState(
          {
            searchpage: Number(this.state.searchpage) - 1,
          },

          () => {
            this.handleSearch();
          }
        );
    } else {
      if (this.state.prevPage === true)
        this.setState(
          {
            page: Number(this.state.page) - 1,
          },

          () => {
            this.getVehicle();
          }
        );
      // this.getVehicle);
    }
  };
  handleModal = (vehicle) => {
    // console.log("this")
    // console.log(vehicle);
    this.setState(
      {
        inf: vehicle,
        visible: true,
      },

      () => {
        console.log(this.state.inf.engine_pic);
      }
    );
    // setTimeout(()=>{
    //   this.setState({
    //     visible: true
    //   })

    // },100)
  };
  handleClose = () => {
    this.setState({
      visible: false,
    });
  };

  handleChange = (event) => {
    if (event.target.value.length < 1) {
      this.setState({
        searchedValue: "",
        search: false,
      });
      // this.defaultValues();
      this.getVehicle();
    } else {
      this.setState({
        searchedValue: event.target.value,
      });
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState(
      {
        searchpage: 1,
      },
      () => {
        this.handleSearch();
      }
    );
  };
  handleSearch = (event) => {
    if (event) {
      this.setState(
        {
          searchedValue: event.target.value,
        },
        () => {
          this.handleSearch();
        }
      );
    }
    if (this.state.searchedValue < 1) {
      this.setState({
        search: false,
      });
      // this.defaultValues();
      this.getVehicle();
    } else {
      if (this.state.searchedValue.length >= 2) {
        this.setState({
          search: true,
          page: 1,
        });
        let auth = localStorage.getItem("accessToken");
        axios
          .get(
            Constants.getUrls.getAllVehicle +
              "?page=" +
              this.state.searchpage +
              "&sort=true&limit=" +
              this.state.limit +
              "&is_sold=" +
              this.state.sold +
              "&q=" +
              this.state.searchedValue +
              "&status=" +
              this.state.sortByStatus,
            { headers: { Authorization: `Bearer ${auth}` } }
            // this.state.name
          )
          .then((resp) => {
            // console.log(resp);
            // let btnIdArray = [];
            // for (let i = 1; i <= resp.data.pageCount; i++) {
            //   btnIdArray.push(i);
            // }

            this.setState({
              fromInc: 1,
              active: 1,
              toInc: 5,
              getVehicle: resp.data.vehicle.docs,
              total: resp.data.vehicle.totalDocs,
              nextPage: resp.data.vehicle.hasNextPage,
              prevPage: resp.data.vehicle.hasPrevPage,
              limit: resp.data.vehicle.limit,
              pagingCounter: resp.data.vehicle.pagingCounter,
              searchTotalPage: resp.data.vehicle.totalPages,
              pageinfo: this.state.searchpage,
              // PAGINATION

              from: 1,
              // to: resp.data.data.length
            });
          });
      }
    }
  };

  handleRestoreModal = (vehicle) => {
    this.setState({
      deleteId: vehicle._id,
      isRestoreOpen: true,
    });
  };

  handleRestoreVehicle = () => {
    this.setState({
      restoreLoading: true,
    });

    let payload = {
      _id: this.state.deleteId,
    };

    if (init() === "success") {
      // this.setState({ delLoading: true });
      let auth = localStorage.getItem("accessToken");
      axios
        .post(
          Constants.getUrls.getAllVehicle + "/restoreDeleteVehicle",
          payload,
          {
            headers: { Authorization: `Bearer ${auth}` },
          }
        )
        .then((res) => {
          console.log(res);

          this.setState({
            notify: true,
            restoreLoading: false,
            msg: res.data.message,
          });
          setTimeout(() => {
            this.setState({ notify: false, isRestoreOpen: false }, () => {
              if (this.state.search) {
                this.handleSearch();
              } else {
                this.getVehicle();
              }
            });
          }, 2000);
        })
        .catch((err) => console.log(err));
    }
  };

  handleDeleteModal = (vehicle) => {
    // console.log(testimonial);
    this.setState(
      {
        deleteId: vehicle._id,
        open: true,
      }
      //   ,
      //   () => {
      //     console.log(this.state.rowId);
      //   }
    );
  };
  handleDelete = () => {
    this.setState({
      delLoading: true,
    });
    // console.log(Constants.deleteUrls.deleteTestimonial + "/" + this.state.rowId);

    if (init() === "success") {
      // this.setState({ delLoading: true });
      let auth = localStorage.getItem("accessToken");
      axios
        .delete(
          Constants.deleteUrls.deleteVehicle + "/" + this.state.deleteId,
          {
            headers: { Authorization: `Bearer ${auth}` },
          }
        )
        .then((res) => {
          // console.log(res);

          this.setState({
            notify: true,
            delLoading: false,
            msg: res.data.message,
          });
          setTimeout(() => {
            this.setState({ notify: false, open: false }, () => {
              if (this.state.search) {
                this.handleSearch();
              } else {
                this.getVehicle();
              }
            });
          }, 2000);
        })
        .catch((err) => console.log(err));
    }
  };
  handleDownload = () => {
    if (init() === "success") {
      let auth = localStorage.getItem("accessToken");
      let object = JSON.stringify({
        columns: [
          "uid",
          "category",
          "brand",
          "model",
          "fuelType",
          "bodyType",
          "state",
          "city",
          "reg_no",
          "years",
          "km_driven",
          "no_of_owner",
          "insurance",
          "tax_validity",
          "vehicle_permit",
          "scrap_vehicle",
          "no_of_tyre",
          "no_of_seats",
          "horse_power",
          "no_of_hrs",
          "tyre_cond",
          "engine_cond",
          "fitness_certificate",
          "selling_price",
          "status",
          "createdAt",
          "user",
          "secondary_name",
          "secondary_email",
          "secondary_mob",
        ],
      });
      axios
        .post(Constants.downloadUrls.downloadVehicles, object, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth}`,
          },
        })
        .then((resp) => {
          // console.log("https://gaddideals.brokerinvoice.co.in"+resp.data.url);

          axios
            .get("https://core.gaddideals.com" + resp.data.url, {
              responseType: "blob",
            })
            .then((res) => {
              fileDownload(res.data, "vehicle_data.csv");
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  handleSort = (name) => {
    console.log(name);
    this.setState(
      {
        sortByStatus: name,
        page: 1,
        searchpage: 1,
      },
      () => {
        if (this.state.search) {
          this.handleSearch();
        } else {
          this.getVehicle();
        }
      }
    );
  };
  handleSold = (name) => {
    this.setState(
      {
        sold: name,
        page: 1,
        searchpage: 1,
      },
      () => {
        if (this.state.search) {
          this.handleSearch();
        } else {
          this.getVehicle();
        }
      }
    );
  };
  handleFeatured = (e, status, id) => {
    let payload;
    let auth = localStorage.getItem("accessToken");

    if (init() === "success") {
      if (status === "yes") {
        payload = {
          isFeatured: "no",
        };
      } else {
        payload = {
          isFeatured: "yes",
        };
      }
      payload = JSON.stringify(payload);
      axios
        .put(Constants.putUrls.updateFeatured + id, payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth}`,
          },
        })
        .then((res) => {
          // console.log(res);
          if (res.data.message) {
            this.setState({
              notify: true,
              msg: res.data.message,
            });
          }
          if (res.data.status !== "success") {
            this.setState({
              notify: true,
              msg: "failed to update status",
            });
          }
          setTimeout(() => {
            this.setState({ notify: false }, () => {
              if (this.state.search) {
                this.handleSearch();
              } else {
                this.getVehicle();
              }
            });
          }, 750);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  render() {
    return (
      <Container maxwidth="xl">
        {this.state.notify ? (
          <Snackbar
            message={this.state.msg}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            autoHideDuration={6000}
            color="white"
            open="true"
          ></Snackbar>
        ) : null}
        <Grid container style={{ margin: "10px 0 10px" }} alignItems="baseline">
          <Grid item xs={4}>
            <Typography variant="h6">
              {/* {this.props.title} */}
              Vehicles
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <FormControl
              size="small"
              fullWidth={true}
              sx={{ marginTop: "0", paddingTop: "0" }}
            >
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.sortByStatus}
                label="Status"
                name="status"
                displayEmpty
                onChange={(e) => this.handleSort(e.target.value)}
              >
                <MenuItem value="">All</MenuItem>
                {/* <MenuItem value="draft">Draft</MenuItem> */}
                {/* <MenuItem value="pending">Pending</MenuItem> */}

                <MenuItem value="published">Pending</MenuItem>
                <MenuItem value="approved">Approved</MenuItem>
                <MenuItem value="yes">Deleted</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl
              size="small"
              fullWidth={true}
              sx={{ marginTop: "0", paddingTop: "0" }}
            >
              <InputLabel id="demo-simple-select-label">Sold</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.sold}
                label="Sold"
                name="sold"
                onChange={(e) => this.handleSold(e.target.value)}
              >
                <MenuItem value="">none</MenuItem>
                {/* <MenuItem value="draft">Draft</MenuItem> */}
                {/* <MenuItem value="pending">Pending</MenuItem> */}

                <MenuItem value="y">Yes</MenuItem>
                <MenuItem value="n">No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <Grid container justifyContent="flex-end">
              <TextField
                id="outlined-basic"
                label={"Search "}
                size="small"
                variant="outlined"
                sx={{ marginRight: "5px" }}
                onChange={this.handleSearch}
              />

              <Button
                variant="contained"
                onClick={() => {
                  this.handleDownload();
                }}
              >
                Download Excel
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Paper elevation={1}>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Sr.No.</TableCell>
                    <TableCell>UID</TableCell>
                    <TableCell align="right">Category</TableCell>

                    <TableCell align="right">Vehicle Model</TableCell>
                    <TableCell align="right">Reg. No.</TableCell>

                    <TableCell align="right">Price(₹)</TableCell>
                    <TableCell align="right">City</TableCell>
                    <TableCell align="right">Seller</TableCell>
                    <TableCell align="right">Secondary User</TableCell>
                    <TableCell align="right">Created At</TableCell>
                    <TableCell align="right">Sold</TableCell>
                    <TableCell align="center">Action</TableCell>
                    <TableCell align="center">Featured</TableCell>

                    <TableCell align="center">Status</TableCell>

                    <TableCell align="center">Read More</TableCell>

                    <TableCell align="center">CRUD</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.getVehicle.length > 0 ? (
                    this.state.getVehicle.map((vehicle, index) => (
                      <TableRow key={vehicle._id}>
                        <TableCell>
                          {this.state.search
                            ? Number(
                                (this.state.searchpage - 1) * this.state.limit
                              ) + Number(index + 1)
                            : Number((this.state.page - 1) * this.state.limit) +
                              Number(index + 1)}
                        </TableCell>
                        <TableCell align="center">
                          {vehicle?.uid && vehicle?.uid}
                        </TableCell>
                        <TableCell align="right">
                          {!vehicle.category
                            ? "No Category Found"
                            : vehicle.category.title
                            ? vehicle.category.title
                            : "No Category Title Found"}
                        </TableCell>

                        <TableCell align="right">
                          {!vehicle.model
                            ? "No Model Found"
                            : vehicle.model.name
                            ? vehicle.model.name
                            : "No Model Name Found"}
                        </TableCell>
                        <TableCell align="right">{vehicle.reg_no}</TableCell>

                        <TableCell allign="right">
                          {vehicle.selling_price}
                        </TableCell>
                        <TableCell align="right">
                          {vehicle.city
                            ? vehicle.city.title
                              ? vehicle.city.title
                              : "No city name Found"
                            : "No City Available"}
                        </TableCell>
                        <TableCell align="right">
                          {vehicle.user ? vehicle.user.name : "No User Found"}
                        </TableCell>
                        <TableCell align="right">
                          {vehicle.secondary_name
                            ? vehicle.secondary_name +
                              ", " +
                              vehicle.secondary_email +
                              ", " +
                              vehicle.secondary_mob
                            : "No Secondary User Found"}
                        </TableCell>
                        <TableCell align="right">
                          {vehicle.createdAt.substring(0, 10)}
                        </TableCell>
                        <TableCell align="right">{vehicle.is_sold}</TableCell>
                        <TableCell align="right">
                          <Button
                            variant="outlined"
                            color="secondary"
                            disabled={vehicle.inspection_status === "approved"}
                            onClick={() => this.handleClick(vehicle._id)}
                          >
                            Approve & Publish
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <Switch
                            name="checkedB"
                            checked={
                              vehicle.is_featured === "yes" ? true : false
                            }
                            onChange={(e) =>
                              // console.log(e.target.checked);
                              this.handleFeatured(
                                e.target.checked,
                                vehicle.is_featured,
                                vehicle._id
                              )
                            }
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Status
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={vehicle.inspection_status}
                              label="Status"
                              name="status"
                              onChange={(e) =>
                                this.handleStatus(vehicle._id, e.target.value)
                              }
                            >
                              <MenuItem value="draft">Draft</MenuItem>
                              {/* <MenuItem value="pending">Pending</MenuItem> */}
                              <MenuItem value="published">Pending</MenuItem>
                              <MenuItem value="approved">Approved</MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>

                        <TableCell align="center">
                          {" "}
                          <Button
                            color="success"
                            variant="outlined"
                            sx={{ width: "90px" }}
                            onClick={() => this.handleModal(vehicle)}
                          >
                            Read More
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <Link
                            to={"/admin/updateVehicles/" + vehicle._id}
                            style={{ textDecoration: "none" }}
                          >
                            <Button
                              color="info"
                              sx={{ width: "90px" }}
                              variant="outlined"
                            >
                              Update
                            </Button>
                          </Link>
                          {vehicle?.is_delete === "yes" ? (
                            <Button
                              sx={{ width: "90px" }}
                              color="error"
                              variant="outlined"
                              onClick={() => this.handleRestoreModal(vehicle)}
                            >
                              Restore Vehicle
                            </Button>
                          ) : (
                            <Button
                              sx={{ width: "90px" }}
                              color="error"
                              variant="outlined"
                              onClick={() => this.handleDeleteModal(vehicle)}
                            >
                              Delete
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Nothing to show here
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter></TableFooter>
              </Table>
              <TableRow
                style={{ display: "flex", justifyContent: "space-evenly" }}
              >
                {/* <div>
                <p>
                  Showing {this.state.pagingCounter} to {this.state.limit} of{" "}
                  {this.state.total} Entries
                </p>
              </div> */}

                {/* Rows PerPage START */}

                <div
                  style={{
                    display: "flex",
                    alignItems: "right",
                    justifyContent: "space-between",
                    minWidth: "200px",
                    marginLeft: "150px",
                    marginTop: "10px",
                  }}
                >
                  <p>Rows Per Page</p>

                  <FormControl
                    className="selectMerchants"
                    style={{
                      minWidth: "30px",
                      marginRight: "20px",
                      marginTop: "-5px",
                    }}
                  >
                    <InputLabel htmlFor="age-native-simple"></InputLabel>
                    <Select
                      native
                      defaultValue={10}
                      // value={state.age}
                      onChange={(e) => this.handleRows(e.target.value)}
                      inputProps={{
                        name: "Merchants",
                        id: "age-native-simple",
                      }}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                    </Select>
                  </FormControl>
                </div>
                {/* Rows PerPage END */}
                <Pagination
                  sx={{ marginTop: "auto", marginBottom: "auto" }}
                  count={
                    this.state.search
                      ? this.state.searchTotalPage
                      : this.state.totalPage
                  }
                  color="secondary"
                  page={
                    this.state.search ? this.state.searchpage : this.state.page
                  }
                  onChange={this.handlePages}
                />

                {/* <div style={{display:"flex",flexDirection:"row",justifyContent:"flex-end",alignContent:"center",
                 alignItems: "baseline"}}>
                    <p>
                    {" "}
                    Page=
                    {this.state.search
                      ? this.state.searchpage
                      : this.state.page}{" "}
                    of{" "}
                    {this.state.search
                      ? this.state.searchTotalPage
                      : this.state.totalPage}
                  </p>
                  <p style={{ marginLeft: "10px" }}>Data&nbsp;
                    {this.state.search ? this.state.total!==0?(Number( (this.state.searchpage - 1) *
                    this.state.limit ) + Number(1)) :0:
                    this.state.total!==0?(Number( (this.state.page - 1) *
                    this.state.limit ) + Number(1)) :0}&nbsp;to&nbsp; {this.state.search ? (Number( (this.state.searchpage - 1) *
                    this.state.limit ) + Number(1+9))>this.state.total?this.state.total:(Number( (this.state.searchpage - 1) *
                    this.state.limit ) + Number(1+9)) :
                    (Number( (this.state.page - 1) *
                    this.state.limit ) + Number(1+9))>this.state.total?this.state.total:(Number( (this.state.page - 1) *
                    this.state.limit ) + Number(1+9))}
                    &nbsp;of&nbsp;{this.state.total} </p>
                  {/* <ButtonGroup
                    variant="contained"
                    aria-label="outlined primary button group"
                  > */}
                {/* <Button
                      variant="contained"
                      color="primary"
                      className="mx-5"
                      style={{ margin: "10px" }}
                      disabled={this.state.prevPage === false}
                      onClick={this.handlePrev}
                      // Hidden
                    >
                      Previous
                    </Button>
                    <form onSubmit={(e)=>this.handlePage(e)}style={{ width: '80px'}}>
  <OutlinedInput sx={{width:"100%"}} onChange={(e)=>this.handleChangePage(e)} size="small" type="number" 
  value={this.state.pageinfo}

  />

  <Button type="submit" style={{visibility:"hidden"}}></Button>
</form>
                    <Button
                      variant="contained"
                      color="primary"
                      className="mx-5"
                      disabled={this.state.nextPage === false}
                      style={{ margin: "10px" }}
                      onClick={this.handleNext}
                    >
                      Next
                    </Button>
                  {/* </ButtonGroup> */}
                {/* </div> */}
              </TableRow>
            </TableContainer>
          </Paper>
        </Grid>
        <MoreInfo
          open={this.state.visible}
          onClose={this.handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ position: "absolute", top: "15vh", left: "20vw" }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            sx={{
              overflow: "scroll",
              maxHeight: "70vh",
              width: "60vw",
              backgroundColor: "#A3A3A8",
            }}
          >
            <Grid item xs={9}>
              <Typography variant="h5">
                {!this.state.inf.model
                  ? "No Model Found"
                  : this.state.inf.model.name
                  ? this.state.inf.model.name
                  : "No Model Name Found"}
              </Typography>
            </Grid>

            <Grid item xs={3} justifyContent="flex-end">
              <Button
                color="secondary"
                variant="contained"
                onClick={this.handleClose}
              >
                X
              </Button>
            </Grid>
            <Grid item sm={12}>
              <Paper elevation={1}>
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Title</TableCell>
                        <TableCell>Description</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Brand</TableCell>
                        <TableCell>
                          {!this.state.inf.brand
                            ? "No Brand Found"
                            : this.state.inf.brand.title
                            ? this.state.inf.brand.title
                            : "No Brand Title Found"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Manufacture Year</TableCell>
                        <TableCell>
                          {" "}
                          {!this.state.inf.years
                            ? "No Year Found"
                            : this.state.inf.years.year
                            ? this.state.inf.years.year
                            : "No Year Name Found"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Fuel Type</TableCell>
                        <TableCell>
                          {!this.state.inf.fuelType
                            ? "No Fuel Type Found"
                            : this.state.inf.fuelType.title
                            ? this.state.inf.fuelType.title
                            : "No Fuel Type Name Found"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Body Type</TableCell>
                        <TableCell>
                          {!this.state.inf.bodyType
                            ? "No Body Type Found"
                            : this.state.inf.bodyType.title
                            ? this.state.inf.bodyType.title
                            : "No Body Type Name Found"}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          {this.state.inf.category &&
                            (this.state.inf.category.title.toLowerCase() ===
                              "tractors" ||
                            this.state.inf.category.title.toLowerCase() ===
                              "construction equipments"
                              ? "Hours Driven"
                              : "Kilometers Driven")}
                        </TableCell>
                        <TableCell>
                          {this.state.inf.category &&
                            (this.state.inf.category.title.toLowerCase() ===
                              "tractors" ||
                            this.state.inf.category.title.toLowerCase() ===
                              "construction equipments"
                              ? this.state.inf.no_of_hrs
                              : this.state.inf.km_driven)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>State</TableCell>
                        <TableCell>
                          {this.state.inf.state
                            ? this.state.inf.state.title
                              ? this.state.inf.state.title
                              : "No state name Found"
                            : "No state Available"}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>No. of Owner</TableCell>
                        <TableCell>{this.state.inf.no_of_owner}</TableCell>
                      </TableRow>

                      {this.state.inf.category &&
                        this.state.inf.category.title.toLowerCase() ===
                          "buses" && (
                          <TableRow>
                            <TableCell>No. of Seat</TableCell>
                            <TableCell>{this.state.inf.no_of_seats}</TableCell>
                          </TableRow>
                        )}
                      {this.state.inf.category &&
                        this.state.inf.category.title.toLowerCase() ===
                          "tractors" && (
                          <TableRow>
                            <TableCell>Horse Power</TableCell>
                            <TableCell>{this.state.inf.horse_power}</TableCell>
                          </TableRow>
                        )}
                      <TableRow>
                        <TableCell>Tyre Condition</TableCell>
                        <TableCell>{this.state.inf.tyre_cond}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Engine Condition</TableCell>
                        <TableCell>{this.state.inf.engine_cond}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Insurance</TableCell>
                        <TableCell>
                          {this.state.inf.insurance &&
                            this.state.inf.insurance.substring(0, 10)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Rc Document</TableCell>
                        <TableCell>{this.state.inf.rc_document}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Fitness Certificate</TableCell>
                        <TableCell>
                          {this.state.inf.fitness_certificate &&
                            this.state.inf.fitness_certificate.substring(0, 10)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>No. of Owner</TableCell>
                        <TableCell>{this.state.inf.no_of_owner}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Tax Validity</TableCell>
                        <TableCell>
                          {this.state.inf.tax_validity &&
                            this.state.inf.tax_validity.substring(0, 10)}
                        </TableCell>
                      </TableRow>
                      {this.state.inf.category &&
                        this.state.inf.category.title.toLowerCase() ===
                          "trucks" && (
                          <TableRow>
                            <TableCell>No. of Tyre</TableCell>
                            <TableCell>{this.state.inf.no_of_tyre}</TableCell>
                          </TableRow>
                        )}

                      {/* <TableRow>
                        <TableCell>Tyre Condition</TableCell>
                        <TableCell>{this.state.inf.tyre_cond}</TableCell>
                      </TableRow> */}
                      {/* <TableRow>
                        <TableCell>Scrap Vehicle</TableCell>
                        <TableCell>{this.state.inf.scrap_vehicle}</TableCell>
                      </TableRow> */}

                      <TableRow>
                        <TableCell>Vehicle Permit</TableCell>
                        <TableCell>{this.state.inf.vehicle_permit}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Brand Other</TableCell>
                        <TableCell>{this.state.inf.brand_other}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Model Other</TableCell>
                        <TableCell>{this.state.inf.model_other}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Secondary Name</TableCell>
                        <TableCell>
                          {this.state.inf.secondary_name
                            ? this.state.inf.secondary_name
                            : "No Secondary Name Found"}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Secondary Email</TableCell>
                        <TableCell>
                          {this.state.inf.secondary_email
                            ? this.state.inf.secondary_email
                            : "No Secondary Email Found"}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Secondary Mobile No</TableCell>
                        <TableCell>
                          {this.state.inf.secondary_mob
                            ? this.state.inf.secondary_mob
                            : "No Secondary Mobile Number Found"}
                        </TableCell>
                      </TableRow>

                      {/* <TableRow>
                <TableCell>{this.state.inf.vehicle_model}</TableCell>
                <TableCell>{this.state.inf.vehicle_model}</TableCell></TableRow>
                <TableRow>
                <TableCell>{this.state.inf.vehicle_model}</TableCell>
                <TableCell>{this.state.inf.vehicle_model}</TableCell></TableRow>

                <TableRow>
                <TableCell>{this.state.inf.vehicle_model}</TableCell>
                <TableCell>{this.state.inf.vehicle_model}</TableCell></TableRow>
                <TableRow>
                <TableCell>{this.state.inf.vehicle_model}</TableCell>
                <TableCell>{this.state.inf.vehicle_model}</TableCell></TableRow>
                <TableRow>
                <TableCell>{this.state.inf.vehicle_model}</TableCell>
                <TableCell>{this.state.inf.vehicle_model}</TableCell></TableRow>
                <TableRow>
                <TableCell>{this.state.inf.vehicle_model}</TableCell>
                <TableCell>{this.state.inf.vehicle_model}</TableCell></TableRow> */}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
            <h3 style={{ width: "100%" }}>Images</h3>
            <br />
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div
                style={{
                  position: "relative",
                  margin: "20px 30px",
                  width: "200px",
                  height: "200px",
                  borderRadius: "5px",
                }}
              >
                <img
                  src={
                    this.state.inf.engine_pic
                      ? " https://core.gaddideals.com" +
                        this.state.inf.engine_pic
                      : Nothing
                  }
                  alt="Engine"
                  style={{ width: "100%", height: "100%" }}
                />
                <h3
                  style={{
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    background: "#000",
                    color: "#fff",
                    width: "100%",
                    margin: "0",
                    opacity: " .6",
                  }}
                >
                  Engine
                </h3>
              </div>
              <div
                style={{
                  position: "relative",
                  margin: "20px 30px",
                  width: "200px",
                  height: "200px",
                  borderRadius: "5px",
                }}
              >
                <img
                  src={
                    this.state.inf.front_side_pic
                      ? " https://core.gaddideals.com" +
                        this.state.inf.front_side_pic
                      : Nothing
                  }
                  alt="Engine"
                  style={{ width: "100%", height: "100%" }}
                />
                <h3
                  style={{
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    background: "#000",
                    color: "#fff",
                    width: "100%",
                    margin: "0",
                    opacity: " .6",
                  }}
                >
                  Front Side
                </h3>
              </div>
              <div
                style={{
                  position: "relative",
                  margin: "20px 30px",
                  width: "200px",
                  height: "200px",
                  borderRadius: "5px",
                }}
              >
                <img
                  src={
                    this.state.inf.back_side_pic
                      ? " https://core.gaddideals.com" +
                        this.state.inf.back_side_pic
                      : Nothing
                  }
                  alt="Engine"
                  style={{ width: "100%", height: "100%" }}
                />
                <h3
                  style={{
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    background: "#000",
                    color: "#fff",
                    width: "100%",
                    margin: "0",
                    opacity: " .6",
                  }}
                >
                  Back Side
                </h3>
              </div>
              {this.state.inf.front_tyre &&
                (this.state.inf.front_tyre.length > 0 ? (
                  this.state.inf.front_tyre.map((resp, index) => (
                    <div
                      style={{
                        position: "relative",
                        margin: "20px 30px",
                        width: "200px",
                        height: "200px",
                        borderRadius: "5px",
                      }}
                      key={index}
                    >
                      <img
                        src={
                          resp ? " https://core.gaddideals.com" + resp : Nothing
                        }
                        alt=" Front Tyre"
                        style={{ width: "100%", height: "100%" }}
                      />
                      <h3
                        style={{
                          position: "absolute",
                          bottom: "0",
                          left: "0",
                          background: "#000",
                          color: "#fff",
                          width: "100%",
                          margin: "0",
                          opacity: " .6",
                        }}
                      >
                        Front Tyre
                      </h3>
                    </div>
                  ))
                ) : (
                  <div
                    style={{
                      position: "relative",
                      margin: "20px 30px",
                      width: "200px",
                      height: "200px",
                      borderRadius: "5px",
                    }}
                  >
                    <img
                      src={Nothing}
                      alt="Front Tyre"
                      style={{ width: "100%", height: "100%" }}
                    />
                    <h3
                      style={{
                        position: "absolute",
                        bottom: "0",
                        left: "0",
                        background: "#000",
                        color: "#fff",
                        width: "100%",
                        margin: "0",
                        opacity: " .6",
                      }}
                    >
                      Front Tyre
                    </h3>
                  </div>
                ))}
              {this.state.inf.side_pic_vehicle &&
                (this.state.inf.side_pic_vehicle.length > 0 ? (
                  this.state.inf.side_pic_vehicle.map((resp, index) => (
                    <div
                      style={{
                        position: "relative",
                        margin: "20px 30px",
                        width: "200px",
                        height: "200px",
                        borderRadius: "5px",
                      }}
                      key={index}
                    >
                      <img
                        src={
                          resp ? " https://core.gaddideals.com" + resp : Nothing
                        }
                        alt="Side Vehicle"
                        style={{ width: "100%", height: "100%" }}
                      />
                      <h3
                        style={{
                          position: "absolute",
                          bottom: "0",
                          left: "0",
                          background: "#000",
                          color: "#fff",
                          width: "100%",
                          margin: "0",
                          opacity: " .6",
                        }}
                      >
                        Side Vehicle
                      </h3>
                    </div>
                  ))
                ) : (
                  <div
                    style={{
                      position: "relative",
                      margin: "20px 30px",
                      width: "200px",
                      height: "200px",
                      borderRadius: "5px",
                    }}
                  >
                    <img
                      src={Nothing}
                      alt="Side Vehicle"
                      style={{ width: "100%", height: "100%" }}
                    />
                    <h3
                      style={{
                        position: "absolute",
                        bottom: "0",
                        left: "0",
                        background: "#000",
                        color: "#fff",
                        width: "100%",
                        margin: "0",
                        opacity: " .6",
                      }}
                    >
                      Side Vehicle
                    </h3>
                  </div>
                ))}
            </div>
          </Grid>
        </MoreInfo>

        {/* Restore Modal */}
        <Modal
          visible={this.state.isRestoreOpen}
          width="450"
          height="100"
          effect="fadeInUp"
          onClickAway={() => this.setState({ isRestoreOpen: false })}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <h4
              style={{ color: "black", textAlign: "center", display: "block" }}
            >
              Are you sure you want restore a vehicle?
            </h4>

            <br></br>
            <ButtonGroup>
              <Button
                className="btn"
                color="secondary"
                variant="contained"
                style={{ margin: "20px" }}
                onClick={() => {
                  this.handleRestoreVehicle();
                }}
              >
                {this.state.restoreLoading ? "Restoring..." : "Yes"}{" "}
              </Button>
              <Button
                className="btn"
                color="primary"
                variant="contained"
                style={{ margin: "20px", marginLeft: "4px" }}
                onClick={() => {
                  this.setState({ isRestoreOpen: false });
                }}
              >
                NO
              </Button>
            </ButtonGroup>
          </Grid>
        </Modal>

        {/* Delete Modal */}
        <Modal
          visible={this.state.open}
          width="450"
          height="100"
          effect="fadeInUp"
          onClickAway={() => this.setState({ open: false })}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <h4
              style={{ color: "black", textAlign: "center", display: "block" }}
            >
              Are you sure about that???
            </h4>

            <br></br>
            <ButtonGroup>
              <Button
                className="btn"
                color="secondary"
                variant="contained"
                style={{ margin: "20px" }}
                onClick={() => {
                  this.handleDelete();
                }}
              >
                {this.state.delLoading ? "Deleting..." : "Yes"}{" "}
              </Button>
              <Button
                className="btn"
                color="primary"
                variant="contained"
                style={{ margin: "20px", marginLeft: "4px" }}
                onClick={() => {
                  this.setState({ open: false });
                }}
              >
                NO
              </Button>
            </ButtonGroup>
          </Grid>
        </Modal>
      </Container>
    );
  }
}
