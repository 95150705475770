import React, { Component } from 'react';
import Constants from '../../Variables/Constant';
import axios from 'axios';

export default class getCategory extends Component {
    state={
        categoryName:""
    }
    componentDidMount(){
        this.getCategory();
    }
    getCategory=()=>{
      let auth = localStorage.getItem("accessToken");
        axios(Constants.getUrls.getCategory+this.props.name,
          {
            headers: { Authorization: `Bearer ${auth}` },
          }).then((resp)=>
        { if(resp.data.category.title){
          this.setState({
            categoryName:resp.data.category.title
          })} else {
            this.setState({
              categoryName:"Category Not Found"
            })
          }
        }
        ).catch(
          (err)=>{
            console.log(err);
            this.setState({
              categoryName:"Error"
            })
            
          }
        )

    }
  render() {
    return (<>
      {this.state.categoryName}</>
    )
  }
}
