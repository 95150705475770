import React, { Component } from "react";
import {
  Button,
  Container,
  FormControl,
  Grid,
  Select,
  InputLabel,
  Paper,
  NativeSelect,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
  ButtonGroup,
  MenuItem,
  Slide,
  OutlinedInput,
  Pagination,
  Fade,
} from "@mui/material";
import axios from "axios";
import { Link } from "react-router-dom";

import init from "../../helpers/WindowToken";
import Constants from "../Variables/Constant.jsx";
import Modal from "react-awesome-modal";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import SearchIcon from "@mui/icons-material/Search";
import ImageZoom from "@mui/material/Modal";
import ReadMore from "./helpers/ReadMore";

import "../../App.css";
const parse = require("html-react-parser");
export default class Banner extends Component {
  state = {
    open: false,
    getBanner: [],
    data: [],
    notifyStatus: false,
    notify: false,
    searchedValue: "",
    visible: false,
    delLoading: false,
    rowId: 0,
    status: "",
    active: 1,
    perPage: "10",
    limit: 10,
    currentPage: 1,
    page: 1,
    searchpage: 1,
    prevPage: 0,
    nextPage: 0,
    lastPage: 0,
    lastPageView: false,
    firstPageView: false,
    total: 0,
    from: 1,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: [],
    msg: "",
    search: false,
    type: "",
    zoom: false,
    zoomImg: "",
    totalPage: 1,
    searchTotalPage: 1,
    pageinfo: 1,
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  close = () => {
    this.setState({
      zoom: false,
    });
  };

  componentDidMount() {
    this.getBanner();
  }
  getBanner = () => {
    axios
      .get(
        Constants.getUrls.getAllBanner +
          "?page=" +
          this.state.page +
          "&limit=" +
          this.state.limit +
          "&sort=true&banner_type=" +
          this.state.type
      )
      .then((resp) => {
        // console.log(resp);

        this.setState({
          fromInc: 1,
          active: 1,
          toInc: 5,
          getBanner: resp.data.banner.docs,
          total: resp.data.banner.totalDocs,
          nextPage: resp.data.banner.hasNextPage,
          prevPage: resp.data.banner.hasPrevPage,
          limit: resp.data.banner.limit,
          pagingCounter: resp.data.banner.pagingCounter,
          totalPage: resp.data.banner.totalPages,
          pageinfo: this.state.page,
          // PAGINATION

          from: 1,
          // to: resp.data.data.length,
        });
      });
  };
  handleRows = (value) => {
    this.setState(
      {
        limit: value,
      },
      () => {
        if (this.state.search) {
          this.handleSearch();
        } else {
          this.getBanner();
        }
      }
    );
    // this.getBanner();
  };
  // PAGINATION BUTTONS

  handleNext = () => {
    if (this.state.search) {
      if (this.state.nextPage === true) {
        // console.log(this.state.nextPage)

        this.setState(
          {
            searchpage: Number(this.state.searchpage) + 1,
            // to: this.state.to + this.state.limit,
          },
          () => {
            this.handleSearch();
          }
        );
        // console.log(this.state.page)
        // this.getBanner();
      }
    } else {
      if (this.state.nextPage === true) {
        // console.log(this.state.nextPage)

        this.setState(
          {
            page: Number(this.state.page) + 1,
            // to: this.state.to + this.state.limit,
          },
          () => {
            this.getBanner();
          }
        );
        // console.log(this.state.page)
        // this.getBanner();
      }
    }
  };

  handlePrev = () => {
    if (this.state.search) {
      if (this.state.prevPage === true)
        this.setState(
          {
            searchpage: Number(this.state.searchpage) - 1,
          },

          () => {
            this.handleSearch();
          }
        );
    } else {
      if (this.state.prevPage === true)
        this.setState(
          {
            page: Number(this.state.page) - 1,
          },

          () => {
            this.getBanner();
          }
        );
      // this.getBanner();
    }
  };
  handleChangePage = (e) => {
    this.setState({
      pageinfo: e.target.value,
    });
  };
  handlePage = (e) => {
    e.preventDefault();
    if (this.state.pageinfo) {
      if (this.state.search) {
        if (
          this.state.pageinfo > 0 &&
          this.state.pageinfo <= this.state.searchTotalPage
        ) {
          // console.log(this.state.nextPage)

          this.setState(
            {
              searchpage: this.state.pageinfo,
              // to: this.state.to + this.state.limit,
            },
            () => {
              this.handleSearch();
            }
          );
          // console.log(this.state.page)
          // this.getAdd();
        }
      } else {
        if (
          this.state.pageinfo > 0 &&
          this.state.pageinfo <= this.state.totalPage
        ) {
          // console.log(this.state.nextPage)

          this.setState(
            {
              page: this.state.pageinfo,
              // to: this.state.to + this.state.limit,
            },
            () => {
              this.getBanner();
            }
          );
          // console.log(this.state.page)
          // this.getBrand();
        }
      }
    }
  };

  handleModal = (banner) => {
    this.setState(
      {
        rowId: banner._id,
        visible: true,
      },
      () => {
        console.log(this.state.rowId);
      }
    );
  };
  handleDelete = () => {
    this.setState({
      delLoading: true,
    });

    if (init() === "success") {
      // this.setState({ delLoading: true });
      let auth = localStorage.getItem("accessToken");
      axios
        .delete(Constants.deleteUrls.deleteBanner + "/" + this.state.rowId, {
          headers: { Authorization: `Bearer ${auth}` },
        })
        .then((res) => {
          //  console .log(res);
          //   console.log(Constants.deleteUrls.deleteBanner + "/" + this.state.rowId);
          this.setState({
            notify: true,
            delLoading: false,
            msg: res.data.message,
          });
          setTimeout(() => {
            this.setState({ notify: false, visible: false });

            if (this.state.search) {
              this.handleSearch();
            } else {
              this.getBanner();
            }
          }, 2000);
          //           setTimeout(() => {
          //             this.setState({ notify: false, visible: false }, () => {
          //              if(this.state.search){this.handleSearch();}else {this.getBanner();}
          // }})
          //             });
          //           }, 2000);
        })
        .catch((err) => console.log(err));
    }
  };
  // handlePage = (e) => {
  //   this.setState(
  //     {
  //       [e.target.name]: e.target.value,
  //       fromInc: 1,
  //       active: 1,
  //       toInc: 5,
  //     },
  //     () => {
  //       axios
  //         .get(
  //           Constants.getUrls.getAllBanner +
  //             "?page=" +
  //             this.state.active +
  //             "&limit=" +
  //             this.state.perPage +
  //             "&wish[]=title&wish[]=description&wish[]=status"
  //         )
  //         .then((resp) => {
  //           //console.log(resp.data);
  //           let btnIdArray = [];
  //           for (let i = 1; i <= resp.data.banner.totalPages; i++) {
  //             btnIdArray.push(i);
  //           }
  //           this.setState(
  //             {
  //               btnIdArray,
  //               getBanner: resp.data.banner.docs,
  //               total: resp.data.banner.totalDocs,
  //               from: 1,
  //               to: resp.data.banner.docs.length,
  //             },
  //             () => {
  //               let filteredBtnIdArray = this.state.btnIdArray.filter(
  //                 (v) => v >= this.state.fromInc && v <= this.state.toInc
  //               );
  //               this.setState({
  //                 filteredBtnIdArray,
  //               });
  //             }
  //           );
  //         });
  //     }
  //   );
  // };
  // currentPage = (v) => {
  //   axios
  //     .get(
  //       Constants.getUrls.getAllBanner +
  //         "?page=" +
  //         v +
  //         "&limit=" +
  //         this.state.perPage +
  //         "&wish[]=title&wish[]=description&wish[]=status"
  //     )
  //     .then((resp) => {
  //       // console.log(resp);
  //       this.setState({
  //         getBanner: resp.data.banner.docs,
  //         total: resp.data.banner.totalDocs,
  //         from: v * this.state.perPage - (this.state.perPage - 1),
  //         to:
  //           v * this.state.perPage > this.state.total
  //             ? this.state.total
  //             : v * this.state.perPage,
  //         active: v,
  //       });
  //     });
  // };
  // prevPages = () => {
  //   this.setState(
  //     {
  //       fromInc: this.state.fromInc - 5,
  //       toInc: this.state.toInc - 5,
  //     },
  //     () => {
  //       let filteredBtnIdArray = this.state.btnIdArray.filter(
  //         (v) => v >= this.state.fromInc && v <= this.state.toInc
  //       );
  //       if (filteredBtnIdArray.length >= 1) {
  //         if (filteredBtnIdArray.length === 1) {
  //           this.setState(
  //             {
  //               fromInc: this.state.fromInc + 4,
  //               toInc: this.state.toInc + 4,
  //             },
  //             () => {
  //               let filteredBtnIdArray = this.state.btnIdArray.filter(
  //                 (v) => v >= this.state.fromInc && v <= this.state.toInc
  //               );
  //               this.setState({ filteredBtnIdArray });
  //             }
  //           );
  //         } else if (filteredBtnIdArray.length === 2) {
  //           this.setState(
  //             {
  //               fromInc: this.state.fromInc + 3,
  //               toInc: this.state.toInc + 3,
  //             },
  //             () => {
  //               let filteredBtnIdArray = this.state.btnIdArray.filter(
  //                 (v) => v >= this.state.fromInc && v <= this.state.toInc
  //               );
  //               this.setState({ filteredBtnIdArray });
  //             }
  //           );
  //         } else if (filteredBtnIdArray.length === 3) {
  //           this.setState(
  //             {
  //               fromInc: this.state.fromInc + 2,
  //               toInc: this.state.toInc + 2,
  //             },
  //             () => {
  //               let filteredBtnIdArray = this.state.btnIdArray.filter(
  //                 (v) => v >= this.state.fromInc && v <= this.state.toInc
  //               );
  //               this.setState({ filteredBtnIdArray });
  //             }
  //           );
  //         } else if (filteredBtnIdArray.length === 4) {
  //           this.setState(
  //             {
  //               fromInc: this.state.fromInc + 1,
  //               toInc: this.state.toInc + 1,
  //             },
  //             () => {
  //               let filteredBtnIdArray = this.state.btnIdArray.filter(
  //                 (v) => v >= this.state.fromInc && v <= this.state.toInc
  //               );
  //               this.setState({ filteredBtnIdArray });
  //             }
  //           );
  //         } else {
  //           this.setState({ filteredBtnIdArray });
  //         }
  //       } else {
  //         this.setState(
  //           {
  //             fromInc: this.state.fromInc + 5,
  //             toInc: this.state.toInc + 5,
  //           },
  //           () => {
  //             let filteredBtnIdArray = this.state.btnIdArray.filter(
  //               (v) => v >= this.state.fromInc && v <= this.state.toInc
  //             );
  //             this.setState({ filteredBtnIdArray });
  //           }
  //         );
  //       }
  //     }
  //   );
  // };
  handleStatus = (e, status, id) => {
    let payload;
    let auth = localStorage.getItem("accessToken");

    if (init() === "success") {
      if (status === "active") {
        payload = {
          status: "draft",
        };
      } else {
        payload = {
          status: "active",
        };
      }
      payload = JSON.stringify(payload);
      axios
        .post(Constants.statusUrl + id + "?type=banner", payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth}`,
          },
        })
        .then((res) => {
          if (res.data.message) {
            this.setState({
              notify: true,
              msg: res.data.message,
            });
          }
          if (res.data.status === "failed") {
            this.setState({
              notify: true,
              msg: "failed to update status",
            });
          }
          setTimeout(() => {
            this.setState({ notify: false }, () => {
              if (this.state.search) {
                this.handleSearch();
              } else {
                this.getBanner();
              }
            });
          }, 750);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  // defaultValues(){
  //   this.setState({
  //     page:1,
  //     limit:10
  //   })
  // }
  handlePages=(e,value)=>{
    if(this.state.search){
      this.setState({
        searchpage:value
      },()=>{
        this.handleSearch()
      })
    } else{
      this.setState({
        page:value
      },()=>{
        this.getBanner()
      })
    }
  }
  handleChange = (event) => {
    if (event.target.value.length < 1) {
      this.setState({
        searchedValue: "",
        search: false,
      });
      // this.defaultValues();
      this.getBanner();
    } else {
      this.setState({
        searchedValue: event.target.value,
      });
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState(
      {
        searchpage: 1,
      },
      () => {
        this.handleSearch();
      }
    );
  };
  handleSearch = (event) => {
   if(event){
    this.setState({
      searchedValue:event.target.value
    },()=>{
      this.handleSearch();
    })
   }
   if (this.state.searchedValue < 1) {
    this.setState({
      search: false,
    });
    // this.defaultValues();
    this.getBanner();
  } else {
    
    // console.log(this.state.searchedValue );
    if (this.state.searchedValue.length >= 2) {
      this.setState({
        search: true,
        page: 1,
      });
      axios
        .get(
          Constants.getUrls.getAllBanner +
            "?page=" +
            this.state.searchpage +
            "&limit=" +
            this.state.limit +
            "&q=" +
            this.state.searchedValue +
            "&sort=true&banner_type=" +
            this.state.type
          // this.state.name
        )
        .then((resp) => {
          // console.log(resp);
          // let btnIdArray = [];
          // for (let i = 1; i <= resp.data.pageCount; i++) {
          //   btnIdArray.push(i);
          // }
          this.setState({
            fromInc: 1,
            active: 1,
            toInc: 5,
            getBanner: resp.data.banner.docs,
            total: resp.data.banner.totalDocs,
            nextPage: resp.data.banner.hasNextPage,
            prevPage: resp.data.banner.hasPrevPage,
            limit: resp.data.banner.limit,
            pagingCounter: resp.data.banner.pagingCounter,
            searchTotalPage: resp.data.banner.totalPages,
            pageinfo: this.state.searchpage,
            // PAGINATION

            from: 1,

            // to: resp.data.data.length,
          });
          // this.setState(
          //   {

          //     // btnIdArray,
          //     // getBanner: resp.data.banner.docs,
          //     // total: resp.data.banner.totalDocs,
          //     // from: 1,
          //     // to: resp.data.docs.length,
          //     // searched: true,
          //     // searching: false,
          //   },
          //   () => {
          //     let filteredBtnIdArray = this.state.btnIdArray.filter(
          //       (v) => v >= this.state.fromInc && v <= this.state.toInc
          //     );
          //     this.setState({
          //       filteredBtnIdArray,
          //     });
          //   }
          // );
        });
    } }
  };
  handleType = (e) => {
    this.setState(
      {
        type: e.target.value,
        page: 1,
        searchpage: 1,
      },
      () => {
        if (this.state.search) {
          this.handleSearch();
        } else {
          this.getBanner();
        }
      }
    );
  };
  handleClick = () => {
    console.log("click");
  };

  // handleSearch = () => {
  //   axios
  //     .get(Constants.getUrls.getAllBanner + "?" + "limit=100")
  //     .then((resp) => {
  //       let filteredArray = resp.data.banner.docs.filter((ele) => {
  //         if (ele.title.toLowerCase().includes(this.state.searchedValue.toLowerCase())) {
  //           return ele;
  //         }
  //       });
  //       if (filteredArray)
  //         this.setState({
  //           getBanner: filteredArray,
  //         });
  //       else {
  //         this.setState({
  //           getBanner: "no result",
  //         });
  //       }
  //     });
  // };
  zoom = (e) => {
    this.setState({
      zoom: true,
      zoomImg: e,
    });
  };
  handleShow = () => {
    console.log("show");
  };
  render() {
    return (
      <Container maxwidth="xl">
        {this.state.notify ? (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={true}
            color={"s"}
            autoHideDuration={6000}
            message={this.state.msg}
          ></Snackbar>
        ) : null}
        <Grid container style={{ margin: "10px 0 10px" }} alignItems="baseline">
          <Grid item xs={4}>
            <Typography variant="h6">
              {/* {this.props.title} */}
              Banner
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <FormControl
              size="small"
              sx={{ width: "100%", paddingTop: "0", marginTop: "0" }}
            >
              <InputLabel id="demo-simple-select-autowidth-label">
                Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={this.state.type}
                onChange={this.handleType}
                autoWidth
                label="Type"
                sx={{ Top: "0", marginBottom: "0" }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>

                <MenuItem value="b">Buyer</MenuItem>
                <MenuItem value="s">Seller</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent="flex-end">
      
                <TextField
                  id="outlined-basic"
                  label="Search "
                  size="small"
                  variant="outlined"
                  onChange={this.handleSearch}
                  sx={{marginRight:"5px"}}
                />
           
            

              <Link to="/admin/addbanner" style={{ textDecoration: "none" }}>
                <Button
                  size="medium"
                  onClick={() => {
                    this.setState({ open: true });
                  }}
                  variant="contained"
                  color="primary"
                >
                  Add Banner
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Paper elevation={1}>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Sr.No.</TableCell>
                    <TableCell align="right">Title</TableCell>
                    <TableCell align="center">Description</TableCell>
                    <TableCell align="center">Sequence</TableCell>
                    <TableCell align="right">Type</TableCell>
                    <TableCell align="right">url</TableCell>
                    <TableCell align="right">Poster</TableCell>
                    <TableCell align="right">Status</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.getBanner.length > 0 ? (
                    this.state.getBanner.map((banner, index) => (
                      <TableRow key={banner._id}>
                        <TableCell>
                          {this.state.search
                            ? Number(
                                (this.state.searchpage - 1) * this.state.limit
                              ) + Number(index + 1)
                            : Number((this.state.page - 1) * this.state.limit) +
                              Number(index + 1)}
                        </TableCell>
                        <TableCell align="right">{banner.title}</TableCell>
                        <TableCell align="center">
                          <ReadMore>{banner.description}</ReadMore>
                          {/* {banner.description.length > 150
                            ? parse(
                                banner.description.substring(0, 150) + "..."
                              )
                            : parse(banner.description)} */}
                        </TableCell>
                        <TableCell align="center">{banner.sequence}</TableCell>
                        <TableCell align="right">
                          {banner.banner_type}
                        </TableCell>
                        <TableCell align="right">{banner.url}</TableCell>
                        <TableCell align="right" style={{ width: "100px" }}>
                          {/* <a href="#"> */}
                          <img
                            src={
                              " https://core.gaddideals.com" +
                              banner.poster
                            }
                            onClick={() => {
                              this.zoom(banner.poster);
                            }}
                            alt="No Image Found"
                            style={{
                              height: "90px",
                              width: "160px",
                              cursor: "pointer",
                            }}
                          />
                          {/* </a> */}
                        </TableCell>

                        <TableCell align="right">
                          <Switch
                            name="checked"
                            checked={banner.status === "active" ? true : false}
                            onChange={(e) =>
                              // console.log(e.target.checked);
                              this.handleStatus(e, banner.status, banner._id)
                            }
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Link
                            to={"/admin/updatebanner/" + banner._id}
                            style={{ textDecoration: "none" }}
                          >
                            <Button variant="outlined">Update</Button>
                          </Link>
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => this.handleModal(banner)}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Nothing to show here
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter></TableFooter>
              </Table>
              <TableRow
                style={{ display: "flex", justifyContent: "space-evenly" }}
              >
                {/* <div>
                  <p>
                    Showing {this.state.pagingCounter} to {this.state.limit} of{" "}
                    {this.state.total} Entries
                  </p>
                </div> */}

                {/* Rows PerPage START */}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "right",
                    justifyContent: "space-between",
                    marginLeft: "150px",
                    minWidth: "200px",
                    marginTop: "10px",
                  }}
                >
                  <p>Rows Per Page</p>

                  <FormControl
                    className="selectMerchants"
                    style={{
                      minWidth: "30px",
                      marginRight: "20px",
                      marginTop: "-5px",
                    }}
                  >
                    <InputLabel htmlFor="age-native-simple"></InputLabel>
                    <Select
                      native
                      defaultValue={10}
                      // value={state.age}
                      onChange={(e) => this.handleRows(e.target.value)}
                      inputProps={{
                        name: "Merchants",
                        id: "age-native-simple",
                      }}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                    </Select>
                  </FormControl>
                </div>
                {/* Rows PerPage END */}

               
                  {/* <ButtonGroup
                    variant="contained"
                    aria-label="outlined primary button group"
                  > */}
               
                  
                 
                  {/* </ButtonGroup> */}
                  <Pagination sx={{marginTop:"auto",marginBottom:"auto"}} count={this.state.search?this.state.searchTotalPage:this.state.totalPage} color="secondary" page={this.state.search?this.state.searchpage:this.state.page} onChange={this.handlePages} />

              
              </TableRow>
            </TableContainer>
          </Paper>
        </Grid>

        <Modal
          visible={this.state.visible}
          width="450"
          height="100"
          effect="fadeInUp"
          onClickAway={() => this.setState({ visible: false })}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <h4
              style={{ color: "black", textAlign: "center", display: "block" }}
            >
              Are you sure about that???
            </h4>

            <br></br>
            <ButtonGroup>
              <Button
                className="btn"
                color="secondary"
                variant="contained"
                style={{ margin: "20px" }}
                onClick={() => {
                  this.handleDelete();
                }}
              >
                {this.state.delLoading ? "Deleting..." : "Yes"}{" "}
              </Button>

              <Button
                className="btn"
                color="primary"
                variant="contained"
                style={{ margin: "20px", marginLeft: "4px" }}
                onClick={() => {
                  this.setState({ visible: false });
                }}
              >
                NO
              </Button>
            </ButtonGroup>
           
          </Grid>
        </Modal>

        <ImageZoom
          open={this.state.zoom}
          onClose={this.close}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <img
            src={" https://core.gaddideals.com" + this.state.zoomImg}
            style={{
              width: "60vw",
              height: "50vh",
              position: "absolute",
              top: "25vh",
              left: "20vw",
              bgcolor: "background.paper",
              boxShadow: 24,
            }}
          ></img>
        </ImageZoom>
      </Container>
    );
  }
}
