import React, { Component } from "react";
import axios from "axios";
import Constants from "../App/Variables/Constant";
import { Link, Navigate } from "react-router-dom";
import init from "../helpers/WindowToken";
import {
  Button,
  Container,
  Grid,
  Snackbar,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Resizer from "react-image-file-resizer";
import DoneIcon from "@mui/icons-material/Done";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

let fd = new FormData();

export default class AddCategory extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.emptyFile = React.createRef();
  }
  state = {
    loading: false,
    title: "",
    image: "",
    icon: "",
    description: "",
    notification: false,
    message: "",
    redirect: false,
    error: false,
    imgwaiting: false,
    iconwaiting: false,
    showLoading: false,
    showDone: false,
    showLoading1: false,
    showDone1: false,
  };

  handleChange = (e) => {
    if (e.target.name === "title") {
      this.setState({
        error: false,
      });
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  // handleChangeDesc(content) {
  //   // console.log(content); //Get Content Inside Editor
  //   this.setState(
  //     {
  //       description: content,
  //     },
  //     () => {
  //       console.log(this.state.description);
  //     }
  //   );
  // }
  handleFile1 = (e) => {
    // console.log(e.target.files)

    var file = e.target.files[0];
    if (file) {
      this.setState({
        imgwaiting: true,
        showLoading: true,
        showDone: false,
      });
      if (file.size > 100000) {
        try {
          this.setState({ imgwaiting: true });
          Resizer.imageFileResizer(
            file,
            500,
            500,
            "JPEG",
            100,
            0,
            (uri) => {
              // console.log(uri);
              this.setState(
                {
                  image: uri,
                  imgwaiting: false,

                  showDone: true,
                },
                () => {
                  if (this.state.loading) {
                    this.handleSubmit();
                  }
                }
              );
            },
            "file",
            300,
            300
          );
        } catch (err) {
          console.log(err);
          this.setState({
            notification: true,
            message:
              "Failed to Compress Image,press Add to submit Uncompressed Image ",
            [e.target.name]: e.target.files[0],
            imgwaiting: false,
            loading: false,
            showDone: true,
          });
          setTimeout(() => {
            this.setState({ notification: false, message: "" });
          }, 2000);
        }
      } else {
        this.setState({
          [e.target.name]: e.target.files[0],
          showDone: true,
          imgwaiting: false,
        });
      }
    }
  };
  handleFile2 = (e) => {
    var file = e.target.files[0];
    if (file) {
      this.setState({
        iconwaiting: true,
        showLoading1: true,
        showDone1: false,
      });
      if (file.size > 100000) {
        try {
          this.setState({ iconwaiting: true });
          Resizer.imageFileResizer(
            file,
            200,
            200,
            "JPEG",
            100,
            0,
            (uri) => {
              // console.log(uri);
              this.setState(
                {
                  icon: uri,
                  iconwaiting: false,

                  showDone1: true,
                },
                () => {
                  if (this.state.loading) {
                    this.handleSubmit();
                  }
                }
              );
            },
            "file",
            30,
            30
          );
        } catch (err) {
          console.log(err);
          this.setState({
            notification: true,
            message:
              "Failed to Compress Icon,press Add to submit Uncompressed Icon ",
            [e.target.name]: e.target.files[0],
            iconwaiting: false,
            loading: false,
            showDone: true,
          });
          setTimeout(() => {
            this.setState({ notification: false, message: "" });
          }, 2000);
        }
      } else {
        this.setState({
          [e.target.name]: e.target.files[0],
          showDone1: true,
          iconwaiting: false,
        });
      }
    }
  };
  handleSubmit = (e) => {
    if (e) e.preventDefault();
    //   if(this.state.description.length===0){
    //     this.setState({notification:true,
    //     message:"Description cannot be empty"}
    //     )
    //     setTimeout(()=>{
    //       this.setState({notification:false})
    //     },2000)
    // } else {
    fd = new FormData();
    // if (this.state.image === "" || this.state.icon === "") {
    //   window.location.reload();
    // } else {

    this.setState({ loading: true });
    fd.append("title", this.state.title);
    fd.append("description", this.state.description);
    fd.append("image", this.state.image);
    fd.append("icon", this.state.icon);

    if (init() === "success") {
      if (this.state.imgwaiting) {
        this.setState({
          notification: true,
          message: "Wait image is still Compressing",
        });
        setTimeout(() => {
          this.setState({ notification: false, message: "" });
        }, 1000);
      } else if (this.state.iconwaiting) {
        this.setState({
          notification: true,
          message: "Wait icon is still Compressing",
        });
        setTimeout(() => {
          this.setState({ notification: false, message: "" });
        }, 1000);
      } else {
        let auth = localStorage.getItem("accessToken");
        axios
          .post(Constants.postUrls.postCategory, fd, {
            headers: { Authorization: `Bearer ${auth}` },
          })
          .then((resp) => {
            // console.log(resp.data.message);
            this.setState({
              loading: false,
              message: resp.data.message,
              notification: true,
            });
            if (resp.data.message === undefined || resp.data.message === null) {
              // console.log(resp);
              if (resp.data.errors.msg) {
                this.setState({
                  message: resp.data.errors.msg,
                });
              } else {
                this.setState({
                  message: "unknown error",
                });
              }
            }
            if (
              resp.data.message ===
              `Title already exist with the name ${this.state.title}`
            ) {
              this.setState({
                error: true,
              });
            }

            setTimeout(() => {
              if (resp.data.message === "Category added successfully") {
                this.setState({
                  redirect: true,
                });
              }
              this.setState({
                // title: "",
                // image: "",
                // icon:"",
                // description: "",
                message: "",
                notification: false,
              });
              // fd.delete("title");
              // fd.delete("image");
              // fd.delete("icon");
              // fd.delete("description");
              // this.textInput.current.value = "";
              // this.emptyFile.current.value = "";
            }, 2000);
          })
          .catch((err) => {
            console.log(err);
            // window.location.reload();
            this.setState({
              notification: true,
              message: "error occured probabaly due to oversize file",
            });
            setTimeout(() => {
              this.setState({
                // title:"",
                //   image:"",
                //   icon:"",
                //   description:"",
                notification: false,
                message: "",
                loading: false,
              });
              // this.textInput.current.value = "";
              // this.emptyFile.current.value = "";
            }, 2000);
          });
      }
    }
    // }
    // }
  };
  render() {
    return (
      <Container maxWidth="xl">
        {this.state.redirect && <Navigate to="/category" replace={true} />}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6"> Category </Typography>
          </Grid>
        </Grid>
        {this.state.notification ? (
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={true}
            autoHideDuration={5000}
            color="green"
            message={this.state.message}
          ></Snackbar>
        ) : null}
        <div
          style={{
            background: "#5b5757",
            padding: "20px",
            borderRadius: "10px",
            // color: rgba(0, 0, 0, 0.87);
            width: "100%",
            border: "0",
            display: "flex",
            position: "relative",
            fontSize: ".875rem",
            minWidth: "0",
            wordWrap: " break-word",
            /* background: #FFF; */
            boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
            marginTop: "30px",
            borderRadius: "6px",
            marginBottom: "30px",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <div
            style={{
              background:
                "linear-gradient(60deg, rgb(128 115 115), rgb(255 250 250)) rgb(153, 153, 153))",
              boxShadow:
                " rgb(0 0 0 / 14%) 0px 4px 20px 0px, rgb(128 120 120 1/ 40%) 0px 7px 10px -5px",
              float: "left",
              padding: "15px",
              marginTop: "-43px",
              marginRight: "15px",
              borderRadius: "3px",
              backgroundColor: "#999",
              width: "6%",
              height: "60px",
              marginBottom: "25px",
              textAlign: "center",
            }}
          >
            <EditIcon />
          </div>
          <form onSubmit={this.handleSubmit}>
            <Grid container sm={12} spacing={2}>
              <Grid item sm={4}>
                <TextField
                  name="title"
                  error={this.state.error}
                  id={this.state.error ? "outlined-error" : "outlined-basic"}
                  label={this.state.error ? "Error" : "Title"}
                  size="medium"
                  variant="outlined"
                  required={true}
                  type="text"
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  value={this.state.title}
                />
              </Grid>
              {/* {
                          this.state.all_exchange_type.map(val =>(
                              console.log(val.instrument)
                          ))
                      } */}

              <Grid item sm={4}>
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    height: "100%",
                    alignItems: "baseline",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "baseline",
                    }}
                  >
                    <label>Image:</label>
                    <input
                      name="image"
                      type="file"
                      onChange={this.handleFile1}
                      accept="image/*"
                      ref={this.emptyFile}
                      disabled={
                        this.state.showLoading &&
                        (this.state.showDone ? false : true)
                      }
                      required
                      style={{ width: "100%" }}
                    />
                  </div>
                  {this.state.showLoading ? (
                    this.state.showDone ? (
                      <DoneIcon
                        color="success"
                        sx={{ position: "absolute", right: "0" }}
                      />
                    ) : (
                      <CircularProgress
                        color="success"
                        size={25}
                        sx={{ position: "absolute", right: "0" }}
                      />
                    )
                  ) : null}
                </div>
                {/* <TextField
                  name="image"
                  id="outlined-basic"
                  placeholder={"Add Category Image"}
                  size="medium"
                  variant="outlined"
                  required={true}
                  type="file"
                  onChange={this.handleFile1}
                  style={{ width: "100%" }}
                /> */}
              </Grid>
              <Grid item sm={4}>
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    height: "100%",
                    alignItems: "baseline",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "baseline",
                    }}
                  >
                    <label>Icon:</label>
                    <input
                      name="icon"
                      type="file"
                      onChange={this.handleFile2}
                      accept="image/*"
                      ref={this.emptyFile}
                      disabled={
                        this.state.showLoading1 &&
                        (this.state.showDone1 ? false : true)
                      }
                      required
                      style={{ width: "100%" }}
                    />
                  </div>
                  {this.state.showLoading1 ? (
                    this.state.showDone1 ? (
                      <DoneIcon
                        color="success"
                        sx={{ position: "absolute", right: "0" }}
                      />
                    ) : (
                      <CircularProgress
                        color="success"
                        size={25}
                        sx={{ position: "absolute", right: "0" }}
                      />
                    )
                  ) : null}
                </div>
                {/* <TextField
                  name="icon"
                  id="outlined-basic"
                  placeholder={"Add Category Icon"}
                  size="medium"
                  variant="outlined"
                  required={true}
                  type="file"
                  onChange={this.handleFile2}
                  style={{ width: "100%" }}
                /> */}
              </Grid>
            </Grid>
            <div style={{ color: "black", marginTop: "20px" }}>
              <label style={{ color: "white" }}>Description:</label>

              <CKEditor
                className="ckeditor"
                value={this.state.description}
                editor={ClassicEditor}
                data={this.state.description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  this.setState({
                    description: data,
                  });
                }}
              />
            </div>
            <br />
            <Button
              variant="contained"
              type="submit"
              size="medium"
              color="primary"
              style={{
                marginRight: "20px",

                marginTop: "35px",
              }}
            >
              {this.state.loading ? "Adding.." : "Add "}
            </Button>
            <Link
              to={{
                pathname: "/category",
              }}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                type="submit"
                color="secondary"
                style={{ marginRight: "20px", marginTop: "35px" }}
              >
                Cancel
              </Button>
            </Link>
          </form>
        </div>
      </Container>
    );
  }
}
