import React, { useState } from 'react';
import {
    Button,
    Container,
    Grid,
    Snackbar,
    TextField,
    Typography,
    CircularProgress,
    Paper
  } from "@mui/material";
  import axios from "axios";
  import Constants from "../../Variables/Constant";
  

const Inspection = (props) => {
    const {id,recall}=props;
    const [file,setFile]=useState("");
    const [loading,setLoading]=useState(false);
    const ref = React.useRef();

    const handleFile=(e)=>{
        if(e){
          console.log(e.target.files[0].name.includes(".pdf"))
            setFile(e.target.files[0]);
        }
    }
    const handleFileUpload=()=>{
      if(file!==""){
        if(file.name.includes(".pdf")){
        setLoading(true);
        let auth=localStorage.getItem("accessToken");
  if(file!==""){
   let fd=new FormData;
   fd.append("physical_inspection_report",file);
console.log(Constants.putUrls.updatePhysicalInpection+id)
axios.put(Constants.putUrls.updatePhysicalInpection+id,fd,{ headers: { Authorization: `Bearer ${auth}` } }).then((resp)=>{

    recall(resp.data.message);
    
        setLoading(false);
        if(resp.data.message==="Updated Successfully")
        {ref.current.value = "";
      setFile("")}

}).catch((err)=>{
    console.log(err);
    recall("Failed to update check console" );
    setLoading(false);
})

    }
    }else{
      recall("Please Upload pdf only" );
    }}}

  return (
    <div
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      justifyContent: "center",
    }}
  >
  
    <input
      name="inspectionReport"
      onChange={(e) => {
        handleFile(e);
      }}
      ref={ref}
      type="file"
      accept="application/pdf"
    />
    <Button
      size="small"
      sx={{ width: "90px" ,cursor:"pointer"}}
      variant="contained"
    
      component="label"
      disabled={file===""}
      onClick={() => handleFileUpload()}
    >
     {loading?"Updating": "Submit"}
    </Button>
  </div>
  )
}
export default Inspection;