import React, { Component } from "react";
import axios from "axios";
import Constants from "../App/Variables/Constant";
import { Link } from "react-router-dom";
import init from "../helpers/WindowToken";
import DoneIcon from "@mui/icons-material/Done";
import {
  Backdrop,
  Button,
  Container,
  Select,
  InputLabel,
  Fade,
  FormControl,
  Grid,
  // Modal,
  Paper,
  Snackbar,
  Switch,
  Form,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Box,
  FormControlLabel,
  TextareaAutosize,
  MenuItem,
  makeStyles,
  CircularProgress,
} from "@mui/material";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  // Table,
  Row,
  Col,
  UncontrolledAlert,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
} from "reactstrap";
import Modal from "react-awesome-modal";

import { Navigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import Autocomplete from "@mui/material/Autocomplete";
// import SunEditor from "suneditor-react";
// import "suneditor/dist/css/suneditor.min.css";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Resizer from "react-image-file-resizer";

let fd = new FormData();
export default class AddBanner extends Component {
  constructor(props) {
    super(props);
    this.handleFile = this.handleFile.bind(this);

    this.emptyFile = React.createRef();
  }

  state = {
    loading: false,
    title: "",
    image: "",
    sequence: "",
    description: "",
    bannerType: "",
    notification: false,
    message: "",
    reload: false,
    redirect: false,
    url: "",
    error: false,
    waiting: false,
    showLoading: false,
    showDone: false,
  };
  handleChange = (e) => {
    if (e.target.name === "title") {
      this.setState({
        error: false,
      });
    }
    // console.log(e);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // handleChangeDesc(content) {
  //   // console.log(content); //Get Content Inside Editor
  //   this.setState(
  //     {
  //       description: content,
  //     },
  //     () => {
  //       console.log(this.state.description);
  //     }
  //   );
  // }
  handleFile = (e) => {
    // console.log(e.target.files[0])
    // console.log(e.target.files[0].size)
    // console.log(e.target.files[0].type)
    // this.setState({
    //   [e.target.name]: e.target.files[0],
    // })

    var file = e.target.files[0];
    if (file) {
      this.setState({
        waiting: true,
        showLoading: true,
        showDone: false,
      });
      if (file.size > 100000) {
        try {
          this.setState({ waiting: true });
          Resizer.imageFileResizer(
            file,
            1500,
            700,
            "JPEG",
            100,
            0,
            (uri) => {
              // console.log(uri);
              this.setState(
                {
                  image: uri,
                  waiting: false,

                  showDone: true,
                },
                () => {
                  if (this.state.loading) {
                    this.handleSubmit();
                  }
                }
              );
            },
            "file",
            1000,
            600
          );
        } catch (err) {
          console.log(err);
          this.setState({
            notification: true,
            message:
              "Failed to Compress Image,press Add to submit Uncompressed Image ",
            [e.target.name]: e.target.files[0],
            waiting: false,
            loading: false,
            showDone: true,
          });
          setTimeout(() => {
            this.setState({ notification: false, message: "" });
          }, 2000);
        }
      } else {
        this.setState({
          [e.target.name]: e.target.files[0],
          showDone: true,
          waiting: false,
        });
      }
    }
  };

  handleSubmit = (e) => {
    if (e) e.preventDefault();

    //   if(this.state.bannerType.length===0){
    //     this.setState({notification:true,
    //     message:"Type cannot be empty"}
    //     )
    //     setTimeout(()=>{
    //       this.setState({notification:false,
    //       message:""})
    //     },2000)
    // } else {
    fd = new FormData();
    // if(this.state.image===""){
    //   window.location.reload();
    // } else{

    this.setState({ loading: true });
    if (init() === "success") {
      if (this.state.waiting) {
        this.setState({
          notification: true,
          message: "Wait image is still Compressing",
        });
        setTimeout(() => {
          this.setState({ notification: false, message: "" });
        }, 1000);
      } else {
        fd.append("title", this.state.title);
        fd.append("sequence", this.state.sequence);
        fd.append("description", this.state.description);
        fd.append("banner_type", this.state.bannerType);
        fd.append("url", this.state.url);
        fd.append("poster", this.state.image);
        let auth = localStorage.getItem("accessToken");
        axios
          .post(Constants.postUrls.postBanner, fd, {
            headers: { Authorization: `Bearer ${auth}` },
          })
          .then((resp) => {
            // axios.post("http://localhost:8081/createStocks", stockLoad).then((resp) => {
            // console.log(resp);
            this.setState({
              loading: false,
              notification: true,
              message: resp.data.message,
            });

            if (resp.data.message === undefined || resp.data.message === null) {
              // console.log(resp);
              if (resp.data.errors.msg) {
                this.setState({
                  message: resp.data.errors.msg,
                });
              } else {
                this.setState({
                  message: "unknown error",
                });
              }
            }
            // console.log(resp.data.message);
            if (
              resp.data.message ===
              `Title already exist with the name ${this.state.title}`
            ) {
              this.setState({
                error: true,
              });
            }
            setTimeout(() => {
              if (resp.data.message === "Banner added successfully") {
                this.setState({
                  redirect: true,
                });
              }
              // else if(resp.data.message===)

              this.setState({
                // title:"",
                // image:"",
                // sequence:"",
                // description:"",
                message: "",
                notification: false,
                //  reload:true
              });
              // this.emptyFile.current.value = "";
            }, 2000);
          })
          .catch((err) => {
            console.log(err);
            // window.location.reload();
            this.setState({
              notification: true,
              message: "error occured probaably due to oversize file",
            });
            setTimeout(() => {
              this.setState({
                // title:"",
                //   image:"",
                //   sequence:"",
                //   description:"",
                notification: false,
                message: "",
                loading: false,
              });
              // this.emptyFile.current.value = "";
            }, 2000);
          });
      }
    }
    // }
    // }
  };
  render() {
    return (
      <Container maxWidth="xl">
        {this.state.redirect && <Navigate to="/banner" replace={true} />}

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6"> Banner </Typography>
          </Grid>
        </Grid>
        <div
          style={{
            background: "#5b5757",
            padding: "20px",
            borderRadius: "10px",
            // color: rgba(0, 0, 0, 0.87);
            width: "100%",
            border: "0",
            display: "flex",
            position: "relative",
            fontSize: ".875rem",
            minWidth: "0",
            wordWrap: " break-word",
            /* background: #FFF; */
            boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
            marginTop: "30px",
            borderRadius: "6px",
            marginBottom: "30px",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <div
            style={{
              background:
                "linear-gradient(60deg, rgb(128 115 115), rgb(255 250 250)) rgb(153, 153, 153))",
              boxShadow:
                " rgb(0 0 0 / 14%) 0px 4px 20px 0px, rgb(128 120 120 1/ 40%) 0px 7px 10px -5px",
              float: "left",
              padding: "15px",
              marginTop: "-43px",
              marginRight: "15px",
              borderRadius: "3px",
              backgroundColor: "#999",
              width: "6%",
              height: "60px",
              marginBottom: "25px",
              textAlign: "center",
            }}
          >
            <EditIcon />
          </div>
          <form onSubmit={this.handleSubmit}>
            <Grid container sm={12} spacing={2}>
              <Grid item sm={4}>
                <TextField
                  name="title"
                  error={this.state.error}
                  id={this.state.error ? "outlined-error" : "outlined-basic"}
                  label={this.state.error ? "Error" : "Title"}
                  size="medium"
                  variant="outlined"
                  // required={true}
                  maxRows={4}
                  multiline={true}
                  type="text"
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  value={this.state.title}
                />
              </Grid>

              {/* {
                        this.state.all_exchange_type.map(val =>(
                            console.log(val.instrument)
                        ))
                    } */}
              <Grid item sm={4}>
                <TextField
                  name="sequence"
                  id="outlined-basic"
                  label="Sequence"
                  size="medium"
                  variant="outlined"
                  required={true}
                  InputProps={{
                    inputProps: {
                      min: 1,
                    },
                  }}
                  rows={2}
                  type="number"
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  value={this.state.sequence}
                />
              </Grid>
              <Grid item sm={4}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="Id">Type</InputLabel>

                  <Select
                    name="bannerType"
                    labelId="Id"
                    id="Id"
                    // value={this.state.bannerType}
                    label="User Type"
                    onChange={this.handleChange}
                  >
                    <MenuItem value="buyer">Buyer</MenuItem>
                    <MenuItem value="seller">Seller</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item sm={4}>
                <TextField
                  name="url"
                  id="outlined-basic"
                  label="url"
                  size="medium"
                  variant="outlined"
                  // required={true}
                  type="text"
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  value={this.state.url}
                />
              </Grid>
              <Grid item sm={4}>
                {/* <TextField
                name="image"
                id="outlined-basic"
                placeholder={"Add Stocks Logo"}
                size="medium"
                variant="outlined"
                required={true}
                type="file"
                onChange={this.handleFile}
                style={{ width: "100%" }}
              /> */}
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    height: "100%",
                    alignItems: "baseline",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "baseline",
                    }}
                  >
                    <label>Poster:</label>
                    <input
                      name="image"
                      type="file"
                      onChange={this.handleFile}
                      accept="image/*"
                      ref={this.emptyFile}
                      disabled={
                        this.state.showLoading &&
                        (this.state.showDone ? false : true)
                      }
                      required
                      style={{ width: "100%" }}
                    />
                  </div>
                  {this.state.showLoading ? (
                    this.state.showDone ? (
                      <DoneIcon
                        color="success"
                        sx={{ position: "absolute", right: "0" }}
                      />
                    ) : (
                      <CircularProgress
                        color="success"
                        size={25}
                        sx={{ position: "absolute", right: "0" }}
                      />
                    )
                  ) : null}
                </div>
              </Grid>
            </Grid>
            <div style={{ color: "black", marginTop: "20px" }}>
              <label style={{ color: "white" }}>Description:</label>

              <CKEditor
                className="ckeditor"
                value={this.state.description}
                editor={ClassicEditor}
                data={this.state.description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  this.setState({
                    description: data,
                  });
                }}
              />
            </div>
            <br />
            <Button
              variant="contained"
              type="submit"
              size="medium"
              color="primary"
              style={{
                marginRight: "20px",

                marginTop: "35px",
              }}
            >
              {this.state.loading ? "Adding.." : "Add "}
            </Button>
            <Link
              to={{
                pathname: "/banner",
              }}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                type="submit"
                Size={15}
                color="secondary"
                style={{ marginRight: "20px", marginTop: "35px" }}
              >
                Cancel
              </Button>
            </Link>
          </form>
        </div>
        {this.state.notification ? (
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={true}
            autoHideDuration={5000}
            color="green"
            message={this.state.message}
            severity="success"
          ></Snackbar>
        ) : null}
      </Container>
    );
  }
}
