import React, { Component } from 'react';
import { Button, Container, Grid, Paper,Snackbar,CircularProgress, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import axios from "axios";
import {Link} from "react-router-dom"

let url="";

export default class NewPassword extends Component {
    state = {
        email: "",
        loading: false,
        error: false,
        messagw: "",
        newPassword: "",
        confirmNewPassword: "",
        redirect: false,
        notification: false,
      };
      submit = (e) => {
        this.setState({
          loading: true,
        });
        e.preventDefault();
        let submitLoad = {
          emailOtp: this.state.OTP,
          email: this.state.email,
         password: this.state.confirmNewPassword,
         
        };
        console.log(submitLoad);
        if (this.state.newPassword === this.state.confirmNewPassword) {
          axios.post(url, submitLoad).then((resp) => {
            console.log(resp);
            if (resp.data.status === 200) {
              setTimeout(() => {
                window.location.href = "/login";
              }, 1500);
              this.setState({
                notification: true,
                loading: false,
                message: resp.data.Message,
              });
  
              
            } else {
              //   localStorage.setItem("accessToken", resp.data.user.accessToken);
    
              this.setState({
                loading: false,
                message: "Wrong Credentials. Enter Valid OTP and Match Password !!!",
                error: true,
              });
              setTimeout(() => {
                this.setState({
                  error: false,
                });
              }, 2000);
            }
          });
        } else {
          this.setState({
            errorP: true,
          });
          setTimeout(() => {
            this.setState({
              loading: false,
              errorP: false,
              new_password: "",
              confirm_password: "",
            });
            document.getElementById("for").reset();
          }, 3000);
        }
      };
      handleInput = (e) => {
      this.setState({
        [e.target.name]: e.target.value,
      });
    };
      
  render() {
    return (
      <div className="loginWrapper">
          
            
              <div  style={{position:"relative",height:"100vh"}} >
              <Container maxWidth="md">
            <Grid container>
              <Grid item sm={6}  style={{ position: "relative", height: "80vh", marginTop: "100px" }}>
                {/* NOTIFICATION */}
                {this.state.notification ? (
                  <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={true}
                    color={"green"}
                    autoHideDuration={6000}
                    message={this.state.message}
                  ></Snackbar>
                ) : null}
                {/* NOTIFICATION */}
                <Paper className="loginCard" elevation={15} style={{ height: "70vh" }}>
                  <Typography variant="h4" component="h2" gutterBottom style={{color: "#64aa37", cursor:"pointer"}}>
                    Gaddideals.com
                  </Typography>
                  <Typography variant="h6" component="h1">
                    New PassWord
                  </Typography>
  
                  {/* <Typography
                      color="primary"
                      variant="body2"
                      style={{ alignSelf: "flex-end" , cursor:"pointer" }}
                    >
                      Forgot password?
                    </Typography> */}
                  <form className="loginFormWrapper forgot" onSubmit={this.submit} style={{minHeight:"180px"}}>
                  <TextField
                      id="outlined-basic"
                      label="Enter Email"
                      variant="outlined"
                      fullWidth
                      name="email"
                      onChange={this.handleInput}
                      required
                      style={{margin: "7px 0"}}
                    /> 
                    <TextField
                      
                      id="outlined-basic"
                      label="Enter OTP"
                      variant="outlined"
                      fullWidth
                      name="OTP"
                      onChange={this.handleInput}
                      required
                      style={{margin: "7px 0"}}
                    />
                     <TextField
                      type="password"
                      id="outlined-basic"
                      label="New Password"
                      variant="outlined"
                      fullWidth
                      name="newPassword"
                      onChange={this.handleInput}
                      required
                      style={{margin: "7px 0"}}
                    />
                     <TextField
                      type="password"
                      id="outlined-basic"
                      label="Confirm New Password"
                      variant="outlined"
                      fullWidth
                      name="confirmNewPassword"
                      onChange={this.handleInput}
                      required
                      style={{margin: "7px 0"}}
                    />
  
                    <Button
                      style={{ alignSelf: "flex-start", backgroundColor: "#64aa37", color: "#fff" }}
                      // style={{ alignSelf: "flex-start" }}
                      variant="contained"
                      size="large"
                      type="submit"
                    >
                      {this.state.loading ? (
                        "Submitting"
                      ) : this.state.error ? (
                        <span style={{ color: "#BD0600" }}>
                          Incorrect Credentials!!!
                        </span>
                      ) : (
                        "Submit"
                      )}
                    </Button>
                    {this.state.loading ? (
                      <CircularProgress size="1.5rem" color="secondary" />
                    ) : null}
                  </form>
  
                  {/* <Grid
                      item
                      sm={12}
                      style={{ position: "relative"}}
                    >
                      <Link to="/forgot" style={{ textDecoration: "none" }}>
                        <Typography
                          color="primary"
                          variant="body2"
                          style={{
                            alignSelf: "center",
                            cursor: "pointer",
                            display: "inline",
                          }}
                        >
                          Forgot password?
                        </Typography> 
                      </Link>
                      <Link to="/change" style={{ textDecoration: "none" }}>
                        <Typography
                          color="primary"
                          variant="body2"
                          style={{
                            alignSelf: "flex-end",
                            cursor: "pointer",
                            display: "inline",
                           
                          }}
                        >
                          Change password?
                        </Typography>
                      </Link>
                    </Grid> */}
                </Paper>
              </Grid>
            </Grid>
          </Container>
              </div>
  
          </div>
        
        
    )
  }
}
