import React, { Component, useCallback } from "react";
import axios from "axios";
import Constants from "../App/Variables/Constant";
import { Link, Navigate } from "react-router-dom";
import init from "../helpers/WindowToken";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {
  Button,
  Grid,
  Snackbar,
  TextField,
  Typography,
  Container,
  Autocomplete,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Modal,
  Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Nothing from "../Assets/Nothing.jpg";
import { width } from "@mui/system";
let fd = new FormData();

export default class UpdateVehicles extends Component {
  state = {
    loading: false,
    vehicleId: "",
    category: "",
    brand: "",
    city: "",
    vehicleModel: "",
    regNo: "",
    yr: "",
    kmDriven: "",
    noOfOwner: "",
    transmission: "",
    states: "",
    insurance: "",
    taxValidity: "",
    vehicleCondition: "",
    scrapVehicle: "",
    noOfTyre: 0,
    noOfSeats: 0,
    horsePower: "",
    tyreCondition: "",
    fitnessCertificate: "",
    price: "",
    rcDocument: "",
    enginePic: "",
    frontSidePic: "",
    backSidePic: "",
    frontTyre: [],
    sidePicVehicle: [],
    brandName: null,
    brandId: "",
    brandData: [],
    categoryName: null,
    categoryData: [],
    categoryId: "",
    modelName: null,
    modelId: "",
    modelData: [],
    yearName: null,
    yearId: "",
    tyre1: "",
    tyre2: "",
    sidePicVehicle1: "",
    sidePicVehicle2: "",
    reftyre1: null,
    reftyre2: null,
    refsidePicVehicle1: null,
    refsidePicVehicle2: null,
    rc: null,
    engine: null,
    engineCond: "",
    frontSide: null,
    backSide: null,
    category: null,
    brand: null,
    year: null,
    model: null,
    fuelType: null,
    bodyType: null,
    noOfHours: 0,

    yearData: [],
    fuelTypeName: null,
    fuelTypeId: "",
    fuelTypeData: [],
    cityName: null,
    cityId: "",
    cityData: [],
    stateName: null,
    stateId: "",
    stateData: [],
    bodyTypeName: null,
    bodyTypeId: "",
    bodyTypeData: [],
    modelName: null,
    modelId: "",
    modelData: [],
    vehiclePermit: "",
    inf: "",

    notification: false,
    message: "",
    redirect: false,
    imageChange: false,
    textChange: false,
    error: false,
    sec_name: "",
    sec_email: "",
    sec_mob_no: "",

    isFrontSideOpen: false,
    frontSideCrop: {
      unit: "%",
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    },

    isEngineOpen: false,
    engineCrop: {
      unit: "%",
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    },

    isBacksideOpen: false,
    backsideCrop: {
      unit: "%",
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    },

    isTyre1Open: false,
    tyre1Crop: {
      unit: "%",
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    },

    isTyre2Open: false,
    tyre2Crop: {
      unit: "%",
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    },

    isSide1Open: false,
    side1Crop: {
      unit: "%",
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    },

    isSide2Open: false,
    side2Crop: {
      unit: "%",
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    },
  };
  componentDidMount() {
    axios
      .get(Constants.getUrls.getALLCategory)
      .then((resp) => {
        let temp = [];
        resp.data.category.docs.forEach((res) => {
          if (res.status === "active")
            temp.push({ label: res.title, id: res._id });
        });
        this.setState(
          {
            categoryData: temp,
          },
          () => {
            this.checkCategory();
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(Constants.getUrls.getAllBrand + "?&limit=500")
      .then((resp) => {
        let temp1 = [];
        resp.data.brand.docs.forEach((res) => {
          if (res.status === "active")
            temp1.push({ label: res.title, id: res._id });
        });
        this.setState(
          {
            brandData: temp1,
          },
          () => {
            this.checkBrand();
          }
        );
        // console.log(temp1);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(Constants.getUrls.getAllyears + "?&limit=500")
      .then((resp) => {
        let temp2 = [];
        resp.data._yrs.docs.forEach((res) => {
          temp2.push({ label: res.year.toString(), id: res._id });
        });
        this.setState(
          {
            yearData: temp2,
          },
          () => {
            this.checkYears();
          }
        );
        // consyearole.log(temp1);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(Constants.getUrls.getAllFueltype + "?status=active" + "&limit=500")
      .then(
        (resp) => {
          let temp3 = [];
          resp.data._getFuel.docs.forEach((res) => {
            temp3.push({ label: res.title, id: res._id });
          });
          this.setState({
            fuelTypeData: temp3,
          });
          // consyearole.log(temp1);
        },
        () => {
          this.checkFuelType();
        }
      )
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(Constants.getUrls.getAllBodyType + "?status=active" + "&limit=500")
      .then((resp) => {
        let temp4 = [];
        resp.data._getBodyType.docs.forEach((res) => {
          temp4.push({ label: res.title, id: res._id });
        });
        this.setState(
          {
            bodyTypeData: temp4,
          },
          () => {
            this.checkBodyType();
          }
        );
        // consyearole.log(temp1);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(Constants.getUrls.getAllModel + "?status=active" + "&limit=5000")
      .then((resp) => {
        let temp5 = [];
        resp.data.model.docs.forEach((res) => {
          temp5.push({ label: res.name, id: res._id });
        });
        this.setState(
          {
            modelData: temp5,
          },
          () => {
            this.checkModel();
          }
        );
        // consyearole.log(temp1);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(Constants.getUrls.getAllState + "?limit=500")
      .then((resp) => {
        let temp6 = [];
        resp.data.getAllStates.docs.forEach((res) => {
          if (res.status === "active")
            temp6.push({ label: res.title, id: res._id });
        });
        this.setState(
          {
            stateData: temp6,
          },
          () => {
            this.checkState();
          }
        );
        // consyearole.log(temp1);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(Constants.getUrls.getAllCity + "?limit=5000")
      .then((resp) => {
        let temp7 = [];
        resp.data.getAllCities.docs.forEach((res) => {
          if (res.status === "active")
            temp7.push({ label: res.title, id: res._id });
        });
        this.setState(
          {
            cityData: temp7,
          },
          () => {
            this.checkCity();
          }
        );
        // consyearole.log(temp1);
      })
      .catch((err) => {
        console.log(err);
      });

    setTimeout(() => {
      this.getVehicles();
    }, 1000);
  }
  getVehicles = () => {
    let id = window.location.pathname.split("/")[3];
    this.setState({
      vehicleId: id,
    });
    if (init() === "success") {
      let auth = localStorage.getItem("accessToken");
      axios
        .get(Constants.getUrls.getVehicle + id, {
          headers: { Authorization: `Bearer ${auth}` },
        })
        .then((resp) => {
          //  console.log("this");
          //  console.log(resp.data.category.image);
          console.log(resp.data.vehicle);
          if (resp.data.vehicle.front_tyre) {
            if (resp.data.vehicle.front_tyre.length > 0) {
              resp.data.vehicle.front_tyre.forEach((ele, index) => {
                this.setState({
                  ["tyre" + (index + 1)]: ele,
                });
              });
            }
          }
          if (resp.data.vehicle.side_pic_vehicle) {
            if (resp.data.vehicle.side_pic_vehicle.length > 0) {
              resp.data.vehicle.side_pic_vehicle.forEach((ele, index) => {
                this.setState({
                  ["sidePicVehicle" + (index + 1)]: ele,
                });
              });
            }
          }

          this.setState(
            {
              category: resp.data.vehicle.category,
              brand: resp.data.vehicle.brand,
              model: resp.data.vehicle.model,
              bodyType: resp.data.vehicle.bodyType,
              fuelType: resp.data.vehicle.fuelType,
              year: resp.data.vehicle.years,
              inf: resp.data.vehicle,
              states: resp.data.vehicle.state,
              city: resp.data.vehicle.city,
              engineCond: resp.data.vehicle.engine_cond,
              vehicleModel: resp.data.vehicle.vehicle_model,
              regNo: resp.data.vehicle.reg_no,
              kmDriven: resp.data.vehicle.km_driven,
              noOfOwner: resp.data.vehicle.no_of_owner.toLowerCase(),
              transmission: resp.data.vehicle.transmission,
              insurance: resp.data.vehicle.insurance || "",
              taxValidity: resp.data.vehicle.tax_validity || "",
              vehicleCondition: resp.data.vehicle.vehicle_condition,
              scrapVehicle: resp.data.vehicle.scrap_vehicle,
              noOfTyre: resp.data.vehicle.no_of_tyre || "",
              noOfSeats: resp.data.vehicle.no_of_seats,
              noOfHours: resp.data.vehicle.no_of_hrs,
              horsePower: resp.data.vehicle.horse_power
                ? resp.data.vehicle.horse_power
                : 0,
              tyreCondition: resp.data.vehicle.tyre_cond,
              fitnessCertificate: resp.data.vehicle.fitness_certificate,
              price: resp.data.vehicle.selling_price,
              rcDocument: resp.data.vehicle.rc_document,
              enginePic: resp.data.vehicle.engine_pic,
              frontSidePic: resp.data.vehicle.front_side_pic,
              backSidePic: resp.data.vehicle.back_side_pic,
              frontTyre: resp.data.vehicle.front_tyre,
              sidePicVehicle: resp.data.vehicle.side_pic_vehicle,
              vehiclePermit: resp.data.vehicle.vehicle_permit,
              bodyType: resp.data.vehicle.bodyType,
              sec_name: resp.data.vehicle?.secondary_name,
              sec_email: resp.data.vehicle?.secondary_email,
              sec_mob_no: resp.data.vehicle?.secondary_mob,
              // horse_power: resp.data.vehicle.horse_power ,
            },
            () => {
              this.checkCategory();
              this.checkModel();
              this.checkBrand();
              this.checkYears();
              this.checkFuelType();
              this.checkBodyType();
              this.checkState();
              this.checkCity();
            }
          );
          if (resp.data.vehicle.category) {
            if (resp.data.vehicle.category.title.toLowerCase() === "tractors") {
              this.setState({
                noOfHours: resp.data.vehicle.no_of_hrs,
              });
              console.log("this");
            }
          }
        });
    }
  };
  checkCategory = () => {
    if (this.state.category) {
      if (this.state.categoryData.length > 0) {
        this.state.categoryData.forEach((element) => {
          if (element.id == this.state.category._id) {
            this.setState({
              categoryName: element.label,
              categoryId: element.id,
            });
          }
        });
      }
    }
  };
  checkState = () => {
    if (this.state.states) {
      if (this.state.stateData.length > 0) {
        this.state.stateData.forEach((element) => {
          if (element.id == this.state.states._id) {
            this.setState({
              stateName: element.label,
              stateId: element.id,
            });
          }
        });
      }
    }
  };
  checkCity = () => {
    if (this.state.city) {
      if (this.state.cityData.length > 0) {
        this.state.cityData.forEach((element) => {
          if (element.id == this.state.city._id) {
            this.setState({
              cityName: element.label,
              cityId: element.id,
            });
          }
        });
      }
    }
  };
  checkBrand = () => {
    if (this.state.brand) {
      if (this.state.brandData.length > 0) {
        this.state.brandData.forEach((element) => {
          if (element.id == this.state.brand._id) {
            this.setState({
              brandName: element.label,
              brandId: element.id,
            });
          }
        });
      }
    }
  };
  checkModel = () => {
    if (this.state.model) {
      if (this.state.modelData.length > 0) {
        this.state.modelData.forEach((element) => {
          if (element.id == this.state.model._id) {
            this.setState({
              modelName: element.label,
              modelId: element.id,
            });
          }
        });
      }
    }
  };
  checkYears = () => {
    if (this.state.year) {
      this.state.yearData.forEach((element) => {
        if (element.id == this.state.year._id) {
          this.setState({
            yearName: element.label,
            yearId: element.id,
          });
        }
      });
    }
  };
  checkFuelType = () => {
    if (this.state.fuelType) {
      if (this.state.fuelTypeData.length > 0) {
        this.state.fuelTypeData.forEach((element) => {
          if (element.id == this.state.fuelType._id) {
            this.setState({
              fuelTypeName: element.label,
              fuelTypeId: element.id,
            });
          }
        });
      }
    }
  };
  checkBodyType = () => {
    if (this.state.bodyType) {
      if (this.state.bodyTypeData.length > 0) {
        this.state.bodyTypeData.forEach((element) => {
          if (element.id == this.state.bodyType._id) {
            this.setState({
              bodyTypeName: element.label,
              bodyTypeId: element.id,
            });
          }
        });
      }
    }
  };

  getBrandId = (e, val_id, val) => {
    // console.log("this")
    // console.log(e)
    this.setState({
      brandId: val_id,
      brandName: val,
    });
  };
  getCategoryId = (e, val_id, val) => {
    this.setState({
      categoryId: val_id,
      categoryName: val,
    });
  };

  getFuelTypeId = (e, val_id, val) => {
    // console.log("this")
    // console.log(e)
    this.setState({
      fuelTypeId: val_id,
      fuelTypeName: val,
    });
  };

  getBodyTypeId = (e, val_id, val) => {
    // console.log("this")
    // console.log(e)
    this.setState({
      bodyTypeId: val_id,
      bodyTypeName: val,
    });
  };
  getStateId = (e, val_id, val) => {
    // console.log("this")
    // console.log(e)
    this.setState({
      stateId: val_id,
      stateName: val,
    });
  };
  getCityId = (e, val_id, val) => {
    // console.log("this")
    // console.log(e)
    this.setState({
      cityId: val_id,
      cityName: val,
    });
  };
  getYearId = (e, val_id, val) => {
    // console.log("this")
    // console.log(e)
    this.setState({
      yearId: val_id,
      yearName: val,
    });
  };
  getModelId = (e, val_id, val) => {
    // console.log("this")
    // console.log(e)
    this.setState({
      modelId: val_id,
      modelName: val,
    });
  };
  handleFile = (e) => {
    if (e.target.files[0]) {
      this.setState(
        {
          [e.target.name]: e.target.files[0],
        },
        () => {
          this.refForImages(e);
        }
      );
    }
  };
  refForImages = (e) => {
    if (e.target.name === "rcDocument") {
      this.setState({ rc: URL.createObjectURL(this.state.rcDocument) });
    }
    if (e.target.name === "enginePic") {
      this.setState({
        isEngineOpen: true,
        engine: URL.createObjectURL(this.state.enginePic),
      });
    }
    if (e.target.name === "frontSidePic") {
      this.setState({
        isFrontSideOpen: true,
        frontSide: URL.createObjectURL(this.state.frontSidePic),
      });
    }
    if (e.target.name === "backSidePic") {
      this.setState({
        isBacksideOpen: true,
        backSide: URL.createObjectURL(this.state.backSidePic),
      });
    }
    if (e.target.name === "tyre1") {
      this.setState({
        isTyre1Open: true,
        reftyre1: URL.createObjectURL(this.state.tyre1),
      });
    }
    if (e.target.name === "tyre2") {
      this.setState({
        isTyre2Open: true,
        reftyre2: URL.createObjectURL(this.state.tyre2),
      });
    }
    if (e.target.name === "sidePicVehicle1") {
      this.setState({
        isSide1Open: true,
        refsidePicVehicle1: URL.createObjectURL(this.state.sidePicVehicle1),
      });
    }
    if (e.target.name === "sidePicVehicle2") {
      this.setState({
        isSide2Open: true,
        refsidePicVehicle2: URL.createObjectURL(this.state.sidePicVehicle2),
      });
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onFrontsideCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onEngineCropComplete = (crop) => {
    this.makeEngineClientCrop(crop);
  };

  onBacksideCropComplete = (crop) => {
    this.makeBacksideClientCrop(crop);
  };

  onFronttyre1CropComplete = (crop) => {
    this.makeFronttyre1ClientCrop(crop);
  };

  onFronttyre2CropComplete = (crop) => {
    this.makeFronttyre2ClientCrop(crop);
  };

  onSidepic1CropComplete = (crop) => {
    this.makeSidepic1ClientCrop(crop);
  };

  onSidepic2CropComplete = (crop) => {
    this.makeSidepic2ClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ frontSideCrop: crop });
  };

  onEngineCropChange = (crop, percentCrop) => {
    this.setState({ engineCrop: crop });
  };

  onBacksideCropChange = (crop, percentCrop) => {
    this.setState({ backsideCrop: crop });
  };

  onFronttyre1CropChange = (crop, percentCrop) => {
    this.setState({ tyre1Crop: crop });
  };

  onFronttyre2CropChange = (crop, percentCrop) => {
    this.setState({ tyre2Crop: crop });
  };

  onSidepic1CropChange = (crop, percentCrop) => {
    this.setState({ side1Crop: crop });
  };

  onSidepic2CropChange = (crop, percentCrop) => {
    this.setState({ side2Crop: crop });
  };

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        // window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const frontsideCroppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ frontsideCroppedImageUrl });
    }
  }

  async makeEngineClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const engineCroppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ engineCroppedImageUrl });
    }
  }

  async makeBacksideClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const backsideCroppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ backsideCroppedImageUrl });
    }
  }

  async makeFronttyre1ClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const tyre1CroppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ tyre1CroppedImageUrl });
    }
  }

  async makeFronttyre2ClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const tyre2CroppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ tyre2CroppedImageUrl });
    }
  }

  async makeSidepic1ClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const side1CroppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ side1CroppedImageUrl });
    }
  }

  async makeSidepic2ClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const side2CroppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ side2CroppedImageUrl });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    // fd = new FormData();
    // // if (this.state.image === "") {
    // //   window.location.reload();
    // // } else {
    //   this.setState({ loading: true });
    //   fd.append("title", this.state.title);
    //   fd.append("description", this.state.description);
    //   fd.append("image", this.state.image);

    // if(this.state.brandId.length===0 z
    //   ||this.state.categoryId.length===0 ||this.state.vehicleId.length===0  ){
    //     this.setState({
    //       notification:true,
    //       message:"Data cannot be empty"
    //     })
    //     setTimeout(()=>{
    //       this.setState({
    //         message:"",
    //         notification:false
    //       })
    //     },2000)

    //   } else{

    if (init() === "success") {
      let fd = new FormData();

      console.log(this.state.modelId);
      this.setState({ loading: true });
      fd.append("category", this.state.categoryId);
      fd.append("state", this.state.stateId);
      fd.append("city", this.state.cityId);
      fd.append("brand", this.state.brandId);
      fd.append("model", this.state.modelId);
      fd.append("years", this.state.yearId);
      fd.append("reg_no", this.state.regNo);
      if (
        this.state.categoryName === "Tractors" ||
        this.state.categoryName === "Construction Equipments"
      ) {
        fd.append("no_of_hrs", this.state.noOfHours);
      } else {
        fd.append("km_driven", this.state.kmDriven);
      }

      fd.append("bodyType", this.state.bodyTypeId);

      // fd.appe nd("no_of hjv_hrs", formData.noofhrs);
      fd.append("no_of_owner", this.state.noOfOwner);
      fd.append("fuelType", this.state.fuelTypeId);
      fd.append("insurance", this.state.insurance);
      fd.append("tax_validity", this.state.taxValidity);
      fd.append("vehicle_permit", this.state.vehiclePermit);
      fd.append("scrap_vehicle", this.state.scrapVehicle);
      if (this.state.categoryName === "Trucks") {
        fd.append("no_of_tyre", this.state.noOfTyre);
      }

      fd.append("horse_power", this.state.horsePower);
      if (this.state.categoryName === "Buses") {
        fd.append("no_of_seats", Number(this.state.noOfSeats));
      }
      fd.append("tyre_cond", this.state.tyreCondition);
      fd.append("engine_cond", this.state.engineCond);
      fd.append("selling_price", this.state.price);
      if (this.state.fitnessCertificate) {
        fd.append("fitness_certificate", this.state.fitnessCertificate);
      } else {
        if (this.state.fitnessCertificate === "") {
          fd.append("fitness_certificate", "");
        }
      }
      fd.append("rc_document", this.state.rcDocument);

      const engFile = await fetch(this.state.engineCroppedImageUrl)
        .then((r) => r.blob())
        .then(
          (blobFile) =>
            new File([blobFile], this.state.engineCroppedImageUrl, {
              type: blobFile.type,
            })
        );

      const frontSideFile = await fetch(this.state.frontsideCroppedImageUrl)
        .then((r) => r.blob())
        .then(
          (blobFile) =>
            new File([blobFile], this.state.frontsideCroppedImageUrl, {
              type: blobFile.type,
            })
        );

      const backSideFile = await fetch(this.state.backsideCroppedImageUrl)
        .then((r) => r.blob())
        .then(
          (blobFile) =>
            new File([blobFile], this.state.backsideCroppedImageUrl, {
              type: blobFile.type,
            })
        );

      const fronttyreLeftFile = await fetch(this.state.tyre1CroppedImageUrl)
        .then((r) => r.blob())
        .then(
          (blobFile) =>
            new File([blobFile], this.state.tyre1CroppedImageUrl, {
              type: blobFile.type,
            })
        );

      const fronttyreRightFile = await fetch(this.state.tyre2CroppedImageUrl)
        .then((r) => r.blob())
        .then(
          (blobFile) =>
            new File([blobFile], this.state.tyre2CroppedImageUrl, {
              type: blobFile.type,
            })
        );

      const sidePicLeftFile = await fetch(this.state.side1CroppedImageUrl)
        .then((r) => r.blob())
        .then(
          (blobFile) =>
            new File([blobFile], this.state.side1CroppedImageUrl, {
              type: blobFile.type,
            })
        );

      const sidePicRightFile = await fetch(this.state.side2CroppedImageUrl)
        .then((r) => r.blob())
        .then(
          (blobFile) =>
            new File([blobFile], this.state.side2CroppedImageUrl, {
              type: blobFile.type,
            })
        );

      if (this.state.engineCroppedImageUrl) {
        fd.append("engine_pic", engFile);
      } else {
        fd.append("engine_pic", this.state.enginePic);
      }

      if (this.state.frontsideCroppedImageUrl) {
        fd.append("front_side_pic", frontSideFile);
      } else {
        fd.append("front_side_pic", this.state.frontSidePic);
      }

      if (this.state.backsideCroppedImageUrl) {
        fd.append("back_side_pic", backSideFile);
      } else {
        fd.append("back_side_pic", this.state.backsideCroppedImageUrl);
      }

      if (this.state.tyre1CroppedImageUrl) {
        fd.append("front_tyre", fronttyreLeftFile);
      } else {
        fd.append("front_tyre", this.state.tyre1);
      }

      if (this.state.tyre2CroppedImageUrl) {
        fd.append("front_tyre", fronttyreRightFile);
      } else {
        fd.append("front_tyre", this.state.tyre2);
      }

      // fd.append("engine_pic", this.state.enginePic);
      // fd.append("front_side_pic", this.state.frontSidePic);
      // fd.append("back_side_pic", this.state.backSidePic);
      // fd.append("front_tyre", this.state.tyre1);
      // fd.append("front_tyre", this.state.tyre2);
      // fd.append("horse_power", this.state.horse_power)

      if (this.state.side1CroppedImageUrl) {
        fd.append("side_pic_vehicle", sidePicLeftFile);
      } else {
        fd.append("side_pic_vehicle", this.state.sidePicVehicle1);
      }

      if (this.state.side2CroppedImageUrl) {
        fd.append("side_pic_vehicle", sidePicRightFile);
      } else {
        fd.append("side_pic_vehicle", this.state.sidePicVehicle2);
      }
      fd.append("secondary_name", this.state.sec_name);
      fd.append("secondary_email", this.state.sec_email);
      fd.append("secondary_mob", this.state.sec_mob_no);

      let auth = localStorage.getItem("accessToken");
      console.log(Constants.putUrls.updateVehicle + this.state.vehicleId);
      axios
        .put(Constants.putUrls.updateVehicle + this.state.vehicleId, fd, {
          headers: { Authorization: `Bearer ${auth}` },
        })
        .then((resp) => {
          console.log(resp);
          this.setState({
            loading: false,
            message: resp.data.message,
            notification: true,
          });
          if (resp.data.message === undefined || resp.data.message === null) {
            // console.log(resp);
            if (resp.data.errors.msg) {
              this.setState({
                message: resp.data.errors.msg,
              });
            } else {
              this.setState({
                message: "unknown error",
              });
            }
          }

          setTimeout(() => {
            this.setState({
              // name:"",
              // brandName:"",
              // brandId:"",
              // categoryName:"",
              // categoryId:"",
              notification: false,
              message: "",
            });
            if (resp.data.message === "Updated Successfully") {
              this.setState({
                redirect: true,
              });
            }
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
          // window.location.reload();
          this.setState({
            notification: true,
            message: "some error occured check your console or try again",
          });
          setTimeout(() => {
            this.setState({
              // name:"",
              // brandName:"",
              // brandId:"",
              // categoryName:"",
              // categoryId:"",
              notification: false,
              message: "",
              loading: false,
            });
          }, 2000);
        });
      // }
    }
    // }
  };

  render() {
    return (
      <Container maxWidth="xl">
        {this.state.redirect && <Navigate to="/vehicles" replace={true} />}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6"> Update Vehicle </Typography>
          </Grid>
        </Grid>
        {this.state.notification ? (
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={true}
            autoHideDuration={5000}
            color="green"
            message={this.state.message}
          ></Snackbar>
        ) : null}
        <div
          style={{
            background: "#5b5757",
            padding: "20px",
            borderRadius: "10px",
            // color: rgba(0, 0, 0, 0.87);
            width: "100%",
            border: "0",
            display: "flex",
            position: "relative",
            fontSize: ".875rem",
            minWidth: "0",
            wordWrap: " break-word",
            /* background: #FFF; */
            boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
            marginTop: "30px",
            borderRadius: "6px",
            marginBottom: "30px",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <div
            style={{
              background:
                "linear-gradient(60deg, rgb(128 115 115), rgb(255 250 250)) rgb(153, 153, 153))",
              boxShadow:
                " rgb(0 0 0 / 14%) 0px 4px 20px 0px, rgb(128 120 120 1/ 40%) 0px 7px 10px -5px",
              float: "left",
              padding: "15px",
              marginTop: "-43px",
              marginRight: "15px",
              borderRadius: "3px",
              backgroundColor: "#999",
              width: "6%",
              height: "60px",
              marginBottom: "25px",
              textAlign: "center",
            }}
          >
            <EditIcon />
          </div>
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item lg={4} sm={6} md={4} xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  fullWidth={true}
                  options={this.state.categoryData}
                  onChange={(e, value) => {
                    if (!value) {
                      this.getCategoryId(e, "", null);
                    } else this.getCategoryId(e, value.id, value.label);
                  }}
                  value={this.state.categoryName}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value
                  }
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Category" />
                  )}
                />
              </Grid>
              <Grid item lg={4} sm={6} md={4} xs={12}>
                <Autocomplete
                  disablePortal
                  fullWidth={true}
                  id="combo-box-demo"
                  options={this.state.brandData}
                  onChange={(e, value) => {
                    if (!value) {
                      this.getBrandId(e, "", null);
                    } else this.getBrandId(e, value.id, value.label);
                  }}
                  value={this.state.brandName}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value
                  }
                  sx={{ width: "100%", minWidth: "300" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Brand" />
                  )}
                />
              </Grid>

              <Grid item lg={4} sm={6} md={4} xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  fullWidth={true}
                  options={this.state.modelData}
                  onChange={(e, value) => {
                    if (!value) {
                      this.getModelId(e, "", null);
                    } else this.getModelId(e, value.id, value.label);
                  }}
                  value={this.state.modelName}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value
                  }
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Model" />
                  )}
                />
              </Grid>
              <Grid item lg={4} sm={6} md={4} xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  fullWidth={true}
                  options={this.state.stateData}
                  onChange={(e, value) => {
                    if (!value) {
                      this.getStateId(e, "", null);
                    } else this.getStateId(e, value.id, value.label);
                  }}
                  value={this.state.stateName}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value
                  }
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="State" />
                  )}
                />
              </Grid>

              <Grid item lg={4} sm={6} md={4} xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  fullWidth={true}
                  options={this.state.cityData}
                  onChange={(e, value) => {
                    if (!value) {
                      this.getCityId(e, "", null);
                    } else this.getCityId(e, value.id, value.label);
                  }}
                  value={this.state.cityName}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value
                  }
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="City" />
                  )}
                />
              </Grid>

              <Grid item lg={4} sm={6} md={4} xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  fullWidth={true}
                  options={this.state.yearData}
                  onChange={(e, value) => {
                    if (!value) {
                      this.getYearId(e, "", null);
                    } else this.getYearId(e, value.id, value.label);
                  }}
                  value={this.state.yearName}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value
                  }
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Year" />
                  )}
                />
              </Grid>
              <Grid item lg={4} sm={6} md={4} xs={12}>
                <TextField
                  name="regNo"
                  id="outlined-basic"
                  label="Reg No."
                  size="medium"
                  variant="outlined"
                  required={true}
                  type="text"
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  value={this.state.regNo}
                />
              </Grid>
              <Grid item lg={4} sm={6} md={4} xs={12}>
                <TextField
                  name={
                    this.state.categoryName === "Tractors" ||
                    this.state.categoryName === "Construction Equipments"
                      ? "noOfHours"
                      : "kmDriven"
                  }
                  id="outlined-basic"
                  label={
                    this.state.categoryName === "Tractors" ||
                    this.state.categoryName === "Construction Equipments"
                      ? "Hours Driven"
                      : "Km Driven"
                  }
                  size="medium"
                  variant="outlined"
                  // required={true}
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 1,
                    },
                  }}
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  value={
                    this.state.categoryName === "Tractors" ||
                    this.state.categoryName === "Construction Equipments"
                      ? this.state.noOfHours
                      : this.state.kmDriven
                  }
                />
              </Grid>
              <Grid item lg={4} sm={6} md={4} xs={12}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">
                    No. of Owner
                  </InputLabel>
                  <Select
                    name="noOfOwner"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.noOfOwner}
                    label="No. of Owner"
                    onChange={this.handleChange}
                  >
                    <MenuItem value="first">First</MenuItem>
                    <MenuItem value="second">Second</MenuItem>
                    <MenuItem value="third">Third</MenuItem>
                    <MenuItem value="fourth">Fourth</MenuItem>
                    <MenuItem value="fifth">Fifth</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={4} sm={6} md={4} xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  fullWidth={true}
                  options={this.state.fuelTypeData}
                  onChange={(e, value) => {
                    if (!value) {
                      this.getFuelTypeId(e, "", null);
                    } else this.getFuelTypeId(e, value.id, value.label);
                  }}
                  value={this.state.fuelTypeName}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value
                  }
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Fuel Type" />
                  )}
                />
              </Grid>
              <Grid item lg={4} sm={6} md={4} xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  fullWidth={true}
                  options={this.state.bodyTypeData}
                  onChange={(e, value) => {
                    if (!value) {
                      this.getBodyTypeId(e, "", null);
                    } else this.getBodyTypeId(e, value.id, value.label);
                  }}
                  value={this.state.bodyTypeName}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value
                  }
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Body Type" />
                  )}
                />
              </Grid>
              <Grid item lg={4} sm={6} md={4} xs={12}>
                <TextField
                  name="insurance"
                  id="outlined-basic"
                  label="Insurance"
                  size="medium"
                  variant="outlined"
                  // required={true}
                  type="date"
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  value={this.state.insurance?.toString().substring(0, 10)}
                />
              </Grid>
              <Grid item lg={4} sm={6} md={4} xs={12}>
                <TextField
                  name="taxValidity"
                  id="outlined-basic"
                  label="Tax Validity"
                  size="medium"
                  variant="outlined"
                  // required={true}
                  type="date"
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  value={this.state.taxValidity?.toString().substring(0, 10)}
                />
              </Grid>
              <Grid item lg={4} sm={6} md={4} xs={12}>
                <TextField
                  name="vehiclePermit"
                  id="outlined-basic"
                  label="Vehicle Length(in Feet)"
                  size="medium"
                  variant="outlined"
                  // required={true}
                  type="text"
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  value={this.state.vehiclePermit}
                />
                {/* <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Vehicle Permit
                  </InputLabel>
                  <Select
                    name="vehiclePermit"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.vehiclePermit}
                    label="Vehicle Permit"
                    onChange={this.handleChange}
                  >
                    <MenuItem value="national">National</MenuItem>
                    <MenuItem value="state">State</MenuItem>
                    <MenuItem value="no">No</MenuItem>
                  </Select>
                </FormControl> */}
              </Grid>

              {this.state.inf.category &&
                this.state.inf.category.title === "Trucks" && (
                  <Grid item lg={4} sm={6} md={4} xs={12}>
                    <TextField
                      name="noOfTyre"
                      id="outlined-basic"
                      label="No. of Tyre"
                      size="medium"
                      variant="outlined"
                      // required={true}
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 1,
                        },
                      }}
                      onChange={this.handleChange}
                      style={{ width: "100%" }}
                      value={this.state.noOfTyre}
                    />
                  </Grid>
                )}
              <Grid item lg={4} sm={6} md={4} xs={12}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Tyre Condition
                  </InputLabel>
                  <Select
                    name="tyreCondition"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.tyreCondition.toLowerCase()}
                    label="Tyre Condition"
                    onChange={this.handleChange}
                  >
                    <MenuItem value="bad">Bad</MenuItem>

                    <MenuItem value="good">Good</MenuItem>
                    <MenuItem value="very good">Very Good</MenuItem>
                    <MenuItem value="average">Average</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={4} sm={6} md={4} xs={12}>
                <TextField
                  name="price"
                  id="outlined-basic"
                  label="Price"
                  size="medium"
                  variant="outlined"
                  required={true}
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 1,
                    },
                  }}
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  value={this.state.price}
                />
              </Grid>
              <Grid item lg={4} sm={6} md={4} xs={12}>
                <TextField
                  name="sec_name"
                  id="outlined-basic"
                  label="Secondary Name"
                  size="medium"
                  variant="outlined"
                  type="text"
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  value={this.state.sec_name}
                />
              </Grid>
              <Grid item lg={4} sm={6} md={4} xs={12}>
                <TextField
                  name="sec_email"
                  id="outlined-basic"
                  label="Secondary Email"
                  size="medium"
                  variant="outlined"
                  type="email"
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  value={this.state.sec_email}
                />
              </Grid>
              <Grid item lg={4} sm={6} md={4} xs={12}>
                <TextField
                  name="sec_mob_no"
                  id="outlined-basic"
                  label="Secondary Mobile No"
                  size="medium"
                  variant="outlined"
                  type="text"
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  value={this.state.sec_mob_no}
                />
              </Grid>
              {this.state?.inf?.category?.title === "Tractors" && (
                <Grid item lg={4} sm={6} md={4} xs={12}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Engine Condition
                    </InputLabel>
                    <Select
                      name="engineCond"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.engineCond.toLowerCase()}
                      label="Engine Condition"
                      onChange={this.handleChange}
                    >
                      <MenuItem value="bad">Bad</MenuItem>

                      <MenuItem value="good">Good</MenuItem>
                      <MenuItem value="average">Average</MenuItem>
                      <MenuItem value="very good">Very Good</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item lg={4} sm={6} md={4} xs={12}>
                <TextField
                  name="fitnessCertificate"
                  id="outlined-basic"
                  label="Fitness Certificate"
                  size="medium"
                  variant="outlined"
                  // required={true}
                  type="date"
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  value={this.state.fitnessCertificate
                    ?.toString()
                    .substring(0, 10)}
                />
              </Grid>

              {this.state.categoryName === "Tractors" && (
                <Grid item lg={4} sm={6} md={4} xs={12}>
                  <TextField
                    name="horsePower"
                    id="outlined-basic"
                    label="Horsepower"
                    size="medium"
                    variant="outlined"
                    // required={true}
                    type="number"
                    InputProps={{
                      inputProps: {
                        min: 1,
                      },
                    }}
                    onChange={this.handleChange}
                    style={{ width: "100%" }}
                    value={this.state.horsePower}
                  />
                </Grid>
              )}

              {this.state.categoryName === "Buses" && (
                <Grid item lg={4} sm={6} md={4} xs={12}>
                  <TextField
                    name="noOfSeats"
                    id="outlined-basic"
                    label="No Of Seats"
                    size="medium"
                    variant="outlined"
                    // required={true}
                    type="number"
                    InputProps={{
                      inputProps: {
                        min: 1,
                      },
                    }}
                    onChange={this.handleChange}
                    style={{ width: "100%" }}
                    value={this.state.noOfSeats}
                  />
                </Grid>
              )}

              <Grid item lg={4} sm={6} md={4} xs={12}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Rc Document
                  </InputLabel>
                  <Select
                    name="rcDocument"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.rcDocument}
                    label="Rc Document"
                    onChange={this.handleChange}
                    sx={{ width: "100%" }}
                  >
                    <MenuItem value="yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {/* Enigine Crop Image Modal */}
              <Modal
                open={this.state.isEngineOpen}
                onClose={() => {
                  this.setState({
                    isEngineOpen: false,
                  });
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "80%",
                    height: "80%",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    display: "flex",
                    p: 4,
                  }}
                >
                  {this.state.engine && (
                    <ReactCrop
                      src={this.state.engine}
                      crop={this.state.engineCrop}
                      ruleOfThirds
                      onImageLoaded={this.onImageLoaded}
                      onComplete={this.onEngineCropComplete}
                      onChange={this.onEngineCropChange}
                      crossorigin="anonymous"
                      aspect={16 / 9}
                    />
                  )}

                  {this.state.engineCroppedImageUrl && (
                    <img
                      alt="Crop"
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        marginLeft: "1rem",
                      }}
                      src={this.state.engineCroppedImageUrl}
                    />
                  )}
                </Box>
              </Modal>
              <Grid item lg={4} sm={6} md={4} xs={12}>
                <TextField
                  name="enginePic"
                  id="outlined-basic"
                  helperText="Engine Pic"
                  size="medium"
                  variant="outlined"
                  // required={true}
                  type="file"
                  onChange={this.handleFile}
                  style={{ width: "100%" }}
                  inputProps={{ accept: "image/*" }}
                  // value={this.state.enginePic}
                />
              </Grid>
              <Grid item lg={4} sm={6} md={4} xs={12}>
                <TextField
                  name="frontSidePic"
                  id="outlined-basic"
                  helperText="Front SIde Pic"
                  size="medium"
                  variant="outlined"
                  // required={true}
                  type="file"
                  onChange={this.handleFile}
                  style={{ width: "100%" }}
                  inputProps={{ accept: "image/*" }}
                  // value={this.state.frontSidePic}
                />
              </Grid>
              {/* Frontside Image Crop Modal */}
              <Modal
                open={this.state.isFrontSideOpen}
                onClose={() => {
                  this.setState({
                    isFrontSideOpen: false,
                  });
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "80%",
                    height: "80%",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                  }}
                >
                  {this.state.frontSide && (
                    <ReactCrop
                      src={this.state.frontSide}
                      crop={this.state.frontSideCrop}
                      ruleOfThirds
                      onImageLoaded={this.onImageLoaded}
                      onComplete={this.onFrontsideCropComplete}
                      onChange={this.onCropChange}
                      crossorigin="anonymous"
                      aspect={16 / 9}
                    />
                  )}

                  {this.state.frontsideCroppedImageUrl && (
                    <img
                      alt="Crop"
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        marginLeft: "1rem",
                      }}
                      src={this.state.frontsideCroppedImageUrl}
                    />
                  )}
                </Box>
              </Modal>
              {/* Backside Crop Image Modal */}
              <Modal
                open={this.state.isBacksideOpen}
                onClose={() => {
                  this.setState({
                    isBacksideOpen: false,
                  });
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "80%",
                    height: "80%",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                  }}
                >
                  {this.state.backSide && (
                    <ReactCrop
                      src={this.state.backSide}
                      crop={this.state.backsideCrop}
                      ruleOfThirds
                      onImageLoaded={this.onImageLoaded}
                      onComplete={this.onBacksideCropComplete}
                      onChange={this.onBacksideCropChange}
                      crossorigin="anonymous"
                      aspect={16 / 9}
                    />
                  )}

                  {this.state.backsideCroppedImageUrl && (
                    <img
                      alt="Crop"
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        marginLeft: "1rem",
                      }}
                      src={this.state.backsideCroppedImageUrl}
                    />
                  )}
                </Box>
              </Modal>
              <Grid item lg={4} sm={6} md={4} xs={12}>
                <TextField
                  name="backSidePic"
                  id="outlined-basic"
                  helperText="Back SIde Pic"
                  size="medium"
                  variant="outlined"
                  // required={true}
                  type="file"
                  onChange={this.handleFile}
                  style={{ width: "100%" }}
                  inputProps={{ accept: "image/*" }}
                  // value={this.state.backSidePic}
                />
              </Grid>
              {/* Tyre 1 crop image Modal */}
              <Modal
                open={this.state.isTyre1Open}
                onClose={() => {
                  this.setState({
                    isTyre1Open: false,
                  });
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "80%",
                    height: "80%",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                  }}
                >
                  {this.state.reftyre1 && (
                    <ReactCrop
                      src={this.state.reftyre1}
                      crop={this.state.tyre1Crop}
                      ruleOfThirds
                      onImageLoaded={this.onImageLoaded}
                      onComplete={this.onFronttyre1CropComplete}
                      onChange={this.onFronttyre1CropChange}
                      crossorigin="anonymous"
                      aspect={16 / 9}
                    />
                  )}

                  {this.state.tyre1CroppedImageUrl && (
                    <img
                      alt="Crop"
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        marginLeft: "1rem",
                      }}
                      src={this.state.tyre1CroppedImageUrl}
                    />
                  )}
                </Box>
              </Modal>
              <Grid item lg={4} sm={6} md={4} xs={12}>
                <TextField
                  name="tyre1"
                  id="outlined-basic"
                  helperText="Front Tyre 1"
                  size="medium"
                  variant="outlined"
                  // required={true}
                  type="file"
                  onChange={this.handleFile}
                  style={{ width: "100%" }}
                  inputProps={{ accept: "image/*" }}
                  // value={this.state.tyre1}
                />
              </Grid>
              {/* Tyre 2 Crop image Modal */}
              <Modal
                open={this.state.isTyre2Open}
                onClose={() => {
                  this.setState({
                    isTyre2Open: false,
                  });
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "80%",
                    height: "80%",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                  }}
                >
                  {this.state.reftyre2 && (
                    <ReactCrop
                      src={this.state.reftyre2}
                      crop={this.state.tyre2Crop}
                      ruleOfThirds
                      onImageLoaded={this.onImageLoaded}
                      onComplete={this.onFronttyre2CropComplete}
                      onChange={this.onFronttyre2CropChange}
                      crossorigin="anonymous"
                      aspect={16 / 9}
                    />
                  )}

                  {this.state.tyre2CroppedImageUrl && (
                    <img
                      alt="Crop"
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        marginLeft: "1rem",
                      }}
                      src={this.state.tyre2CroppedImageUrl}
                    />
                  )}
                </Box>
              </Modal>
              <Grid item lg={4} sm={6} md={4} xs={12}>
                <TextField
                  name="tyre2"
                  id="outlined-basic"
                  helperText="Front Tyre 2"
                  size="medium"
                  variant="outlined"
                  // required={true}
                  type="file"
                  onChange={this.handleFile}
                  style={{ width: "100%" }}
                  inputProps={{ accept: "image/*" }}
                  // value={this.state.tyre2}
                />
              </Grid>
              {/* Side Pic 1 Crop image Modal */}
              <Modal
                open={this.state.isSide1Open}
                onClose={() => {
                  this.setState({
                    isSide1Open: false,
                  });
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "80%",
                    height: "80%",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                  }}
                >
                  {this.state.refsidePicVehicle1 && (
                    <ReactCrop
                      src={this.state.refsidePicVehicle1}
                      crop={this.state.side1Crop}
                      ruleOfThirds
                      onImageLoaded={this.onImageLoaded}
                      onComplete={this.onSidepic1CropComplete}
                      onChange={this.onSidepic1CropChange}
                      crossorigin="anonymous"
                      aspect={16 / 9}
                    />
                  )}

                  {this.state.side1CroppedImageUrl && (
                    <img
                      alt="Crop"
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        marginLeft: "1rem",
                      }}
                      src={this.state.side1CroppedImageUrl}
                    />
                  )}
                </Box>
              </Modal>
              <Grid item lg={4} sm={6} md={4} xs={12}>
                <TextField
                  name="sidePicVehicle1"
                  id="outlined-basic"
                  helperText="Side Vehicle 1"
                  size="medium"
                  variant="outlined"
                  // required={true}
                  type="file"
                  onChange={this.handleFile}
                  style={{ width: "100%" }}
                  inputProps={{ accept: "image/*" }}
                  // value={this.state.sidePicVehicle1}
                />
              </Grid>
              {/* Side Pic 2 Crop image Modal */}
              <Modal
                open={this.state.isSide2Open}
                onClose={() => {
                  this.setState({
                    isSide2Open: false,
                  });
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "80%",
                    height: "80%",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                  }}
                >
                  {this.state.refsidePicVehicle2 && (
                    <ReactCrop
                      src={this.state.refsidePicVehicle2}
                      crop={this.state.side2Crop}
                      ruleOfThirds
                      onImageLoaded={this.onImageLoaded}
                      onComplete={this.onSidepic2CropComplete}
                      onChange={this.onSidepic2CropChange}
                      crossorigin="anonymous"
                      aspect={16 / 9}
                    />
                  )}

                  {this.state.side2CroppedImageUrl && (
                    <img
                      alt="Crop"
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        marginLeft: "1rem",
                      }}
                      src={this.state.side2CroppedImageUrl}
                    />
                  )}
                </Box>
              </Modal>
              <Grid item lg={4} sm={6} md={4} xs={12}>
                <TextField
                  name="sidePicVehicle2"
                  id="outlined-basic"
                  helperText="Side Vehicle 2"
                  size="medium"
                  variant="outlined"
                  // required={true}
                  type="file"
                  onChange={this.handleFile}
                  style={{ width: "100%" }}
                  inputProps={{ accept: "image/*" }}
                  // value={this.state.sidePicVehicle2}
                />
              </Grid>
              <h3 style={{ width: "100%" }}>Images</h3>
              <br />
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div
                  style={{
                    position: "relative",
                    margin: "20px 30px",
                    width: "200px",
                    height: "200px",
                    borderRadius: "5px",
                  }}
                >
                  <img
                    src={
                      this.state.engine
                        ? this.state.engineCroppedImageUrl
                        : this.state.inf.engine_pic
                        ? " https://core.gaddideals.com" +
                          this.state.inf.engine_pic
                        : Nothing
                    }
                    alt="Engine"
                    style={{ width: "100%", height: "100%" }}
                  />
                  <h3
                    style={{
                      position: "absolute",
                      bottom: "0",
                      left: "0",
                      background: "#000",
                      color: "#fff",
                      width: "100%",
                      margin: "0",
                      opacity: " .6",
                    }}
                  >
                    Engine
                  </h3>
                </div>
                <div
                  style={{
                    position: "relative",
                    margin: "20px 30px",
                    width: "200px",
                    height: "200px",
                    borderRadius: "5px",
                  }}
                >
                  <img
                    src={
                      this.state.frontSide
                        ? this.state.frontsideCroppedImageUrl
                        : this.state.inf.front_side_pic
                        ? "https://core.gaddideals.com" +
                          this.state.inf.front_side_pic
                        : Nothing
                    }
                    alt="Engine"
                    style={{ width: "100%", height: "100%" }}
                  />
                  <h3
                    style={{
                      position: "absolute",
                      bottom: "0",
                      left: "0",
                      background: "#000",
                      color: "#fff",
                      width: "100%",
                      margin: "0",
                      opacity: " .6",
                    }}
                  >
                    Front Side
                  </h3>
                </div>
                <div
                  style={{
                    position: "relative",
                    margin: "20px 30px",
                    width: "200px",
                    height: "200px",
                    borderRadius: "5px",
                  }}
                >
                  <img
                    src={
                      this.state.backSide
                        ? this.state.backsideCroppedImageUrl
                        : this.state.inf.back_side_pic
                        ? " https://core.gaddideals.com" +
                          this.state.inf.back_side_pic
                        : Nothing
                    }
                    alt="Engine"
                    style={{ width: "100%", height: "100%" }}
                  />
                  <h3
                    style={{
                      position: "absolute",
                      bottom: "0",
                      left: "0",
                      background: "#000",
                      color: "#fff",
                      width: "100%",
                      margin: "0",
                      opacity: " .6",
                    }}
                  >
                    Back Side
                  </h3>
                </div>
                <div
                  style={{
                    position: "relative",
                    margin: "20px 30px",
                    width: "200px",
                    height: "200px",
                    borderRadius: "5px",
                  }}
                >
                  <img
                    src={
                      this.state.reftyre1
                        ? this.state.tyre1CroppedImageUrl
                        : this.state.tyre1 !== ""
                        ? " https://core.gaddideals.com" + this.state.tyre1
                        : Nothing
                    }
                    alt="Tyre 1"
                    style={{ width: "100%", height: "100%" }}
                  />
                  <h3
                    style={{
                      position: "absolute",
                      bottom: "0",
                      left: "0",
                      background: "#000",
                      color: "#fff",
                      width: "100%",
                      margin: "0",
                      opacity: " .6",
                    }}
                  >
                    Tyre 1
                  </h3>
                </div>
                <div
                  style={{
                    position: "relative",
                    margin: "20px 30px",
                    width: "200px",
                    height: "200px",
                    borderRadius: "5px",
                  }}
                >
                  <img
                    src={
                      this.state.reftyre2
                        ? this.state.tyre2CroppedImageUrl
                        : this.state.tyre2 !== ""
                        ? " https://core.gaddideals.com" + this.state.tyre2
                        : Nothing
                    }
                    alt="Tyre 2"
                    style={{ width: "100%", height: "100%" }}
                  />
                  <h3
                    style={{
                      position: "absolute",
                      bottom: "0",
                      left: "0",
                      background: "#000",
                      color: "#fff",
                      width: "100%",
                      margin: "0",
                      opacity: " .6",
                    }}
                  >
                    Tyre 2
                  </h3>
                </div>
                <div
                  style={{
                    position: "relative",
                    margin: "20px 30px",
                    width: "200px",
                    height: "200px",
                    borderRadius: "5px",
                  }}
                >
                  <img
                    src={
                      this.state.refsidePicVehicle1
                        ? this.state.side1CroppedImageUrl
                        : this.state.sidePicVehicle1 !== ""
                        ? "https://core.gaddideals.com" +
                          this.state.sidePicVehicle1
                        : Nothing
                    }
                    alt="Side Vehicle 1"
                    style={{ width: "100%", height: "100%" }}
                  />
                  <h3
                    style={{
                      position: "absolute",
                      bottom: "0",
                      left: "0",
                      background: "#000",
                      color: "#fff",
                      width: "100%",
                      margin: "0",
                      opacity: " .6",
                    }}
                  >
                    Side Vehicle 1
                  </h3>
                </div>
                <div
                  style={{
                    position: "relative",
                    margin: "20px 30px",
                    width: "200px",
                    height: "200px",
                    borderRadius: "5px",
                  }}
                >
                  <img
                    src={
                      this.state.refsidePicVehicle2
                        ? this.state.side2CroppedImageUrl
                        : this.state.sidePicVehicle2 !== ""
                        ? " https://core.gaddideals.com" +
                          this.state.sidePicVehicle2
                        : Nothing
                    }
                    alt="Side Vehicle 2"
                    style={{ width: "100%", height: "100%" }}
                  />
                  <h3
                    style={{
                      position: "absolute",
                      bottom: "0",
                      left: "0",
                      background: "#000",
                      color: "#fff",
                      width: "100%",
                      margin: "0",
                      opacity: " .6",
                    }}
                  >
                    Side Vehicle 2
                  </h3>
                </div>
              </div>
            </Grid>

            <br />
            <Button
              variant="contained"
              type="submit"
              size="medium"
              color="primary"
              style={{
                marginRight: "20px",

                marginTop: "35px",
              }}
            >
              {this.state.loading ? "Updating.." : "Update "}
            </Button>
            <Link
              to={{
                pathname: "/vehicles",
              }}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                type="submit"
                color="secondary"
                style={{ marginRight: "20px", marginTop: "35px" }}
              >
                Cancel
              </Button>
            </Link>
          </form>
        </div>
      </Container>
    );
  }
}
