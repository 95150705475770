import React, { Component } from 'react';
import axios from "axios";
import Constants from "../App/Variables/Constant";
import { Link, Navigate } from "react-router-dom";
import init from "../helpers/WindowToken";
import {
  Button,
  Container,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";


export default class AddSubscription extends Component {
  state = {
    loading: false,
    title:"",
    no_of_seller:"",
    validity:"",
    price:"",
    notification: false,
    message: "",
    redirect:false,
    error:false
   
  };
  handleChange = (e) => {
    if(e.target.name==="title"){
      this.setState({
        error:false
      })
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
 
  handleSubmit = (e) => {
    e.preventDefault();
    // fd = new FormData();
    // // if (this.state.image === "") {
    // //   window.location.reload();
    // // } else {
    //   this.setState({ loading: true });
    //   fd.append("title", this.state.title);
    //   fd.append("description", this.state.description);
    //   fd.append("image", this.state.image);
  

      if (init() === "success") {
        this.setState({loading:true,}); 
        let fd={
          title:this.state.title,
    no_of_seller:this.state.no_of_seller,
    validity:this.state.validity,
    price:this.state.price,
   
   

}
        let auth = localStorage.getItem("accessToken");
        axios
          .post(Constants.postUrls.postSubscription, fd
            , {
            headers: { Authorization: `Bearer ${auth}` },
          }
          )
          .then((resp) => {
            console.log(resp.data.message);
            this.setState({
              loading: false,
              message: resp.data.message,
              notification: true,
            });
            if (resp.data.message === undefined || resp.data.message === null) {
              console.log(resp);
              if (resp.data.errors.msg) {
                this.setState({
                  message: resp.data.errors.msg,
                });
              } else {
                this.setState({
                  message: "unknown error",
                });
              }
            }
            if(resp.data.message===`Title already exist with the name ${this.state.title}`){
              this.setState({
                error:true
              })
            }

            setTimeout(() => {
              this.setState({
                // title:"",
                // no_of_seller:"",
                // validity:"",
                // price:"",
                notification: false,
                message:""
              });
              if(resp.data.message==="Subscription added successfully"){
                this.setState({
                  redirect:true
                })}
             
            }, 2000);
          })
          .catch((err) => {
            console.log(err);
            // window.location.reload();
            this.setState({
              notification:true,
              message:"some error occured check your console or try again"
            })
            setTimeout(() => {
              this.setState(
                {
                  // title:"",
                  // no_of_seller:"",
                  // validity:"",
                  // price:"",
                 notification:false,
                 message:"",
                 loading: false
                }
               
              );
              
              
            }, 2000);
          });
      // }
    }
  };
  render() {
    return (
      <Container maxWidth="xl">
          { 
   this.state.redirect && <Navigate to='/subscription' replace={true}/>
}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6"> Add Subscription </Typography>
          </Grid>
        </Grid>
        {this.state.notification ? (
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={true}
            autoHideDuration={5000}
            color="green"
            message={this.state.message}
          ></Snackbar>
        ) : null}
        <div
          style={{
            background: "#5b5757",
            padding: "20px",
            borderRadius: "10px",
            // color: rgba(0, 0, 0, 0.87);
            width: "100%",
            border: "0",
            display: "flex",
            position: "relative",
            fontSize: ".875rem",
            minWidth: "0",
            wordWrap: " break-word",
            /* background: #FFF; */
            boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
            marginTop: "30px",
            borderRadius: "6px",
            marginBottom: "30px",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <div
            style={{
              background:
                "linear-gradient(60deg, rgb(128 115 115), rgb(255 250 250)) rgb(153, 153, 153))",
              boxShadow:
                " rgb(0 0 0 / 14%) 0px 4px 20px 0px, rgb(128 120 120 1/ 40%) 0px 7px 10px -5px",
              float: "left",
              padding: "15px",
              marginTop: "-43px",
              marginRight: "15px",
              borderRadius: "3px",
              backgroundColor: "#999",
              width: "6%",
              height: "60px",
              marginBottom: "25px",
              textAlign: "center",
            }}
          >
            <EditIcon />
          </div>
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item sm={4}>
                <TextField
                  name="title"
                  error={this.state.error}
                id={this.state.error?"outlined-error":"outlined-basic"}
                label={this.state.error?"Error":"Title"}
                  size="medium"
                  variant="outlined"
                  required={true}
                  type="text"
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  value={this.state.title}
                />
              </Grid>
              <Grid item sm={4}>
                <TextField
                  name="no_of_seller"
                  id="outlined-basic"
                  label="No. of Seller"
                  size="medium"
                  variant="outlined"
                  required={true}
                  type="number"
                  InputProps={{
        inputProps: { 
            min:0
        }
    }}
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  value={this.state.no_of_seller}
                />
              </Grid>
              <Grid item sm={4}>
                <TextField
                  name="validity"
                  id="outlined-basic"
                  label="Validity (days)"
                  size="medium"
                  variant="outlined"
                  required={true}
                  type="number"
                  InputProps={{
        inputProps: { 
            min:1
        }
    }}
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  value={this.state.validity}
                />
              </Grid>
              <Grid item sm={4}>
                <TextField
                  name="price"
                  id="outlined-basic"
                  label="Price"
                  size="medium"
                  variant="outlined"
                  required={true}
                  type="number"
                  InputProps={{
        inputProps: { 
            min:0
        }
    }}
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  value={this.state.price}
                />
              </Grid>
            </Grid>
          
                
          
                <br />
            <Button
              variant="contained"
              type="submit"
              size="medium"
              color="primary"
              style={{
                marginRight: "20px",

                marginTop: "35px",
              }}
            >
              {this.state.loading ? "Adding.." : "Add "}
            </Button>
            <Link
              to={{
                pathname: "/subscription",
              }}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                type="submit"
                color="secondary"
                style={{ marginRight: "20px", marginTop: "35px" }}
              >
                Cancel
              </Button>
            </Link>
          </form>
        </div>
      </Container>
    )
  }
}
