import React, { Component } from 'react';


    import {
        Button,
        Container,
        FormControl,
        Grid,
        Select,
        InputLabel,
        Paper,
        Snackbar,
        Switch,
        Table,
        TableBody,
        TableCell,
        TableContainer,
        TableFooter,
        TableHead,
        TableRow,
        TextField,
        Typography,
        ButtonGroup,
        OutlinedInput,
        MenuItem,
        Pagination
      } from "@mui/material";
      import axios from "axios";
      import { Link } from "react-router-dom";
      import init from "../../helpers/WindowToken";
      import Constants from "../Variables/Constant.jsx";
      import Modal from "react-awesome-modal";
      import SearchIcon from "@mui/icons-material/Search";
      import ReadMore from "./helpers/ReadMore"
      const parse = require('html-react-parser');
     export default class Faq extends Component {
          state = {
              open: false,
              getFaq: [],
              data: [],
              notifyStatus: false,
              notify: false,
              searchedValue: "",
              visible: false,
              delLoading: false,
              rowId: 0,
              status: "",
              active: 1,
              perPage: "10",
              limit:10,
              searchpage:1,
              search:false,
              currentPage: 1,
              page: 1,
              prevPage: 0,
              nextPage: 0,
              lastPage: 0,
              lastPageView: false,
              firstPageView: false,
              total: 0,
              from: 1,
              to: 0,
              fromInc: 1,
              toInc: 5,
              btnIdArray: [],
              filteredBtnIdArray: [],
              msg: "",
              totalPage:1,
              searchTotalPage:1,
              pageinfo:1, 
              type:"" 
            };
            handleClose = () => {
              this.setState({ open: false });
            };
          
            componentDidMount() {
              this.getFaq();
            }
            getFaq = () => {
              axios
                .get(
                  Constants.getUrls.getAllFaq +
                    "?page=" +
                    this.state.page +
                    "&sort=true&limit=" +
                    this.state.limit+"&type=" +
                    this.state.type
                )
                .then((resp) => {
                //   console.log(resp.data.faq.docs);
          
                  this.setState({
                    fromInc: 1,
                    active: 1,
                    toInc: 5,
                    getFaq: resp.data.faq.docs,
                    total: resp.data.faq.totalDocs,
                    nextPage: resp.data.faq.hasNextPage,
                    prevPage: resp.data.faq.hasPrevPage,
                    limit: resp.data.faq.limit,
                    pagingCounter: resp.data.faq.pagingCounter,
                    totalPage:resp.data.faq.totalPages,
                    pageinfo:this.state.page,
                    // PAGINATION
                    from: 1,
                    // to: resp.data.data.length,
                  });
                });
            };
            handleRows = (value) => {
              this.setState(
                {
                  limit: value,
                },
                () => {
                  if(this.state.search){
                    this.handleSearch();
                  }else
                  {this.getFaq();}
                }
              );
              // this.getBanner();
            };
            // PAGINATION BUTTONS
          
            handleNext = () => {
              if(this.state.search){
                if (this.state.nextPage === true) {
                  // console.log(this.state.nextPage)
            
                  this.setState(
                    {
                      searchpage: Number(this.state.searchpage) + 1,
                      // to: this.state.to + this.state.limit,
                    },
                    () => {
                      this.handleSearch();
                    }
                  );
                  // console.log(this.state.page)
                  // this.getAdd();
                }
          
              }else{
              if (this.state.nextPage === true) {
                // console.log(this.state.nextPage)
          
                this.setState(
                  {
                    page: Number(this.state.page) + 1,
                    // to: this.state.to + this.state.limit,
                  },
                  () => {
                    this.getFaq();
                  }
                );
                // console.log(this.state.page)
                // this.getBrand();
              }}
            };
          
            handlePrev = () => {
              if(this.state.search){
                if (this.state.prevPage === true)
                this.setState(
                  {
                    searchpage: Number(this.state.searchpage) - 1,
                  },
          
                  () => {
                    this.handleSearch();
                  }
                );
              }
          else{    if (this.state.prevPage === true)
                this.setState(
                  {
                    page: Number(this.state.page) - 1,
                  },
          
                  () => {
                    this.getFaq();
                  }
                );
              // this.getFaq();
            }
            };
            handleChangePage=(e)=>{
              this.setState({
                pageinfo:e.target.value
              })
            }
            handlePages=(e,value)=>{
              if(this.state.search){
                this.setState({
                  searchpage:value
                },()=>{
                  this.handleSearch()
                })
              } else{
                this.setState({
                  page:value
                },()=>{
                  this.getFaq()
                })
              }
            }
            handlePage=(e)=>{
              e.preventDefault();
              if(this.state.pageinfo){
              if (this.state.search) {
                if (this.state.pageinfo>0 && this.state.pageinfo<=this.state.searchTotalPage) {
                  // console.log(this.state.nextPage)
          
                  this.setState(
                    {
                      searchpage: this.state.pageinfo,
                      // to: this.state.to + this.state.limit,
                    },
                    () => {
                      this.handleSearch();
                    }
                  );
                  // console.log(this.state.page)
                  // this.getAdd();
                }
              } else {
                if (this.state.pageinfo>0 && this.state.pageinfo<=this.state.totalPage) {
                  // console.log(this.state.nextPage)
          
                  this.setState(
                    {
                      page: this.state.pageinfo,
                      // to: this.state.to + this.state.limit,
                    },
                    () => {
                      this.getFaq();
                    }
                  );
                  // console.log(this.state.page)
                  // this.getCategory();
                }
              }}
          
            }
            handleModal = (faq) => {
              // console.log(faq);
              this.setState(
                {
                  rowId: faq._id,
                  visible: true,
                }
                // ,
                // () => {
                //   console.log(this.state.faq);
                // }
              );
            };
            handleDelete = () => {
              this.setState({
                delLoading: true,
              });
            //   console.log(Constants.deleteUrls.deleteFaq + "/" + this.state.rowId);
          
              if (init() === "success") {
                // this.setState({ delLoading: true });
                let auth = localStorage.getItem("accessToken");
                axios
                  .delete(Constants.deleteUrls.deleteFaq + "/" + this.state.rowId, {
                    headers: { Authorization: `Bearer ${auth}` },
                  })
                  .then((res) => {
                  //   console.log(res);
          
                    this.setState({
                      notify: true,
                      delLoading: false,
                      msg: res.data.message,
                    });
                    setTimeout(() => {
                      this.setState({ notify: false, visible: false }, () => {
                        if(this.state.search){this.handleSearch();}else {this.getFaq();}
                      });
                    }, 2000);
                  })
                  .catch((err) => console.log(err));
              }
            };
            handleStatus = (e, status, id) => {
              let payload;
              // console.log(id);
              let auth=localStorage.getItem("accessToken");
          
              if(init()==="success"){
                if(status==="active"){
                payload={
                  status:"draft"}
                }
                else{
                  payload={
                    status:"active"
                  }
                }
                payload=JSON.stringify(payload);
                axios
                      .post(Constants.statusUrl + id + "?type=faq", payload, {headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${auth}`
                      }}
                      )
                      .then((res)=>{
                        // console.log(res);
                        if(res.data.message){
                          this.setState({
                                        notify: true,
                                        msg:res.data.message
                                      });}
                                      if(res.data.status==="failed"){
                                        this.setState({
                                          notify:true,
                                          msg:"failed to update status"
                                        })
                                      }
                                    setTimeout(() => {
                                      this.setState({ notify: false }, () => {
                                        if(this.state.search){this.handleSearch();}else {this.getFaq();}
                                      });
                                    }, 750);
                      }).catch((err)=>{
                        console.log(err);
                      })
          
              }
            };
            handleChange = (event) => {
              if (event.target.value.length<1) {
                this.setState({
                  searchedValue: "",
                  search:false,
                 
                  
          
          
                });
                // this.defaultValues();
                this.getFaq();
              } else {
                this.setState({
                  searchedValue: event.target.value,
                });
              }
            };
            handleSubmit=(e)=>{
              e.preventDefault();
              this.setState({
                searchpage:1
              },
              () => {
                this.handleSearch();
              }
              )
          
            }
            handleSearch = (event) => {
              if(event){
                this.setState({
                  searchedValue:event.target.value
                },()=>{
                  this.handleSearch();
                })
               }
               if (this.state.searchedValue < 1) {
                this.setState({
                  search: false,
                });
                // this.defaultValues();
                this.getFaq();
              } else {
              if (this.state.searchedValue.length >= 2) {
                this.setState({ 
                search:true,
              page:1});
                axios
                  .get(
                    Constants.getUrls.getAllFaq +
                      "?page=" +
                      this.state.searchpage +
                      "&limit=" +
                      this.state.limit +
                      "&sort=true&q=" +
                     this.state.searchedValue+"&type=" +
                     this.state.type
                    // this.state.name
                  )
                  .then((resp) => {
                    // console.log(resp);
                    // let btnIdArray = [];
                    // for (let i = 1; i <= resp.data.pageCount; i++) {
                    //   btnIdArray.push(i);
                    // }
                   
                  this.setState({
                    fromInc: 1,
                    active: 1,
                    toInc: 5,
                    getFaq: resp.data.faq.docs,
                    total: resp.data.faq.totalDocs,
                    nextPage: resp.data.faq.hasNextPage,
                    prevPage: resp.data.faq.hasPrevPage,
                    limit: resp.data.faq.limit,
                    pagingCounter: resp.data.faq.pagingCounter,
                    searchTotalPage:resp.data.faq.totalPages,
                    pageinfo:this.state.searchpage,
                    // PAGINATION
          
                    from: 1,
                    // to: resp.data.data.length
                  });
                
                  });
              } 
              }
              };
              handleType = (e) => {
                this.setState(
                  {
                    type: e.target.value,
                    page: 1,
                    searchpage: 1,
                  },
                  () => {
                    if (this.state.search) {
                      this.handleSearch();
                    } else {
                      this.getFaq();
                    }
                  }
                );
              };
  render() {
    return (
        <Container maxwidth="xl">
        {this.state.notify ? (
          <Snackbar
            message={this.state.msg}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            autoHideDuration={6000}
            color="white"
            open="true"
          ></Snackbar>
        ) : null}
        <Grid container style={{ margin: "10px 0 10px" }} alignItems="baseline">
          <Grid item xs={4}>
            <Typography variant="h6">
              {/* {this.props.title} */}
              Faq
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <FormControl
              size="small"
              sx={{ width: "100%", paddingTop: "0", marginTop: "0" }}
            >
              <InputLabel id="demo-simple-select-autowidth-label">
                Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={this.state.type}
                onChange={this.handleType}
                autoWidth
                label="Type"
                sx={{ Top: "0", marginBottom: "0" }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>

                <MenuItem value="b">Buyer</MenuItem>
                <MenuItem value="s">Seller</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent="flex-end">
       
                <TextField
                  id="outlined-basic"
                  label={"Search "}
                  size="small"
                  variant="outlined"
                  sx={{marginRight:"5px"}}
                  onChange={this.handleSearch}
                />
              
              <Link to="/admin/addFaq" style={{ textDecoration: "none" }}>
                <Button
                  size="medium"
                  onClick={() => {
                    this.setState({ open: true });
                  }}
                  variant="contained"
                  color="primary"
                >
                  Add Faq
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Paper elevation={1}>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                  <TableCell >Sr.No.</TableCell>
                    <TableCell align="center">Question</TableCell>
                    <TableCell align="center">Answer</TableCell>
                    <TableCell align="right">Type</TableCell>
                    <TableCell align="right">Status</TableCell>
                  
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.getFaq.length > 0 ? (
                    this.state.getFaq.map((faq,index) => (
                      <TableRow key={faq._id}>
                      <TableCell >{this.state.search?Number((this.state.searchpage-1)*this.state.limit)+Number(index+1):Number((this.state.page-1)*this.state.limit)+Number(index+1)}</TableCell>
                        <TableCell align="center">{faq.question}</TableCell>
                        <TableCell align="center"><ReadMore>{faq.answer}</ReadMore></TableCell>
                        
                        <TableCell align="right">{faq.type}</TableCell>

                        <TableCell align="right">
                          <Switch
                            name="checkedB"
                            checked={faq.status === "active" ? true : false}
                            onChange={(e) =>
                              // console.log(e.target.checked);
                              this.handleStatus(
                                e.target.checked,
                                faq.status,
                                faq._id
                              )
                            }
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Link
                            to={"/admin/updateFaq/" + faq._id}
                            style={{ textDecoration: "none" }}
                          >
                            <Button variant="outlined">Update</Button>
                          </Link>
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => this.handleModal(faq)}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Nothing to show here
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter></TableFooter>
              </Table>
              <TableRow
                style={{ display: "flex", justifyContent: "space-evenly" }}
              >
                {/* <div>
                <p>
                  Showing {this.state.pagingCounter} to {this.state.limit} of{" "}
                  {this.state.total} Entries
                </p>
              </div> */}

                {/* Rows PerPage START */}

                <div
                  style={{
                    display: "flex",
                    alignItems: "right",
                    justifyContent: "space-between",
                    width: "200px",
                    marginTop: "10px",
                    marginLeft:"150px",
                  }}
                >
                  <p>Rows Per Page</p>

                  <FormControl
                    className="selectMerchants"
                    style={{ marginRight: "20px", marginTop: "-5px" }}
                  >
                    <InputLabel htmlFor="age-native-simple"></InputLabel>
                    <Select
                      native
                      defaultValue={10}
                      // value={state.age}
                      onChange={(e) => this.handleRows(e.target.value)}
                      inputProps={{
                        name: "Merchants",
                        id: "age-native-simple",
                      }}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                    </Select>
                  </FormControl>
                </div>
                {/* Rows PerPage END */}

                <Pagination sx={{marginTop:"auto",marginBottom:"auto"}} count={this.state.search?this.state.searchTotalPage:this.state.totalPage} color="secondary" page={this.state.search?this.state.searchpage:this.state.page} onChange={this.handlePages} />
              </TableRow>
            </TableContainer>
          </Paper>
        </Grid>

        <Modal
          visible={this.state.visible}
          width="450"
          height="100"
          effect="fadeInUp"
          onClickAway={() => this.setState({ visible: false })}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <h4
              style={{ color: "black", textAlign: "center", display: "block" }}
            >
              Are you sure about that???
            </h4>

            <br></br>
            <ButtonGroup>
              <Button
                className="btn"
                color="secondary"
                variant="contained"
                style={{ margin: "20px" }}
                onClick={() => {
                  this.handleDelete();
                }}
              >
                {this.state.delLoading ? "Deleting..." : "Yes"}{" "}
              </Button>
              <Button
                className="btn"
                color="primary"
                variant="contained"
                style={{ margin: "20px", marginLeft: "4px" }}
                onClick={() => {
                  this.setState({ visible: false });
                }}
              >
                NO
              </Button>
            </ButtonGroup>
          </Grid>
        </Modal>
      </Container>
    )
  }
}
