import React, { Component } from "react";
import axios from "axios";
import Constants from "../App/Variables/Constant";
import { Link, Navigate } from "react-router-dom";
import init from "../helpers/WindowToken";
import {
  Button,
  Container,
  Grid,
  Snackbar,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

export default class AddModel extends Component {
  state = {
    loading: false,
    name: "",
    brandName: null,
    brandId: "",
    brandData: [],
    categoryName: null,
    categoryData: [],
    categoryId: "",
    error: false,
    redirect: false,

    notification: false,
    message: "",
  };
  componentDidMount() {
    axios
      .get(Constants.getUrls.getALLCategory)
      .then((resp) => {
        let temp = [];
        resp.data.category.docs.forEach((res) => {
          if (res.status === "active")
            temp.push({ label: res.title, id: res._id });
        });
        this.setState({
          categoryData: temp,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(Constants.getUrls.getAllBrand + "?&limit=500")
      .then((resp) => {
        let temp1 = [];
        resp.data.brand.docs.forEach((res) => {
          if (res.status === "active")
            temp1.push({ label: res.title, id: res._id });
        });
        this.setState({
          brandData: temp1,
        });

        // console.log(temp1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  handleChange = (e) => {
    if (e.target.name === "name") {
      this.setState({
        error: false,
      });
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  getBrandId = (e, val_id, val) => {
    // console.log("this")
    // console.log(e)
    this.setState({
      brandId: val_id,
      brandName: val,
    });
  };
  getCategoryId = (e, val_id, val) => {
    this.setState({
      categoryId: val_id,
      categoryName: val,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    // fd = new FormData();
    // // if (this.state.image === "") {
    // //   window.location.reload();
    // // } else {
    //   this.setState({ loading: true });
    //   fd.append("title", this.state.title);
    //   fd.append("description", this.state.description);
    //   fd.append("image", this.state.image);

    if (this.state.brandId.length === 0 || this.state.categoryId.length === 0) {
      this.setState({
        notification: true,
        message: "Brand or Category cannot be empty",
      });
      setTimeout(() => {
        this.setState({
          message: "",
          notification: false,
        });
      }, 2000);
    } else {
      if (init() === "success") {
        this.setState({ loading: true });
        let fd = {
          name: this.state.name,
          brand: this.state.brandId,
          category: this.state.categoryId,
        };
        let auth = localStorage.getItem("accessToken");
        axios
          .post(Constants.postUrls.postModel, fd, {
            headers: { Authorization: `Bearer ${auth}` },
          })
          .then((resp) => {
            // console.log(resp.data.message);
            this.setState({
              loading: false,
              message: resp.data.message,
              notification: true,
            });
            if (resp.data.message === undefined || resp.data.message === null) {
              // console.log(resp);
              if (resp.data.errors.msg) {
                this.setState({
                  message: resp.data.errors.msg,
                });
              } else {
                this.setState({
                  message: "unknown error",
                });
              }
            }
            if (
              resp.data.message ===
              `Name already exist with the name ${this.state.name}`
            ) {
              this.setState({
                error: true,
              });
            }

            setTimeout(() => {
              this.setState({
                // name:"",
                // brandName:"",
                // brandId:"",
                // categoryName:"",
                // categoryId:"",
                notification: false,
                message: "",
              });
              if (resp.data.message === "Model added successfully") {
                this.setState({
                  redirect: true,
                });
              }
            }, 2000);
          })
          .catch((err) => {
            console.log(err);
            // window.location.reload();
            this.setState({
              notification: true,
              message: "some error occured check your console or try again",
            });
            setTimeout(() => {
              this.setState({
                // name:"",
                // brandName:"",
                // brandId:"",
                // categoryName:"",
                // categoryId:"",
                notification: false,
                message: "",
                loading: false,
              });
            }, 2000);
          });
        // }
      }
    }
  };
  render() {
    return (
      <Container maxWidth="xl">
        {this.state.redirect && <Navigate to="/model" replace={true} />}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6"> Add Model </Typography>
          </Grid>
        </Grid>
        {this.state.notification ? (
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={true}
            autoHideDuration={5000}
            color="green"
            message={this.state.message}
          ></Snackbar>
        ) : null}
        <div
          style={{
            background: "#5b5757",
            padding: "20px",
            borderRadius: "10px",
            // color: rgba(0, 0, 0, 0.87);
            width: "100%",
            border: "0",
            display: "flex",
            position: "relative",
            fontSize: ".875rem",
            minWidth: "0",
            wordWrap: " break-word",
            /* background: #FFF; */
            boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
            marginTop: "30px",
            borderRadius: "6px",
            marginBottom: "30px",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <div
            style={{
              background:
                "linear-gradient(60deg, rgb(128 115 115), rgb(255 250 250)) rgb(153, 153, 153))",
              boxShadow:
                " rgb(0 0 0 / 14%) 0px 4px 20px 0px, rgb(128 120 120 1/ 40%) 0px 7px 10px -5px",
              float: "left",
              padding: "15px",
              marginTop: "-43px",
              marginRight: "15px",
              borderRadius: "3px",
              backgroundColor: "#999",
              width: "6%",
              height: "60px",
              marginBottom: "25px",
              textAlign: "center",
            }}
          >
            <EditIcon />
          </div>
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item lg={4}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  fullWidth={true}
                  options={this.state.categoryData}
                  onChange={(e, value) => {
                    if (!value) {
                      this.getCategoryId(e, "", null);
                    } else this.getCategoryId(e, value.id, value.label);
                  }}
                  value={this.state.categoryName}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value
                  }
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Category" />
                  )}
                />
              </Grid>
              <Grid item lg={4}>
                <Autocomplete
                  disablePortal
                  fullWidth={true}
                  id="combo-box-demo"
                  options={this.state.brandData}
                  onChange={(e, value) => {
                    if (!value) {
                      this.getBrandId(e, "", null);
                    } else this.getBrandId(e, value.id, value.label);
                  }}
                  value={this.state.brandName}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value
                  }
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Brand" />
                  )}
                />
              </Grid>

              <Grid item sm={4}>
                <TextField
                  name="name"
                  error={this.state.error}
                  id={this.state.error ? "outlined-error" : "outlined-basic"}
                  label={this.state.error ? "Error" : "Name"}
                  size="medium"
                  variant="outlined"
                  required={true}
                  type="text"
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  value={this.state.name}
                />
              </Grid>
            </Grid>

            <br />
            <Button
              variant="contained"
              type="submit"
              size="medium"
              color="primary"
              style={{
                marginRight: "20px",

                marginTop: "35px",
              }}
            >
              {this.state.loading ? "Adding.." : "Add "}
            </Button>
            <Link
              to={{
                pathname: "/model",
              }}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                type="submit"
                color="secondary"
                style={{ marginRight: "20px", marginTop: "35px" }}
              >
                Cancel
              </Button>
            </Link>
          </form>
        </div>
      </Container>
    );
  }
}
