import React, { Component } from 'react';
import {
    Button,
    Container,
    FormControl,
    Grid,
    Select,
    InputLabel,
    Paper,
    Snackbar,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    TextField,
    Typography,
    ButtonGroup,
    MenuItem,
    OutlinedInput,
    Pagination
  } from "@mui/material";
  import axios from "axios";
  import { Link } from "react-router-dom";
  import init from "../../helpers/WindowToken";
  import Constants from "../Variables/Constant.jsx";
  import Modal from "react-awesome-modal";
  import SearchIcon from "@mui/icons-material/Search";
  import ReadMore from "./helpers/ReadMore"
  const parse = require('html-react-parser');

export default class Testimonial extends Component {
    state = {
        open: false,
        getTestimonial: [],
        data: [],
        notifyStatus: false,
        notify: false,
        searchedValue: "",
        visible: false,
        delLoading: false,
        rowId: 0,
        status: "",
        search:false,
        searchpage:1,
        limit:10,
        active: 1,
        perPage: "10",
        currentPage: 1,
        page: 1,
        prevPage: 0,
        nextPage: 0,
        lastPage: 0,
        lastPageView: false,
        firstPageView: false,
        total: 0,
        from: 1,
        to: 0,
        fromInc: 1,
        toInc: 5,
        btnIdArray: [],
        filteredBtnIdArray: [],
        msg: "",
        type:"",
        totalPage:1,
        searchTotalPage:1,
        pageinfo:1, 
      };
      handleClose = () => {
        this.setState({ open: false });

      };
      componentDidMount() {
        this.getTestimonial();
      }
      getTestimonial = () => {
        axios
          .get(
            Constants.getUrls.getAllTestimonial +
              "?page=" +
              this.state.page +
              "&limit=" +
              this.state.limit+
              "&sort=true&type="+
          this.state.type
          )
          .then((resp) => {
            // console.log(resp.data.testimonial.docs);
    
            this.setState({
              fromInc: 1,
              active: 1,
              toInc: 5,
              getTestimonial: resp.data.testimonial.docs,
              total: resp.data.testimonial.totalDocs,
              nextPage: resp.data.testimonial.hasNextPage,
              prevPage: resp.data.testimonial.hasPrevPage,
              limit: resp.data.testimonial.limit,
              pagingCounter: resp.data.testimonial.pagingCounter,
              totalPage:resp.data.testimonial.totalPages,
              pageinfo:this.state.page,
              // PAGINATION
    
              from: 1,
              // to: resp.data.data.length,
            });
          });
      };
      handleRows = (value) => {
        this.setState(
          {
            limit: value,
          },
          () => {
            if(this.state.search){
              this.handleSearch();
            }else
            {this.getTestimonial();}
          }
        );
        // this.getTestimonial();
      };
      // PAGINATION BUTTONS
    
      handleNext = () => {
        if(this.state.search){
          if (this.state.nextPage === true) {
            // console.log(this.state.nextPage)
      
            this.setState(
              {
                searchpage: Number(this.state.searchpage) + 1,
                // to: this.state.to + this.state.limit,
              },
              () => {
                this.handleSearch();
              }
            );
            // console.log(this.state.page)
            // this.getAdd();
          }
    
        }else{
        if (this.state.nextPage === true) {
          // console.log(this.state.nextPage)
    
          this.setState(
            {
              page: Number(this.state.page) + 1,
              // to: this.state.to + this.state.limit,
            },
            () => {
              this.getTestimonial();
            }
          );
          // console.log(this.state.page)
          // this.getTestimonial();
        }}
      };
    
      handlePrev = () => {
        if(this.state.search){
          if (this.state.prevPage === true)
          this.setState(
            {
              searchpage: Number(this.state.searchpage) - 1,
            },
    
            () => {
              this.handleSearch();
            }
          );
        }
    else{    if (this.state.prevPage === true)
          this.setState(
            {
              page: Number(this.state.page) - 1,
            },
    
            () => {
              this.getTestimonial();
            }
          );
        // this.getTestimonial();
      }
      };
      handleChangePage=(e)=>{
        this.setState({
          pageinfo:e.target.value
        })
      }
      handlePages=(e,value)=>{
        if(this.state.search){
          this.setState({
            searchpage:value
          },()=>{
            this.handleSearch()
          })
        } else{
          this.setState({
            page:value
          },()=>{
            this.getTestimonial()
          })
        }
      }
      handlePage=(e)=>{
        e.preventDefault();
        if(this.state.pageinfo){
        if (this.state.search) {
          if (this.state.pageinfo>0 && this.state.pageinfo<=this.state.searchTotalPage) {
            // console.log(this.state.nextPage)
    
            this.setState(
              {
                searchpage: this.state.pageinfo,
                // to: this.state.to + this.state.limit,
              },
              () => {
                this.handleSearch();
              }
            );
            // console.log(this.state.page)
            // this.getAdd();
          }
        } else {
          if (this.state.pageinfo>0 && this.state.pageinfo<=this.state.totalPage) {
            // console.log(this.state.nextPage)
    
            this.setState(
              {
                page: this.state.pageinfo,
                // to: this.state.to + this.state.limit,
              },
              () => {
                this.getTestimonial();
              }
            );
            // console.log(this.state.page)
            // this.getCategory();
          }
        }}
    
      }
      handleModal = (testimonial) => {
        // console.log(testimonial);
        this.setState(
          {
            rowId: testimonial._id,
            visible: true,
          }
        //   ,
        //   () => {
        //     console.log(this.state.rowId);
        //   }
        );
      };
      handleDelete = () => {
        this.setState({
          delLoading: true,
        });
        // console.log(Constants.deleteUrls.deleteTestimonial + "/" + this.state.rowId);
    
        if (init() === "success") {
          // this.setState({ delLoading: true });
          let auth = localStorage.getItem("accessToken");
          axios
            .delete(Constants.deleteUrls.deleteTestimonial + "/" + this.state.rowId, {
              headers: { Authorization: `Bearer ${auth}` },
            })
            .then((res) => {
              // console.log(res);
    
              this.setState({
                notify: true,
                delLoading: false,
                msg: res.data.message,
              });
              setTimeout(() => {
                this.setState({ notify: false, visible: false }, () => {
                  if(this.state.search){this.handleSearch();}else {this.getTestimonial();}
                });
              }, 2000);
            })
            .catch((err) => console.log(err));
        }
      };
      handleStatus = (e, status, id) => {
        let payload;
        // console.log(id);
        let auth=localStorage.getItem("accessToken");
    
        if(init()==="success"){
          if(status==="active"){
          payload={
            status:"draft"}
          }
          else{
            payload={
              status:"active"
            }
          }
          payload=JSON.stringify(payload);
          axios
                .post(Constants.statusUrl + id + "?type=testimonial", payload, {headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${auth}`
                }}
                )
                .then((res)=>{
                  // console.log(res);
                  if(res.data.message){
                    this.setState({
                                  notify: true,
                                  msg:res.data.message
                                });}
                                if(res.data.status==="failed"){
                                  this.setState({
                                    notify:true,
                                    msg:"failed to update status"
                                  })
                                }
                              setTimeout(() => {
                                this.setState({ notify: false }, () => {
                                  if(this.state.search){this.handleSearch();}else {this.getTestimonial();}
                                });
                              }, 750);
                }).catch((err)=>{
                  console.log(err);
                })
    
        }
        
      };
      handleChange = (event) => {
        if (event.target.value.length<1) {
          this.setState({
            searchedValue: "",
            search:false,
           
            
    
    
          });
          // this.defaultValues();
          this.getTestimonial();
        } else {
          this.setState({
            searchedValue: event.target.value,
          });
        }
      };
      handleSubmit=(e)=>{
        e.preventDefault();
        this.setState({
          searchpage:1
        },
        () => {
          this.handleSearch();
        }
        )
    
      }
      handleSearch = (event) => {
        if(event){
          this.setState({
            searchedValue:event.target.value
          },()=>{
            this.handleSearch();
          })
         }
         if (this.state.searchedValue < 1) {
          this.setState({
            search: false,
          });
          // this.defaultValues();
          this.getTestimonial();
        } else {
        if (this.state.searchedValue.length >= 2) {
          this.setState({ 
          search:true,
        page:1});
          axios
            .get(
              Constants.getUrls.getAllTestimonial +
                "?page=" +
                this.state.searchpage +
                "&limit=" +
                this.state.limit +
                "&q=" +
               this.state.searchedValue+
               "&sort=true&type="+
          this.state.type
               // this.state.name
            )
            .then((resp) => {
              // console.log(resp);
              // let btnIdArray = [];
              // for (let i = 1; i <= resp.data.pageCount; i++) {
              //   btnIdArray.push(i);
              // }
             
            this.setState({
                fromInc: 1,
                active: 1,
                toInc: 5,
                getTestimonial: resp.data.testimonial.docs,
                total: resp.data.testimonial.totalDocs,
                nextPage: resp.data.testimonial.hasNextPage,
                prevPage: resp.data.testimonial.hasPrevPage,
                limit: resp.data.testimonial.limit,
                pagingCounter: resp.data.testimonial.pagingCounter,
                searchTotalPage:resp.data.testimonial.totalPages,
                pageinfo:this.state.searchpage,
                // PAGINATION
      
                from: 1,
                // to: resp.data.data.length,
            });
          
            });
        }
        }
      };
      handleType=(e)=>{
        this.setState({
          type:e.target.value,
          page:1,searchpage:1
    
        },()=>{
          if(this.state.search){this.handleSearch();}else {this.getTestimonial();}
        }
        )
     
      }
    
  render() {
    return (
   <Container maxwidth="xl">
        {this.state.notify ? (
          <Snackbar
            message={this.state.msg}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            autoHideDuration={6000}
            color="white"
            open="true"
          ></Snackbar>
        ) : null}
        <Grid container style={{ margin: "10px 0 10px" }} alignItems="baseline">
          <Grid item xs={4}>
            <Typography variant="h6">
              {/* {this.props.title} */}
              Testimonial
            </Typography>
          </Grid>
          <Grid item xs={2}>
          <FormControl size="small" sx={{ width:"100%" ,paddingTop:"0",marginTop:"0"}}>
        <InputLabel id="demo-simple-select-autowidth-label">Type</InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={this.state.type}
          onChange={this.handleType}
          autoWidth
          label="Type"
          sx={{Top:"0",marginBottom:"0"}}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          
          <MenuItem value="b">Buyer</MenuItem>
          <MenuItem value="s">Seller</MenuItem>
        </Select>
      </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent="flex-end">
        
                <TextField
                  id="outlined-basic"
                  label={"Search "}
                  size="small"
                  variant="outlined"
                  sx={{marginRight:"5px"}}
                  onChange={this.handleSearch}
                />
              
           
              <Link to="/admin/addTestimonial" style={{ textDecoration: "none" }}>
                <Button
                  size="medium"
                  onClick={() => {
                    this.setState({ open: true });
                  }}
                  variant="contained"
                  color="primary"
                >
                  Add Testimonial
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Paper elevation={1}>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                  <TableCell >Sr.No.</TableCell>
                    <TableCell align="right">Name</TableCell>
                    <TableCell align="center">Description</TableCell>
                    <TableCell align="right">Location</TableCell>
                    <TableCell align="right">Type</TableCell>
                    <TableCell align="right">Image</TableCell>

                    <TableCell align="right">Status</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.getTestimonial.length > 0 ? (
                    this.state.getTestimonial.map((testimonial,index) => (
                      <TableRow key={testimonial._id}>
                      <TableCell >{this.state.search?Number((this.state.searchpage-1)*this.state.limit)+Number(index+1):Number((this.state.page-1)*this.state.limit)+Number(index+1)}</TableCell>
                        <TableCell align="right">{testimonial.title}</TableCell>
                        <TableCell align="center"> <ReadMore>{testimonial.description}</ReadMore></TableCell>
                        <TableCell align="right">{testimonial.location}</TableCell>
                        <TableCell align="right">{testimonial.type}</TableCell>

                        <TableCell align="right" style={{ width: "100px" }}>
                          <img
                            src={
                              "https://core.gaddideals.com" +
                              testimonial.image
                            }
                            alt="No Image Found"
                            style={{
                              height: "100px",
                              width: "100px",
                            }}
                          />
                        </TableCell>

                        <TableCell align="right">
                          <Switch
                            name="checkedB"
                            checked={testimonial.status === "active" ? true : false}
                            onChange={(e) =>
                              // console.log(e.target.checked);
                              this.handleStatus(
                                e.target.checked,
                                testimonial.status,
                                testimonial._id
                              )
                            }
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Link
                            to={"/admin/updateTestimonial/" + testimonial._id}
                            style={{ textDecoration: "none" }}
                          >
                            <Button variant="outlined">Update</Button>
                          </Link>
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => this.handleModal(testimonial)}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Nothing to show here
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter></TableFooter>
              </Table>
              <TableRow
                style={{ display: "flex", justifyContent: "space-evenly" }}
              >
                {/* <div>
                <p>
                  Showing {this.state.pagingCounter} to {this.state.limit} of{" "}
                  {this.state.total} Entries
                </p>
              </div> */}

                {/* Rows PerPage START */}

                <div
                  style={{
                    display: "flex",
                    alignItems: "right",
                    justifyContent: "space-between",
                    width: "200px",
                    marginTop: "10px",
                    marginLeft:"150px",
                  }}
                >
                  <p>Rows Per Page</p>

                  <FormControl
                    className="selectMerchants"
                    style={{ marginRight: "20px", marginTop: "-12px" }}
                  >
                    <InputLabel htmlFor="age-native-simple"></InputLabel>
                    <Select
                      native
                      defaultValue={10}
                      // value={state.age}
                      onChange={(e) => this.handleRows(e.target.value)}
                      inputProps={{
                        name: "Merchants",
                        id: "age-native-simple",
                      }}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                    </Select>
                  </FormControl>
                </div>
                {/* Rows PerPage END */}

                <Pagination sx={{marginTop:"auto",marginBottom:"auto"}} count={this.state.search?this.state.searchTotalPage:this.state.totalPage} color="secondary" page={this.state.search?this.state.searchpage:this.state.page} onChange={this.handlePages} />
              </TableRow>
            </TableContainer>
          </Paper>
        </Grid>

        <Modal
          visible={this.state.visible}
          width="450"
          height="100"
          effect="fadeInUp"
          onClickAway={() => this.setState({ visible: false })}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <h4
              style={{ color: "black", textAlign: "center", display: "block" }}
            >
              Are you sure about that???
            </h4>

            <br></br>
            <ButtonGroup>
              <Button
                className="btn"
                color="secondary"
                variant="contained"
                style={{ margin: "20px" }}
                onClick={() => {
                  this.handleDelete();
                }}
              >
                {this.state.delLoading ? "Deleting..." : "Yes"}{" "}
              </Button>
              <Button
                className="btn"
                color="primary"
                variant="contained"
                style={{ margin: "20px", marginLeft: "4px" }}
                onClick={() => {
                  this.setState({ visible: false });
                }}
              >
                NO
              </Button>
            </ButtonGroup>
          </Grid>
        </Modal>
      </Container>
    )
  }
}
