import React, { Component } from "react";
import {
  Button,
  Container,
  FormControl,
  Grid,
  Select,
  InputLabel,
  Paper,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
  ButtonGroup,
  MenuItem,
  OutlinedInput,
  Pagination
} from "@mui/material";
import axios from "axios";
import { Link } from "react-router-dom";
import init from "../../helpers/WindowToken";
import Constants from "../Variables/Constant.jsx";
import Modal from "react-awesome-modal";
import SearchIcon from "@mui/icons-material/Search";
import ImageZoom from "@mui/material/Modal";

export default class Advertisement extends Component {
  state = {
    open: false,
    getAdd: [],
    msg: "",
    data: [],
    notifyStatus: false,
    notify: false,
    searchedValue: "",
    visible: false,
    delLoading: false,
    rowId: 0,
    status: "",
    search:false,
    searchpage:1,
    limit:10,
    active: 1,
    perPage: "10",
    currentPage: 1,
    page: 1,
    prevPage: 0,
    nextPage: 0,
    lastPage: 0,
    lastPageView: false,
    firstPageView: false,
    total: 0,
    from: 1,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: [],
    type:"",
    zoom: false,
    zoomImg: "",
    totalPage:1,
    searchTotalPage:1,
    pageinfo:1,

  };
  handleClose = () => {
    this.setState({ open: false });
  };

  componentDidMount() {
    this.getAdd();
  }
  getAdd = () => {
    axios
      .get(
        Constants.getUrls.getAllAdvertisment +
          "?page=" +
          this.state.page +
          "&sort=true&limit=" +
          this.state.limit+"&type="+this.state.type
      )
      .then((resp) => {
        // console.log(resp.data.advertisment.docs);

        this.setState({
          fromInc: 1,
          active: 1,
          toInc: 5,
          getAdd: resp.data.advertisment.docs,
          total: resp.data.advertisment.totalDocs,
          nextPage: resp.data.advertisment.hasNextPage,
          prevPage: resp.data.advertisment.hasPrevPage,
          limit: resp.data.advertisment.limit,
          pagingCounter: resp.data.advertisment.pagingCounter,
          totalPage:resp.data.advertisment.totalPages,
          pageinfo:this.state.page,
          // PAGINATION

          from: 1,
          // to: resp.data.data.length,
        });
      });
  };
  handleRows = (value) => {
    this.setState(
      {
        limit: value,
      },
      () => {
        if(this.state.search){
          this.handleSearch();
        }else
        {this.getAdd();}
      }
    );
    // this.getAdd();
  };
  // PAGINATION BUTTONS

  handleNext = () => {
    if(this.state.search){
      if (this.state.nextPage === true) {
        // console.log(this.state.nextPage)
  
        this.setState(
          {
            searchpage: Number(this.state.searchpage) + 1,
            // to: this.state.to + this.state.limit,
          },
          () => {
            this.handleSearch();
          }
        );
        // console.log(this.state.page)
        // this.getAdd();
      }

    }else{
    if (this.state.nextPage === true) {
      // console.log(this.state.nextPage)

      this.setState(
        {
          page: Number(this.state.page) + 1,
          // to: this.state.to + this.state.limit,
        },
        () => {
          this.getAdd();
        }
      );
      // console.log(this.state.page)
      // this.getAdd();
    }}
  };
  handlePages=(e,value)=>{
    if(this.state.search){
      this.setState({
        searchpage:value
      },()=>{
        this.handleSearch()
      })
    } else{
      this.setState({
        page:value
      },()=>{
        this.getAdd()
      })
    }
  }
  handlePrev = () => {
    if(this.state.search){
      if (this.state.prevPage === true)
      this.setState(
        {
          searchpage: Number(this.state.searchpage) - 1,
        },

        () => {
          this.handleSearch();
        }
      );
    }
else{    if (this.state.prevPage === true)
      this.setState(
        {
          page: Number(this.state.page) - 1,
        },

        () => {
          this.getAdd();
        }
      );
    // this.getAdd();
  }
  };
  handleChangePage=(e)=>{
    this.setState({
      pageinfo:e.target.value
    })
  }
  handlePage=(e)=>{
    e.preventDefault();
    if(this.state.pageinfo){
    if (this.state.search) {
      if (this.state.pageinfo>0 && this.state.pageinfo<=this.state.searchTotalPage) {
        // console.log(this.state.nextPage)

        this.setState(
          {
            searchpage: this.state.pageinfo,
            // to: this.state.to + this.state.limit,
          },
          () => {
            this.handleSearch();
          }
        );
        // console.log(this.state.page)
        // this.getAdd();
      }
    } else {
      if (this.state.pageinfo>0 && this.state.pageinfo<=this.state.totalPage) {
        // console.log(this.state.nextPage)

        this.setState(
          {
            page: this.state.pageinfo,
            // to: this.state.to + this.state.limit,
          },
          () => {
            this.getAdd();
          }
        );
        // console.log(this.state.page)
        // this.getBrand();
      }
    }}

  }
  handleModal = (advertisement) => {
    // console.log(advertisement);
    this.setState(
      {
        rowId: advertisement._id,
        visible: true,
      },
      // () => {
      //   console.log(this.state.rowId);
      // }
    );
  };
  handleDelete = () => {
    this.setState({
      delLoading: true,
    });
    // console.log(
    //   Constants.deleteUrls.deleteAdvertisment + "/" + this.state.rowId
    // );

    if (init() === "success") {
      // this.setState({ delLoading: true });
      let auth = localStorage.getItem("accessToken");
      axios
        .delete(
          Constants.deleteUrls.deleteAdvertisment + "/" + this.state.rowId,
          {
            headers: { Authorization: `Bearer ${auth}` },
          }
        )
        .then((res) => {
          // console.log(res);
          // console.log(
          //   Constants.deleteUrls.deleteAdvertisment + "/" + this.state.rowId
          // );
          this.setState({
            notify: true,
            delLoading: false,
            msg: res.data.message,
          });
          setTimeout(()=>{
            this.setState({ notify: false, visible: false });

 if(this.state.search){this.handleSearch();}else {this.getAdd();}
          },2000);
        })
        .catch((err) => console.log(err));
    }
  };
  handleStatus = (e, status, id) => {
    let payload;
    let auth=localStorage.getItem("accessToken");

    if(init()==="success"){
      if(status==="active"){
      payload={
        status:"draft"}
      }
      else{
        payload={
          status:"active"
        }
      }
      payload=JSON.stringify(payload);
      axios
            .post(Constants.statusUrl + id + "?type=advertisment", payload, {headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${auth}`
            }}
            )
            .then((res)=>{
            if(res.data.message){
              this.setState({
                            notify: true,
                            msg:res.data.message
                          });}
                          if(res.data.status==="failed"){
                            this.setState({
                              notify:true,
                              msg:"failed to update status"
                            })
                          }
                          setTimeout(() => {
                            this.setState({ notify: false }, () => {
                              if(this.state.search){this.handleSearch();}else {this.getAdd();}
                            });
                          }, 750);
            }).catch((err)=>{
              console.log(err);
            })

    }
  };
  handleChange = (event) => {
    if (event.target.value.length<1) {
      this.setState({
        searchedValue: "",
        search:false,
       
        


      });
      // this.defaultValues();
      this.getAdd();
    } else {
      this.setState({
        searchedValue: event.target.value,
      });
    }
  };
  handleSubmit=(e)=>{
    e.preventDefault();
    this.setState({
      searchpage:1
    },
    () => {
      this.handleSearch();
    }
    )

  }
  handleSearch = (event) => {
    if(event){
      this.setState({
        searchedValue:event.target.value
      },()=>{
        this.handleSearch();
      })
     }
     if (this.state.searchedValue < 1) {
      this.setState({
        search: false,
      });
      // this.defaultValues();
      this.getAdd();
    } else {
    if (this.state.searchedValue.length >= 2) {
      
      this.setState({ 
      search:true,
    page:1});
      axios
        .get(
          Constants.getUrls.getAllAdvertisment +
            "?page=" +
            this.state.searchpage +
            "&sort=true&limit=" +
            this.state.limit +
            "&q=" +
           this.state.searchedValue+"&type="+this.state.type
          // this.state.name
        )
        .then((resp) => {
          // console.log(resp);
          // let btnIdArray = [];
          // for (let i = 1; i <= resp.data.pageCount; i++) {
          //   btnIdArray.push(i);
          // }
         
        this.setState({
          fromInc: 1,
          active: 1,
          toInc: 5,
          getAdd: resp.data.advertisment.docs,
          total: resp.data.advertisment.totalDocs,
          nextPage: resp.data.advertisment.hasNextPage,
          prevPage: resp.data.advertisment.hasPrevPage,
          limit: resp.data.advertisment.limit,
          pagingCounter: resp.data.advertisment.pagingCounter,
          searchTotalPage:resp.data.advertisment.totalPages,
          pageinfo:this.state.searchpage,
          // PAGINATION

          from: 1,
          // to: resp.data.data.length,
        });
      
        });
    } }
  };
  handleType=(e)=>{
    this.setState({
      type:e.target.value,
      page:1,searchpage:1

    },()=>{
      if(this.state.search){this.handleSearch();}else {this.getAdd();}
    }
    )
 
  }
  zoom = (e) => {
    this.setState({
      zoom: true,
      zoomImg: e,
    });
  };
  close = () => {
    this.setState({
      zoom: false,
    });
  };

  render() {
    return (
      <Container maxwidth="xl">
        {this.state.notify ? (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={true}
            color={"s"}
            autoHideDuration={6000}
            message={this.state.msg}
          ></Snackbar>
        ) : null}
        <Grid container style={{ margin: "10px 0 10px" }} alignItems="baseline">
          <Grid item xs={4}>
            <Typography variant="h6">
              {/* {this.props.title} */}
              Advertisement
            </Typography>
          </Grid>
          <Grid item xs={2}>
          <FormControl size="small" sx={{ width:"100%" ,paddingTop:"0",marginTop:"0"}}>
        <InputLabel id="demo-simple-select-autowidth-label">Type</InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={this.state.type}
          onChange={this.handleType}
          autoWidth
          label="Type"
          sx={{Top:"0",marginBottom:"0"}}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          
          <MenuItem value="b">Buyer</MenuItem>
          <MenuItem value="s">Seller</MenuItem>
        </Select>
      </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent="flex-end">
            {/* <form onSubmit={this.handleSubmit} style={{ marginRight: 8 }}> */}
                <TextField
                  id="outlined-basic"
                  label={"Search "}
                  size="small"
                  variant="outlined"
                  onChange={this.handleSearch}
                  sx={{marginRight:"5px"}}
                />
                 {/* <button
                color="primary"
                className="btn btn-primary"
                style={{ marginLeft: "5px" }}
                type="submit"
               
              >
                <SearchIcon />
              </button>
              </form> */}
              <Link
                to="/admin/addAdvertisment"
                style={{ textDecoration: "none" }}
              >
                <Button
                  size="medium"
                  onClick={() => {
                    this.setState({ open: true });
                  }}
                  variant="contained"
                  color="primary"
                >
                  Add Advertisement
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Paper elevation={1}>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                  <TableCell >Sr.No.</TableCell>
                    <TableCell align="right">Title</TableCell>
                    <TableCell align="right">Type</TableCell>
                    <TableCell align="right">Image</TableCell>
                    <TableCell align="right">Url</TableCell>
                    <TableCell align="right">Status</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.getAdd.length > 0 ? (
                    this.state.getAdd.map((advertisement,index) => (
                      <TableRow key={advertisement._id}>
                      <TableCell >{this.state.search?Number((this.state.searchpage-1)*this.state.limit)+Number(index+1):Number((this.state.page-1)*this.state.limit)+Number(index+1)}</TableCell>
                        <TableCell align="right">{advertisement.title}</TableCell>
                        <TableCell align="right">
                          {advertisement.type}
                        </TableCell>

                        <TableCell align="right" style={{ width: "100px" }}>
                           <img
                            src={
                              "https://core.gaddideals.com" +
                              advertisement.image
                            }
                            onClick={() => {
                                this.zoom(advertisement.image);
                              }}
                            alt="No Image Found"
                            style={{
                           height: "90px",
                              width: "160px",
                              cursor: "pointer"
                            }}
                          />    
                        </TableCell>
                        <TableCell align="right">{advertisement.url}</TableCell>

                        <TableCell align="right">
                          <Switch
                            name="checkedB"
                            checked={
                              advertisement.status === "active" ? true : false
                            }
                            onChange={(e) =>
                              // console.log(e.target.checked);
                              this.handleStatus(
                                e.target.checked,
                                advertisement.status,
                                advertisement._id
                              )
                            }
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Link
                            to={
                              "/admin/updateAdvertisement/" + advertisement._id
                            }
                            style={{ textDecoration: "none" }}
                          >
                            <Button variant="outlined">Update</Button>
                          </Link>
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => this.handleModal(advertisement)}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Nothing to show here
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter></TableFooter>
              </Table>
              <TableRow
                style={{ display: "flex", justifyContent: "space-evenly" }}
              >
                {/* <div>
                  <p>
                    Showing {this.state.pagingCounter} to {this.state.limit} of{" "}
                    {this.state.total} Entries
                  </p>
                </div> */}

                {/* Rows PerPage START */}

                <div
                  style={{
                    display: "flex",
                    alignItems: "right",
                    justifyContent: "space-between",
                    width: "200px",
                    marginTop: "10px",
                    marginLeft:"150px",
                  }}
                >
                  <p>Rows Per Page</p>

                  <FormControl
                    className="selectMerchants"
                    style={{ marginRight: "20px", marginTop: "-5px" }}
                  >
                    <InputLabel htmlFor="age-native-simple"></InputLabel>
                    <Select
                      native
                      defaultValue={10}
                      // value={state.age}
                      onChange={(e) => this.handleRows(e.target.value)}
                      inputProps={{
                        name: "Merchants",
                        id: "age-native-simple",
                      }}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                    </Select>
                  </FormControl>
                </div>
                {/* Rows PerPage END */}

           <Pagination sx={{marginTop:"auto",marginBottom:"auto"}} count={this.state.search?this.state.searchTotalPage:this.state.totalPage} color="secondary" page={this.state.search?this.state.searchpage:this.state.page} onChange={this.handlePages} />
              </TableRow>
            </TableContainer>
          </Paper>
        </Grid>

        <Modal
          visible={this.state.visible}
          width="450"
          height="100"
          effect="fadeInUp"
          onClickAway={() => this.setState({ visible: false })}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <h4
              style={{ color: "black", textAlign: "center", display: "block" }}
            >
              Are you sure about that???
            </h4>

            <br></br>
            <ButtonGroup>
              <Button
                className="btn"
                color="secondary"
                variant="contained"
                style={{ margin: "20px" }}
                onClick={() => {
                  this.handleDelete();
                }}
              >
                {this.state.delLoading ? "Deleting..." : "Yes"}{" "}
              </Button>
              <Button
                className="btn"
                color="primary"
                variant="contained"
                style={{ margin: "20px", marginLeft: "4px" }}
                onClick={() => {
                  this.setState({ visible: false });
                }}
              >
                NO
              </Button>
            </ButtonGroup>
          </Grid>
        </Modal>
        <ImageZoom
          open={this.state.zoom}
          onClose={this.close}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <img
            src={" https://core.gaddideals.com" + this.state.zoomImg}
            style={{
              width: "60vw",
              height: "50vh",
              position: "absolute",
              top: "25vh",
              left: "20vw",
              bgcolor: "background.paper",
              boxShadow: 24,
            }}
          ></img>
        </ImageZoom>
      </Container>
    );
  }
}
