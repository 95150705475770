
import React, { Component } from 'react'
//to restrict new password; 


export default function handle() {
  return (
    <div>handle</div>
  )
}
