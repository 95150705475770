import React, { Component } from "react";
import axios from "axios";
import Constants from "../App/Variables/Constant";
import { Link, Navigate } from "react-router-dom";
import init from "../helpers/WindowToken";
import {
  Button,
  Grid,
  Snackbar,
  TextField,
  Typography,
  Container,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Resizer from "react-image-file-resizer";
import DoneIcon from "@mui/icons-material/Done";
// import SunEditor from "suneditor-react";
// import "suneditor/dist/css/suneditor.min.css";
let fd = new FormData();

export default class UpdateBrand extends Component {
  state = {
    loading: false,
    title: "",
    image: "",
    description: "",
    notification: false,
    brandId: "",
    message: "",
    redirect: false,
    imgChange: false,
    textChange: false,
    error: false,
    waiting: false,
    showLoading: false,
    showDone: false,
  };
  componentDidMount() {
    this.getBrand();
  }
  getBrand = () => {
    let id = window.location.pathname.split("/")[3];
    this.setState({
      brandId: id,
    });

    if (init() === "success") {
      let auth = localStorage.getItem("accessToken");
      axios
        .get(Constants.getUrls.getBrand + id, {
          headers: { Authorization: `Bearer ${auth}` },
        })
        .then((resp) => {
          // console.log("this");
          // console.log(resp.data.brand.image);

          this.setState(
            {
              title: resp.data.brand.title,
              description: resp.data.brand.description,
              image: resp.data.brand.image,
            }
            // ,()=>{
            //   console.log(this.state.image)
            // }
          );
        });
    }
  };
  // handleChangeDesc(content) {
  //   // console.log(content); //Get Content Inside Editor
  //   this.setState(
  //     {
  //       description: content,
  //       textChange: true,
  //     },
  //     () => {
  //       console.log(this.state.description);
  //     }
  //   );
  // }
  handleChange = (e) => {
    if (e.target.name === "title") {
      this.setState({
        error: false,
      });
    }
    this.setState({ textChange: true, [e.target.name]: e.target.value });
  };
  handleFile = (e) => {
    var file = e.target.files[0];
    if (file) {
      this.setState({
        waiting: true,
        showLoading: true,
        showDone: false,
      });
      if (file.size > 100000) {
        try {
          this.setState({ imgChange: false });
          this.setState({ waiting: true });
          Resizer.imageFileResizer(
            file,
            300,
            300,
            "JPEG",
            100,
            0,
            (uri) => {
              // console.log(uri);
              this.setState(
                {
                  image: uri,
                  waiting: false,

                  showDone: true,
                },
                () => {
                  if (uri) {
                    this.setState({ imgChange: true });
                  }
                  if (this.state.loading) {
                    this.handleSubmit();
                  }
                }
              );
            },
            "file",
            150,
            150
          );
        } catch (err) {
          console.log(err);
          this.setState({
            notification: true,
            message:
              "Failed to Compress Image,press Add to submit Uncompressed Image ",
            [e.target.name]: e.target.files[0],
            imgChange: true,
            waiting: false,
            loading: false,
            showDone: true,
          });
          setTimeout(() => {
            this.setState({ notification: false, message: "" });
          }, 2000);
        }
      } else {
        this.setState({
          [e.target.name]: e.target.files[0],
          imgChange: true,
          showDone: true,
          waiting: false,
        });
      }
    }
  };
  handleSubmit = (e) => {
    if (e) e.preventDefault();

    fd = new FormData();

    this.setState({ loading: true });

    if (init() === "success") {
      let auth = localStorage.getItem("accessToken");
      // if (
      //   this.state.image === null ||
      //   this.state.image === "" ||
      //   this.state.title === null ||
      //   this.state.title === "" ||
      //   this.state.description === null ||
      //   this.state.description === ""
      // ) {
      //   this.setState({
      //     notification: true,
      //     message: "Check your input.It cant be null or empty",
      //     loading: false,
      //   });
      //   setTimeout(
      //     () =>
      //       this.setState({ notifcation: false, message: "", loading: false }),
      //     2000
      //   );
      //   setTimeout(() => this.getBrand(), 3000);
      // } else {

      fd.append("title", this.state.title);
      fd.append("description", this.state.description);

      fd.append("image", this.state.image);

      // if(this.state.textChange===false && this.state.imgChange===false){
      //   this.setState({notification:true,
      //   message:"nothing to change here"})
      //   setTimeout(() => {
      //     this.setState({notification:false,
      //       loading:false,
      //       redirect:true
      //     })
      //   }, 2000);
      // } else {
      axios
        .put(Constants.putUrls.updateBrand + this.state.brandId, fd, {
          headers: {
            // "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth}`,
          },
        })
        .then((resp) => {
          // axios.post("http://localhost:8081/createStocks", stockLoad).then((resp) => {
          // console.log(this.state.image);
          this.setState({
            loading: false,
            notification: true,
            message: resp.data.message,
          });
          if (resp.data.message === undefined || resp.data.message === null) {
            console.log(resp);
            if (resp.data.errors.msg) {
              this.setState({
                message: resp.data.errors.msg,
              });
            } else {
              this.setState({
                message: "unknown error",
              });
            }
          }
          if (
            resp.data.message ===
            `title already exist with the name ${this.state.title}`
          ) {
            this.setState({
              error: true,
            });
          }
          setTimeout(() => {
            this.setState({
              // title: "",
              // image: "",

              // description: "",
              message: "",
              notification: false,
            });
            // fd.delete("title", this.state.title);
            // fd.delete("description", this.state.description);
            // fd.delete("image", this.state.image);
          }, 2000);
          if (resp.data.message === "Updated Successfully") {
            setTimeout(() => {
              this.setState({
                redirect: true,
              });
            }, 3000);
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            notification: true,
            message: "error occured probaably due to oversize file",
          });
          setTimeout(() => {
            this.setState({
              // title:"",
              //   image:"",
              //   description:"",
              notification: false,
              message: "",
              loading: false,
            });
          }, 2000);
          // window.location.reload();
          // setTimeout(() => {
          //   this.setState({
          //     title: "",
          //     image: "",
          //     description: "",
          //     notification: true,
          //     loading: false,
          //     message: "error",
          //   });
          //   fd.append("title", this.state.title);
          //   fd.append("description", this.state.description);
          //   fd.append("image", this.state.image);
          // }, 2000);
          // setTimeout(() => {
          //   this.getBrand();
          // }, 3000);
        });

      // }
    }
  };
  render() {
    return (
      <Container maxWidth="xl">
        {this.state.redirect && <Navigate to="/brand" replace={true} />}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6"> Brand </Typography>
          </Grid>
        </Grid>
        {this.state.notification ? (
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={true}
            autoHideDuration={5000}
            color="green"
            message={this.state.message}
          ></Snackbar>
        ) : null}
        <div
          style={{
            background: "#5b5757",
            padding: "20px",
            borderRadius: "10px",
            // color: rgba(0, 0, 0, 0.87);
            width: "100%",
            border: "0",
            display: "flex",
            position: "relative",
            fontSize: ".875rem",
            minWidth: "0",
            wordWrap: " break-word",
            /* background: #FFF; */
            boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
            marginTop: "30px",
            borderRadius: "6px",
            marginBottom: "30px",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <div
            style={{
              background:
                "linear-gradient(60deg, rgb(128 115 115), rgb(255 250 250)) rgb(153, 153, 153))",
              boxShadow:
                " rgb(0 0 0 / 14%) 0px 4px 20px 0px, rgb(128 120 120 1/ 40%) 0px 7px 10px -5px",
              float: "left",
              padding: "15px",
              marginTop: "-43px",
              marginRight: "15px",
              borderRadius: "3px",
              backgroundColor: "#999",
              width: "6%",
              height: "60px",
              marginBottom: "25px",
              textAlign: "center",
            }}
          >
            <EditIcon />
          </div>
          <form onSubmit={this.handleSubmit}>
            <Grid container sm={12} spacing={2}>
              <Grid item sm={6}>
                <TextField
                  name="title"
                  error={this.state.error}
                  id={this.state.error ? "outlined-error" : "outlined-basic"}
                  label={this.state.error ? "Error" : "Title"}
                  multiline={true}
                  maxRows={4}
                  size="medium"
                  variant="outlined"
                  required={true}
                  type="text"
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  value={this.state.title}
                />
              </Grid>
              {/* {
                          this.state.all_exchange_type.map(val =>(
                              console.log(val.instrument)
                          ))
                      } */}

              <Grid item sm={6}>
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    height: "100%",
                    alignItems: "baseline",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "baseline",
                    }}
                  >
                    <label>Image:</label>
                    <input
                      name="image"
                      type="file"
                      onChange={this.handleFile}
                      accept="image/*"
                      //  ref={this.emptyFile}
                      disabled={
                        this.state.showLoading &&
                        (this.state.showDone ? false : true)
                      }
                      required
                      style={{ width: "100%" }}
                    />
                  </div>
                  {this.state.showLoading ? (
                    this.state.showDone ? (
                      <DoneIcon
                        color="success"
                        sx={{ position: "absolute", right: "0" }}
                      />
                    ) : (
                      <CircularProgress
                        color="success"
                        size={25}
                        sx={{ position: "absolute", right: "0" }}
                      />
                    )
                  ) : null}
                </div>
              </Grid>
              <Grid item sm={4}>
                <label>Image:</label>
                <img
                  style={{
                    height: "150px",
                    width: "200px",
                    marginBottom: "10px 20px",
                  }}
                  src={
                    this.state.imgChange
                      ? URL.createObjectURL(this.state.image)
                      : " https://core.gaddideals.com" + this.state.image
                  }
                  alt="Updating...."
                  class="rounded"
                />
              </Grid>
            </Grid>
            <div style={{ color: "black", marginTop: "20px" }}>
              <label style={{ color: "white" }}>Description:</label>

              <CKEditor
                className="ckeditor"
                value={this.state.description}
                editor={ClassicEditor}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  this.setState({
                    description: data,
                  });
                }}
                data={this.state.description}
              />
            </div>
            <br />
            <Button
              variant="contained"
              type="submit"
              size="medium"
              color="primary"
              style={{
                marginRight: "20px",

                marginTop: "35px",
              }}
            >
              {this.state.loading ? "Updating.." : "Update "}
            </Button>
            <Link
              to={{
                pathname: "/brand",
              }}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                type="submit"
                color="secondary"
                style={{ marginRight: "20px", marginTop: "35px" }}
              >
                Cancel
              </Button>
            </Link>
          </form>
        </div>
      </Container>
    );
  }
}
