import { useState } from "react";
const parse = require("html-react-parser");
const ReadMoreLess = ({ children }) => {
    const [readMoreLess, setReadMoreLess] = useState(false);

  
    const toggleButton = () => setReadMoreLess(!readMoreLess);
    return (
      <div>
        <p>{children.length > 150
                            ?readMoreLess ?parse(children):parse(
                                children.substring(0, 150) + "..."
                              ):parse(children)
                            }</p>
        <strong onClick={toggleButton} style={{ cursor: "pointer",color:"#66BFBF" }}>
          {children.length > 150 ? (readMoreLess ? " Read Less" : " Read More"):null}
        </strong>
      </div>
    );
  };
  export default ReadMoreLess;