import React, { Component } from 'react';
import axios from "axios";
import Constants from "../App/Variables/Constant";
import { Link, Navigate } from "react-router-dom";
import init from "../helpers/WindowToken";
import {
  Button,
  Container,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

export default class AddFuelType extends Component {
  state = {
    loading: false,
    fuelType: "",
    notification: false,
    message: "",
    error:false,
    redirect:false
  };
  handleChange = (e) => {
    if(e.target.name==="fuelType"){
      this.setState({
        error:false
      })
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
 
  handleSubmit = (e) => {
    e.preventDefault();
    // fd = new FormData();
    // // if (this.state.image === "") {
    // //   window.location.reload();
    // // } else {
    //   this.setState({ loading: true });
    //   fd.append("title", this.state.title);
    //   fd.append("description", this.state.description);
    //   fd.append("image", this.state.image);
  

      if (init() === "success") {
        this.setState({loading:true,}); 
        let fd={
          title:this.state.fuelType,
   

}
        let auth = localStorage.getItem("accessToken");
        axios
          .post(Constants.postUrls.postFueltype, fd
            , {
            headers: { Authorization: `Bearer ${auth}` },
          }
          )
          .then((resp) => {
            // console.log(resp.data.message);
            this.setState({
              loading: false,
              message: resp.data.message,
              notification: true,
            });
            if (resp.data.message === undefined || resp.data.message === null) {
              // console.log(resp);
              if (resp.data.errors.msg) {
                this.setState({
                  message: resp.data.errors.msg,
                });
              } else {
                this.setState({
                  message: "unknown error",
                });
              }
            }
            if(resp.data.message===`title already exist with the title${this.state.fuelType}` ){
              this.setState({
                error:true
              })
            }

            setTimeout(() => {
              this.setState({
              
                

                // fuelType: "",
                notification: false,
                message:""
              });
              if(resp.data.message==="Fuel type added successfully"){
                this.setState({
                  redirect:true
                })}
             
            }, 2000);
          })
          .catch((err) => {
            console.log(err);
            // window.location.reload();
            this.setState({
              notification:true,
              message:"some error occured check your console or try again"
            })
            setTimeout(() => {
              this.setState(
                {
                  // fuelType:"",
                 notification:false,
                 message:"",
                 loading: false
                }
               
              );
              
              
            }, 2000);
          });
      // }
    }
  };
  render() {
    return (
      <Container maxWidth="xl">
      { 
   this.state.redirect && <Navigate to='/fuelType' replace={true}/>
}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h6"> Add Fuel Type </Typography>
        </Grid>
      </Grid>
      {this.state.notification ? (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={true}
          autoHideDuration={5000}
          color="green"
          message={this.state.message}
        ></Snackbar>
      ) : null}
      <div
        style={{
          background: "#5b5757",
          padding: "20px",
          borderRadius: "10px",
          // color: rgba(0, 0, 0, 0.87);
          width: "100%",
          border: "0",
          display: "flex",
          position: "relative",
          fontSize: ".875rem",
          minWidth: "0",
          wordWrap: " break-word",
          /* background: #FFF; */
          boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
          marginTop: "30px",
          borderRadius: "6px",
          marginBottom: "30px",
          flexDirection: "column",
          textAlign: "left",
        }}
      >
        <div
          style={{
            background:
              "linear-gradient(60deg, rgb(128 115 115), rgb(255 250 250)) rgb(153, 153, 153))",
            boxShadow:
              " rgb(0 0 0 / 14%) 0px 4px 20px 0px, rgb(128 120 120 1/ 40%) 0px 7px 10px -5px",
            float: "left",
            padding: "15px",
            marginTop: "-43px",
            marginRight: "15px",
            borderRadius: "3px",
            backgroundColor: "#999",
            width: "6%",
            height: "60px",
            marginBottom: "25px",
            textAlign: "center",
          }}
        >
          <EditIcon />
        </div>
        <form onSubmit={this.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <TextField
                name="fuelType"
                error={this.state.error}
                id={this.state.error?"outlined-error":"outlined-basic"}
                label={this.state.error?"Error":"Fuel Type"}
                size="medium"
                variant="outlined"
                required={true}
                type="text"
                onChange={this.handleChange}
                style={{ width: "100%" }}
                value={this.state.fuelType}
              />
            </Grid>
            
          </Grid>
        
              
        
              <br />
          <Button
            variant="contained"
            type="submit"
            size="medium"
            color="primary"
            style={{
              marginRight: "20px",

              marginTop: "35px",
            }}
          >
            {this.state.loading ? "Adding.." : "Add "}
          </Button>
          <Link
            to={{
              pathname: "/fuelType",
            }}
            style={{ textDecoration: "none" }}
          >
            <Button
              variant="contained"
              type="submit"
              color="secondary"
              style={{ marginRight: "20px", marginTop: "35px" }}
            >
              Cancel
            </Button>
          </Link>
        </form>
      </div>
    </Container>
    )
  }
}
