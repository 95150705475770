const mainDomain = "https://core.gaddideals.com/";
// const mainDomain = "http://192.168.1.12:3003/";

const Constants = {
  getUrls: {
    getAllBanner: mainDomain + "api/banner",
    // getAllVehicles:mainDomain+"",
    getAllAdvertisment: mainDomain + "api/advertisment",
    getAllBrand: mainDomain + "api/brand",
    getAllCms: mainDomain + "api/cms",
    getALLCategory: mainDomain + "api/category",
    getAllTestimonial: mainDomain + "api/testimonial",
    getAllContactUs: mainDomain + "api/contactUs",
    getAllFaq: mainDomain + "api/faq",
    getAllKms: mainDomain + "api/kms",
    getAllyears: mainDomain + "api/years",
    getAllFueltype: mainDomain + "api/fuelType",
    getAllModel: mainDomain + "api/model",
    getAllSubscription: mainDomain + "api/subscription",
    getAllUser: mainDomain + "api/user/all",
    getAllVehicle: mainDomain + "api/vehicle",
    getAllFeaturedVehicle: mainDomain + "api/featuredList",
    getAllNotification: mainDomain + "api/mannualNotification",
    getAllBodyType: mainDomain + "api/bodyType",
    getAllEnquiry: mainDomain + "api/enquiries",
    getAllState: mainDomain + "api/state",
    getAllCity: mainDomain + "api/city",
    getAllTrustedClient: mainDomain + "api/trustedClient",

    getBannerById: mainDomain + "api/banner/",
    getAdvertisment: mainDomain + "api/advertisment/",
    getBrand: mainDomain + "api/brand/",
    getCms: mainDomain + "api/cms/",
    getCategory: mainDomain + "api/category/",
    getTestimonial: mainDomain + "api/testimonial/",
    getFaq: mainDomain + "api/faq/",
    getKms: mainDomain + "api/kms/",
    getyears: mainDomain + "api/years/",
    getFueltype: mainDomain + "api/fuelType/",
    getModel: mainDomain + "api/model/",
    getSubscription: mainDomain + "api/subscription/",
    getVehicle: mainDomain + "api/vehicle/vehicleDetails/",
    getSetting: mainDomain + "api/setting",
    getFeaturedVehicle: mainDomain + "api/featuredList/",
    getNotification: mainDomain + "api/mannualNotification/",
    getBodyType: mainDomain + "api/bodyType/",
    getAgent: mainDomain + "api/user/",
    getState: mainDomain + "api/state/",
    getCity: mainDomain + "api/city/",
    getTrustedClient: mainDomain + "api/trustedClient/getSingle/",

    getDashboard: mainDomain + "api/dashboard",
  },
  postUrls: {
    adminLogin: mainDomain + "api/user/admin_login",
    postBanner: mainDomain + "api/banner",

    postAdvertisment: mainDomain + "api/advertisment",
    postBrand: mainDomain + "api/brand",
    postCms: mainDomain + "api/cms",
    postCategory: mainDomain + "api/category",
    postTestimonial: mainDomain + "api/testimonial",
    postFaq: mainDomain + "api/faq",
    postKms: mainDomain + "api/kms",
    postyears: mainDomain + "api/years",
    postFueltype: mainDomain + "api/fuelType",
    postModel: mainDomain + "api/model",
    postSubscription: mainDomain + "api/subscription",
    postFeaturedVehicle: mainDomain + "api/featuredList",
    postAgent: mainDomain + "api/user/createAgent",
    postNotification: mainDomain + "api/mannualNotification/",
    postBodyType: mainDomain + "api/bodyType/",
    postState: mainDomain + "api/state/",
    postCity: mainDomain + "api/city/",
    postTrustedClient: mainDomain + "api/trustedClient",
  },
  putUrls: {
    updateBanner: mainDomain + "api/banner/",
    updateAdvertisement: mainDomain + "api/advertisment/",
    updateBrand: mainDomain + "api/brand/",
    updateCms: mainDomain + "api/cms/",
    updateCategory: mainDomain + "api/category/",
    updateTestimonial: mainDomain + "api/testimonial/",
    updateFaq: mainDomain + "api/faq/",
    updateKms: mainDomain + "api/kms/",
    updateyears: mainDomain + "api/years/",
    updateFueltype: mainDomain + "api/fuelType/",
    updateModel: mainDomain + "api/model/",
    updateSubscription: mainDomain + "api/subscription/",
    updateVehicle: mainDomain + "api/vehicle/updateVehicle/",
    updateSetting: mainDomain + "api/setting/",
    updateFeaturedVehicle: mainDomain + "api/featuredList/",
    updateAgent: mainDomain + "api/user/updateAgent/",
    updateNotification: mainDomain + "api/mannualNotification/",
    updateBodyType: mainDomain + "api/bodyType/",
    updatePhysicalInpection: mainDomain + "api/vehicle/physical_inspection/",
    updateState: mainDomain + "api/state/",
    updateCity: mainDomain + "api/city/",
    updateFeatured: mainDomain + "api/vehicle/addFeaturedVehicle/",
    updateTruustedClient: mainDomain + "api/trustedClient/",
  },
  deleteUrls: {
    deleteBanner: mainDomain + "api/banner",
    deleteAdvertisment: mainDomain + "api/advertisment",
    deleteBrand: mainDomain + "api/brand",
    deleteCms: mainDomain + "api/cms",
    deleteCategory: mainDomain + "api/category",
    deleteTestimonial: mainDomain + "api/testimonial",
    deleteFaq: mainDomain + "api/faq",
    deleteKms: mainDomain + "api/kms",
    deleteyears: mainDomain + "api/years/delete",
    deleteFueltype: mainDomain + "api/fuelType",
    deleteModel: mainDomain + "api/model",
    deleteSubscription: mainDomain + "api/subscription",
    deleteFeaturedVehicle: mainDomain + "api/featuredList",
    deleteAgent: mainDomain + "api/user/delAgent",
    deleteNotification: mainDomain + "api/mannualNotification",
    deleteBodyType: mainDomain + "api/bodyType",
    deleteVehicle: mainDomain + "api/vehicle/delete",
    deleteContactUs: mainDomain + "api/contactUs",
    deleteEnquiry: mainDomain + "api/enquiries/delete",
    deleteState: mainDomain + "api/state",
    deleteCity: mainDomain + "api/city",
    deleteTrustedClient: mainDomain + "api/trustedClient/delete",
    deleteUser: mainDomain + "api/user/delete",
  },
  downloadUrls: {
    downloadUser: mainDomain + "api/user/exportExcel",
    downloadVehicles: mainDomain + "api/vehicle/exportExcel",
    downloadEnquiries: mainDomain + "api/enquiries/exportExcel",
  },
  importUrls: {
    importModel: mainDomain + "api/model/importCSV",
    importBrand: mainDomain + "api/brand/importCSV",
    importState: mainDomain + "api/state/importCSV",
    importCity: mainDomain + "api/city/importCSV",
    importYear: mainDomain + "api/years/importCSV",
    importTrustedClient: mainDomain + "api/trustedClient/importCSV",
  },
  statusUrl: mainDomain + "api/status/",
};
export default Constants;
