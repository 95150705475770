import React, { Component } from "react";
import axios from "axios";
import Constants from "../App/Variables/Constant";
import { Link, Navigate } from "react-router-dom";
import init from "../helpers/WindowToken";
import {
  Button,
  Container,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import SunEditor from "suneditor-react";
// import "suneditor/dist/css/suneditor.min.css";
let fd = new FormData();

export default class AddCms extends Component {
  constructor(props) {
    super(props);
    this.emptyFile = React.createRef();
  }

  state = {
    loading: false,
    title: "",
    image: "",
    redirect: false,
    description: "",
    notification: false,
    message: "",
    error: false,
  };
  handleChange = (e) => {
    if (e.target.name === "title") {
      this.setState({
        error: false,
      });
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  // handleChangeDesc(content) {
  //   // console.log(content); //Get Content Inside Editor
  //   this.setState(
  //     {
  //       description: content,
  //     },
  //     () => {
  //       console.log(this.state.description);
  //     }
  //   );
  // }

  handleFile = (e) => {
    this.setState({
      [e.target.name]: e.target.files[0],
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    //   if(this.state.description.length===0){
    //     this.setState({notification:true,
    //     message:"Description cannot be empty"}
    //     )
    //     setTimeout(()=>{
    //       this.setState({notification:false})
    //     },2000)
    // } else {
    fd = new FormData();
    // if (this.state.image === "") {
    //   window.location.reload();
    // } else {
    this.setState({ loading: true });
    fd.append("title", this.state.title);
    fd.append("description", this.state.description);
    fd.append("image", this.state.image);

    if (init() === "success") {
      let auth = localStorage.getItem("accessToken");
      axios
        .post(Constants.postUrls.postCms, fd, {
          headers: { Authorization: `Bearer ${auth}` },
        })
        .then((resp) => {
          console.log(resp.data.message);
          this.setState({
            loading: false,
            message: resp.data.message,
            notification: true,
          });
          if (resp.data.message === undefined || resp.data.message === null) {
            // console.log(resp);
            if (resp.data.errors.msg) {
              this.setState({
                message: resp.data.errors.msg,
              });
            } else {
              this.setState({
                message: "unknown error",
              });
            }
          }
          if (
            resp.data.message ===
            `Title already exist with the name ${this.state.title}`
          ) {
            this.setState({
              error: true,
            });
          }

          setTimeout(() => {
            this.setState({
              // title: "",
              // image: "",

              // description: "",
              notification: false,
              message: "",
            });
            // fd.delete("title");
            // fd.delete("image");

            // fd.delete("description");
            // this.emptyFile.current.value = "";
            if (resp.data.message === "Cms added successfully") {
              this.setState({
                redirect: true,
              });
            }
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
          // window.location.reload();
          this.setState({
            notification: true,
            message: "error occured check console",
          });
          setTimeout(() => {
            this.setState({
              // title:"",
              //   image:"",
              //   description:"",
              notification: false,
              message: "",
              loading: false,
            });
            // this.emptyFile.current.value = "";
          }, 2000);
        });
      // }
    }
    // }
  };

  render() {
    return (
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          {this.state.redirect && <Navigate to="/cms" replace={true} />}
          <Grid item xs={6}>
            <Typography variant="h6"> CMS </Typography>
          </Grid>
        </Grid>
        {this.state.notification ? (
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={true}
            autoHideDuration={5000}
            color="green"
            message={this.state.message}
          ></Snackbar>
        ) : null}
        <div
          style={{
            background: "#5b5757",
            padding: "20px",
            borderRadius: "10px",
            // color: rgba(0, 0, 0, 0.87);
            width: "100%",
            border: "0",
            display: "flex",
            position: "relative",
            fontSize: ".875rem",
            minWidth: "0",
            wordWrap: " break-word",
            /* background: #FFF; */
            boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
            marginTop: "30px",
            borderRadius: "6px",
            marginBottom: "30px",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <div
            style={{
              background:
                "linear-gradient(60deg, rgb(128 115 115), rgb(255 250 250)) rgb(153, 153, 153))",
              boxShadow:
                " rgb(0 0 0 / 14%) 0px 4px 20px 0px, rgb(128 120 120 1/ 40%) 0px 7px 10px -5px",
              float: "left",
              padding: "15px",
              marginTop: "-43px",
              marginRight: "15px",
              borderRadius: "3px",
              backgroundColor: "#999",
              width: "6%",
              height: "60px",
              marginBottom: "25px",
              textAlign: "center",
            }}
          >
            <EditIcon />
          </div>
          <form onSubmit={this.handleSubmit}>
            <Grid container sm={12} spacing={2}>
              <Grid item sm={6}>
                <TextField
                  name="title"
                  error={this.state.error}
                  id={this.state.error ? "outlined-error" : "outlined-basic"}
                  label={this.state.error ? "Error" : "Title"}
                  size="medium"
                  variant="outlined"
                  required={true}
                  type="text"
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  value={this.state.title}
                />
              </Grid>
              {/* {
                          this.state.all_exchange_type.map(val =>(
                              console.log(val.instrument)
                          ))
                      } */}

              <Grid item sm={6}>
                {/* <TextField
                  name="image"
                  id="outlined-basic"
                  placeholder={"Add Cms Image"}
                  size="medium"
                  variant="outlined"
                  required={true}
                  type="file"
                  onChange={this.handleFile}
                  style={{ width: "100%" }}
                /> */}
                <label>Image:</label>
                <input
                  name="image"
                  type="file"
                  onChange={this.handleFile}
                  accept="image/*"
                  ref={this.emptyFile}
                  required
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <div style={{ color: "black", marginTop: "20px" }}>
              <label style={{ color: "white" }}>Description:</label>

              <CKEditor
                className="ckeditor"
                value={this.state.description}
                editor={ClassicEditor}
                data={this.state.description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  this.setState({
                    description: data,
                  });
                }}
              />
            </div>
            <br />
            <Button
              variant="contained"
              type="submit"
              size="medium"
              color="primary"
              style={{
                marginRight: "20px",

                marginTop: "35px",
              }}
            >
              {this.state.loading ? "Adding.." : "Add "}
            </Button>
            <Link
              to={{
                pathname: "/cms",
              }}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                type="submit"
                color="secondary"
                style={{ marginRight: "20px", marginTop: "35px" }}
              >
                Cancel
              </Button>
            </Link>
          </form>
        </div>
      </Container>
    );
  }
}
