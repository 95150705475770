import React, { Component } from 'react';
import axios from "axios";
import Constants from "../App/Variables/Constant";
import {Link,Navigate } from "react-router-dom";
import init from "../helpers/WindowToken";
import {
    Button,
    Container,
    Grid,
    Snackbar,
    TextField,
    Typography,
    Autocomplete
}from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';


export default class UpdateFeatured extends Component {
  state = {
    loading: false,
   name:"",
   brandName:null,
   brandId:"",
   brandData:[],
   categoryName:null,
   categoryData:[],
   categoryId:"",
   modelName:null,
   modelId:"",
   modelData:[],
   vehicleName:null,
   vehicleId:"",
   vehicleData:[],
   vehicle:null,
   brand:null,
   category:null,
   
   error:false,
   redirect:false,
   featuredId:"",

    notification: false,
    message: "",
   
  };
  componentDidMount(){
    axios.get(Constants.getUrls.getALLCategory).then((resp)=>{
      let temp=[];
      resp.data.category.docs.forEach((res)=>{
        if(res.status==="active")
        temp.push( {label:res.title,id:res._id});
      })
      this.setState({
        categoryData:temp
      },()=>{
        this.checkCategory();
      })
      console.log(this.state.categoryData)
    
    }).catch((err)=>{
      console.log(err);
    }
    

    )
    axios.get(Constants.getUrls.getAllBrand+"?&limit=500").then((resp)=>{
      let temp1=[];
      resp.data.brand.docs.forEach((res)=>{
        if(res.status==="active")
        temp1.push( {label:res.title,id:res._id});
      })
      this.setState({
        brandData:temp1
      },()=>{
        this.checkBrand();
      })
      // console.log(this.state.brandData)
      
      
      // console.log(temp1);
    
    }).catch((err)=>{
      console.log(err);
    })
    axios.get(Constants.getUrls.getAllVehicle+"?status=approved&limit=1000").then((resp)=>{
      let temp2=[];
      resp.data.vehicle.docs.forEach((res)=>{
       
        temp2.push( {label:res.reg_no,id:res._id});
      })
      this.setState({
        vehicleData:temp2
      },()=>{
        this.checkVehicle();
      })
      // console.log(this.state.modelData)
      
      
      // console.log(temp1);
    
    }).catch((err)=>{
      console.log(err);
    })
    // setTimeout(()=>{
      this.getFeatured(); 
    //  },500)
  }
  getFeatured=()=>{
    let id = window.location.pathname.split("/")[3];
    this.setState({
      featuredId: id,
    });

    if (init() === "success") {
      let auth = localStorage.getItem("accessToken");
      axios
        .get(Constants.getUrls.getFeaturedVehicle + id, {
          headers: { Authorization: `Bearer ${auth}` },
        })
        .then((resp) => {
        
          this.setState({
            category:resp.data.getFeaturedList.category,
            brand:resp.data.getFeaturedList.brand,
            vehicle:resp.data.getFeaturedList.vehicle

          },()=>{
            this.checkBrand();
            this.checkCategory();
            this.checkVehicle();
          })
        
    
          // console.log("this");
          // console.log(resp);
         
          // this.setState({
          //   year: resp.data._years.year,

        
          // }
          // // ,()=>{
          //   console.log(this.state.image)
          // }
          // );
        }).catch(err=>{
          console.log(err);
        });
    }
  }
  checkCategory=()=>{
    if(this.state.category){if(this.state.categoryData.length>0){

      this.state.categoryData.forEach(element => {
        if(element.id==this.state.category._id){
          this.setState({
            categoryName:element.label,
            categoryId:element.id
            
          })
        }
        
      });}
     }
   }
   checkBrand=()=>{
    if(this.state.brand){
      if(this.state.brandData.length>0){
      this.state.brandData.forEach(element => {
        if(element.id==this.state.brand._id){
          this.setState({
            brandName:element.label,
            brandId:element.id
            
          })
        }
        
      });
     }
    }
   }
   checkVehicle=()=>{if(this.state.vehicle){
    if(this.state.vehicleData.length>0){
      this.state.vehicleData.forEach(element => {
        if(element.id==this.state.vehicle._id){
          this.setState({
            vehicleName:element.label,
            vehicleId:element.id
            
          })
        }
        
      });
    }
   }
   
   }
  getBrandId=(e,val_id,val)=>{
        // console.log("this")
        // console.log(e)
        this.setState({
          brandId:val_id,
          brandName:val
        })
      
      
      }
      getCategoryId=(e,val_id,val)=>{
        this.setState({
          categoryId:val_id,
          categoryName:val
        })
      
      }
      getVehicleId=(e,val_id,val)=>{
        this.setState({
          vehicleId:val_id,
          vehicleName:val
        })
      
      }
     
      handleChange = (e) => {
     
        this.setState({
          [e.target.name]: e.target.value,
        });
      };
    
      handleSubmit = (e) => {
        e.preventDefault();
        // fd = new FormData();
        // // if (this.state.image === "") {
        // //   window.location.reload();
        // // } else {
        //   this.setState({ loading: true });
        //   fd.append("title", this.state.title);
        //   fd.append("description", this.state.description);
        //   fd.append("image", this.state.image);
      
    if(this.state.brandId.length===0 
      ||this.state.categoryId.length===0 ||this.state.vehicleId.length===0  ){
        this.setState({
          notification:true,
          message:"Data cannot be empty"
        })
        setTimeout(()=>{
          this.setState({
            message:"",
            notification:false
          })
        },2000)
    
      } else{
          if (init() === "success") {
            this.setState({loading:true,}); 
            let fd={
              vehicle:this.state.vehicleId,
              brand:this.state.brandId,
              category:this.state.categoryId
       
    
    }
            let auth = localStorage.getItem("accessToken");
            axios
              .put(Constants.putUrls.updateFeaturedVehicle+this.state.featuredId, fd
                , {
                headers: { Authorization: `Bearer ${auth}` },
              }
              )
              .then((resp) => {
                // console.log(resp.data.message);
                this.setState({
                  loading: false,
                  message: resp.data.message,
                  notification: true,
                });
                if (resp.data.message === undefined || resp.data.message === null) {
                  // console.log(resp);
                  if (resp.data.errors.msg) {
                    this.setState({
                      message: resp.data.errors.msg,
                    });
                  } else {
                    this.setState({
                      message: "unknown error",
                    });
                  }
                }
               
    
                setTimeout(() => {
                  this.setState({
                  
                    
    
                    // name:"",
                    // brandName:"",
                    // brandId:"",
                    // categoryName:"",
                    // categoryId:"",
                    notification: false,
                    message:""
                  });
                  if(resp.data.message==="Updated Successfully"){
                    this.setState({
                      redirect:true
                    })}
                }, 2000);
              })
              .catch((err) => {
                console.log(err);
                // window.location.reload();
                this.setState({
                  notification:true,
                  message:"some error occured check your console or try again"
                })
                setTimeout(() => {
                  this.setState(
                    {
                      // name:"",
                      // brandName:"",
                      // brandId:"",
                      // categoryName:"",
                      // categoryId:"",
                     notification:false,
                     message:"",
                     loading: false
                    }
                   
                  );
                  
                  
                }, 2000);
              });
          // }
        }}
      };
     
  render() {
    return (
     <Container maxWidth="xl">
      { 
    this.state.redirect && <Navigate to='/featuredVehicle' replace={true}/>
 }
     <Grid container spacing={2}>
       <Grid item xs={6}>
         <Typography variant="h6"> Update Featured Vehicle </Typography>
       </Grid>
     </Grid>
     {this.state.notification ? (
       <Snackbar
         anchorOrigin={{
           vertical: "top",
           horizontal: "right",
         }}
         open={true}
         autoHideDuration={5000}
         color="green"
         message={this.state.message}
       ></Snackbar>
     ) : null}
     <div
       style={{
         background: "#5b5757",
         padding: "20px",
         borderRadius: "10px",
         // color: rgba(0, 0, 0, 0.87);
         width: "100%",
         border: "0",
         display: "flex",
         position: "relative",
         fontSize: ".875rem",
         minWidth: "0",
         wordWrap: " break-word",
         /* background: #FFF; */
         boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
         marginTop: "30px",
         borderRadius: "6px",
         marginBottom: "30px",
         flexDirection: "column",
         textAlign: "left",
       }}
     >
       <div
         style={{
           background:
             "linear-gradient(60deg, rgb(128 115 115), rgb(255 250 250)) rgb(153, 153, 153))",
           boxShadow:
             " rgb(0 0 0 / 14%) 0px 4px 20px 0px, rgb(128 120 120 1/ 40%) 0px 7px 10px -5px",
           float: "left",
           padding: "15px",
           marginTop: "-43px",
           marginRight: "15px",
           borderRadius: "3px",
           backgroundColor: "#999",
           width: "6%",
           height: "60px",
           marginBottom: "25px",
           textAlign: "center",
         }}
       >
         <EditIcon />
       </div>
       <form onSubmit={this.handleSubmit}>
         <Grid container spacing={2}>
         <Grid item lg={4}>
          
          <Autocomplete
      disablePortal
      id="combo-box-demo"
      fullWidth={true}
      options={this.state.categoryData}
      onChange={(e, value) => { if(!value){
       this.getCategoryId(e, "",null)}else this.getCategoryId(e, value.id,value.label);}}
      value={this.state.categoryName}
      isOptionEqualToValue={(option, value) => option.label === value}
      sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Category" />}
    />
          </Grid>
          <Grid item lg={4}>
           <Autocomplete
       disablePortal
       fullWidth={true}
       id="combo-box-demo"
       options={this.state.brandData}
       onChange={(e, value) => {
         if(!value){
         this.getBrandId(e, "",null)
       } else this.getBrandId(e, value.id,value.label);}}
       value={this.state.brandName}
       isOptionEqualToValue={(option, value) => option.label === value}
       sx={{ width: 300 }}
       renderInput={(params) => <TextField {...params} label="Brand" />}
     />
           </Grid>
          
           <Grid item lg={4}>
          
          <Autocomplete
      disablePortal
      id="combo-box-demo"
      fullWidth={true}
      options={this.state.vehicleData}
      onChange={(e, value) => { if(!value){
       this.getVehicleId(e, "",null)}else this.getVehicleId(e, value.id,value.label);}}
      value={this.state.vehicleName}
      isOptionEqualToValue={(option, value) => option.label === value}
      sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Vehicle" />}
    />
          </Grid>
           
           
         </Grid>
       
             
       
             <br />
         <Button
           variant="contained"
           type="submit"
           size="medium"
           color="primary"
           style={{
             marginRight: "20px",
 
             marginTop: "35px",
           }}
         >
           {this.state.loading ? "Updating.." : "Update "}
         </Button>
         <Link
           to={{
             pathname: "/featuredVehicle",
           }}
           style={{ textDecoration: "none" }}
         >
           <Button
             variant="contained"
             type="submit"
             color="secondary"
             style={{ marginRight: "20px", marginTop: "35px" }}
           >
             Cancel
           </Button>
         </Link>
       </form>
     </div>
   </Container>
    )
  }
}
