import React, { Component } from "react";
import axios from "axios";
import Constants from "../App/Variables/Constant";
import { Link, Navigate } from "react-router-dom";
import init from "../helpers/WindowToken";
import {
  Button,
  Container,
  Grid,
  Snackbar,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import Resizer from "react-image-file-resizer";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

let fd = new FormData();

export default class UpdateNotification extends Component {
  state = {
    loading: false,
    title: "",
    error: false,
    notificationId: "",
    showDone: false,
    description: "",
    notification: false,
    message: "",
    redirect: false,
    imgChange: false,
    image: "",
    // image_url: "",
  };
  componentDidMount() {
    this.getNotification();
  }
  getNotification = () => {
    let id = window.location.pathname.split("/")[3];
    this.setState({
      notificationId: id,
    });

    if (init() === "success") {
      let auth = localStorage.getItem("accessToken");
      axios
        .get(Constants.getUrls.getNotification + id, {
          headers: { Authorization: `Bearer ${auth}` },
        })
        .then((resp) => {
          // console.log("this");
          // console.log(resp.data.notification.image_url);

          this.setState(
            {
              title: resp.data.notification.title,
              description: resp.data.notification.description,
              image: resp.data.notification.image_url,
            },
            () => {
              console.log(this.state.image);
            }
          );
        });
    }
  };
  // handleChangeDesc(content) {
  //   // console.log(content); //Get Content Inside Editor
  //   this.setState(
  //     {
  //       description: content,
  //       textChange: true,
  //     },
  //     () => {
  //       console.log(this.state.description);
  //     }
  //   );
  // }

  handleChange = (e) => {
    if (e.target.name === "title") {
      this.setState({
        error: false,
      });
    }

    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    // fd = new FormData();
    // // if (this.state.image === "") {
    // //   window.location.reload();
    // // } else {
    //   this.setState({ loading: true });
    //   fd.append("title", this.state.title);
    //   fd.append("description", this.state.description);

    // if(this.state.description.length===0){
    //     this.setState({notification:true,
    //     message:"Description cannot be empty"});
    //     setTimeout(()=>{
    //       this.setState({notification:false})
    //     },2000)
    // } else {

    if (init() === "success") {
      this.setState({ loading: true });
      // let fd = {
      //   title: this.state.title,
      //   description: this.state.description,
      //   image_url: this.state.image_url,
      // };

      fd.append("title", this.state.title);
      fd.append("description", this.state.description);
      fd.append("image_url", this.state.image);
      let auth = localStorage.getItem("accessToken");
      axios
        .put(
          Constants.putUrls.updateNotification + this.state.notificationId,
          fd,
          {
            headers: { Authorization: `Bearer ${auth}` },
          }
        )
        .then((resp) => {
          console.log(resp.data.message);
          this.setState({
            loading: false,
            message: resp.data.message,
            notification: true,
          });
          if (resp.data.message === undefined || resp.data.message === null) {
            console.log(resp);
            if (resp.data.errors.msg) {
              this.setState({
                message: resp.data.errors.msg,
              });
            } else {
              this.setState({
                message: "unknown error",
              });
            }
          }
          if (
            resp.data.message ===
            `title already exist with the name ${this.state.title}`
          ) {
            this.setState({
              error: true,
            });
          }
          setTimeout(() => {
            this.setState({
              notification: false,
              message: "",
            });
            if (resp.data.message === "Updated Successfully") {
              this.setState({
                redirect: true,
              });
            }
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
          // window.location.reload();
          this.setState({
            notification: true,
            message: "some error occured check your console or try again",
          });
          setTimeout(() => {
            this.setState({
              notification: false,
              message: "",
              loading: false,
            });
          }, 2000);
        });
      // }
    }
    // }
  };

  handleFile = (e) => {
    if (e.target.files[0]) {
      this.setState({ imgChange: true, image: e.target.files[0] });
    }
    // var file = e.target.files[0];
    // if (file) {
    //   this.setState({
    //     waiting: true,
    //     showLoading: true,
    //     showDone: false,
    //   });

    //   this.setState({ imgChange: false });
    //   if (file["type"].split("/")[0] !== "image") {
    //     this.setState({ imgChange: false });
    //     this.setState({
    //       notification: true,
    //       message: "Warning Uploaded file should be image",
    //     });
    //     setTimeout(() => {
    //       this.setState({ notification: false, message: "" });
    //     }, 2500);
    //   } else {
    //     if (file.size > 100000) {
    //       try {
    //         this.setState({ waiting: true });
    //         Resizer.imageFileResizer(
    //           file,
    //           1500,
    //           700,
    //           "JPEG",
    //           100,
    //           0,
    //           (uri) => {
    //             // console.log(uri);

    //             this.setState(
    //               {
    //                 image: uri,
    //                 waiting: false,

    //                 showDone: true,
    //               },
    //               () => {
    //                 if (this.state.loading) {
    //                   this.handleSubmit();
    //                 }
    //               }
    //             );
    //             if (uri) {
    //               this.setState({ imgChange: true });
    //             }
    //           },
    //           "file",
    //           1000,
    //           600
    //         );
    //       } catch (err) {
    //         console.log(err);
    //         this.setState({
    //           notification: true,
    //           message:
    //             "Failed to Compress Image,press Add to submit Uncompressed Image ",
    //           [e.target.name]: e.target.files[0],
    //           waiting: false,
    //           loading: false,
    //           imgChange: true,
    //           showDone: true,
    //         });
    //         setTimeout(() => {
    //           this.setState({ notification: false, message: "" });
    //         }, 2000);
    //       }
    //     } else {
    //       this.setState({
    //         [e.target.name]: e.target.files[0],
    //         imgChange: true,
    //         showDone: true,
    //         waiting: false,
    //       });
    //     }
    //   }
    // }
  };
  render() {
    return (
      <Container maxWidth="xl">
        {this.state.redirect && (
          <Navigate to="/manualNotification" replace={true} />
        )}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6"> Manual Notification</Typography>
          </Grid>
        </Grid>
        {this.state.notification ? (
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={true}
            autoHideDuration={5000}
            color="green"
            message={this.state.message}
          ></Snackbar>
        ) : null}
        <div
          style={{
            background: "#5b5757",
            padding: "20px",
            borderRadius: "10px",
            // color: rgba(0, 0, 0, 0.87);
            width: "100%",
            border: "0",
            display: "flex",
            position: "relative",
            fontSize: ".875rem",
            minWidth: "0",
            wordWrap: " break-word",
            /* background: #FFF; */
            boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
            marginTop: "30px",
            borderRadius: "6px",
            marginBottom: "30px",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <div
            style={{
              background:
                "linear-gradient(60deg, rgb(128 115 115), rgb(255 250 250)) rgb(153, 153, 153))",
              boxShadow:
                " rgb(0 0 0 / 14%) 0px 4px 20px 0px, rgb(128 120 120 1/ 40%) 0px 7px 10px -5px",
              float: "left",
              padding: "15px",
              marginTop: "-43px",
              marginRight: "15px",
              borderRadius: "3px",
              backgroundColor: "#999",
              width: "6%",
              height: "60px",
              marginBottom: "25px",
              textAlign: "center",
            }}
          >
            <EditIcon />
          </div>
          <form onSubmit={this.handleSubmit}>
            <Grid container sm={12} spacing={2}>
              <Grid item sm={6}>
                <TextField
                  name="title"
                  error={this.state.error}
                  id={this.state.error ? "outlined-error" : "outlined-basic"}
                  label={this.state.error ? "Error" : "Title"}
                  size="medium"
                  variant="outlined"
                  multiline
                  maxRows={4}
                  required={true}
                  type="text"
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  value={this.state.title}
                />
              </Grid>

              <Grid item sm={6}>
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    height: "100%",
                    alignItems: "baseline",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "baseline",
                    }}
                  >
                    <label>Image:</label>
                    <input
                      name="image"
                      type="file"
                      onChange={this.handleFile}
                      accept="image/*"
                      //  ref={this.emptyFile}
                      disabled={
                        this.state.showLoading &&
                        (this.state.showDone ? false : true)
                      }
                      required
                      style={{ width: "100%" }}
                    />
                  </div>
                  {this.state.showLoading ? (
                    this.state.showDone ? (
                      <DoneIcon
                        color="success"
                        sx={{ position: "absolute", right: "0" }}
                      />
                    ) : (
                      <CircularProgress
                        color="success"
                        size={25}
                        sx={{ position: "absolute", right: "0" }}
                      />
                    )
                  ) : null}
                </div>
              </Grid>
            </Grid>
            <Grid item sm={4}>
              <label>Image:</label>
              <img
                style={{
                  height: "150px",
                  width: "200px",
                  marginBottom: "10px 20px",
                  marginTop: "20px",
                }}
                src={
                  this.state.imgChange
                    ? URL.createObjectURL(this.state.image)
                    : "https://core.gaddideals.com" + this.state.image
                }
                alt="Updating...."
                class="rounded"
              />
            </Grid>
            <div style={{ color: "black", marginTop: "20px" }}>
              <label style={{ color: "white" }}>Description:</label>

              <CKEditor
                className="ckeditor"
                value={this.state.description}
                editor={ClassicEditor}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  this.setState({
                    description: data,
                  });
                }}
                data={this.state.description}
              />
            </div>

            <br />
            <Button
              variant="contained"
              type="submit"
              size="medium"
              color="primary"
              style={{
                marginRight: "20px",

                marginTop: "35px",
              }}
            >
              {this.state.loading ? "Updating.." : "Update "}
            </Button>
            <Link
              to={{
                pathname: "/manualNotification",
              }}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                type="submit"
                color="secondary"
                style={{ marginRight: "20px", marginTop: "35px" }}
              >
                Cancel
              </Button>
            </Link>
          </form>
        </div>
      </Container>
    );
  }
}
