import React, { Component } from 'react';
import Constants from '../../Variables/Constant';
import axios from 'axios';

export default class getCategory extends Component {
    state={
        brandName:""
    }
    componentDidMount(){
        this.getBrand();
    }
    getBrand=()=>{
      let auth = localStorage.getItem("accessToken");
        axios(Constants.getUrls.getBrand+this.props.name,
          {
            headers: { Authorization: `Bearer ${auth}` },
          }).then((resp)=>
        {  if(resp.data.brand.title){
          this.setState({
            brandName:resp.data.brand.title
          })}
        else{
          this.setState({
            brandName:"Brand Not Found"
          })
        }}
        ).catch(
          (err)=>{
            console.log(err);
            this.setState({
              categoryName:"Error"
            })
          }
        )

    }
  render() {
    return (<>
      {this.state.brandName}</>
    )
  }
}