import React, { Component } from 'react';
import {  Button,
  Container,
  FormControl,
  Grid,
  Select,
  InputLabel,
  Paper,
  Snackbar,
  Switch,
  Table,
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
  ButtonGroup,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  ListSubheader,
  MenuItem,
  OutlinedInput} from "@mui/material";
import axios from "axios";
import {Navigate} from "react-router-dom";
import CardBox from "./Card";
import PersonIcon from '@mui/icons-material/Person';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import DownloadIcon from '@mui/icons-material/Download';
import Constant from "../Variables/Constant";
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import BoyIcon from '@mui/icons-material/Boy';
import Constants from '../Variables/Constant';
import init from '../../helpers/WindowToken';
import CategoryIcon from '@mui/icons-material/Category';
import FeaturedVideoIcon from '@mui/icons-material/FeaturedVideo';
import InventoryIcon from '@mui/icons-material/Inventory';
import SellIcon from '@mui/icons-material/Sell';
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import MoreInfo from "@mui/material/Modal";
import Modal from "react-awesome-modal";
import fileDownload from 'js-file-download';
import Nothing from "../../Assets/Nothing.jpg";
import { Line } from 'react-chartjs-2';
// import Graph from "../../Assets/Gaddideals.jpg"




export default class Dashboard extends Component {
  state={
    totalUsers:"",
    totalVehicles:"",
    totalDownloads:"",
    totalBrands:"",
    totalSubscriptions:"",
    totalAgents:"",
    totalCategories:"",
    totalPublishVehicle:"",
    totalUnpublishedVehicle:"",
    totalSeller:"",
    totalBuyer:"",
    totalAdvertisment:"",
    adBuyer:"",
    adSeller:"",
    approvedVehicles:"",
redirect:false,
open: false,
    getVehicle: [],
    data: [],
    notifyStatus: false,
    notify: false,
    searchedValue: "",
    visible: false,
    delLoading: false,
    rowId: 0,
    status: "",
    active: 1,
    perPage: "10",
    limit: 10,
    searchpage: 1,
    search: false,
    currentPage: 1,
    page: 1,
    prevPage: 0,
    nextPage: 0,
    lastPage: 0,
    lastPageView: false,
    firstPageView: false,
    total: 0,
    from: 1,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    inf: "",
    filteredBtnIdArray: [],
    msg: "",
    open: false,
    deleteId: "",
    pageinfo:1,
    sortByStatus:"published",
    searchTotalPage:1,
    totalPage:1

  };
  componentDidMount(){
    this.getData();
    this.getVehicle();
  }
  getData=()=>{if(init()==="success"){
    let auth=localStorage.getItem("accessToken");
    axios.get(Constants.getUrls.getDashboard,{
      headers: {
        // "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${auth}`,
      },
    }).then((resp)=>{
      this.setState({
        totalUsers:resp.data.usersCount,
        totalVehicles:resp.data.totalVehicle,
        totalAgents:resp.data.agentCount,
        totalCategories:resp.data.categoriesCount,
        totalPublishVehicle:resp.data.publishVehicle,
        totalUnpublishedVehicle:resp.data.unpublishedVehicle,
        totalSeller:resp.data.sellerCount,
        totalBuyer:resp.data.buyerCount,
        totalAdvertisment:resp.data.advertismentCount,
        adBuyer:resp.data.adBuyerCount,
        adSeller:resp.data.adSellerCount,
        approvedVehicles:resp.data.approvedVehicle,

      })
      console.log(resp)
    })}

  }
  getVehicle = () => {
   
    let auth = localStorage.getItem("accessToken");
    axios
      .get(
        Constants.getUrls.getAllVehicle +
          "?page=" +
          this.state.page +
          "&sort=true&limit=" +
          this.state.limit+"&status="+this.state.sortByStatus,
        { headers: { Authorization: `Bearer ${auth}` } }
      )
      .then((resp) => {
        console.log(resp);

        this.setState({
          fromInc: 1,
          active: 1,
          toInc: 5,
          getVehicle: resp.data.vehicle.docs,
          total: resp.data.vehicle.totalDocs,
          nextPage: resp.data.vehicle.hasNextPage,
          prevPage: resp.data.vehicle.hasPrevPage,
          limit: resp.data.vehicle.limit,
          pagingCounter: resp.data.vehicle.pagingCounter,
          totalPage:resp.data.vehicle.totalPages,
          pageinfo:this.state.page,
          // PAGINATION

          from: 1,
          // to: resp.data.data.length,
        });
      });
  };
  handleClose = () => {
    this.setState({ visible: false });
  };
  handleStatus = (id,value) => {
    let payload;
    // console.log(id);
    let auth = localStorage.getItem("accessToken");

    if (init() === "success") {
     
        payload = {
          status: value
        };
    
      payload = JSON.stringify(payload);
      axios
        .post(Constants.statusUrl + id + "?type=vehicle", payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth}`,
          },
        })
        .then((res) => {
          console.log(res);
          this.setState({
            notify: true,
            msg: res.data.message,
          });
          setTimeout(() => {
            this.setState({ notify: false }, () => {
              if (this.state.search) {
                this.handleSearch();
              } else {
                this.getVehicle();
              }
            });
          }, 750);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  handleModal = (vehicle) => {
    // console.log("this")
    // console.log(vehicle);
    this.setState(
      {
        inf: vehicle,
        visible: true,
      }

        ,
        () => {
          console.log(this.state.inf.engine_pic);
        }
    );
    // setTimeout(()=>{
    //   this.setState({
    //     visible: true
    //   })

    // },100)
  };
  handleChange = (event) => {
    if (event.target.value.length < 1) {
      this.setState({
        searchedValue: "",
        search: false,
      });
      // this.defaultValues();
      this.getVehicle();
    } else {
      this.setState({
        searchedValue: event.target.value,
      });
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState(
      {
        searchpage: 1,
      },
      () => {
        this.handleSearch();
      }
    );
  };
  handleSearch = (e) => {
    if (this.state.searchedValue.length >= 2) {
      this.setState({
        search: true,
        page: 1,
      });
      let auth = localStorage.getItem("accessToken");
      axios
        .get(
          Constants.getUrls.getAllVehicle +
            "?page=" +
            this.state.searchpage +
            "&sort=true&limit=" +
            this.state.limit +
            "&q=" +
            this.state.searchedValue+"&status="+this.state.sortByStatus,
          { headers: { Authorization: `Bearer ${auth}` } }
          // this.state.name
        )
        .then((resp) => {
          console.log(resp);
          // let btnIdArray = [];
          // for (let i = 1; i <= resp.data.pageCount; i++) {
          //   btnIdArray.push(i);
          // }

          this.setState({
            fromInc: 1,
            active: 1,
            toInc: 5,
            getVehicle: resp.data.vehicle.docs,
            total: resp.data.vehicle.totalDocs,
            nextPage: resp.data.vehicle.hasNextPage,
            prevPage: resp.data.vehicle.hasPrevPage,
            limit: resp.data.vehicle.limit,
            pagingCounter: resp.data.vehicle.pagingCounter,
            searchTotalPage:resp.data.vehicle.totalPages,
            pageinfo:this.state.searchpage,
            // PAGINATION

            from: 1,
            // to: resp.data.data.length
          });
        });
    } else {
      this.setState({
        notify: true,
        msg: "Atleast 2 letter required",
        search: false,
      });
      setTimeout(() => {
        this.setState({
          notify: false,
          msg: "",
        });
      }, 2000);
    }
  };
  handleSort=(name)=>{
    this.setState({
      sortByStatus:name,
      page: 1,
      searchpage: 1,
    },()=>{
      if (this.state.search) {
        this.handleSearch();
      } else {
        this.getVehicle();
      }
    })
      }
  
 
  render() {
    return (
      
      <>
      
      
      <Container maxWidth={"xl"}>
          <Grid container spacing={2}
          >
            <Grid item xs={4}>
              <Typography variant="h6">Dashboard</Typography>
            </Grid>
            <Grid item xs={8}></Grid>
          </Grid>

          <Grid container spacing={4} direction="row"
  style={{ marginTop: "30px" }}>
         
            <CardBox
              name={"Total Users"}
              icon={<PersonIcon  size={"small"}/>}
              value={this.state.totalUsers}
              // icon={<DoneAllIcon style={{ fontSize: "30" }} />}
              color={"#6c6cff"}
              shadow={" 247, 220, 111"}
             
            />
            <CardBox
              name={"Total Vehicles"}
              value={this.state.totalVehicles}
              icon={<DirectionsCarFilledIcon style={{ fontSize: "30" }} />}
              color={"#B83B5E"}
              shadow={" 82, 190, 128"}
              />
                  <CardBox
              name={"Approved Vehicles"}
              value={this.state.approvedVehicles}
              icon={<DirectionsCarFilledIcon style={{ fontSize: "30" }} />}
              color={"#1A4D2E"}
              shadow={"  241, 148, 138 "}
            />
            <CardBox
              name={"Total Agent"}
              value={this.state.totalAgents}
              icon={<BoyIcon style={{ fontSize: "30" }} />}
              color={"#FE7E6D"}
              shadow={"  241, 148, 138 "}
            />
            <CardBox
              name={"Total Categories"}
              value={this.state.totalCategories}
              icon={<CategoryIcon style={{ fontSize: "30" }} />}
              color={"#34b7eb"}
              shadow={"  241, 148, 138 "}
            />
           
                 <CardBox
              name={"Total Advertisement"}
              value={this.state.totalAdvertisment}
              icon={<FeaturedVideoIcon style={{ fontSize: "30" }} />}
              color={"#892CDC"}
              shadow={"  241, 148, 138 "}
            />
             <CardBox
              name={"Total Buyer"}
              value={this.state.totalBuyer}
              icon={<InventoryIcon style={{ fontSize: "30" }} />}
              color={"#17B978"}
              shadow={"  241, 148, 138 "}
            />
             <CardBox
              name={"Total Seller"}
              value={this.state.totalSeller}
              icon={<SellIcon style={{ fontSize: "30" }} />}
              color={"#FD5D5D"}
              shadow={"  241, 148, 138 "}
            />
          
{/* <img
  src={Graph}
  style={{width:"50%",height:"360px",marginRight:"auto",marginLeft:"auto",marginTop:"50px"}}
/> */}
            {/* <CardBox
              name={"Total Commission "}
              value= {this.currency(this.state.dashboardData.total_commission_amount)}

              // value={new Intl.NumberFormat("en-IN", {
              //   style: "currency",
              //   currency: "INR",
              //   maximumSignificantDigits: 3,
              // }).format(this.state.dashboardData.total_commission_amount)}


              icon={<AccountBalanceIcon style={{ fontSize: "30" }} />}
              color={"#85C1E9"}
              shadow={"133, 193, 233"}
            /> */}
          </Grid>

          <Container sx={{marginTop:"50px"}} maxwidth="xl">
        {/* {this.state.notify ? (
          <Snackbar
            message={this.state.msg}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            autoHideDuration={6000}
            color="white"
            open="true"
          ></Snackbar>
        ) : null} */}
        <Grid container style={{ margin: "10px 0 10px" }} alignItems="baseline">
          <Grid item xs={6}>
            <Typography variant="h6">
              {/* {this.props.title} */}
             Recent Vehicles
            </Typography>
        
          </Grid>
         
          <Grid item xs={6}>
            <Grid container justifyContent="flex-end">
            <FormControl  
          size="small"
          
          sx={{width:"200px",marginTop:"0",paddingTop:"0"}}
          >
  <InputLabel id="demo-simple-select-label">Status</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={this.state.sortByStatus}
    label="Status"
    name="status"
    onChange={(e)=>this.handleSort(e.target.value)}
    displayEmpty
  >
     <MenuItem value="">
     All
                </MenuItem>
                {/* <MenuItem value="draft">Draft</MenuItem> */}
    <MenuItem value="approved">Approved</MenuItem>
    <MenuItem value="published">Pending</MenuItem>
   
  </Select>
</FormControl>
             
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Paper elevation={1}>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Sr.No.</TableCell>
                    <TableCell align="right">Category</TableCell>
                    <TableCell align="right">Brand</TableCell>
                    <TableCell align="right">Vehicle Model</TableCell>
                    <TableCell align="right">Reg. No.</TableCell>

                    
                    <TableCell align="right">Price(₹)</TableCell>
 <TableCell align="right">City</TableCell>
                    <TableCell align="center">Status</TableCell>
                   
                    <TableCell align="center">Read More</TableCell>

                 
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.getVehicle.length > 0 ? (
                    this.state.getVehicle.map((vehicle, index) => (
                      <TableRow key={vehicle._id}>
                        <TableCell>
                          {this.state.search
                            ? Number(
                                (this.state.searchpage - 1) * this.state.limit
                              ) + Number(index + 1)
                            : Number((this.state.page - 1) * this.state.limit) +
                              Number(index + 1)}
                        </TableCell>
                        <TableCell align="right">
                          {!vehicle.category
                            ? "No Category Found"
                            : vehicle.category.title
                            ? vehicle.category.title
                            : "No Category Title Found"}
                        </TableCell>
                        <TableCell align="right">
                          {!vehicle.brand
                            ? "No Brand Found"
                            : vehicle.brand.title
                            ? vehicle.brand.title
                            : "No Brand Title Found"}
                        </TableCell>
                        <TableCell align="right">
                        {!vehicle.model
                            ? "No Model Found"
                            : vehicle.model.name
                            ? vehicle.model.name
                            : "No Model Name Found"}
                        </TableCell>
                        <TableCell align="right">{vehicle.reg_no}</TableCell>
                     
                        
                        <TableCell allign="right">{vehicle.selling_price}</TableCell>
                        <TableCell align="right">{vehicle.city?vehicle.city.title?vehicle.city.title:"No city name Found":"No City Available"}</TableCell>
                        <TableCell align="center">
                        <FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">Status</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={vehicle.inspection_status}
    label="Status"
    name="status"
    onChange={(e)=>this.handleStatus(vehicle._id,e.target.value)}
  >
     <MenuItem value="draft">Draft</MenuItem>
                {/* <MenuItem value="pending">Pending</MenuItem> */}
    <MenuItem value="approved">Approved</MenuItem>
    <MenuItem value="published">Pending</MenuItem>
  </Select>
</FormControl>
                        </TableCell>

                       
                        <TableCell align="center"> <Button
                            color="success"
                            variant="outlined"
                            sx={{width:"90px"}}
                            onClick={() => this.handleModal(vehicle)}
                          >
                            Read More
                          </Button></TableCell>
                        
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Nothing to show here
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter></TableFooter>
              </Table>
         
            </TableContainer>
          </Paper>
        </Grid>
        <MoreInfo
          open={this.state.visible}
          onClose={this.handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ position: "absolute", top: "15vh", left: "20vw" }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            sx={{
              overflow: "scroll",
              maxHeight: "70vh",
              width: "60vw",
              backgroundColor: "#A3A3A8",
            }}
          >
            <Grid item xs={9}>
              <Typography variant="h5">
                {!this.state.inf.model
                            ? "No Model Found"
                            : this.state.inf.model.name
                            ? this.state.inf.model.name
                            : "No Model Name Found"}
              </Typography>
            </Grid>

            <Grid item xs={3} justifyContent="flex-end">
              <Button
                color="secondary"
                variant="contained"
                onClick={this.handleClose}
              >
                X
              </Button>
            </Grid>
            <Grid item sm={12}>
              <Paper elevation={1}>
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Title</TableCell>
                        <TableCell>Description</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    <TableRow>
                      
                    <TableCell >Manufacture Year</TableCell>
                    <TableCell > {!this.state.inf.years
                            ? "No Year Found"
                            : this.state.inf.years.year
                            ? this.state.inf.years.year
                            : "No Year Name Found"}</TableCell>
                    </TableRow>
                      <TableRow>
                        <TableCell>Fuel Type</TableCell>
                        <TableCell>{!this.state.inf.fuelType
                            ? "No Fuel Type Found"
                            : this.state.inf.fuelType.title
                            ? this.state.inf.fuelType.title
                            : "No Fuel Type Name Found"}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Body Type</TableCell>
                        <TableCell>{!this.state.inf.bodyType
                            ? "No Body Type Found"
                            : this.state.inf.bodyType.title
                            ? this.state.inf.bodyType.title
                            : "No Body Type Name Found"}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>{this.state.inf.category && (this.state.inf.category.title.toLowerCase()==="tractors"?"Hours Driven":"Kilometers Driven")}</TableCell>
                        <TableCell>{this.state.inf.category && (this.state.inf.category.title.toLowerCase()==="tractors"?this.state.inf.no_of_hrs:this.state.inf.km_driven)}</TableCell>
                      </TableRow>
                       <TableRow>
                        <TableCell>State</TableCell>
                        <TableCell>
                          {this.state.inf.state?this.state.inf.state.title?this.state.inf.state.title:"No state name Found":"No state Available"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Seller</TableCell>
                        <TableCell>{!this.state.inf.user
                            ? "No user Found"
                            : this.state.inf.user.name
                            ? this.state.inf.user.name
                            : "No user Name Found"}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>No. of Owner</TableCell>
                        <TableCell>{this.state.inf.no_of_owner}</TableCell>
                      </TableRow>
                      
                    
                      
                     
                      {this.state.inf.category && (this.state.inf.category.title.toLowerCase()==="buses"&&( <TableRow>
                        <TableCell>No. of Seat</TableCell>
                        <TableCell>{this.state.inf.no_of_seats}</TableCell>
                      </TableRow>))}
                                         {this.state.inf.category && (this.state.inf.category.title.toLowerCase()==="tractors"&&( <TableRow>
                        <TableCell>Horse Power</TableCell>
                        <TableCell>{this.state.inf.horse_power}</TableCell>
                      </TableRow>))} 
                      <TableRow>
                        <TableCell>Tyre Condition</TableCell>
                        <TableCell>{this.state.inf.tyre_cond}</TableCell>
                      </TableRow>
                      
                      <TableRow>
                        <TableCell>Insurance</TableCell>
                        <TableCell>{this.state.inf.insurance && (this.state.inf.insurance.substring(0,10))}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Rc Document</TableCell>
                        <TableCell>{this.state.inf.rc_document}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Fitness Certificate</TableCell>
                        <TableCell>
                          {this.state.inf.fitness_certificate && (this.state.inf.fitness_certificate.substring(0,10))}
                        </TableCell>
                      </TableRow>
                   
                    
                      <TableRow>
                        <TableCell>No. of Owner</TableCell>
                        <TableCell>{this.state.inf.no_of_owner}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Tax Validity</TableCell>
                        <TableCell>{this.state.inf.tax_validity && (this.state.inf.tax_validity.substring(0,10))}</TableCell>
                      </TableRow>
                      {this.state.inf.category && ((this.state.inf.category.title.toLowerCase()==="trucks" || this.state.inf.category.title.toLowerCase()==="construction equipments" )&&(  <TableRow>
                        <TableCell>No. of Tyre</TableCell>
                        <TableCell>{this.state.inf.no_of_tyre}</TableCell>
                      </TableRow>))}
                    
                    
                      <TableRow>
                        <TableCell>Tyre Condition</TableCell>
                        <TableCell>{this.state.inf.tyre_cond}</TableCell>
                      </TableRow>
                    
                     
                      <TableRow>
                        <TableCell>Vehicle Permit</TableCell>
                        <TableCell>{this.state.inf.vehicle_permit}</TableCell>
                      </TableRow>

                    
                      

                      {/* <TableRow>
                <TableCell>{this.state.inf.vehicle_model}</TableCell>
                <TableCell>{this.state.inf.vehicle_model}</TableCell></TableRow>
                <TableRow>
                <TableCell>{this.state.inf.vehicle_model}</TableCell>
                <TableCell>{this.state.inf.vehicle_model}</TableCell></TableRow>

                <TableRow>
                <TableCell>{this.state.inf.vehicle_model}</TableCell>
                <TableCell>{this.state.inf.vehicle_model}</TableCell></TableRow>
                <TableRow>
                <TableCell>{this.state.inf.vehicle_model}</TableCell>
                <TableCell>{this.state.inf.vehicle_model}</TableCell></TableRow>
                <TableRow>
                <TableCell>{this.state.inf.vehicle_model}</TableCell>
                <TableCell>{this.state.inf.vehicle_model}</TableCell></TableRow>
                <TableRow>
                <TableCell>{this.state.inf.vehicle_model}</TableCell>
                <TableCell>{this.state.inf.vehicle_model}</TableCell></TableRow> */}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
            <h3 style={{ width: "100%" }}>Images</h3>
            <br />
            <div style={{ display: "flex", flexWrap: "wrap" }}>
         
             
              <div
                style={{
                  position: "relative",
                  margin: "20px 30px",
                  width: "200px", height: "200px",borderRadius: '5px'
                  
                }}
              >
                <img
                  src={this.state.inf.engine_pic?
                 (   " https://gaddideals.brokerinvoice.co.in" +
                    this.state.inf.engine_pic):Nothing
                  }
              
                  alt="Engine"
                  style={{ width:"100%",height:"100%" }}
                />
                <h3
                  style={{
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    background: "#000",
                    color: "#fff",
                    width: "100%",
                    margin: "0",
                    opacity: " .6",
                  }}
                >
               Engine
                </h3>
              </div>
              <div
                style={{
                  position: "relative",
                  margin: "20px 30px",
                  width: "200px", height: "200px",borderRadius: '5px'
                  
                }}
              >
                <img
                  src={this.state.inf.front_side_pic?
                 (   " https://gaddideals.brokerinvoice.co.in" +
                    this.state.inf.front_side_pic):Nothing
                  }
              
                  alt="Engine"
                  style={{ width:"100%",height:"100%" }}
                />
                <h3
                  style={{
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    background: "#000",
                    color: "#fff",
                    width: "100%",
                    margin: "0",
                    opacity: " .6",
                  }}
                >
               Front Side
                </h3>
              </div>
              <div
                style={{
                  position: "relative",
                  margin: "20px 30px",
                  width: "200px", height: "200px",borderRadius: '5px'
                  
                }}
              >
                <img
                  src={this.state.inf.back_side_pic?
                 (   " https://gaddideals.brokerinvoice.co.in" +
                    this.state.inf.back_side_pic):Nothing
                  }
              
                  alt="Engine"
                  style={{ width:"100%",height:"100%" }}
                />
                <h3
                  style={{
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    background: "#000",
                    color: "#fff",
                    width: "100%",
                    margin: "0",
                    opacity: " .6",
                  }}
                >
               Back Side
                </h3>
              </div>
         {this.state.inf.front_tyre && (this.state.inf.front_tyre.length>0 ? (this.state.inf.front_tyre.map(((resp,index)=>(
          <div
                style={{
                  position: "relative",
                  margin: "20px 30px",
                  width: "200px", height: "200px",borderRadius: '5px'
                  
                }}
                key={index}
              >
                <img
                  src={resp?
                 (   " https://gaddideals.brokerinvoice.co.in" +
                    resp):Nothing
                  }
              
                  alt=" Front Tyre"
                  style={{ width:"100%",height:"100%" }}
                />
                <h3
                  style={{
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    background: "#000",
                    color: "#fff",
                    width: "100%",
                    margin: "0",
                    opacity: " .6",
                  }}
                >
               Front Tyre
                </h3>
              </div>
         )))):  (<div
                style={{
                  position: "relative",
                  margin: "20px 30px",
                  width: "200px", height: "200px",borderRadius: '5px'
                  
                }}
              >
                <img
                  src={Nothing
                  }
              
                  alt="Front Tyre"
                  style={{ width:"100%",height:"100%" }}
                />
                <h3
                  style={{
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    background: "#000",
                    color: "#fff",
                    width: "100%",
                    margin: "0",
                    opacity: " .6",
                  }}
                >
               Front Tyre
                </h3>
              </div>))}
              {this.state.inf.side_pic_vehicle && (this.state.inf.side_pic_vehicle.length>0 ? (this.state.inf.side_pic_vehicle.map(((resp,index)=>(
          <div
                style={{
                  position: "relative",
                  margin: "20px 30px",
                  width: "200px", height: "200px",borderRadius: '5px'
                  
                }}
                key={index}
              >
                <img
                  src={resp?
                 (   " https://gaddideals.brokerinvoice.co.in" +
                    resp):Nothing
                  }
              
                  alt="Side Vehicle"
                  style={{ width:"100%",height:"100%" }}
                />
                <h3
                  style={{
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    background: "#000",
                    color: "#fff",
                    width: "100%",
                    margin: "0",
                    opacity: " .6",
                  }}
                >
               Side Vehicle
                </h3>
              </div>
         )))):  (<div
                style={{
                  position: "relative",
                  margin: "20px 30px",
                  width: "200px", height: "200px",borderRadius: '5px'
                  
                }}
              >
                <img
                  src={Nothing
                  }
              
                  alt="Side Vehicle"
                  style={{ width:"100%",height:"100%" }}
                />
                <h3
                  style={{
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    background: "#000",
                    color: "#fff",
                    width: "100%",
                    margin: "0",
                    opacity: " .6",
                  }}
                >
              Side Vehicle
                </h3>
              </div>))}

            </div>
            
          </Grid>
        </MoreInfo>
        <Modal
          visible={this.state.open}
          width="450"
          height="100"
          effect="fadeInUp"
          onClickAway={() => this.setState({ open: false })}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <h4
              style={{ color: "black", textAlign: "center", display: "block" }}
            >
              Are you sure about that???
            </h4>

            <br></br>
            <ButtonGroup>
              <Button
                className="btn"
                color="secondary"
                variant="contained"
                style={{ margin: "20px" }}
                onClick={() => {
                  this.handleDelete();
                }}
              >
                {this.state.delLoading ? "Deleting..." : "Yes"}{" "}
              </Button>
              <Button
                className="btn"
                color="primary"
                variant="contained"
                style={{ margin: "20px", marginLeft: "4px" }}
                onClick={() => {
                  this.setState({ open: false });
                }}
              >
                NO
              </Button>
            </ButtonGroup>
          </Grid>
        </Modal>
      </Container>

          {/* <div className="cardContainer">
            <CardBox
              name={"Invoice All Count"}
              value={this.state.dashboardData.invoice_all_count}
            />
            <CardBox
              name={"Invoice Today's Count"}
              value={this.state.dashboardData.invoice_todays_count}
            />
            <CardBox
              name={"Todays Total Commission Amount"}
              value={this.state.dashboardData.todays_total_commission_amount}
            />
            <CardBox
              name={"Total Commission Amount"}
              value={this.state.dashboardData.total_commission_amount}
            />
          </div> */}

          {/* <div style={{ marginTop: "30px" }}>
            <h3 style={{ borderBottom: "2px solid white", width: "8rem" }}>
              Recent invoices
            </h3>
            <p>
              last invoice created at :{" "}
              {this.state.invoices.length > 0
                ? new Date(this.state.invoices[0].createdAt).toDateString()
                : ""}
            </p>
            <Grid item sm={12}>
              <Paper elevation={1}>
                <TableContainer>
                  <Table size="small" stickyHeader aria-label="a dense table">
                    <TableRow
                      className="tableHead"
                      style={{
                        height: "60px",
                        backgroundColor: "#85C1E9",
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      <TableCell align="center" style={{ fontWeight: "bold" }}>
                        Sr.No
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{ fontWeight: "bolder" }}
                      >
                        Contract No
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontWeight: "bolder" }}
                      >
                        Buyer Name
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontWeight: "bolder" }}
                      >
                        Seller Name
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontWeight: "bolder" }}
                      >
                        Total Commission Amount
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontWeight: "bolder" }}
                      >
                        Total Invoice Amount
                      </TableCell>
                    </TableRow>
                    <TableBody>
                      {this.state.invoices.map((invoice, index) => (
                        <TableRow key={invoice._id} style={{ height: "70px" }}>
                          <TableCell component="th" scope="row" align="center">
                            {index + 1}
                          </TableCell>

                          <TableCell align="center">
                            {" "}
                            {invoice.contract_no}
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            {invoice.buyer.name}
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            {invoice.seller.name}
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            {this.currency(invoice.total_commission_amount)}
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            {this.currency(invoice.total_amount)}
                            {/* &#8377;{invoice.total_amount} */}
                          {/* </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table> */}
                  {/* PAGINATION TABLE START */}

                  {/* PAGINATION TABLE END */}
                {/* </TableContainer>
              </Paper>
            </Grid>
          </div> */} 


        </Container>
      
      </>
    )
  }
}

