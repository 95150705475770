import React, { Component } from "react";
import axios from "axios";
import Constants from "../App/Variables/Constant";
import { Link, Navigate } from "react-router-dom";
import init from "../helpers/WindowToken";
import Resizer from "react-image-file-resizer";
import {
  Button,
  Container,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import state from "./../App/Main/State";
// import SunEditor from "suneditor-react";
// import "suneditor/dist/css/suneditor.min.css";

let fd = new FormData();
export default class AddNotification extends Component {
  state = {
    loading: false,
    title: "",
    error: false,
    image_url: "",
    description: "",
    notification: false,
    message: "",
    redirect: false,
    file: [],
    waiting: false,
    loading: false,
    showDone: false,
  };

  constructor(props) {
    super(props);
    this.emptyFile = React.createRef();
  }
  handleChange = (e) => {
    if (e.target.name === "title") {
      this.setState({
        error: false,
      });
    }

    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeDesc(content) {
    // console.log(content); //Get Content Inside Editor
    this.setState(
      {
        description: content,
      },
      () => {
        console.log(this.state.description);
      }
    );
  }

  handleImage = (e) => {
    this.setState({
      [e.target.name]: e.target.files[0],
    });
    // var file = e.target.files[0];
    // if (file) {
    //   this.setState({
    //     waiting: true,
    //     showLoading: true,
    //     showDone: false,
    //   });
    //   if (file.size > 100000) {
    //     try {
    //       this.setState({ waiting: true });
    //       Resizer.imageFileResizer(
    //         file,
    //         300,
    //         300,
    //         "JPEG",
    //         100,
    //         0,
    //         (uri) => {
    //           this.setState(
    //             { image: uri, waiting: false, showDone: true },
    //             () => {
    //               if (this.state.loading) {
    //                 this.handleSubmit();
    //               }
    //             }
    //           );
    //         },
    //         "file",
    //         100,
    //         100
    //       );
    //     } catch (err) {
    //       console.log(err);
    //       this.setState({
    //         notification: true,
    //         message:
    //           "Failed to Compress Image,press Add to submit Uncompressed Image",
    //         [e.target.name]: e.target.files[0],
    //         waiting: false,
    //         loading: false,
    //         showDone: true,
    //       });
    //       setTimeout(() => {
    //         this.setState({ notification: false, message: "" });
    //       }, 2000);
    //     }
    //   }
    // }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    fd = new FormData();
    // // if (this.state.image === "") {
    // //   window.location.reload();
    // // } else {
    //   this.setState({ loading: true });
    //   fd.append("title", this.state.title);
    //   fd.append("description", this.state.description);

    // if(this.state.description.length===0){
    //     this.setState({notification:true,
    //     message:"Description cannot be empty"});
    //     setTimeout(()=>{
    //       this.setState({notification:false})
    //     },2000)
    // } else {

    if (init() === "success") {
      this.setState({ loading: true });
      // let fd = {
      //   title: this.state.title,
      //   description: this.state.description,
      //   file

      // };
      fd.append("title", this.state.title);
      fd.append("image_url", this.state.image_url);
      fd.append("description", this.state.description);
      let auth = localStorage.getItem("accessToken");
      axios
        .post(Constants.postUrls.postNotification, fd, {
          headers: { Authorization: `Bearer ${auth}` },
        })
        .then((resp) => {
          // console.log(resp.data.message);
          this.setState({
            loading: false,
            message: resp.data.message,
            notification: true,
          });
          if (resp.data.message === undefined || resp.data.message === null) {
            console.log(resp);
            if (resp.data.errors.msg) {
              this.setState({
                message: resp.data.errors.msg,
              });
            } else {
              this.setState({
                message: "unknown error",
              });
            }
          }
          if (
            resp.data.message ===
            `title already exist with the name ${this.state.title}`
          ) {
            this.setState({
              error: true,
            });
          }
          setTimeout(() => {
            this.setState({
              notification: false,
              message: "",
            });
            if (
              resp.data.message === "Mannual notification added successfully"
            ) {
              this.setState({
                redirect: true,
              });
            }
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
          // window.location.reload();
          this.setState({
            notification: true,
            message: "some error occured check your console or try again",
          });
          setTimeout(() => {
            this.setState({
              notification: false,
              message: "",
              loading: false,
            });
          }, 2000);
        });
      // }
    }
    // }
  };
  render() {
    return (
      <Container maxWidth="xl">
        {this.state.redirect && (
          <Navigate to="/manualNotification" replace={true} />
        )}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6"> Manual Notification </Typography>
          </Grid>
        </Grid>
        {this.state.notification ? (
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={true}
            autoHideDuration={5000}
            color="green"
            message={this.state.message}
          ></Snackbar>
        ) : null}
        <div
          style={{
            background: "#5b5757",
            padding: "20px",
            borderRadius: "10px",
            // color: rgba(0, 0, 0, 0.87);
            width: "100%",
            border: "0",
            display: "flex",
            position: "relative",
            fontSize: ".875rem",
            minWidth: "0",
            wordWrap: " break-word",
            /* background: #FFF; */
            boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
            marginTop: "30px",
            borderRadius: "6px",
            marginBottom: "30px",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <div
            style={{
              background:
                "linear-gradient(60deg, rgb(128 115 115), rgb(255 250 250)) rgb(153, 153, 153))",
              boxShadow:
                " rgb(0 0 0 / 14%) 0px 4px 20px 0px, rgb(128 120 120 1/ 40%) 0px 7px 10px -5px",
              float: "left",
              padding: "15px",
              marginTop: "-43px",
              marginRight: "15px",
              borderRadius: "3px",
              backgroundColor: "#999",
              width: "6%",
              height: "60px",
              marginBottom: "25px",
              textAlign: "center",
            }}
          >
            <EditIcon />
          </div>
          <form onSubmit={this.handleSubmit}>
            <Grid container sm={12} spacing={2}>
              <Grid item sm={6}>
                <TextField
                  name="title"
                  error={this.state.error}
                  id={this.state.error ? "outlined-error" : "outlined-basic"}
                  label={this.state.error ? "Error" : "Title"}
                  size="medium"
                  variant="outlined"
                  multiline
                  maxRows={4}
                  required={true}
                  type="text"
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  value={this.state.title}
                />
              </Grid>

              <Grid item sm={6}>
                <TextField
                  name="image_url"
                  type="file"
                  accept="image/*"
                  // error={this.state.error}
                  // id={this.state.error ? "outlined-error" : "outlined-basic"}
                  // label={this.state.error ? "Error" : "Title"}
                  size="medium"
                  variant="outlined"
                  onChange={this.handleImage}
                  style={{ width: "100%" }}
                  // ref={this.emptyFile}
                />
              </Grid>

              <Grid item sm={12}>
                <TextField
                  name="description"
                  error={this.state.error}
                  id={this.state.error ? "outlined-error" : "outlined-basic"}
                  label={this.state.error ? "Error" : "Description"}
                  size="medium"
                  variant="outlined"
                  multiline
                  maxRows={4}
                  required={true}
                  type="text"
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  value={this.state.description}
                />
              </Grid>
            </Grid>

            {/* <div style={{ color: "black", marginTop: "20px" }}>
              <label style={{ color: "white" }}>Description:</label>

             <CKEditor
                className="ckeditor"
                value={this.state.description}
                editor={ClassicEditor}
                data={this.state.description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  this.setState({
                    description: data,
                  });
                }}
              />
            </div> */}

            <br />
            <Button
              variant="contained"
              type="submit"
              size="medium"
              color="primary"
              style={{
                marginRight: "20px",

                marginTop: "35px",
              }}
            >
              {this.state.loading ? "Adding.." : "Add "}
            </Button>
            <Link
              to={{
                pathname: "/manualNotification",
              }}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                type="submit"
                color="secondary"
                style={{ marginRight: "20px", marginTop: "35px" }}
              >
                Cancel
              </Button>
            </Link>
          </form>
        </div>
      </Container>
    );
  }
}
