import React, { Component } from 'react';
import axios from "axios";
import Constants from "../App/Variables/Constant";
import { Link, Navigate } from "react-router-dom";
import init from "../helpers/WindowToken";
import {
  Button,
  Container,
  Grid,
  Snackbar,
  TextField,
  Typography,
  Autocomplete,
  CircularProgress
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Resizer from "react-image-file-resizer";
import DoneIcon from '@mui/icons-material/Done';
let fd= new FormData();

export default class AddCity extends Component {
  state = {
    loading: false,
   title:"",
   stateName:null,
   stateId:"",
   stateData:[],
   error:false,
   redirect:false,

    notification: false,
    message: "",
   
  };
  componentDidMount(){
    axios.get(Constants.getUrls.getAllState+ "?&limit=100").then((resp)=>{
      let temp=[];
      resp.data.getAllStates.docs.forEach((res)=>{
        if(res.status==="active")
        temp.push( {label:res.title,id:res._id});
      })
      this.setState({
        stateData:temp
      })
    
    }).catch((err)=>{
      console.log(err);
    })
  }
  handleChange = (e) => {
    if(e.target.name==="title"){
      this.setState({
        error:false
      })
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleFile=(e)=>{
        
    var  file=e.target.files[0];
    if(file){
   this.setState({
     waiting:true,
   showLoading:true,
   showDone:false
   })
    if(file.size>100000){
     
     try {
       this.setState({waiting:true})
       Resizer.imageFileResizer(
         file,
         300,
         300,
         "JPEG",
         100,
         0,
         (uri) => {
           // console.log(uri);
           this.setState({ image: uri,
             waiting:false,
            
             showDone:true
            }
           ,()=>{
             if(this.state.loading){
               this.handleSubmit();
             }
            
           }
           );
         },
         "file",
         100,
         100
         
         
       );
     } catch (err) {
       console.log(err);
       this.setState({notification:true,
         message:"Failed to Compress Image,press Add to submit Uncompressed Image ",
         [e.target.name]: e.target.files[0]
       ,    waiting:false,
            loading:false,
       showDone:true},
         
         
         )
         setTimeout(()=>{
           this.setState({notification:false,
           message:"",
         })
         },2000)

     }

    }else{
      this.setState({
       [e.target.name]: e.target.files[0],
       showDone:true,
       waiting:false

     })

    }}
  }
  getStateId=(e,val_id,val)=>{
    // console.log("this")
    // console.log(e)
    this.setState({
      stateId:val_id,
      stateName:val
    })
  
  
  }
  handleSubmit=(e)=>{
    e.preventDefault();
  //   if(this.state.description.length===0){
  //     this.setState({notification:true,
  //     message:"Description cannot be empty"})
  //     setTimeout(()=>{
  //       this.setState({notification:false})
  //     },2000)
  // } else {
    fd=new FormData();
    // if(this.state.image===""){
    //   window.location.reload();
    // } else{
    this.setState({loading:true,});
    fd.append("title", this.state.title);
    fd.append("state", this.state.stateId);
    fd.append("image", this.state.image);
   
    
if(init()==="success"){
if(this.state.waiting){
  this.setState({notification:true,
    message:"Wait image is still Compressing"}
    )
    setTimeout(()=>{
      this.setState({notification:false,
      message:""})
    },1000)
}
else{
let auth=localStorage.getItem("accessToken");
axios.post(Constants.postUrls.postCity, fd,{
headers:{"Authorization": `Bearer ${auth}`}}).then((resp) => {

// console.log(resp.data.message);
this.setState({
  loading: false,
  message: resp.data.message,
  notification: true,
  
});
if (resp.data.message === undefined || resp.data.message === null) {
  // console.log(resp);
  if (resp.data.errors.msg) {
    this.setState({
      message: resp.data.errors.msg,
    });
  } else {
    this.setState({
      message: "unknown error",
    });
  }
}
if(resp.data.message===`title already exist with the name ${this.state.title}`){
  this.setState({
    error:true
  })
}


setTimeout(() => {
  if(resp.data.message==="City added successfully"){
    this.setState({
      redirect:true
    })
  }

  
  this.setState(
    {
      // title:"",
      // image:"",
    
      // description:"",
     notification:false,
     message:"",      
    }
    
  );
//   fd.delete('title');
// fd.delete('image');

// fd.delete('description');
// this.emptyFile.current.value = "";

}, 2000);
}).catch((err)=>{
console.log(err);
// window.location.reload();
this.setState({
  notification:true,
  message:"error occured check console"
})
setTimeout(() => {
  this.setState(
    {
      // title:"",
      //   image:"",
      //   description:"",
     notification:false,
     message:"",
     loading: false
    }
   
  );
  // this.emptyFile.current.value = "";
 
  
}, 2000);


});

}
// }
    
} 
// } 
}
  
  render() {
    return (
      <Container maxWidth="xl">     
        <Grid container spacing={2}>
        { 
   this.state.redirect && <Navigate to='/city' replace={true}/>
}
          <Grid item xs={6}>
            <Typography variant="h6"> Add City </Typography>
          </Grid>
        </Grid>
        {this.state.notification ?
        (   <Snackbar
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          open={true}
                          autoHideDuration={5000}
                          color="green"
                          message={this.state.message}
                        ></Snackbar>):null}
        <div
          style={{
            background: "#5b5757",
            padding: "20px",
            borderRadius: "10px",
            // color: rgba(0, 0, 0, 0.87);
            width: "100%",
            border: "0",
            display: "flex",
            position: "relative",
            fontSize: ".875rem",
            minWidth: "0",
            wordWrap: " break-word",
            /* background: #FFF; */
            boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
            marginTop: "30px",
            borderRadius: "6px",
            marginBottom: "30px",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <div
            style={{
              background:
                "linear-gradient(60deg, rgb(128 115 115), rgb(255 250 250)) rgb(153, 153, 153))",
              boxShadow:
                " rgb(0 0 0 / 14%) 0px 4px 20px 0px, rgb(128 120 120 1/ 40%) 0px 7px 10px -5px",
              float: "left",
              padding: "15px",
              marginTop: "-43px",
              marginRight: "15px",
              borderRadius: "3px",
              backgroundColor: "#999",
              width: "6%",
              height: "60px",
              marginBottom: "25px",
              textAlign: "center",
            }}
          >
            <EditIcon />
          </div>
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={2}>
            <Grid item lg={4}>
         
         <Autocomplete
     disablePortal
     id="combo-box-demo"
     fullWidth={true}
     options={this.state.stateData}
     onChange={(e, value) => { if(!value){
      this.getStateId(e, "",null)}else this.getStateId(e, value.id,value.label);}}
     value={this.state.stateName}
     isOptionEqualToValue={(option, value) => option.label === value}
     sx={{ width: 300 }}
     renderInput={(params) => <TextField {...params} label="State" />}
   />
         </Grid>

              <Grid item sm={6}>
              <TextField
                  name="title"
                  error={this.state.error}
                id={this.state.error?"outlined-error":"outlined-basic"}
                label={this.state.error?"Error":"Title"}
              
                  size="medium"
                  variant="outlined"
                  // multiline={true}
                  //     maxRows={4}
                  required={true}
                  type="text"
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  value={this.state.title}
                />
              </Grid>
              {/* {
                          this.state.all_exchange_type.map(val =>(
                              console.log(val.instrument)
                          ))
                      } */}
        
  
              <Grid item sm={6}>
                {/* <TextField
                  name="image"
                  id="outlined-basic"
                  placeholder={"Add Brand Image"}
                  size="medium"
                  variant="outlined"
                  required={true}
                  type="file"
                  onChange={this.handleFile}
                  style={{ width: "100%" }}
                /> */}
                <div style={{position:"relative",display:"flex",flexDirection:"row",width:"100%",height:"100%","alignItems": "baseline",justifyContent: 'center', alignItems: 'center'}}>
              <div style={{ width:"90%",display:"flex",flexDirection:"row","alignItems": "baseline"}}>
              <label>Image:</label>
              <input name="image" 
               type="file"
                 onChange={this.handleFile}
                 accept="image/*"
                //  ref={this.emptyFile} 
                 disabled={this.state.showLoading && (this.state.showDone?false:true)}
                  
                  style={{ width: "100%" }}
              /></div>
              {this.state.showLoading ? (this.state.showDone?<DoneIcon color="success"  sx={{position:"absolute",right:"0"}}/>: <CircularProgress
              color="success"
              size={25}
              sx={{position:"absolute",right:"0"}}
              />):null}
             

              </div>
              </Grid>
            
            </Grid>
        
                <br />
            <Button
              variant="contained"
              type="submit"
              size="medium"
              color="primary"
              style={{
                marginRight: "20px",
  
                marginTop: "35px",
              }}
            >
              {this.state.loading ? "Adding.." : "Add "}
            </Button>
            <Link
                    to={{
                      pathname: "/City",
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      variant="contained"
                      type="submit"
                   
                      color="secondary"
                      style={{ marginRight: "20px", marginTop: "35px" }}
                    >
                      Cancel
                    </Button>
                  </Link>
          </form>
        </div></Container>
    )
  }
}
