import React, { Component } from 'react';
import axios from "axios";
import Constants from "../App/Variables/Constant";
import {Link,Navigate } from "react-router-dom";
import init from "../helpers/WindowToken";
import {
    Button,
    Container,
    Grid,
    Snackbar,
    TextField,
    Typography
}from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

export default class UpdateYear extends Component {
  state = {
    loading: false,
    yearId:"",
    year: "",
    notification: false,
    testimonialId: "",
    message: "",
    redirect: false,
  textChange:false,
  error:false
  };
  componentDidMount() {
    this.getYear();
 
   }
   getYear=()=>{
    let id = window.location.pathname.split("/")[3];
    this.setState({
      yearId: id,
    });

    if (init() === "success") {
      let auth = localStorage.getItem("accessToken");
      axios
        .get(Constants.getUrls.getyears + id, {
          headers: { Authorization: `Bearer ${auth}` },
        })
        .then((resp) => {
          // console.log("this");
          // console.log(resp);
         
          this.setState({
            year: resp.data._years.year,

        
          }
          // ,()=>{
          //   console.log(this.state.image)
          // }
          );
        });
    }
  }
  handleChange=(e)=>{
    if(e.target.name==="year"){
      this.setState({
        error:false
      })
    }
    this.setState({
      [e.target.name]:e.target.value,
      textChange:true
    })
  }
 
  handleSubmit = (e) => {
    e.preventDefault();

  

    this.setState({ loading: true });

    if (init() === "success") {
      let auth = localStorage.getItem("accessToken");
      // if (
      //   this.state.image === null ||
      //   this.state.image === "" ||
      //   this.state.title === null ||
      //   this.state.title === "" ||
      //   this.state.description === null ||
      //   this.state.description === ""
      // ) {
      //   this.setState({
      //     notification: true,
      //     message: "Check your input.It cant be null or empty",
      //     loading: false,
      //   });
      //   setTimeout(
      //     () =>
      //       this.setState({ notifcation: false, message: "", loading: false }),
      //     2000
      //   );
      //   setTimeout(() => this.getTestimonial(), 3000);
      // } else {
        
        let fd={
          year:this.state.year
    
        }
      
// if(this.state.textChange===false){
//   this.setState({notification:true,
//   message:"nothing to change here"})
//   setTimeout(() => {
//     this.setState({notification:false,
//       loading:false,
//       redirect:true
//     })
//   }, 2000);
// } else {
        axios
          .put(Constants.putUrls.updateyears + this.state.yearId, fd, {
            headers: {
              // "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${auth}`,
            },
          })
          .then((resp) => {
            // axios.post("http://localhost:8081/createStocks", stockLoad).then((resp) => {
              // console.log(this.state.image);
            this.setState({
              loading: false,
              notification: true,
              message: resp.data.message,
              
            });
            if (resp.data.message === undefined || resp.data.message === null) {
              // console.log(resp);
              if(resp.data.errors.msg){
              this.setState({
                message: resp.data.errors.msg,
              });}
              else {
                this.setState({
                  message:"unknown error"
                })
              }
            }
           if(resp.data.message===`Year already exist with the year ${this.state.year}`){
              this.setState({
                error:true
              })
            }  
            setTimeout(() => {
              this.setState({
                // year:"",
                notification: false,
                message:"",
              });
              if(resp.data.message==="Updated Successfully"){
                this.setState({
                  redirect:true
                })
              }
              // fd.delete("title", this.state.title);
              // fd.delete("description", this.state.description);
              // fd.delete("image", this.state.image);
            }, 2000);
           
            
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              notification:true,
              message:"some error occured check your console"
            })
            setTimeout(() => {
              this.setState(
                {
                  // year:"",
                 notification:false,
                 message:"",
                 loading: false
                }
               
              );
             
              
            }, 2000);
            
            // setTimeout(() => {
            //   this.getYear();
            // }, 3000);
          });
        // }
      // }
    }
  };
  render() {
    return (
      <Container maxWidth="xl">
      { 
   this.state.redirect && <Navigate to='/years' replace={true}/>
}  
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant="h6"> Update Year </Typography>
      </Grid>
    </Grid>
    {this.state.notification ? (
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={true}
        autoHideDuration={5000}
        color="green"
        message={this.state.message}
      ></Snackbar>
    ) : null}
    <div
      style={{
        background: "#5b5757",
        padding: "20px",
        borderRadius: "10px",
        // color: rgba(0, 0, 0, 0.87);
        width: "100%",
        border: "0",
        display: "flex",
        position: "relative",
        fontSize: ".875rem",
        minWidth: "0",
        wordWrap: " break-word",
        /* background: #FFF; */
        boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
        marginTop: "30px",
        borderRadius: "6px",
        marginBottom: "30px",
        flexDirection: "column",
        textAlign: "left",
      }}
    >
      <div
        style={{
          background:
            "linear-gradient(60deg, rgb(128 115 115), rgb(255 250 250)) rgb(153, 153, 153))",
          boxShadow:
            " rgb(0 0 0 / 14%) 0px 4px 20px 0px, rgb(128 120 120 1/ 40%) 0px 7px 10px -5px",
          float: "left",
          padding: "15px",
          marginTop: "-43px",
          marginRight: "15px",
          borderRadius: "3px",
          backgroundColor: "#999",
          width: "6%",
          height: "60px",
          marginBottom: "25px",
          textAlign: "center",
        }}
      >
        <EditIcon />
      </div>
      <form onSubmit={this.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <TextField
              name="year"
              error={this.state.error}
                id={this.state.error?"outlined-error":"outlined-basic"}
                label={this.state.error?"Error":"Year"}
              size="medium"
              variant="outlined"
              required={true}
              type="number"
              InputProps={{
        inputProps: { 
            min:1
        }
    }}
              onChange={this.handleChange}
              style={{ width: "100%" }}
              value={this.state.year}
            />
          </Grid>
          
        </Grid>
      
            
      
            <br />
        <Button
          variant="contained"
          type="submit"
          size="medium"
          color="primary"
          style={{
            marginRight: "20px",

            marginTop: "35px",
          }}
        >
          {this.state.loading ? "Updating.." : "Update "}
        </Button>
        <Link
          to={{
            pathname: "/years",
          }}
          style={{ textDecoration: "none" }}
        >
          <Button
            variant="contained"
            type="submit"
            color="secondary"
            style={{ marginRight: "20px", marginTop: "35px" }}
          >
            Cancel
          </Button>
        </Link>
      </form>
    </div>
  </Container>
    )
  }
}
