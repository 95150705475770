import React, { Component } from 'react';
import {
  Button,
  Container,
  FormControl,
  Grid,
  Select,
  InputLabel,
  Paper,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
  ButtonGroup,
  OutlinedInput,
  Pagination
} from "@mui/material";
import axios from "axios";
import { Link } from "react-router-dom";
import init from "../../helpers/WindowToken";
import Constants from "../Variables/Constant.jsx";
import Modal from "react-awesome-modal";
import SearchIcon from "@mui/icons-material/Search";

export default class FuelType extends Component {
  state = {
    open: false,
    getFuelType: [],
    data: [],
    notifyStatus: false,
    notify: false,
    searchedValue: "",
    visible: false,
    delLoading: false,
    rowId: 0,
    status: "",
    active: 1,
    perPage: "10",
    limit:10,
    searchpage:1,
    search:false,
    currentPage: 1,
    page: 1,
    prevPage: 0,
    nextPage: 0,
    lastPage: 0,
    lastPageView: false,
    firstPageView: false,
    total: 0,
    from: 1,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: [],
    msg: "",
    totalPage:1,
    searchTotalPage:1,
    pageinfo:1, 
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  componentDidMount() {
    this.getFuelType();
  }
  getFuelType = () => {
    axios
      .get(
        Constants.getUrls.getAllFueltype +
          "?page=" +
          this.state.page +
          "&limit=" +
          this.state.limit
      )
      .then((resp) => {
        // console.log(resp.data._getFuel.docs);

        this.setState({
          fromInc: 1,
          active: 1,
          toInc: 5,
          getFuelType: resp.data._getFuel.docs,
          total: resp.data._getFuel.totalDocs,
          nextPage: resp.data._getFuel.hasNextPage,
          prevPage: resp.data._getFuel.hasPrevPage,
          limit: resp.data._getFuel.limit,
          pagingCounter: resp.data._getFuel.pagingCounter,
          totalPage:resp.data._getFuel.totalPages,
          pageinfo:this.state.page,
          // PAGINATION

          from: 1,
          // to: resp.data.data.length,
        });
      });
  };
  handleRows = (value) => {
    this.setState(
      {
        limit: value,
      },
      () => {
        if(this.state.search){
          this.handleSearch();
        }else
        {this.getFuelType();}
      }
    );
    // this.getFuelType();
  };
  // PAGINATION BUTTONS

  handleNext = () => {
    if(this.state.search){
      if (this.state.nextPage === true) {
        // console.log(this.state.nextPage)
  
        this.setState(
          {
            searchpage: Number(this.state.searchpage) + 1,
            // to: this.state.to + this.state.limit,
          },
          () => {
            this.handleSearch();
          }
        );
        // console.log(this.state.page)
        // this.getFuelType();
      }

    }else{
    if (this.state.nextPage === true) {
      // console.log(this.state.nextPage)

      this.setState(
        {
          page: Number(this.state.page) + 1,
          // to: this.state.to + this.state.limit,
        },
        () => {
          this.getFuelType();
        }
      );
      // console.log(this.state.page)
      // this.getBrand();
    }}
  };

  handlePrev = () => {
    if(this.state.search){
      if (this.state.prevPage === true)
      this.setState(
        {
          searchpage: Number(this.state.searchpage) - 1,
        },

        () => {
          this.handleSearch();
        }
      );
    }
else{    if (this.state.prevPage === true)
      this.setState(
        {
          page: Number(this.state.page) - 1,
        },

        () => {
          this.getFuelType();
        }
      );
    // this.getFuelType();
  }
  };
  handleChangePage=(e)=>{
    this.setState({
      pageinfo:e.target.value
    })
  }
  handlePages=(e,value)=>{
    if(this.state.search){
      this.setState({
        searchpage:value
      },()=>{
        this.handleSearch()
      })
    } else{
      this.setState({
        page:value
      },()=>{
        this.getFuelType()
      })
    }
  }
  handlePage=(e)=>{
    e.preventDefault();
    if(this.state.pageinfo){
    if (this.state.search) {
      if (this.state.pageinfo>0 && this.state.pageinfo<=this.state.searchTotalPage) {
        // console.log(this.state.nextPage)

        this.setState(
          {
            searchpage: this.state.pageinfo,
            // to: this.state.to + this.state.limit,
          },
          () => {
            this.handleSearch();
          }
        );
        // console.log(this.state.page)
        // this.getAdd();
      }
    } else {
      if (this.state.pageinfo>0 && this.state.pageinfo<=this.state.totalPage) {
        // console.log(this.state.nextPage)

        this.setState(
          {
            page: this.state.pageinfo,
            // to: this.state.to + this.state.limit,
          },
          () => {
            this.getFuelType();
          }
        );
        // console.log(this.state.page)
        // this.getCategory();
      }
    }}

  }
  handleModal = (fuelType) => {
    // console.log(fuelType);
    this.setState(
      {
        rowId: fuelType._id,
        visible: true,
      }
      // ,
      // () => {
      //   console.log(this.state.fuelType);
      // }
    );
  };
  handleDelete = () => {
    this.setState({
      delLoading: true,
    });
  //   console.log(Constants.deleteUrls.deleteFueltype + "/" + this.state.rowId);

    if (init() === "success") {
      // this.setState({ delLoading: true });
      let auth = localStorage.getItem("accessToken");
      axios
        .delete(Constants.deleteUrls.deleteFueltype + "/" + this.state.rowId, {
          headers: { Authorization: `Bearer ${auth}` },
        })
        .then((res) => {
        //   console.log(res);

          this.setState({
            notify: true,
            delLoading: false,
            msg: res.data.message,
          });
          setTimeout(() => {
            this.setState({ notify: false, visible: false }, () => {
              if(this.state.search){this.handleSearch();}else {this.getFuelType();}
            });
          }, 2000);
        })
        .catch((err) => console.log(err));
    }
  };
  handleStatus = (e, status, id) => {
    let payload;
    // console.log(id);
    let auth = localStorage.getItem("accessToken");
    if (init() === "success") {
      if (status === "active") {
        payload = {
          status: "draft",
        };
      } else {
        payload = {
          status: "active",
        };
      }
      payload = JSON.stringify(payload);
      axios
        .post(Constants.statusUrl + id + "?type=fuelType", payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth}`,
          },
        })
        .then((res) => {
          // console.log(res);
          if(res.data.message){
            this.setState({
                          notify: true,
                          msg:res.data.message
                        });}
                        if(res.data.status==="failed"){
                          this.setState({
                            notify:true,
                            msg:"failed to update status"
                          })
                        }
          setTimeout(() => {
            this.setState({ notify: false }, () => {
              if (this.state.search) {
                this.handleSearch();
              } else {
                this.getFuelType();
              }
            });
          }, 750);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  handleChange = (event) => {
    if (event.target.value.length<1) {
      this.setState({
        searchedValue: "",
        search:false,
       
        


      });
      // this.defaultValues();
      this.getFuelType();
    } else {
      this.setState({
        searchedValue: event.target.value,
      });
    }
  };
  handleSubmit=(e)=>{
    e.preventDefault();
    this.setState({
      searchpage:1
    },
    () => {
      this.handleSearch();
    }
    )

  }
  handleSearch = (event) => {
    if(event){
      this.setState({
        searchedValue:event.target.value
      },()=>{
        this.handleSearch();
      })
     }
     if (this.state.searchedValue < 1) {
      this.setState({
        search: false,
      });
      // this.defaultValues();
      this.getFuelType();
    } else {
    if (this.state.searchedValue.length >= 2) {
      this.setState({ 
      search:true,
    page:1});
      axios
        .get(
          Constants.getUrls.getAllFueltype +
            "?page=" +
            this.state.searchpage +
            "&limit=" +
            this.state.limit +
            "&q=" +
           this.state.searchedValue
          // this.state.name
        )
        .then((resp) => {
          console.log(resp);
          // let btnIdArray = [];
          // for (let i = 1; i <= resp.data.pageCount; i++) {
          //   btnIdArray.push(i);
          // }
         
        this.setState({
          fromInc: 1,
          active: 1,
          toInc: 5,
          getFuelType: resp.data._getFuel.docs,
          total: resp.data._getFuel.totalDocs,
          nextPage: resp.data._getFuel.hasNextPage,
          prevPage: resp.data._getFuel.hasPrevPage,
          limit: resp.data._getFuel.limit,
          pagingCounter: resp.data._getFuel.pagingCounter,
          searchTotalPage:resp.data._getFuel.totalPages,
          pageinfo:this.state.searchpage,
          // PAGINATION

          from: 1,
          // to: resp.data.data.length,
        });
      
        });
    } 
    }
    };
  render() {
    return (
      <Container maxwidth="xl">
      {this.state.notify ? (
        <Snackbar
          message={this.state.msg}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={6000}
          color="white"
          open="true"
        ></Snackbar>
      ) : null}
      <Grid container style={{ margin: "10px 0 10px" }} alignItems="baseline">
        <Grid item xs={6}>
          <Typography variant="h6">
            {/* {this.props.title} */}
           Fuel Type
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid container justifyContent="flex-end">
         
              <TextField
                id="outlined-basic"
                label={"Search "}
                size="small"
                variant="outlined"
                onChange={this.handleSearch}
                sx={{marginRight:"5px"}}
              />
            
           
            <Link to="/admin/addFuelType" style={{ textDecoration: "none" }}>
              <Button
                size="medium"
                onClick={() => {
                  this.setState({ open: true });
                }}
                variant="contained"
                color="primary"
              >
                Add Fuel Type
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={12}>
        <Paper elevation={1}>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
           
                  <TableCell>Fuel Type</TableCell>
                  <TableCell align="right">Status</TableCell>
                
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.getFuelType.length > 0 ? (
                  this.state.getFuelType.map((fuelType) => (
                    <TableRow key={fuelType._id}>
                      <TableCell>{fuelType.title}</TableCell>
                      
                      


                      <TableCell align="right">
                        <Switch
                          name="checkedB"
                          checked={fuelType.status === "active" ? true : false}
                          onChange={(e) =>
                            // console.log(e.target.checked);
                            this.handleStatus(
                              e.target.checked,
                              fuelType.status,
                              fuelType._id
                            )
                          }
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Link
                          to={"/admin/updateFuelType/" + fuelType._id}
                          style={{ textDecoration: "none" }}
                        >
                          <Button variant="outlined">Update</Button>
                        </Link>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => this.handleModal(fuelType)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Nothing to show here
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter></TableFooter>
            </Table>
            <TableRow
              style={{ display: "flex", justifyContent: "space-evenly" }}
            >
           

              <div
                style={{
                  display: "flex",
                  alignItems: "right",
                  justifyContent: "space-between",
                  width: "200px",
                  marginTop: "10px",
                  marginLeft:"150px",
                }}
              >
                <p>Rows Per Page</p>

                <FormControl
                  className="selectMerchants"
                  style={{ marginRight: "20px", marginTop: "-12px" }}
                >
                  <InputLabel htmlFor="age-native-simple"></InputLabel>
                  <Select
                    native
                    defaultValue={10}
                    // value={state.age}
                    onChange={(e) => this.handleRows(e.target.value)}
                    inputProps={{
                      name: "Merchants",
                      id: "age-native-simple",
                    }}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                  </Select>
                </FormControl>
              </div>
              {/* Rows PerPage END */}

                <Pagination sx={{marginTop:"auto",marginBottom:"auto"}} count={this.state.search?this.state.searchTotalPage:this.state.totalPage} color="secondary" page={this.state.search?this.state.searchpage:this.state.page} onChange={this.handlePages} />
            </TableRow>
          </TableContainer>
        </Paper>
      </Grid>

      <Modal
        visible={this.state.visible}
        width="450"
        height="100"
        effect="fadeInUp"
        onClickAway={() => this.setState({ visible: false })}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <h4
            style={{ color: "black", textAlign: "center", display: "block" }}
          >
            Are you sure about that???
          </h4>

          <br></br>
          <ButtonGroup>
            <Button
              className="btn"
              color="secondary"
              variant="contained"
              style={{ margin: "20px" }}
              onClick={() => {
                this.handleDelete();
              }}
            >
              {this.state.delLoading ? "Deleting..." : "Yes"}{" "}
            </Button>
            <Button
              className="btn"
              color="primary"
              variant="contained"
              style={{ margin: "20px", marginLeft: "4px" }}
              onClick={() => {
                this.setState({ visible: false });
              }}
            >
              NO
            </Button>
          </ButtonGroup>
        </Grid>
      </Modal>
    </Container>
    )
  }
}
