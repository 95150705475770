import { Button, Container, Grid, Paper, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { Link} from "react-router-dom";
import React, { Component } from "react";
import Constants from "../Variables/Constant";
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
      typography: {
        fontFamily: 'Maven Pro'}})
export default class Login extends Component {
  state = {
    error:false,
    email: "",
    password: "",
  };
  
  login = (e) => {
    this.setState({
      loading: true,
    });
    
    e.preventDefault();
    let loginLoad = {
      email: this.state.email,
      password: this.state.password,
    };
    console.log(loginLoad);
    axios.post(Constants.postUrls.adminLogin, loginLoad).then((resp) => {
    console.log(resp.data.message);
    
    if(resp.data.message==="signed in successfully"){
      console.log(resp);
      
        localStorage.setItem("accessToken", resp.data.user.accessToken);
        // localStorage.setItem("accessName", resp.data.user.name);

        // setTimeout(() => {
        window.location.href = "/dashboard";
        // }, 1000);
        this.setState({
          notification: true,
          loading: false,
        });}
        else{
          this.setState({
            loading:false,
            error:true
          });
          setTimeout(
            () => this.setState({ error: false }), 
            3000
          );

        }
      
    })
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    return (
      <div className="loginWrapper">

        <div className="container">

        <Container maxWidth="md">
          <Grid container>
            <Grid item sm={6} style={{ position: "relative", height: "100vh",width:"100vh" }}>
              <Paper className="loginCard" elevation={15}>
              <ThemeProvider theme={theme}>
                <Typography variant="h4" component="h2" 
                gutterBottom
                >
                  Gaddideals.com
                </Typography></ThemeProvider>
                <Typography variant="h5" component="h1">
                  Log in
                </Typography>
                <Typography
                  variant="body2"
                  component="h1"
                  gutterBottom
                  color="textSecondary"
                >
                  Continue to admin dashboard
                </Typography>
                {/* <Typography
                  color="primary"
                  variant="body2"
                  style={{ alignSelf: "flex-end" , cursor:"pointer" }}
                >
                  Forgot password?
                </Typography> */}
                <form className="loginFormWrapper" onSubmit={this.login}>
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    name="email"
                    onChange={this.handleInput}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Password"
                    variant="outlined"
                    fullWidth
                    name="password"
                    type="password"
                    onChange={this.handleInput}
                  />

                  <Button
                    color="primary"
                    style={{ alignSelf: "flex-start" }}
                    variant="contained"
                    size="large"
                    type="submit"
                  >
                    {this.state.loading ? (
                      "Logging In..."
                    ) : this.state.error ? (
                      <span style={{ color: "#BD0600" }}>
                        Incorrect Credentials!!!
                      </span>
                    ) : (
                      " Log In"
                    )}
                  </Button>
                </form>

                <Grid item sm={12} style={{ position: "relative" }}>
                  {/* <Link to="/forgot" style={{ textDecoration: "none" }}>
                    <Typography
                      color="primary"
                      variant="body2"
                      style={{
                        alignSelf: "right",
                        cursor: "pointer",
                        display: "inline",
                      }}
                    >
                      Forgot password?
                    </Typography>
                  </Link> */}
                  {/* <Link to="/change" style={{ textDecoration: "none" }}>
                    <Typography
                      color="primary"
                      variant="body2"
                      style={{
                        alignSelf: "flex-end",
                        cursor: "pointer",
                        display: "inline",
                        marginLeft: "10px",
                      }}
                    >
                      Change password?
                    </Typography>
                  </Link> */}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
      </div>
    );
  }
}
