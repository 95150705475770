import {
    AppBar,
    Button,
    Grid,
    Menu,
    MenuItem,
    Snackbar,
    Toolbar,
    Typography,
    TextField,
  } from "@mui/material";
  import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    // Table,
    Row,
    Col,
    UncontrolledAlert,
    Pagination,
    PaginationItem,
    PaginationLink,
    FormGroup,
  } from "reactstrap";
  import {ArrowDropDown,
    LockRounded,
    PersonAddOutlined,
    SettingsBrightness,
    WbSunnyTwoTone,
    WindowSharp
    
} from '@mui/icons-material';
import photo from "../../../Assets/logo.png"
import Modal from 'react-awesome-modal';
import axios from "axios";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Constants from "../../Variables/Constant";
import profile from "../../../Assets/profile.jpg";
import init from "../../../helpers/WindowToken";
import React, { Component } from 'react';
let url="";



let authToken = localStorage.getItem("accessToken");


export default class MainAppbar extends Component {
    state = {
        theme: localStorage.getItem("theme"),
        showMenu: false,
        anchorEl: null,
        profileData: [],
        notifyStatus: false,
        notifyDelete: false,
        visible: false,
        delLoading: false,
        visibleProfile: false,
        name: "",
        email: "",
        mobile_no: "",
      };
    //   componentDidMount(){
    //       this.getProfile();
    //   }
    //   getProfile = () => {
    //     // if( init() === "success"){
    //       axios.get(url)
    //       .then((res) => {
    //        console.log(res);
    //        if(res.data.status===200)
    //        {
    //          this.setState({
    //            profileData: res.data.Data,
    //          });
    //        }
    //      })
    //     // }
    //    ;
    //   };
     
    //   submit = (e) => {
    //     this.setState({
    //       loading: true,
    //     });
    //     e.preventDefault();
        
        
    
        // if ( init() === "success" ){
        //   let submitLoad = {
        //     oldPassword: this.state.current_password,
        //     newPassword: this.state.new_password,
        //   };
        //   console.log(submitLoad);
        //   axios.post(Constants.postUrls.changePassword, submitLoad).then((resp) => {
        //     console.log(resp);
        //     setTimeout(() => {
        //       this.setState({
        //         notification: true,
        //         loading: true,
        //         message: resp.data.Message,
        //       });
        //     }, 2000);
        //     setTimeout(() => {
        //       this.setState({
        //         loading: false,
        //         redirect: true
        //       })
        //     }, 4000);
        //   }).catch(err => {
        //     if (err) {
        //       console.log(err);
        //       this.setState({
        //       loading: false,
        //       message: "Old Password Does Not Match",
        //       error: true,
        //     });
        //     setTimeout(() => {
        //       this.setState({
        //         error: false,
        //       });
        //       }, 3000);
        //     }
        //   });
            
          
        // }
        
    //   };
      handleInput = (e) => {
        this.setState({
          [e.target.name]: e.target.value,
        });
      };
      handleRedirect=()=>{
          window.location.href="/newpassword"
      }
      handleModal = () => {
        this.setState({
          visible: true,
        });
      };
      handleProfileModal = () => {
        this.setState({
          visibleProfile: true,
        });
      };
    
      handleClick = (event) => {
        this.setState({ showMenu: true, anchorEl: event.currentTarget });
      };
      handleClose = (event) => {
        this.setState({ showMenu: false, anchorEl: null });
      };
      applyTheme = () => {
        const theme = localStorage.getItem("theme");
        localStorage.setItem("theme", theme === "dark" ? "light" : "dark");
        window.location.reload();
      };
      logout = () => {
        localStorage.removeItem("accessToken");
        window.location.href = "/login";
      };

  render() {
      const state=this.state;
    return (
      <><AppBar position="fixed" color="default" style={{ height: "56px" }}>
      <Toolbar style={{ height: "56px" }}>
        <Grid container>
          <Grid sm={6} xs={9} item>
            <div
              style={{
                display: "flex",
                width: "20rem",
                // justifyContent: "space-between",
                alignItems: "center",
              }}
            >
             
                
                  {
                    //  this.state.profileData.vendor ? 
                    //  this.state.profileData.vendor.brand_logo_url ? 

                     <img
                     style={{
                            width: "3rem",
                            height: "3rem",
                            marginRight:"1rem",
                            borderRadius: "100%"
                          }}
                      src={photo} />
                  //   <img src={Constants.imgUrl+ this.state.profileData.vendor.brand_logo_url}
                  //     alt=" test logo"
                  //     style={{
                  //       width: "3rem",
                  //       height: "3rem",
                  //       display: "flex",
                  //       justifyContent: "center",
                  //       alignItems: "center",
                  //       marginRight:"1rem",
                  //     }}
                  //   />
                  //   :
                  // <img src="https://images.squarespace-cdn.com/content/v1/575a6067b654f9b902f452f4/1552683653140-0UUVQSSUEWVC73AWAEQG/300Logo.png"
                  //   alt=" test logo"
                  //   style={{
                  //     width: "3rem",
                  //     height: "3rem",
                  //     display: "flex",
                  //     justifyContent: "center",
                  //     alignItems: "center",
                  //     marginRight:"1rem",
                  //   }}
                  // />
                  // :
                  // <img src="https://images.squarespace-cdn.com/content/v1/575a6067b654f9b902f452f4/1552683653140-0UUVQSSUEWVC73AWAEQG/300Logo.png"
                  //   alt=" test logo"
                  //   style={{
                  //     width: "3rem",
                  //     height: "3rem",
                  //     display: "flex",
                  //     justifyContent: "center",
                  //     alignItems: "center",
                  //     marginRight:"1rem",
                  //   }}
                  // />

              }
               
              <Typography variant="h6">
                {/* {this.state.profileData.vendor ? 
                this.state.profileData.vendor.brand_name ?
                this.state.profileData.vendor.brand_name
                  : "Brand Name"
                  : "Brand Name"
                } */}

                Gaddideals.com
                  
              </Typography>
            </div>
          </Grid>

          {/* NOTIFICATION */}
          {this.state.notification ? (
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={true}
              color={"s"}
              autoHideDuration={6000}
              message={this.state.message}
            ></Snackbar>
          ) : null}
          {/* NOTIFICATION */}
          <Grid sm={6} xs={3} item>
            <Grid container   justifyContent="flex-end" alignItems="baseline">
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={this.handleClick}
                endIcon={<AccountCircleIcon />}
              >
                {/* {this.state.profileData.adminName} */}
              </Button>
              <Menu
                anchorEl={this.state.anchorEl}
                id="simple-menu"
                keepMounted
                open={this.state.showMenu}
                onClose={this.handleClose}
              >
                {/* <MenuItem onClick={this.handleClose}>
                  <Button
                    onClick={this.handleProfileModal}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography
                      children={<p style={{ fontSize: 12 }}>Profile</p>}
                    />
                    <PersonAddOutlined color="action" size={"small"} />
                  </Button>
                </MenuItem> */}

                <MenuItem onClick={this.handleClose}>
                  {" "}
                  <Button
                    onClick={this.applyTheme}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    {state.theme === "dark" ? (
                      <>
                      <Typography variant="button" display="block" gutterBottom>
        Dark Mode
      </Typography>
                        <WbSunnyTwoTone color="action" />
                      </>
                    ) : (
                      <>
                      <Typography variant="button" display="block" gutterBottom>
        Dark Mode
      </Typography>
                        <SettingsBrightness color="action" />
                      </>
                    )}
                  </Button>
                </MenuItem>
                {/* <MenuItem onClick={this.handleClose}>
                  <Button
                    onClick={this.handleRedirect}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "100%"
                    }}
                  >
                   
                   <Typography variant="button" display="block" gutterBottom>
        Change Password
      </Typography>
                    <LockOpenIcon color="action" size={"small"} />
                  </Button>
                </MenuItem> */}
                <MenuItem onClick={this.handleClose}>
                  <Button
                    onClick={this.logout}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography variant="button" display="block" gutterBottom>
        Logout
      </Typography>
                    <LockRounded color="action" size={"small"} />
                  </Button>
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
    </>
    )
  }
}
