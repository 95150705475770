import {
  Button,
  Container,
  Grid,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { Link } from "react-router-dom";
import React, { Component } from 'react'

export default class ResetPassword extends Component {
  render() {
    return (
      <div>ResetPassword</div>
    )
  }
}

