import React, { Component } from "react";
import axios from "axios";
import Constants from "../App/Variables/Constant";
import { Link, Navigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
// import { makeStyles } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import init from "../helpers/WindowToken";
import {
  Button,
  Container,
  Grid,
  Snackbar,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
// const classes = makeStyles();
let fd = new FormData();
export default class AddBodyType extends Component {
  state = {
    loading: false,
    bodyType: "",
    notification: false,
    message: "",
    error: false,
    redirect: false,
    categoryType: [],
    categoryName: null,
    categoryData: [],
    categoryId: "",
  };

  componentDidMount() {
    axios
      .get(Constants.getUrls.getALLCategory)
      .then((resp) => {
        let temp = [];
        resp.data.category.docs.forEach((res) => {
          if (res.status === "active")
            temp.push({ label: res.title, id: res._id });
        });
        this.setState({
          categoryData: temp,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleChange = (e) => {
    if (e.target.name === "bodyType") {
      this.setState({
        error: false,
      });
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  getCategoryId = (e, val_id, val) => {
    this.setState({
      categoryId: val_id,
      categoryName: val,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    fd = new FormData();
    // // if (this.state.image === "") {
    // //   window.location.reload();
    // // } else {
    //   this.setState({ loading: true });
    //   fd.append("title", this.state.title);
    //   fd.append("description", this.state.description);
    //   fd.append("image", this.state.image);

    if (init() === "success") {
      this.setState({ loading: true });
      let fd = {
        title: this.state.bodyType,
        category: this.state.categoryId,
      };
      {
        // console.log(this.state.bodyType);
      }

      let auth = localStorage.getItem("accessToken");
      axios
        .post(Constants.postUrls.postBodyType, fd, {
          headers: { Authorization: `Bearer ${auth}` },
        })
        .then((resp) => {
          console.log(resp);
          this.setState({
            loading: false,
            message: resp.data.message,
            notification: true,
          });
          // console.log(resp.data.message);
          if (resp.data.message === undefined || resp.data.message === null) {
            console.log(resp);
            if (resp.data.errors.msg) {
              this.setState({
                message: resp.data.errors.msg,
              });
            } else {
              this.setState({
                message: "unknown error",
              });
            }
          }
          // console.log(resp.data.message);
          if (
            resp.data.message ===
            `title already exist with the title${this.state.bodyType}`
          ) {
            this.setState({
              error: true,
            });
          }
          setTimeout(() => {
            this.setState({
              // bodyType: "",
              notification: false,
              message: "",
            });
            if (resp.data.message === "Body type added successfully") {
              this.setState({
                redirect: true,
              });
            }
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
          // window.location.reload();
          this.setState({
            notification: true,
            message: "some error occured check your console or try again",
          });
          setTimeout(() => {
            this.setState({
              // kms:"",
              notification: false,
              message: "",
              loading: false,
            });
          }, 2000);
        });
      // }
    }
  };
  render() {
    return (
      <Container maxWidth="xl">
        {this.state.redirect && <Navigate to="/bodyType" replace={true} />}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6">Body Type </Typography>
          </Grid>
        </Grid>
        {this.state.notification ? (
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={true}
            autoHideDuration={5000}
            color="green"
            message={this.state.message}
          ></Snackbar>
        ) : null}
        <div
          style={{
            background: "#5b5757",
            padding: "20px",
            borderRadius: "10px",
            // color: rgba(0, 0, 0, 0.87);
            width: "100%",
            border: "0",
            display: "flex",
            position: "relative",
            fontSize: ".875rem",
            minWidth: "0",
            wordWrap: " break-word",
            /* background: #FFF; */
            boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
            marginTop: "30px",
            borderRadius: "6px",
            marginBottom: "30px",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <div
            style={{
              background:
                "linear-gradient(60deg, rgb(128 115 115), rgb(255 250 250)) rgb(153, 153, 153))",
              boxShadow:
                " rgb(0 0 0 / 14%) 0px 4px 20px 0px, rgb(128 120 120 1/ 40%) 0px 7px 10px -5px",
              float: "left",
              padding: "15px",
              marginTop: "-43px",
              marginRight: "15px",
              borderRadius: "3px",
              backgroundColor: "#999",
              width: "6%",
              height: "60px",
              marginBottom: "25px",
              textAlign: "center",
            }}
          >
            <EditIcon />
          </div>
          <form onSubmit={this.handleSubmit}>
            <Grid container sm={12} spacing={2}>
              <Grid sm={3} style={{ marginTop: "15px", marginLeft: "15px" }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  fullWidth={true}
                  options={this.state.categoryData}
                  onChange={(e, value) => {
                    if (!value) {
                      this.getCategoryId(e, "", null);
                    } else this.getCategoryId(e, value.id, value.label);
                  }}
                  value={this.state.categoryName}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value
                  }
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Category" />
                  )}
                />
              </Grid>

              <Grid item sm={6}>
                <TextField
                  name="bodyType"
                  error={this.state.error}
                  id={this.state.error ? "outlined-error" : "outlined-basic"}
                  label={this.state.error ? "Error" : "Body Type"}
                  size="medium"
                  variant="outlined"
                  required={true}
                  type="text"
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                  value={this.state.bodyType}
                />
              </Grid>
            </Grid>

            <br />
            <Button
              variant="contained"
              type="submit"
              size="medium"
              color="primary"
              style={{
                marginRight: "20px",

                marginTop: "35px",
              }}
            >
              {this.state.loading ? "Adding.." : "Add "}
            </Button>
            <Link
              to={{
                pathname: "/bodyType",
              }}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                type="submit"
                color="secondary"
                style={{ marginRight: "20px", marginTop: "35px" }}
              >
                Cancel
              </Button>
            </Link>
          </form>
        </div>
      </Container>
    );
  }
}
