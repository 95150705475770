import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import DashboardIcon from "@mui/icons-material/Dashboard";
import axios from "axios";
import { Navigate, Route, Routes } from "react-router-dom";
import Authloading from "../Auth/Authloading";
import Dashboard from "./Dashboard";
import MainAppbar from "./layout/MainAppbar";
import Sidebar from "./layout/Sidebar";
import routes from "../../routes";
import AddBanner from "../../AddViews/AddBanner";
import UpdateBanner from "../../UpdateViews/UpdateBanner";
import AddAdvertisment from "../../AddViews/addAdvertisment";
import UpdateAdvertisement from "../../UpdateViews/UpdateAdvertisment";
import AddBrand from "../../AddViews/AddBrand";
import UpdateBrand from "../../UpdateViews/UpdateBrand";
import AddCms from "../../AddViews/AddCms";
import UpdateCms from "../../UpdateViews/UpdateCms";
import AddCategory from "../../AddViews/AddCategory";
import UpdateCategory from "../../UpdateViews/UpdateCategory";
import AddTestimonial from "../../AddViews/AddTestimonial";
import UpdateTestimonial from "../../UpdateViews/UpdateTestimonial";
import AddFaq from "../../AddViews/AddFaq";
import UpdateFaq from "../../UpdateViews/UpdateFaq";
import AddKilometer from "../../AddViews/AddKilometer";
import UpdateKilometer from "../../UpdateViews/UpdateKilometer";
import AddYear from "../../AddViews/AddYear";
import UpdateYear from "../../UpdateViews/UpdateYear";
import AddFuelType from "../../AddViews/AddFuelType";
import UpdateFuelType from "../../UpdateViews/UpdateFuelType";
import AddModel from "../../AddViews/AddModel";
import UpdateModel from "../../UpdateViews/UpdateModel";
import AddSubscription from "../../AddViews/AddSubscription";
import UpdateSubscription from "../../UpdateViews/UpdateSubscription";
import UpdateVehicless from "../../UpdateViews/UpdateVehicles";
import AddFeaturedList from "../../AddViews/AddFeaturedList";
import UpdateFeatured from "../../UpdateViews/UpdateFeatured";
import AddNotification from "../../AddViews/AddNotification";
import UpdateNotification from "../../UpdateViews/UpdateNotification";
import AddAgent from "../../AddViews/AddAgent";
import UpdateAgent from "../../UpdateViews/UpdateAgent";
import AddBodyType from "../../AddViews/AddBodyType";
import UpdateBodyType from "../../UpdateViews/UpdateBodyType";
import UpdateState from "../../UpdateViews/UpdateState";
import AddState from "../../AddViews/AddState";
import AddCity from "../../AddViews/AddCity";
import UpdateCity from "../../UpdateViews/UpdateCity";
import AddCLient from "../../AddViews/AddCLient";
import UpdateClient from "../../UpdateViews/UpdateClient";

export default class Main extends Component {
  state = {
    loading: true,
    authenticated: false,

    profileData: [],
  };
  checkAuth = (status) => {
    if (status) {
      // console.log(status)
      this.setState({ loading: false, authenticated: true });
      this.getProfile();
    } else {
      // this.props.history.push("/login");
      this.setState({ loading: false, authenticated: false });
    }
  };
  getProfile = () => {
    // axios.get(url).then((res) => {
    //   // console.log(res.data);
    //   if(res.data.status==="success")
    //   {
    //     this.setState({
    //       profileData: res.data.user,
    //     });
    //   }
    //   // console.log(this.state.profileData.role);
    // });
  };
  render() {
    return (
      <div>
        {this.state.loading ? <Authloading checkAuth={this.checkAuth} /> : null}
        {this.state.authenticated ? (
          <>
            <MainAppbar />
            <Grid container spacing={2} className="main">
              <Grid
                item
                style={{
                  position: "fixed",
                  width: "16.666667%",
                  marginTop: "70px",
                }}
                className="sidebar"
              >
                <Sidebar />
              </Grid>

              <Grid item xs={2} style={{ margin: "12px 0" }}></Grid>

              <Grid item xs={10} style={{ margin: "90px 0" }}>
                <Routes>
                  {routes.Dashboard.map((route, index) => (
                    <Route
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      element={route.component}
                      // component={route.component} -- NOT WORKING
                    />
                  ))}
                  {routes.Banners.map((route, index) => (
                    <Route
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      element={route.component}
                      // component={route.component} -- NOT WORKING
                    />
                  ))}
                  {routes.Vehicles.map((route, index) => (
                    <Route
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      element={route.component}
                      // component={route.component} -- NOT WORKING
                    />
                  ))}
                  {routes.Motors.map((route, index) => (
                    <Route
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      element={route.component}
                      // component={route.component} -- NOT WORKING
                    />
                  ))}
                  {routes.ContactUs.map((route, index) => (
                    <Route
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      element={route.component}
                      // component={route.component} -- NOT WORKING
                    />
                  ))}
                  {routes.Masters.map((route, index) => (
                    <Route
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      element={route.component}
                      // component={route.component} -- NOT WORKING
                    />
                  ))}
                  {routes.Enquiry.map((route, index) => (
                    <Route
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      element={route.component}
                      // component={route.component} -- NOT WORKING
                    />
                  ))}
                  {routes.Users.map((route, index) => (
                    <Route
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      element={route.component}
                      // component={route.component} -- NOT WORKING
                    />
                  ))}
                  {routes.Report.map((route, index) => (
                    <Route
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      element={route.component}
                      // component={route.component} -- NOT WORKING
                    />
                  ))}
                  {routes.Client.map((route, index) => (
                    <Route
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      element={route.component}
                      // component={route.component} -- NOT WORKING
                    />
                  ))}
                  <Route path="/admin/addbanner" element={<AddBanner />} />
                  <Route
                    path="/admin/updatebanner/"
                    element={<UpdateBanner />}
                  />
                  <Route
                    path="/admin/updatebanner/*"
                    element={<UpdateBanner />}
                  />

                  <Route
                    path="/admin/addadvertisment/"
                    element={<AddAdvertisment />}
                  />

                  <Route
                    path="/admin/updateAdvertisement/"
                    element={<UpdateAdvertisement />}
                  />
                  <Route
                    path="/admin/updateAdvertisement/*"
                    element={<UpdateAdvertisement />}
                  />

                  <Route path="/admin/addBrand" element={<AddBrand />} />
                  <Route path="/admin/updateBrand/" element={<UpdateBrand />} />
                  <Route
                    path="/admin/updateBrand/*"
                    element={<UpdateBrand />}
                  />

                  <Route path="/admin/addCms" element={<AddCms />} />
                  <Route path="/admin/updateCms/" element={<UpdateCms />} />
                  <Route path="/admin/updateCms/*" element={<UpdateCms />} />

                  <Route path="/admin/addCategory" element={<AddCategory />} />
                  <Route
                    path="/admin/updateCategory/"
                    element={<UpdateCategory />}
                  />
                  <Route
                    path="/admin/updateCategory/*"
                    element={<UpdateCategory />}
                  />

                  <Route
                    path="/admin/addTestimonial"
                    element={<AddTestimonial />}
                  />
                  <Route
                    path="/admin/updateTestimonial/"
                    element={<UpdateTestimonial />}
                  />
                  <Route
                    path="/admin/updateTestimonial/*"
                    element={<UpdateTestimonial />}
                  />

                  <Route path="/admin/addFaq" element={<AddFaq />} />
                  <Route path="/admin/updateFaq/" element={<UpdateFaq />} />
                  <Route path="/admin/updateFaq/*" element={<UpdateFaq />} />

                  <Route path="/admin/addKms" element={<AddKilometer />} />
                  <Route
                    path="/admin/updateKms/"
                    element={<UpdateKilometer />}
                  />
                  <Route
                    path="/admin/updateKms/*"
                    element={<UpdateKilometer />}
                  />

                  <Route path="/admin/addYear" element={<AddYear />} />
                  <Route path="/admin/updateYear/" element={<UpdateYear />} />
                  <Route path="/admin/updateYear/*" element={<UpdateYear />} />

                  <Route path="/admin/addFuelType" element={<AddFuelType />} />
                  <Route
                    path="/admin/updateFuelType/"
                    element={<UpdateFuelType />}
                  />
                  <Route
                    path="/admin/updateFuelType/*"
                    element={<UpdateFuelType />}
                  />

                  <Route path="/admin/addModel" element={<AddModel />} />
                  <Route path="/admin/updateModel/" element={<UpdateModel />} />
                  <Route
                    path="/admin/updateModel/*"
                    element={<UpdateModel />}
                  />

                  <Route
                    path="/admin/addSubscription"
                    element={<AddSubscription />}
                  />
                  <Route
                    path="/admin/updateSubscription/"
                    element={<UpdateSubscription />}
                  />
                  <Route
                    path="/admin/updateSubscription/*"
                    element={<UpdateSubscription />}
                  />

                  <Route
                    path="/admin/updateVehicles/"
                    element={<UpdateVehicless />}
                  />
                  <Route
                    path="/admin/updateVehicles/*"
                    element={<UpdateVehicless />}
                  />

                  <Route
                    path="/admin/featuredList"
                    element={<AddFeaturedList />}
                  />
                  <Route
                    path="/admin/updateFeaturedList/"
                    element={<UpdateFeatured />}
                  />
                  <Route
                    path="/admin/updateFeaturedList/*"
                    element={<UpdateFeatured />}
                  />

                  <Route path="/admin/addAgent" element={<AddAgent />} />
                  <Route path="/admin/updateAgent/" element={<UpdateAgent />} />
                  <Route
                    path="/admin/updateAgent/*"
                    element={<UpdateAgent />}
                  />

                  <Route
                    path="/admin/addNotification"
                    element={<AddNotification />}
                  />
                  <Route
                    path="/admin/updateNotification/"
                    element={<UpdateNotification />}
                  />
                  <Route
                    path="/admin/updateNotification/*"
                    element={<UpdateNotification />}
                  />

                  <Route path="/admin/addBodyType" element={<AddBodyType />} />
                  <Route
                    path="/admin/updateBodyType/"
                    element={<UpdateBodyType />}
                  />
                  <Route
                    path="/admin/updateBodyType/*"
                    element={<UpdateBodyType />}
                  />

                  <Route path="/admin/addState" element={<AddState />} />
                  <Route path="/admin/updateState/" element={<UpdateState />} />
                  <Route
                    path="/admin/updateState/*"
                    element={<UpdateState />}
                  />

                  <Route path="/admin/addCity" element={<AddCity />} />
                  <Route path="/admin/updateCity/" element={<UpdateCity />} />
                  <Route path="/admin/updateCity/*" element={<UpdateCity />} />

                  <Route path="/admin/addClient" element={<AddCLient />} />
                  <Route
                    path="/admin/updateClient/"
                    element={<UpdateClient />}
                  />
                  <Route
                    path="/admin/updateClient/*"
                    element={<UpdateClient />}
                  />

                  {/* <Route path="/admin/vehicle/*" element={<SingleVehicle />} />
                  <Route path="/admin/vehicle/" element={<SingleVehicle />} /> */}
                </Routes>
              </Grid>
            </Grid>
          </>
        ) : !this.state.loading ? (
          ""
        ) : null}{" "}
      </div>
    );
  }
}
