import React, { Component } from "react";
import axios from "axios";
import Constants from "../App/Variables/Constant";
import { Link } from "react-router-dom";
import init from "../helpers/WindowToken";
import {
  Backdrop,
  Button,
  Container,
  Select,
  InputLabel,
  Fade,
  FormControl,
  Grid,
  // Modal,
  Paper,
  Snackbar,
  Switch,
  Form,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Box,
  FormControlLabel,
  MenuItem,
  makeStyles,
  CircularProgress,
} from "@mui/material";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  // Table,
  Row,
  Col,
  UncontrolledAlert,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
} from "reactstrap";
import Modal from "react-awesome-modal";

import { Navigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import Autocomplete from "@mui/material/Autocomplete";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Resizer from "react-image-file-resizer";
import DoneIcon from "@mui/icons-material/Done";
// import SunEditor from "suneditor-react";
// import "suneditor/dist/css/suneditor.min.css";

let fd = new FormData();

export default class UpdateBanner extends Component {
  state = {
    title: "",
    sequence: "",
    image: "",
    // blogPath: "",
    loading: false,
    notification: false,
    bannerId: "",
    message: "",
    description: "",
    redirect: false,
    imgChange: false,
    textChange: false,
    bannerType: "",
    url: "",
    error: false,
    waiting: false,
    showLoading: false,
    showDone: false,
  };
  componentDidMount() {
    this.getBanner();
  }
  getBanner = () => {
    let id = window.location.pathname.split("/")[3];
    this.setState({
      bannerId: id,
    });

    if (init() === "success") {
      let auth = localStorage.getItem("accessToken");
      axios
        .get(Constants.getUrls.getBannerById + id, {
          headers: { Authorization: `Bearer ${auth}` },
        })
        .then((resp) => {
          // console.log(Constants.getUrls.getBannerById + id);
          // console.log(resp)
          // console.log(resp.data.banner);
          this.setState({
            title: resp.data.banner.title,
            sequence: resp.data.banner.sequence,
            image: resp.data.banner.poster,
            description: resp.data.banner.description,
            bannerType: resp.data.banner.banner_type,
            url: resp.data.banner.url,
          });
          // console.log(resp.data.banner);
        });
    }
  };
  handleChange = (e) => {
    if (e.target.name === "title") {
      this.setState({
        error: false,
      });
    }
    this.setState({ textChange: true, [e.target.name]: e.target.value });
  };

  // handleChangeDesc(content) {
  //   // console.log(content); //Get Content Inside Editor
  //   this.setState(
  //     {
  //       description: content,
  //       textChange: true,
  //     },
  //     () => {
  //       console.log(this.state.description);
  //     }
  //   );
  // }
  handleFile = (e) => {
    // console.log(e.target.files);
    // if(e.target.files[0]){
    //   this.setState({ imgChange:true,
    //     image: e.target.files[0],})
    // }

    var file = e.target.files[0];
    if (file) {
      this.setState({
        waiting: true,
        showLoading: true,
        showDone: false,
      });

      this.setState({ imgChange: false });
      if (file["type"].split("/")[0] !== "image") {
        this.setState({ imgChange: false });
        this.setState({
          notification: true,
          message: "Warning Uploaded file should be image",
        });
        setTimeout(() => {
          this.setState({ notification: false, message: "" });
        }, 2500);
      } else {
        if (file.size > 100000) {
          try {
            this.setState({ waiting: true });
            Resizer.imageFileResizer(
              file,
              1500,
              700,
              "JPEG",
              100,
              0,
              (uri) => {
                //  console.log(uri);

                this.setState(
                  {
                    image: uri,
                    waiting: false,

                    showDone: true,
                  },
                  () => {
                    if (this.state.loading) {
                      this.handleSubmit();
                    }
                  }
                );
                if (uri) {
                  this.setState({ imgChange: true });
                }
              },
              "file",
              1000,
              600
            );
          } catch (err) {
            console.log(err);
            this.setState({
              notification: true,
              message:
                "Failed to Compress Image,press Add to submit Uncompressed Image ",
              [e.target.name]: e.target.files[0],
              waiting: false,
              loading: false,
              imgChange: true,
              showDone: true,
            });
            setTimeout(() => {
              this.setState({ notification: false, message: "" });
            }, 2000);
          }
        } else {
          this.setState({
            [e.target.name]: e.target.files[0],
            imgChange: true,
            showDone: true,
            waiting: false,
          });
        }
      }
    }

    // fd.append("poster", e.target.files[0]);
    // console.log(e.target.files[0]);
  };
  handleSubmit = (e) => {
    if (e) e.preventDefault();
    //   if(this.state.bannerType.length===0){
    //     this.setState({notification:true,
    //     message:"Type cannot be empty"}
    //     )
    //     setTimeout(()=>{
    //       this.setState({notification:false,
    //       message:""})
    //     },2000)
    // } else {

    fd = new FormData();

    this.setState({ loading: true });

    if (init() === "success") {
      if (this.state.waiting) {
        this.setState({
          notification: true,
          message: "Wait image is still Compressing",
        });
        setTimeout(() => {
          this.setState({ notification: false, message: "" });
        }, 1000);
      } else {
        let auth = localStorage.getItem("accessToken");
        fd.append("title", this.state.title);
        fd.append("description", this.state.description);
        fd.append("sequence", this.state.sequence);
        fd.append("banner_type", this.state.bannerType);
        fd.append("poster", this.state.image);
        fd.append("url", this.state.url);
        if (this.state.textChange === false && this.state.imgChange === false) {
          this.setState({
            notification: true,
            message: "nothing to change here",
          });
          setTimeout(() => {
            this.setState({
              notification: false,
              loading: false,
              redirect: true,
            });
          }, 2000);
        } else {
          if (
            this.state.bannerType === undefined ||
            this.state.bannerType === ""
          ) {
            this.setState({
              notification: true,
              message: "Type cannot be empty",
            });
            setTimeout(() => {
              this.setState({ notification: false, message: "" });
            }, 2000);
          } else {
            axios
              .put(Constants.putUrls.updateBanner + this.state.bannerId, fd, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${auth}`,
                },
              })
              .then((resp) => {
                // axios.post("http://localhost:8081/createStocks", stockLoad).then((resp) => {
                console.log(resp);
                this.setState({
                  loading: false,
                  notification: true,
                  message: resp.data.message,
                });
                if (
                  resp.data.message === undefined ||
                  resp.data.message === null
                ) {
                  // console.log(resp);
                  if (resp.data.errors.msg) {
                    this.setState({
                      message: resp.data.errors.msg,
                    });
                  } else {
                    this.setState({
                      message: "unknown error",
                    });
                  }
                }
                if (
                  resp.data.message ===
                  `title already exist with the name ${this.state.title}`
                ) {
                  this.setState({
                    error: true,
                  });
                }
                console.log(resp.data.message);
                setTimeout(() => {
                  this.setState({
                    // title: "",
                    // image: "",
                    //     sequence:"",
                    // description: "",
                    notification: false,
                    message: "",
                  });
                  if (resp.data.message === "Updated Successfully") {
                    this.setState({
                      redirect: true,
                    });
                  }
                  // fd.delete("title", this.state.title);
                  // fd.delete("description", this.state.description);
                  // fd.delete("image", this.state.image);
                }, 2000);
              })
              .catch((err) => {
                console.log(err);
                // window.location.reload();
                this.setState({
                  notification: true,
                  message: "error occured probaably due to oversize file",
                });
                setTimeout(() => {
                  this.setState({
                    // title:"",
                    //   image:"",
                    //   sequence:"",
                    notification: false,
                    message: "",
                    loading: false,
                    //  imgChange:false
                  });
                }, 2000);
                // setTimeout(() => {
                //   this.getBanner();
                // }, 3000);
              });
          }
        }
      }
    } else {
      this.setState({
        notification: true,
        message: "login error!!",
      });
      setTimeout(() => {
        this.setState({
          notification: false,
        });
      });
    }
    // }
  };
  render() {
    return (
      <Container maxWidth="xl">
        {this.state.redirect && <Navigate to="/banner" replace={true} />}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6"> Banner </Typography>
          </Grid>
        </Grid>
        <div
          style={{
            background: "#5b5757",
            padding: "20px",
            borderRadius: "10px",
            // color: rgba(0, 0, 0, 0.87);
            width: "100%",
            border: "0",
            display: "flex",
            position: "relative",
            fontSize: ".875rem",
            minWidth: "0",
            wordWrap: " break-word",
            /* background: #FFF; */
            boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
            marginTop: "30px",
            borderRadius: "6px",
            marginBottom: "30px",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <div
            style={{
              background:
                "linear-gradient(60deg, rgb(128 115 115), rgb(255 250 250)) rgb(153, 153, 153))",
              boxShadow:
                " rgb(0 0 0 / 14%) 0px 4px 20px 0px, rgb(128 120 120 1/ 40%) 0px 7px 10px -5px",
              float: "left",
              padding: "15px",
              marginTop: "-43px",
              marginRight: "15px",
              borderRadius: "3px",
              backgroundColor: "#999",
              width: "6%",
              height: "60px",
              marginBottom: "25px",
              textAlign: "center",
            }}
          >
            <EditIcon />
          </div>
          <form onSubmit={this.handleSubmit}>
            <Grid container sm={12} spacing={2}>
              <Grid item sm={4}>
                <TextField
                  name="title"
                  error={this.state.error}
                  id={this.state.error ? "outlined-error" : "outlined-basic"}
                  label={this.state.error ? "Error" : "Title"}
                  size="medium"
                  variant="outlined"
                  value={this.state.title}
                  required={true}
                  maxRows={4}
                  multiline={true}
                  type="text"
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                />
              </Grid>

              {/* {
                        this.state.all_exchange_type.map(val =>(
                            console.log(val.instrument)
                        ))
                    } */}
              <Grid item sm={4}>
                <TextField
                  name="sequence"
                  id="outlined-basic"
                  label="Sequence"
                  size="medium"
                  variant="outlined"
                  value={this.state.sequence}
                  required={true}
                  InputProps={{
                    inputProps: {
                      min: 1,
                    },
                  }}
                  type="number"
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                />
              </Grid>

              <Grid item sm={4}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="Id">Type</InputLabel>

                  <Select
                    name="bannerType"
                    labelId="Id"
                    id="Id"
                    value={this.state.bannerType}
                    label="User Type"
                    onChange={this.handleChange}
                  >
                    <MenuItem value="buyer">Buyer</MenuItem>
                    <MenuItem value="seller">Seller</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <TextField
                  name="url"
                  id="outlined-basic"
                  label="url"
                  size="medium"
                  variant="outlined"
                  value={this.state.url}
                  // required={true}
                  type="text"
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                />
              </Grid>

              <Grid item sm={4}>
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    height: "60px",
                    alignItems: "baseline",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "80%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "baseline",
                    }}
                  >
                    <label>Poster:</label>
                    <input
                      name="image"
                      type="file"
                      onChange={this.handleFile}
                      accept="image/*"
                      ref={this.emptyFile}
                      disabled={
                        this.state.showLoading &&
                        (this.state.showDone ? false : true)
                      }
                      //  required
                      style={{ width: "100%" }}
                    />
                  </div>
                  {this.state.showLoading ? (
                    this.state.showDone ? (
                      <DoneIcon
                        color="success"
                        sx={{ position: "absolute", right: "0" }}
                      />
                    ) : (
                      <CircularProgress
                        color="success"
                        size={25}
                        sx={{ position: "absolute", right: "0" }}
                      />
                    )
                  ) : null}
                </div>
              </Grid>

              <Grid item sm={4}>
                <img
                  style={{
                    height: "150px",
                    width: "200px",
                    margin: "10px 20px",
                  }}
                  src={
                    this.state.imgChange &&
                    this.state.image["type"].split("/")[0] === "image"
                      ? URL.createObjectURL(this.state.image)
                      : "https://core.gaddideals.com" + this.state.image
                  }
                  alt="Updating...."
                  class="rounded"
                />
              </Grid>
            </Grid>
            <div style={{ color: "black", marginTop: "20px" }}>
              <label style={{ color: "white" }}>Description:</label>

              <CKEditor
                className="ckeditor"
                value={this.state.description}
                editor={ClassicEditor}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  this.setState({
                    description: data,
                    textChange: true,
                  });
                }}
                data={this.state.description}
              />
            </div>
            <br />

            <Button
              variant="contained"
              type="submit"
              size="medium"
              color="primary"
              style={{
                marginRight: "20px",

                marginTop: "35px",
              }}
            >
              {this.state.loading ? "Updating.." : "Update "}
            </Button>
            <Link
              to={{
                pathname: "/banner",
              }}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                type="submit"
                Size={15}
                color="secondary"
                style={{ marginRight: "20px", marginTop: "35px" }}
              >
                Cancel
              </Button>
            </Link>
          </form>
        </div>
        {this.state.notification ? (
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={true}
            autoHideDuration={5000}
            color="green"
            message={this.state.message}
            severity="success"
          ></Snackbar>
        ) : null}
      </Container>
    );
  }
}
