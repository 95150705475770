
import React, { Component } from 'react';
import {
  Button,
  Container,
  FormControl,
  Grid,
  Select,
  InputLabel,
  Paper,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
  ButtonGroup,
  OutlinedInput,
  Pagination
} from "@mui/material";
import axios from "axios";
import { Link } from "react-router-dom";
import init from "../../helpers/WindowToken";
import Constants from "../Variables/Constant.jsx";
import Modal from "react-awesome-modal";
import SearchIcon from "@mui/icons-material/Search";
import ReadMore from "./helpers/ReadMore"
const parse = require('html-react-parser');
export default class Cms extends Component {
    state = {
        open: false,
        getCms: [],
        data: [],
        notifyStatus: false,
        notify: false,
        searchedValue: "",
        visible: false,
        delLoading: false,
        rowId: 0,
        status: "",
        active: 1,
        perPage: "10",
        limit:10,
        searchpage:1,
        search:false,
        currentPage: 1,
        page: 1,
        prevPage: 0,
        nextPage: 0,
        lastPage: 0,
        lastPageView: false,
        firstPageView: false,
        total: 0,
        from: 1,
        to: 0,
        fromInc: 1,
        toInc: 5,
        btnIdArray: [],
        filteredBtnIdArray: [],
        msg: "",
        totalPage:1,
        searchTotalPage:1,
        pageinfo:1,
      };
      handleClose = () => {
        this.setState({ open: false });
      };
    
      componentDidMount() {
        this.getCms();
      }
      getCms = () => {
        axios
          .get(
            Constants.getUrls.getAllCms +
              "?page=" +
              this.state.page +
              "&sort=true&limit=" +
              this.state.limit
          )
          .then((resp) => {
            // console.log(resp.data.cms.docs);
    
            this.setState({
              fromInc: 1,
              active: 1,
              toInc: 5,
              getCms: resp.data.cms.docs,
              total: resp.data.cms.totalDocs,
              nextPage: resp.data.cms.hasNextPage,
              prevPage: resp.data.cms.hasPrevPage,
              limit: resp.data.cms.limit,
              pagingCounter: resp.data.cms.pagingCounter,
              totalPage:resp.data.cms.totalPages,
              pageinfo:this.state.page,
              // PAGINATION
    
              from: 1,
              // to: resp.data.data.length,
            });
          });
      };
      handleRows = (value) => {
        this.setState(
          {
            limit: value,
          },
          () => {
            if(this.state.search){
              this.handleSearch();
            }else
            {this.getCms();}
          }
        );
        // this.getBanner();
      };
      // PAGINATION BUTTONS
    
      handleNext = () => {
        if(this.state.search){
          if (this.state.nextPage === true) {
            // console.log(this.state.nextPage)
      
            this.setState(
              {
                searchpage: Number(this.state.searchpage) + 1,
                // to: this.state.to + this.state.limit,
              },
              () => {
                this.handleSearch();
              }
            );
            // console.log(this.state.page)
            // this.getAdd();
          }
    
        }else{
        if (this.state.nextPage === true) {
          // console.log(this.state.nextPage)
    
          this.setState(
            {
              page: Number(this.state.page) + 1,
              // to: this.state.to + this.state.limit,
            },
            () => {
              this.getCms();
            }
          );
          // console.log(this.state.page)
          // this.getBrand();
        }}
      };
    
      handlePrev = () => {
        if(this.state.search){
          if (this.state.prevPage === true)
          this.setState(
            {
              searchpage: Number(this.state.searchpage) - 1,
            },
    
            () => {
              this.handleSearch();
            }
          );
        }
    else{    if (this.state.prevPage === true)
          this.setState(
            {
              page: Number(this.state.page) - 1,
            },
    
            () => {
              this.getCms();
            }
          );
        // this.getCms();
      }
      };
      handleChangePage=(e)=>{
        this.setState({
          pageinfo:e.target.value
        })
      }
      handlePages=(e,value)=>{
        if(this.state.search){
          this.setState({
            searchpage:value
          },()=>{
            this.handleSearch()
          })
        } else{
          this.setState({
            page:value
          },()=>{
            this.getCms()
          })
        }
      }
      handlePage=(e)=>{
        e.preventDefault();
        if(this.state.pageinfo){
        if (this.state.search) {
          if (this.state.pageinfo>0 && this.state.pageinfo<=this.state.searchTotalPage) {
            // console.log(this.state.nextPage)
    
            this.setState(
              {
                searchpage: this.state.pageinfo,
                // to: this.state.to + this.state.limit,
              },
              () => {
                this.handleSearch();
              }
            );
            // console.log(this.state.page)
            // this.getAdd();
          }
        } else {
          if (this.state.pageinfo>0 && this.state.pageinfo<=this.state.totalPage) {
            // console.log(this.state.nextPage)
    
            this.setState(
              {
                page: this.state.pageinfo,
                // to: this.state.to + this.state.limit,
              },
              () => {
                this.getCms();
              }
            );
            // console.log(this.state.page)
            // this.getCms();
          }
        }}
    
      }
      handleModal = (cms) => {
        // console.log(cms);
        this.setState(
          {
            rowId: cms._id,
            visible: true,
          },
          () => {
            console.log(this.state.cms);
          }
        );
      };
      handleDelete = () => {
        this.setState({
          delLoading: true,
        });
        // console.log(Constants.deleteUrls.deleteCms + "/" + this.state.rowId);
    
        if (init() === "success") {
          // this.setState({ delLoading: true });
          let auth = localStorage.getItem("accessToken");
          axios
            .delete(Constants.deleteUrls.deleteCms + "/" + this.state.rowId, {
              headers: { Authorization: `Bearer ${auth}` },
            })
            .then((res) => {
            //   console.log(res);
    
              this.setState({
                notify: true,
                delLoading: false,
                msg: res.data.message,
              });
              setTimeout(() => {
                this.setState({ notify: false, visible: false }, () => {
                  if(this.state.search){this.handleSearch();}else {this.getCms();}
                });
              }, 2000);
            })
            .catch((err) => console.log(err));
        }
      };
      handleStatus = (e, status, id) => {
        let payload;
        let auth=localStorage.getItem("accessToken");
    
        if(init()==="success"){
          if(status==="active"){
          payload={
            status:"draft"}
          }
          else{
            payload={
              status:"active"
            }
          }
          payload=JSON.stringify(payload);
          axios
                .post(Constants.statusUrl + id + "?type=cms", payload, {headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${auth}`
                }}
                )
                .then((res)=>{
                  // console.log(res);
                  if(res.data.message){
                    this.setState({
                                  notify: true,
                                  msg:res.data.message
                                });}
                                if(res.data.status==="failed"){
                                  this.setState({
                                    notify:true,
                                    msg:"failed to update status"
                                  })
                                }
                              setTimeout(() => {
                                this.setState({ notify: false }, () => {
                                  if(this.state.search){this.handleSearch();}else {this.getCms();}
                                });
                              }, 750);
                }).catch((err)=>{
                  console.log(err);
                })
    
        }
      };
      handleChange = (event) => {
        if (event.target.value.length<1) {
          this.setState({
            searchedValue: "",
            search:false,
           
            
    
    
          });
          // this.defaultValues();
          this.getCms();
        } else {
          this.setState({
            searchedValue: event.target.value,
          });
        }
      };
      handleSubmit=(e)=>{
        e.preventDefault();
        this.setState({
          searchpage:1
        },
        () => {
          this.handleSearch();
        }
        )
    
      }
      handleSearch = (event) => {
        if(event){
          this.setState({
            searchedValue:event.target.value
          },()=>{
            this.handleSearch();
          })
         }
         if (this.state.searchedValue < 1) {
          this.setState({
            search: false,
          });
          // this.defaultValues();
          this.getCms();
        } else {
        if (this.state.searchedValue.length >= 2) {
          this.setState({ 
          search:true,
        page:1});
          axios
            .get(
              Constants.getUrls.getAllCms +
                "?page=" +
                this.state.searchpage +
                "&limit=" +
                this.state.limit +
                "&sort=true&q=" +
               this.state.searchedValue
              // this.state.name
            )
            .then((resp) => {
              
              // let btnIdArray = [];
              // for (let i = 1; i <= resp.data.pageCount; i++) {
              //   btnIdArray.push(i);
              // }
             
            this.setState({
              fromInc: 1,
              active: 1,
              toInc: 5,
              getCms: resp.data.cms.docs,
              total: resp.data.cms.totalDocs,
              nextPage: resp.data.cms.hasNextPage,
              prevPage: resp.data.cms.hasPrevPage,
              limit: resp.data.cms.limit,
              pagingCounter: resp.data.cms.pagingCounter,
              searchTotalPage:resp.data.cms.totalPages,
              pageinfo:this.state.searchpage,
              // PAGINATION
    
              from: 1,
              // to: resp.data.data.length
            });
          
            });
        } }
        };
  render() {
    return (
        <Container maxwidth="xl">
        {this.state.notify ? (
          <Snackbar
            message={this.state.msg}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            autoHideDuration={6000}
            color="white"
            open="true"
          ></Snackbar>
        ) : null}
        <Grid container style={{ margin: "10px 0 10px" }} alignItems="baseline">
          <Grid item xs={6}>
            <Typography variant="h6">
              {/* {this.props.title} */}
              CMS
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent="flex-end">
          
                <TextField
                  id="outlined-basic"
                  label={"Search "}
                  size="small"
                  variant="outlined"
                  sx={{marginRight:"5px"}}
                  onChange={this.handleSearch}
                />
              
          
              <Link to="/admin/addCms" style={{ textDecoration: "none" }}>
                <Button
                  size="medium"
                  onClick={() => {
                    this.setState({ open: true });
                  }}
                  variant="contained"
                  color="primary"
                >
                  Add Cms
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Paper elevation={1}>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                  <TableCell >Sr.No.</TableCell>
                    <TableCell align="right">Title</TableCell>
                    <TableCell align="left">Description</TableCell>
                    <TableCell align="right">Image</TableCell>

                    <TableCell align="right">Status</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.getCms.length > 0 ? (
                    this.state.getCms.map((cms,index) => (
                      <TableRow key={cms._id}>
                      <TableCell >{this.state.search?Number((this.state.searchpage-1)*this.state.limit)+Number(index+1):Number((this.state.page-1)*this.state.limit)+Number(index+1)}</TableCell>
                        <TableCell align="right">{cms.title}</TableCell>
                        <TableCell align="left"> <ReadMore>{cms.description}</ReadMore></TableCell>

                        <TableCell align="right" style={{ width: "100px" }}>
                          <img
                            src={
                              "https://core.gaddideals.com" +
                              cms.image
                            }
                            alt="No Image Found"
                            style={{
                              height: "100px",
                              width: "100px",
                            }}
                          />
                        </TableCell>

                        <TableCell align="right">
                          <Switch
                            name="checkedB"
                            checked={cms.status === "active" ? true : false}
                            onChange={(e) =>
                              // console.log(e.target.checked);
                              this.handleStatus(
                                e.target.checked,
                                cms.status,
                                cms._id
                              )
                            }
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Link
                            to={"/admin/updateCms/" + cms._id}
                            style={{ textDecoration: "none" }}
                          >
                            <Button variant="outlined">Update</Button>
                          </Link>
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => this.handleModal(cms)}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Nothing to show here
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter></TableFooter>
              </Table>
              <TableRow
                style={{ display: "flex", justifyContent: "space-evenly" }}
              >
                {/* <div>
                <p>
                  Showing {this.state.pagingCounter} to {this.state.limit} of{" "}
                  {this.state.total} Entries
                </p>
              </div> */}

                {/* Rows PerPage START */}

                <div
                  style={{
                    display: "flex",
                    alignItems: "right",
                    justifyContent: "space-between",
                    width: "200px",
                    marginTop: "10px",
                    marginLeft:"150px",
                  }}
                >
                  <p>Rows Per Page</p>

                  <FormControl
                    className="selectMerchants"
                    style={{ marginRight: "20px", marginTop: "-5px" }}
                  >
                    <InputLabel htmlFor="age-native-simple"></InputLabel>
                    <Select
                      native
                      defaultValue={10}
                      // value={state.age}
                      onChange={(e) => this.handleRows(e.target.value)}
                      inputProps={{
                        name: "Merchants",
                        id: "age-native-simple",
                      }}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                    </Select>
                  </FormControl>
                </div>
                {/* Rows PerPage END */}

                <Pagination sx={{marginTop:"auto",marginBottom:"auto"}} count={this.state.search?this.state.searchTotalPage:this.state.totalPage} color="secondary" page={this.state.search?this.state.searchpage:this.state.page} onChange={this.handlePages} />
              </TableRow>
            </TableContainer>
          </Paper>
        </Grid>

        <Modal
          visible={this.state.visible}
          width="450"
          height="100"
          effect="fadeInUp"
          onClickAway={() => this.setState({ visible: false })}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <h4
              style={{ color: "black", textAlign: "center", display: "block" }}
            >
              Are you sure about that???
            </h4>

            <br></br>
            <ButtonGroup>
              <Button
                className="btn"
                color="secondary"
                variant="contained"
                style={{ margin: "20px" }}
                onClick={() => {
                  this.handleDelete();
                }}
              >
                {this.state.delLoading ? "Deleting..." : "Yes"}{" "}
              </Button>
              <Button
                className="btn"
                color="primary"
                variant="contained"
                style={{ margin: "20px", marginLeft: "4px" }}
                onClick={() => {
                  this.setState({ visible: false });
                }}
              >
                NO
              </Button>
            </ButtonGroup>
          </Grid>
        </Modal>
      </Container>
    )
  }
}
