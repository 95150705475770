import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    ListSubheader ,
    Collapse,
    ListItemButton,

    
  }from '@mui/material';
  import axios from "axios";
  import React, { Component } from 'react';
  import routes from "../../../routes";
  import {NavLink} from "react-router-dom";
  import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
  
  export default class Sidebar extends Component {
    state = {
      role: "",
      x: false,
      open:false,
      openBanner:false,
      openVehicles:false,
      filterdRoutes: [],
      openMotors:false,
      openMasters:false,
      openUsers:false
    };
    // componentDidMount() {
    //   // this.getProfile();
    //   // getProfile = () => {
    //     let filtValue = []
      //   axios.get(Constants.getUrls.getProfile).then((res) => {
      //     console.log(res);
      //      res.data.Data.accessAreas.forEach((val)=>{
      //       //  console.log(val)
      //        routes.forEach((route)=>{
      //          if(route.pathId === val._id){
      //            filtValue.push(route)
      //           //  console.log(route)
      //           //  console.log(filtValue)
      //          }
      //        })
      //      })
      //      this.setState({
      //        filterdRoutes: filtValue
      //      })
      //     // console.log(this.state.role);
      //   });
      // // };
      // // co


      // componentDidMount() {
      //   this.getProfile();
      // }
    
      // getProfile = () => {
      //   axios.get(Constants.getUrls.getProfile).then((res) => {
      //     console.log(res);
      //     if(res.data.status=="success")
      //     {
      //       this.setState({
      //         role: res.data.user.role,
      //       });
      //     }
      //     // console.log(this.state.role);
      //   });
      // };

    // }
    handleBanner=()=>{
      let b=!this.state.openBanner;
      this.setState({
        openBanner:b
      })
    }
    handleVehicles=()=>{
      let b=!this.state.openVehicles;
      this.setState({
        openVehicles:b
      })
    }
    handleMotors=()=>{
      let b=!this.state.openMotors;
      this.setState({
        openMotors:b
      })
    }
    handleMasters=()=>{
      let b=!this.state.openMasters;
      this.setState({
        openMasters:b
      })
    }
    handleUsers=()=>{
      let b=!this.state.openUsers;
      this.setState({
        openUsers:b
      })
    }
    
    render() {
      return (
        <List
        component="nav"
        style={{ height: "100vh", borderRight: "1px solid #ddd",paddingBottom:"75px", overflowY: "scroll" }}
      >
      
    
        
        {routes.Dashboard.map((route, index) => (
         
         
          //  route.role===this.state.role ? (

          <NavLink
            to={route.path}
            key={index}
            style={{ color: "inherit", textDecoration: "none" }}
            // activeClassName={"navactive"}
          >

<ListItemButton >
            <ListItemIcon>
             {route.icon}
            </ListItemIcon>
            <ListItemText primary={route.title} />
          </ListItemButton>
         </NavLink>
          //  ) : null
         
        ))}

        <ListItemButton onClick={this.handleBanner}>
        <ListItemIcon>
          <ViewCarouselIcon />
        </ListItemIcon>
        <ListItemText primary="Banners & Ad" />
        {this.state.openBanner ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={this.state.openBanner} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        {routes.Banners.map((route, index) => (
         
         
         //  route.role===this.state.role ? (

         <NavLink
           to={route.path}
           key={index}
           style={{ color: "inherit", textDecoration: "none" }}
          //  activeClassName={"navactive"}
         >

<ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
             {route.icon}
            </ListItemIcon>
            <ListItemText primary={route.title} />
          </ListItemButton>
         </NavLink>
         //  ) : null
        
       ))}
      
      
        </List>
      </Collapse>

      <ListItemButton onClick={this.handleVehicles}>
        <ListItemIcon>
          <AgricultureIcon />
        </ListItemIcon>
        <ListItemText primary="Vehicles" />
        {this.state.openVehicles ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={this.state.openVehicles} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        {routes.Vehicles.map((route, index) => (
         
         
         //  route.role===this.state.role ? (

         <NavLink
           to={route.path}
           key={index}
           style={{ color: "inherit", textDecoration: "none" }}
          //  activeClassName={"navactive"}
         >

<ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
             {route.icon}
            </ListItemIcon>
            <ListItemText primary={route.title} />
          </ListItemButton>
         </NavLink>
         //  ) : null
        
       ))}
        </List>
      </Collapse>

      
      <ListItemButton onClick={this.handleMotors}>
        <ListItemIcon>
          <LocalShippingIcon />
        </ListItemIcon>
        <ListItemText primary="Motors" />
        {this.state.openMotors ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={this.state.openMotors} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        {routes.Motors.map((route, index) => (
         
         
         //  route.role===this.state.role ? (

         <NavLink
           to={route.path}
           key={index}
           style={{ color: "inherit", textDecoration: "none" }}
          //  activeClassName={"navactive"}
         >

<ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
             {route.icon}
            </ListItemIcon>
            <ListItemText primary={route.title} />
          </ListItemButton>
         </NavLink>
         //  ) : null
        
       ))}
        </List>
      </Collapse>


        
      <ListItemButton onClick={this.handleMasters}>
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        <ListItemText primary="Masters" />
        {this.state.openMasters ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={this.state.openMasters} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        {routes.Masters.map((route, index) => (
         
         
         //  route.role===this.state.role ? (

         <NavLink
           to={route.path}
           key={index}
           style={{ color: "inherit", textDecoration: "none" }}
          //  activeClassName={"navactive"}
         >

<ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
             {route.icon}
            </ListItemIcon>
            <ListItemText primary={route.title} />
          </ListItemButton>
         </NavLink>
         //  ) : null
        
       ))}
        </List>
      </Collapse>
      {routes.Client.map((route, index) => (
         
         
         //  route.role===this.state.role ? (

         <NavLink
           to={route.path}
           key={index}
           style={{ color: "inherit", textDecoration: "none" }}
           // activeClassName={"navactive"}
         >

<ListItemButton >
           <ListItemIcon>
            {route.icon}
           </ListItemIcon>
           <ListItemText primary={route.title} />
         </ListItemButton>
        </NavLink>
         //  ) : null
        
       ))}


      {routes.Enquiry.map((route, index) => (
         
         
         //  route.role===this.state.role ? (

         <NavLink
           to={route.path}
           key={index}
           style={{ color: "inherit", textDecoration: "none" }}
           // activeClassName={"navactive"}
         >

<ListItemButton >
           <ListItemIcon>
            {route.icon}
           </ListItemIcon>
           <ListItemText primary={route.title} />
         </ListItemButton>
        </NavLink>
         //  ) : null
        
       ))}

      {routes.ContactUs.map((route, index) => (
         
         
         //  route.role===this.state.role ? (

         <NavLink
           to={route.path}
           key={index}
           style={{ color: "inherit", textDecoration: "none" }}
           // activeClassName={"navactive"}
         >

<ListItemButton >
           <ListItemIcon>
            {route.icon}
           </ListItemIcon>
           <ListItemText primary={route.title} />
         </ListItemButton>
        </NavLink>
         //  ) : null
        
       ))}

       <ListItemButton onClick={this.handleUsers}>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary="Users" />
        {this.state.openUsers ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={this.state.openUsers} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        {routes.Users.map((route, index) => (
         
         
         //  route.role===this.state.role ? (

         <NavLink
           to={route.path}
           key={index}
           style={{ color: "inherit", textDecoration: "none" }}
          //  activeClassName={"navactive"}
         >

<ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
             {route.icon}
            </ListItemIcon>
            <ListItemText primary={route.title} />
          </ListItemButton>
         </NavLink>
         //  ) : null
        
       ))}
        </List>
      </Collapse>

      {routes.Report.map((route, index) => (
         
         
         //  route.role===this.state.role ? (

         <NavLink
           to={route.path}
           key={index}
           style={{ color: "inherit", textDecoration: "none" }}
           // activeClassName={"navactive"}
         >

<ListItemButton >
           <ListItemIcon>
            {route.icon}
           </ListItemIcon>
           <ListItemText primary={route.title} />
         </ListItemButton>
        </NavLink>
         //  ) : null
        
       ))}
      </List>
      )
    }
  }
  