import React, { Component } from "react";
import {
  Button,
  Container,
  FormControl,
  Grid,
  Select,
  InputLabel,
  Paper,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
  ButtonGroup,
  MenuItem,
  OutlinedInput,
  Pagination,
} from "@mui/material";
import axios from "axios";
import { Link } from "react-router-dom";
import init from "../../helpers/WindowToken";
import Constants from "../Variables/Constant.jsx";
import Modal from "react-awesome-modal";
import SearchIcon from "@mui/icons-material/Search";
import ReadMore from "./helpers/ReadMore";
import fileDownload from "js-file-download";

export default class Enquiry extends Component {
  state = {
    open: false,
    getEnquiry: [],
    data: [],
    notifyStatus: false,
    notify: false,
    searchedValue: "",
    visible: false,
    delLoading: false,
    rowId: 0,
    status: "",
    search: false,
    searchpage: 1,
    limit: 10,
    active: 1,
    perPage: "10",
    currentPage: 1,
    page: 1,
    prevPage: 0,
    nextPage: 0,
    lastPage: 0,
    lastPageView: false,
    firstPageView: false,
    total: 0,
    from: 1,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: [],
    msg: "",
    type: "",
    totalPage: 1,
    searchTotalPage: 1,
    pageinfo: 1,
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  componentDidMount() {
    this.getEnquiry();
  }
  getEnquiry = () => {
    axios
      .get(
        Constants.getUrls.getAllEnquiry +
          "?page=" +
          this.state.page +
          "&limit=" +
          this.state.limit +
          "&sort=true&type=" +
          this.state.type
      )
      .then((resp) => {
        // console.log(resp.data.enquiry.docs);

        this.setState({
          fromInc: 1,
          active: 1,
          toInc: 5,
          getEnquiry: resp.data.enquiry.docs,
          total: resp.data.enquiry.totalDocs,
          nextPage: resp.data.enquiry.hasNextPage,
          prevPage: resp.data.enquiry.hasPrevPage,
          limit: resp.data.enquiry.limit,
          pagingCounter: resp.data.enquiry.pagingCounter,
          totalPage: resp.data.enquiry.totalPages,
          pageinfo: this.state.page,
          // PAGINATION

          from: 1,
          // to: resp.data.data.length,
        });
      });
  };
  handleRows = (value) => {
    this.setState(
      {
        limit: value,
      },
      () => {
        if (this.state.search) {
          this.handleSearch();
        } else {
          this.getEnquiry();
        }
      }
    );
    // this.getEnquiry ();
  };
  // PAGINATION BUTTONS

  handleNext = () => {
    if (this.state.search) {
      if (this.state.nextPage === true) {
        // console.log(this.state.nextPage)

        this.setState(
          {
            searchpage: Number(this.state.searchpage) + 1,
            // to: this.state.to + this.state.limit,
          },
          () => {
            this.handleSearch();
          }
        );
        // console.log(this.state.page)
        // this.getAdd();
      }
    } else {
      if (this.state.nextPage === true) {
        // console.log(this.state.nextPage)

        this.setState(
          {
            page: Number(this.state.page) + 1,
            // to: this.state.to + this.state.limit,
          },
          () => {
            this.getEnquiry();
          }
        );
        // console.log(this.state.page)
        // this.getEnquiry();
      }
    }
  };

  handlePrev = () => {
    if (this.state.search) {
      if (this.state.prevPage === true)
        this.setState(
          {
            searchpage: Number(this.state.searchpage) - 1,
          },

          () => {
            this.handleSearch();
          }
        );
    } else {
      if (this.state.prevPage === true)
        this.setState(
          {
            page: Number(this.state.page) - 1,
          },

          () => {
            this.getEnquiry();
          }
        );
      // this.getEnquiry();
    }
  };
  handleChangePage = (e) => {
    this.setState({
      pageinfo: e.target.value,
    });
  };
  handlePage = (e) => {
    e.preventDefault();
    if (this.state.pageinfo) {
      if (this.state.search) {
        if (
          this.state.pageinfo > 0 &&
          this.state.pageinfo <= this.state.searchTotalPage
        ) {
          // console.log(this.state.nextPage)

          this.setState(
            {
              searchpage: this.state.pageinfo,
              // to: this.state.to + this.state.limit,
            },
            () => {
              this.handleSearch();
            }
          );
          // console.log(this.state.page)
          // this.getAdd();
        }
      } else {
        if (
          this.state.pageinfo > 0 &&
          this.state.pageinfo <= this.state.totalPage
        ) {
          // console.log(this.state.nextPage)

          this.setState(
            {
              page: this.state.pageinfo,
              // to: this.state.to + this.state.limit,
            },
            () => {
              this.getEnquiry();
            }
          );
          // console.log(this.state.page)
          // this.getCategory();
        }
      }
    }
  };
  handleModal = (enquiry) => {
    // console.log(enquiry);
    this.setState(
      {
        rowId: enquiry._id,
        visible: true,
      }
      //   ,
      //   () => {
      //     console.log(this.state.rowId);
      //   }
    );
  };
  handleDelete = () => {
    this.setState({
      delLoading: true,
    });

    if (init() === "success") {
      // this.setState({ delLoading: true });
      let auth = localStorage.getItem("accessToken");
      console.log(Constants.deleteUrls.deleteEnquiry + "/" + this.state.rowId);
      axios
        .delete(Constants.deleteUrls.deleteEnquiry + "/" + this.state.rowId, {
          headers: { Authorization: `Bearer ${auth}` },
        })
        .then((res) => {
          // console.log(res);

          this.setState({
            notify: true,
            delLoading: false,
            msg: res.data.message,
          });
          setTimeout(() => {
            this.setState({ notify: false, visible: false }, () => {
              if (this.state.search) {
                this.handleSearch();
              } else {
                this.getEnquiry();
              }
            });
          }, 2000);
        })
        .catch((err) => console.log(err));
    }
  };
  handlePages = (e, value) => {
    if (this.state.search) {
      this.setState(
        {
          searchpage: value,
        },
        () => {
          this.handleSearch();
        }
      );
    } else {
      this.setState(
        {
          page: value,
        },
        () => {
          this.getEnquiry();
        }
      );
    }
  };
  handleStatus = (e, status, id) => {
    let payload;
    // console.log(id);
    let auth = localStorage.getItem("accessToken");

    if (init() === "success") {
      if (status === "active") {
        payload = {
          status: "draft",
        };
      } else {
        payload = {
          status: "active",
        };
      }
      payload = JSON.stringify(payload);
      axios
        .post(Constants.statusUrl + id + "?type=enquiries", payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth}`,
          },
        })
        .then((res) => {
          // console.log(res);
          if (res.data.message) {
            this.setState({
              notify: true,
              msg: res.data.message,
            });
          }
          if (res.data.status === "failed") {
            this.setState({
              notify: true,
              msg: "failed to update status",
            });
          }
          setTimeout(() => {
            this.setState({ notify: false }, () => {
              if (this.state.search) {
                this.handleSearch();
              } else {
                this.getEnquiry();
              }
            });
          }, 750);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  handleChange = (event) => {
    if (event.target.value.length < 1) {
      this.setState({
        searchedValue: "",
        search: false,
      });
      // this.defaultValues();
      this.getEnquiry();
    } else {
      this.setState({
        searchedValue: event.target.value,
      });
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState(
      {
        searchpage: 1,
      },
      () => {
        this.handleSearch();
      }
    );
  };
  handleSearch = (event) => {
    if (event) {
      this.setState(
        {
          searchedValue: event.target.value,
        },
        () => {
          this.handleSearch();
        }
      );
    }
    if (this.state.searchedValue < 1) {
      this.setState({
        search: false,
      });
      // this.defaultValues();
      this.getEnquiry();
    } else {
      if (this.state.searchedValue.length >= 2) {
        this.setState({
          search: true,
          page: 1,
        });
        axios
          .get(
            Constants.getUrls.getAllEnquiry +
              "?page=" +
              this.state.searchpage +
              "&limit=" +
              this.state.limit +
              "&q=" +
              this.state.searchedValue +
              "&sort=true&type=" +
              this.state.type
            // this.state.name
          )
          .then((resp) => {
            // console.log(resp);
            // let btnIdArray = [];
            // for (let i = 1; i <= resp.data.pageCount; i++) {
            //   btnIdArray.push(i);
            // }

            this.setState({
              fromInc: 1,
              active: 1,
              toInc: 5,
              getEnquiry: resp.data.enquiry.docs,
              total: resp.data.enquiry.totalDocs,
              nextPage: resp.data.enquiry.hasNextPage,
              prevPage: resp.data.enquiry.hasPrevPage,
              limit: resp.data.enquiry.limit,
              pagingCounter: resp.data.enquiry.pagingCounter,
              searchTotalPage: resp.data.enquiry.totalPages,
              pageinfo: this.state.page,
              // PAGINATION

              from: 1,
              // to: resp.data.data.length,
            });
          });
      }
    }
  };
  handleType = (e) => {
    this.setState(
      {
        type: e.target.value,
        page: 1,
        searchpage: 1,
      },
      () => {
        if (this.state.search) {
          this.handleSearch();
        } else {
          this.getEnquiry();
        }
      }
    );
  };
  handleDownload = () => {
    if (init() === "success") {
      let auth = localStorage.getItem("accessToken");
      let object = JSON.stringify({
        columns: [
          "buyer_id",
          "seller_id",
          "vehicle_id",
          "uid",
          "city",
          "otp",
          "otp_verify",
          "name",
          "email",
          "status",
          "mob_no",
          "user_type",
          "createdAt",
          "model_id",
        ],
      });
      axios
        .post(Constants.downloadUrls.downloadEnquiries, object, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth}`,
          },
        })
        .then((resp) => {
          axios
            .get("https://core.gaddideals.com" + resp.data.url, {
              responseType: "blob",
            })
            .then((res) => {
              fileDownload(res.data, "enquiry_data.csv");
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  render() {
    return (
      <Container maxwidth="xl">
        {this.state.notify ? (
          <Snackbar
            message={this.state.msg}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            autoHideDuration={6000}
            color="white"
            open="true"
          ></Snackbar>
        ) : null}
        <Grid container style={{ margin: "10px 0 10px" }} alignItems="baseline">
          <Grid item xs={6}>
            <Typography variant="h6">
              {/* {this.props.title} */}
              Enquiries
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Grid container justifyContent="flex-end">
              <TextField
                id="outlined-basic"
                label={"Search "}
                size="small"
                variant="outlined"
                onChange={this.handleSearch}
                sx={{ marginRight: "5px" }}
              />

              <Button
                variant="contained"
                onClick={() => {
                  this.handleDownload();
                }}
              >
                Download Excel
              </Button>
              {/* <Link to="/admin/addTestimonial" style={{ textDecoration: "none" }}>
                <Button
                  size="medium"
                  onClick={() => {
                    this.setState({ open: true });
                  }}
                  variant="contained"
                  color="primary"
                >
                  Add Testimonial
                </Button>
              </Link> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Paper elevation={1}>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Sr.No.</TableCell>
                    <TableCell align="center">UID</TableCell>
                    <TableCell align="right">Name</TableCell>
                    <TableCell align="center">Email</TableCell>
                    <TableCell align="right">Mob No.</TableCell>
                    <TableCell align="right">User Type</TableCell>
                    <TableCell align="right">City</TableCell>
                    <TableCell align="right">Vehicle</TableCell>
                    <TableCell align="right">User Profile Name</TableCell>
                    <TableCell align="right">Seller</TableCell>
                    <TableCell align="right">Created At</TableCell>

                    {/* <TableCell align="right">Status</TableCell> */}
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.getEnquiry.length > 0 ? (
                    this.state.getEnquiry.map((enquiry, index) => (
                      <TableRow key={enquiry._id}>
                        <TableCell>
                          {this.state.search
                            ? Number(
                                (this.state.searchpage - 1) * this.state.limit
                              ) + Number(index + 1)
                            : Number((this.state.page - 1) * this.state.limit) +
                              Number(index + 1)}
                        </TableCell>
                        <TableCell align="right">
                          {enquiry.vehicle_id.uid}
                        </TableCell>
                        <TableCell align="right">{enquiry.name}</TableCell>
                        <TableCell align="center"> {enquiry.email}</TableCell>
                        <TableCell align="right">{enquiry.mob_no}</TableCell>
                        <TableCell align="right">{enquiry.user_type}</TableCell>
                        <TableCell align="right">{enquiry.city}</TableCell>
                        <TableCell align="right">
                          {enquiry.vehicle_id
                            ? enquiry.vehicle_id.model
                              ? enquiry.vehicle_id.model.name
                              : "No Model Found"
                            : "No Vehicle Found"}
                        </TableCell>
                        <TableCell align="right">
                          {enquiry.buyer_id
                            ? enquiry.buyer_id.name +
                              "  (" +
                              enquiry.buyer_id.email +
                              ")"
                            : "No Data"}
                        </TableCell>
                        <TableCell align="right">
                          {enquiry.seller_id
                            ? enquiry.seller_id.name +
                              "  (" +
                              enquiry.seller_id.email +
                              ")"
                            : "No Data"}
                        </TableCell>

                        <TableCell align="right">
                          {enquiry.createdAt.substring(0, 10)}
                        </TableCell>

                        <TableCell align="right">
                          {/* <Link
                            to={"/admin/reply/" + enquiry._id}
                            style={{ textDecoration: "none" ,
                         width:"150x"   }}
                          >
                            <Button       sx={{width:"90px" }} variant="outlined">Reply</Button>
                          </Link> */}

                          <Button
                            variant="outlined"
                            color="error"
                            sx={{ width: "90px" }}
                            onClick={() => this.handleModal(enquiry)}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Nothing to show here
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter></TableFooter>
              </Table>
              <TableRow
                style={{ display: "flex", justifyContent: "space-evenly" }}
              >
                {/* <div>
                <p>
                  Showing {this.state.pagingCounter} to {this.state.limit} of{" "}
                  {this.state.total} Entries
                </p>
              </div> */}

                {/* Rows PerPage START */}

                <div
                  style={{
                    display: "flex",
                    alignItems: "right",
                    justifyContent: "space-between",
                    width: "200px",
                    marginTop: "10px",
                    marginLeft: "150px",
                  }}
                >
                  <p>Rows Per Page</p>

                  <FormControl
                    className="selectMerchants"
                    style={{ marginRight: "20px", marginTop: "-12px" }}
                  >
                    <InputLabel htmlFor="age-native-simple"></InputLabel>
                    <Select
                      native
                      defaultValue={10}
                      // value={state.age}
                      onChange={(e) => this.handleRows(e.target.value)}
                      inputProps={{
                        name: "Merchants",
                        id: "age-native-simple",
                      }}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                    </Select>
                  </FormControl>
                </div>
                {/* Rows PerPage END */}

                <Pagination
                  sx={{ marginTop: "auto", marginBottom: "auto" }}
                  count={
                    this.state.search
                      ? this.state.searchTotalPage
                      : this.state.totalPage
                  }
                  color="secondary"
                  page={
                    this.state.search ? this.state.searchpage : this.state.page
                  }
                  onChange={this.handlePages}
                />
              </TableRow>
            </TableContainer>
          </Paper>
        </Grid>

        <Modal
          visible={this.state.visible}
          width="450"
          height="100"
          effect="fadeInUp"
          onClickAway={() => this.setState({ visible: false })}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <h4
              style={{ color: "black", textAlign: "center", display: "block" }}
            >
              Are you sure about that???
            </h4>

            <br></br>
            <ButtonGroup>
              <Button
                className="btn"
                color="secondary"
                variant="contained"
                style={{ margin: "20px" }}
                onClick={() => {
                  this.handleDelete();
                }}
              >
                {this.state.delLoading ? "Deleting..." : "Yes"}{" "}
              </Button>
              <Button
                className="btn"
                color="primary"
                variant="contained"
                style={{ margin: "20px", marginLeft: "4px" }}
                onClick={() => {
                  this.setState({ visible: false });
                }}
              >
                NO
              </Button>
            </ButtonGroup>
          </Grid>
        </Modal>
      </Container>
    );
  }
}
